import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import { PrimaryButton } from 'components/styled/Buttons'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'pages/online/components/styled/dialog'
import { mainWhite } from 'components/styled/Theme'

const DialogCGU = ({
    open,
    cgu = {},
    onRefuse = () => {},
    onValidate = () => {},
    consult,
}) => (
    <DialogMUI
        open={open}
        maxWidth='xl'
        PaperProps={{
            sx: {
                minHeight: 'auto',
                maxHeight: 'auto',
            },
        }}
    >
        <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item>
                <h3 style={{ margin: 0, fontWeight: '600', fontSize: '20px' }} dangerouslySetInnerHTML={{ __html: cgu.title }} />
            </Grid>
            <Grid item>
                <Icon
                    sx={{ color: mainWhite, cursor: 'pointer' }}
                    size='small'
                    icon='close'
                    onClick={onRefuse}
                >
                    close
                </Icon>
            </Grid>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid container>
                <Grid item xs={12}>
                    <p
                        style={{
                            whiteSpace: 'pre-wrap',
                            fontSize: '16px',
                        }}
                        dangerouslySetInnerHTML={{ __html: cgu.comment }}
                    />
                </Grid>
            </Grid>
        </DialogContentMUI>
        {!consult && (
            <DialogActionsMUI>
                <Grid container item xs={12} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <PrimaryButton onClick={onValidate}>
                            {i18n.acceptCGU}
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        )}
    </DialogMUI>
)

DialogCGU.propTypes = {
    open: PropTypes.bool.isRequired,
    onRefuse: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    cgu: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)).isRequired,
    consult: PropTypes.bool,
}

export default DialogCGU