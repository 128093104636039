import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import SimpleTabSideList from 'pages/components/SimpleTabSideList'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import StationTab from './StationTab'
import { HOME_LIST_OF_LAYERS_OPACITY, HOME_LIST_OF_SELECTED_LAYERS, HOME_LIST_OF_SELECTED_STATIONS, HOME_SELECTED_BACKGROUND, MY_MAPS, OSM_BACKGROUND } from 'pages/home/constants/HomeConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HomeAction from 'pages/home/actions/HomeAction'
import LayerTab from './LayerTab'
import BackgroundTab from './BackgroundTab'
import OlMap from './OlMap'
import { v4 as uuidv4 } from 'uuid'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE } from 'pages/home/constants/AccessRulesConstants'
import { HYDRO, PIEZO, PLUVIO, PRODUCTION, QUALITO } from 'pages/home/constants/HabilitationConstants'
import { keys, orderBy } from 'lodash'
import { componentHasHabilitations, isAuthorized } from 'utils/HabilitationUtil'
import LayerDto from 'pages/home/dto/LayerDto'
import { EPSG3857, KML_LAYER_TYPE, WMS_LAYER_TYPE } from './MapConstant'
import TileLayer from 'ol/layer/Tile'
import { TileWMS } from 'ol/source'
import { parseNowVariableURL } from 'utils/mapUtils/UrlUtils'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { KML } from 'ol/format'
import VectorSource from 'ol/source/Vector'
import { checkStatus } from 'utils/ActionUtils'
import LogAction from 'log/actions/LogAction'
import VectorLayer from 'ol/layer/Vector'
import { removeNullKeys } from 'utils/StoreUtils'
import ActualitiesCard from 'pages/online/documents/ActualitiesCard'

const TAB_STATIONS = 1
const TAB_LAYERS = 2
const TAB_BACKGROUND = 3
const TAB_ACTUALITIES = 4

const MARGE = '0.5rem'

const ObservatoryMap = ({
    defaultSelectedStations = [],
    defaultSelectedLayers = [],
    defaultLayersOpacity = [],
    defaultSelectedBackground = OSM_BACKGROUND,

    layers = [],
}) => {
    const {
        // Station Tab
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        productionUnits,
        piezoObservatoryFollowResults,

        // Layer Tab
        cartographyThemes,
    } = useSelector(store => ({
        // Station Tab
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        productionUnits: store.HomeReducer.productionUnits,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,

        // Layer Tab
        cartographyThemes: store.HomeReducer.cartographyThemes,
    }), shallowEqual)

    const URL_SETTING = useApplicationSetting(MY_MAPS)

    const layersByThemes = useRef([])
    const layersName = useRef([])
    const formattedLayers = useRef([])

    const [selectedStations, setSelectedStations] = useState(defaultSelectedStations)
    const [selectedLayers, setSelectedLayers] = useState(defaultSelectedLayers)
    const [layersOpacity, setLayersOpacity] = useState(defaultLayersOpacity)
    const [selectedBackground, setSelectedBackground] = useState(defaultSelectedBackground)

    const [dataFormatted, setDataFormatted] = useState(false)

    const dispatch = useDispatch()

    const tabs = [{
        icon: 'format_list_bulleted',
        constant: TAB_STATIONS,
        label: i18n.stations,
    }, {
        icon: 'layers',
        constant: TAB_LAYERS,
        label: i18n.mapLayers,
    }, {
        icon: 'insert_photo',
        constant: TAB_BACKGROUND,
        label: i18n.backgroundLayers,
    }, {
        icon: 'event',
        constant: TAB_ACTUALITIES,
        label: i18n.actualities,
    }]

    // Station Tab
    const stationLength = piezometersLight.length + hydrometers.length + pluviometers.length + qualitometersLight.length + productionUnits.length
    const moduleByType = useMemo(() => {
        const catchments = piezoObservatoryFollowResults.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment)
        return {
            [OBSERVATORY_STATION_TYPE_NAME.catchment]: {
                habs: [H_PIEZO_MODULE, H_PRODUCTION_MODULE],
                authorized: PIEZO,
                length: catchments.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.CATCHMENT,
            },
            [STATION_TYPE_NAME.piezometer]: {
                habs: [H_PIEZO_MODULE],
                authorized: PIEZO,
                length: piezometersLight.length - catchments.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.PIEZOMETER,
            },
            [STATION_TYPE_NAME.hydrometricStation]: {
                habs: [H_HYDRO_MODULE],
                authorized: HYDRO,
                length: hydrometers.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.HYDROMETRIC_STATION,
            },
            [STATION_TYPE_NAME.pluviometer]: {
                habs: [H_PLUVIO_MODULE],
                authorized: PLUVIO,
                length: pluviometers.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.PLUVIOMETER,
            },
            [STATION_TYPE_NAME.qualitometer]: {
                habs: [H_QUALITO_MODULE],
                authorized: QUALITO,
                length: qualitometersLight.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.QUALITOMETER,
            },
            [STATION_TYPE_NAME.productionUnit]: {
                habs: [H_PRODUCTION_MODULE],
                authorized: PRODUCTION,
                length: productionUnits.length,
                obsStationType: OBSERVATORY_STATION_TYPE_NAME.PRODUCTION_UNIT,
            },
        }
    }, [hydrometers.length, piezoObservatoryFollowResults, piezometersLight.length, pluviometers.length, productionUnits.length, qualitometersLight.length])
    const stationsTypes = useMemo(() => keys(moduleByType).filter(key => !moduleByType[key].habs.filter(hab => !componentHasHabilitations(hab)).length && isAuthorized(moduleByType[key].authorized)), [moduleByType])

    useEffect(() => {
        layersByThemes.current = cartographyThemes.map(t => ({
            ...t,
            layers: orderBy(layers, 'name').filter(layer => layer.theme === t.id),
        }))

        layersName.current = layers.map(({ layer }) => layer)

        formattedLayers.current = layers.map(ml => {
            const layerVisibility = selectedLayers.includes(`${ml.id}`)
            const layerOpacity = selectedLayers.includes(`${ml.id}`) ? (layersOpacity.find(o => o.layerName === ml.layer)?.opacity ?? 1) : 0
            if (parseInt(ml.typeLayer) === WMS_LAYER_TYPE) {
                return new TileLayer({
                    source: new TileWMS({
                        url: parseNowVariableURL(ml.url, URL_SETTING),
                        projection: ml.projection || EPSG3857,
                        params: { LAYERS: ml.layer, TILED: true },
                        serverType: 'geoserver',
                    }),
                    id: ml.id,
                    name: ml.name,
                    layerName: ml.layer,
                    opacity: layerOpacity,
                    visible: layerVisibility,
                    zIndex: 2,
                })
            } else if (parseInt(ml.typeLayer) === KML_LAYER_TYPE) {
                const color = ml.color || false
                const kmlFormatWater = new KML({
                    extractStyles: !color || false,
                })
                const vct = new VectorSource({
                    loader () {
                        fetch(parseNowVariableURL(ml.url, URL_SETTING), {
                            headers: { Accept: 'application/vnd.google-earth.kml+xml' },
                            method: 'GET',
                        })
                            .then(response => checkStatus({
                                200: ((rep) => rep.text()),
                                204: (() => []),
                            }, response))
                            .then((response = []) => {
                                const feature = kmlFormatWater.readFeatures(response, { featureProjection: EPSG3857 })
                                feature.map(f => f.setId(uuidv4()))
                                vct.addFeatures(feature)
                            }).catch(err => dispatch(LogAction.logError(err.stack)))
                    },
                    format: kmlFormatWater,
                })
                return new VectorLayer(removeNullKeys({
                    source: vct,
                    id: ml.id,
                    name: ml.name,
                    layerName: ml.layer,
                    zIndex: 2,
                    opacity: layerOpacity,
                    visible: layerVisibility,
                }))
            }
            return null
        }).filter(l => !!l)

        setDataFormatted(true)
    }, [])

    return !!dataFormatted && (
        <Grid container item xs={12} alignItems='stretch'>
            <SimpleTabSideList
                position='right'
                defaultTab={TAB_ACTUALITIES}
                isOpenByDefault
                tabs={tabs}
            >
                {tab => (
                    <>
                        {tab === TAB_STATIONS && (
                            <StationTab
                                selectedStations={selectedStations}
                                setSelectedStations={newSelectedStations => {
                                    setSelectedStations(newSelectedStations)
                                    dispatch(HomeAction.updateSetting({ settingName: HOME_LIST_OF_SELECTED_STATIONS, value: newSelectedStations.join(','), refreshData: true }))
                                }}

                                stationLength={stationLength}
                                moduleByType={moduleByType}
                                stationsTypes={stationsTypes}

                                marge={MARGE}
                            />
                        )}
                        {tab === TAB_LAYERS && (
                            <LayerTab
                                selectedLayers={selectedLayers}
                                setSelectedLayers={newLayers => {
                                    setSelectedLayers(newLayers)
                                    dispatch(HomeAction.updateSetting({ settingName: HOME_LIST_OF_SELECTED_LAYERS, value: newLayers.join(',') }))
                                }}
                                layersOpacity={layersOpacity}
                                setLayersOpacity={setLayersOpacity}
                                setLayersOpacityCommitted={newLayersOpacity => {
                                    setLayersOpacity(newLayersOpacity)
                                    dispatch(HomeAction.updateSetting({ settingName: HOME_LIST_OF_LAYERS_OPACITY, value: JSON.stringify(newLayersOpacity) }))
                                }}

                                layers={layers}
                                layersByThemes={layersByThemes.current}

                                marge={MARGE}
                            />
                        )}
                        {tab === TAB_BACKGROUND && (
                            <BackgroundTab
                                selectedBackground={selectedBackground}
                                setSelectedBackground={newSelectedBackground => {
                                    setSelectedBackground(newSelectedBackground)
                                    dispatch(HomeAction.updateSetting({ settingName: HOME_SELECTED_BACKGROUND, value: newSelectedBackground }))
                                }}

                                marge={MARGE}
                            />
                        )}
                        {tab === TAB_ACTUALITIES && (
                            <ActualitiesCard height='100%' />
                        )}
                    </>
                )}
            </SimpleTabSideList>
            <OlMap
                selectedStations={selectedStations}
                selectedLayers={selectedLayers}
                selectedBackground={selectedBackground}
                layersOpacity={layersOpacity}

                layersName={layersName.current}
                formattedLayers={formattedLayers.current}
            />
        </Grid>
    )
}

ObservatoryMap.propTypes = {
    defaultSelectedStations: PropTypes.arrayOf(PropTypes.string),
    defaultSelectedLayers: PropTypes.arrayOf(PropTypes.string),
    defaultLayersOpacity: PropTypes.arrayOf(PropTypes.string),
    defaultSelectedBackground: PropTypes.string,

    layers: PropTypes.arrayOf(PropTypes.instanceOf(LayerDto)),
}

export default ObservatoryMap