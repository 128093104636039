import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid, Icon } from '@mui/material'
import { getLogin } from 'utils/LocalStorageUtils'
import DtoContact from 'pages/dto/DtoContact'
import ToastrAction from 'toastr/actions/ToastrAction'
import { isStringNumber, isValidEmail } from 'utils/FormUtils'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import DialogUpdatePassword from './components/DialogUpdatePassword'
import HomeAction from 'pages/home/actions/HomeAction'
import AccountAction from './actions/AccountAction'
import ContactAction from '../contact/actions/ContactAction'
import DtoUser from './dto/DtoUser'
import DtoApplicationSettings from 'pages/home/dto/DtoApplicationSettings'
import { styled } from '@mui/styles'
import DialogCGU from 'pages/home/components/DialogCGU'
import SupportCard from './components/SupportCard'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { getFullDate } from 'utils/DateUtil'
import ProgressBar from 'components/progress/ProgressBar'
import { isNumber } from 'lodash'
import { textBlue } from 'components/styled/Theme'

const Label = styled('h4')({
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: 0,
    color: textBlue,
})

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModal: false,
            isUnvalidEmail: false,
            contact: {},
            name: '',
            phone: '',
            email: '',
            dateValidCgu: '',
            mdp: '',
            newMdp: '',
            mdpConfirmation: '',
            openDialogCGU: false,
        }
    }

    componentDidMount() {
        const { applicationSettings } = this.props
        this.props.setTitle([
            {
                title: i18n.myAccount,
                href: '/account',
            },
        ])
        this.getContact()
        this.getDateCgu()
        if (!applicationSettings.length) {
            this.props.fetchApplicationSettings().then(() => this.props.getAllCGU())
        } else {
            this.props.getAllCGU()
        }
    }

    handleChangeValue = (value) => {
        this.setState({ ...value })
    }

    getContact = () => {
        const login = getLogin()
        this.props.fetchUser(login).then(() => {
            const { accountUser } = this.props
            if (accountUser.contactCode) {
                this.props.fetchContact(accountUser.contactCode).then(() => {
                    const { contact } = this.props
                    this.setState({
                        dataLoaded: true,
                        contact: contact.id ? contact : new DtoContact({}),
                        name: contact.name || '',
                        phone: contact.phoneTel || '',
                        email: contact.email || '',
                    })
                })
            } else {
                this.createContact(login)
            }
        })
    }

    createContact = (login) => {
        const { accountUser } = this.props
        this.props.createContact({ name: login }).then((contactCode) => {
            this.props.updateUser({ user: { ...accountUser, contactCode, contactName: login } })
            this.props.fetchContact(contactCode).then(() => {
                const { contact } = this.props
                this.setState({
                    dataLoaded: true,
                    contact: contact.id ? contact : new DtoContact({}),
                    name: contact.name || '',
                    phone: contact.phoneTel || '',
                    email: contact.email || '',
                })
            })
        })
    }

    getDateCgu = () => {
        const login = getLogin()
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu } = this.props
            this.setState({ dateValidCgu })
        })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSavePassword = () => {
        const { newMdp, mdpConfirmation, mdp } = this.state
        const { applicationSettings } = this.props
        const login = getLogin()
        if (mdp) {
            this.props.login({ login, password: mdp }).then(() => {
                if (
                    newMdp !== null &&
                    newMdp.length &&
                    mdpConfirmation !== null &&
                    mdpConfirmation.length
                ) {
                    if (newMdp === mdpConfirmation) {
                        const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                        if (regex.test(newMdp)) {
                            if (newMdp !== login) {
                                const newMdpEncoding = btoa(unescape(encodeURIComponent(newMdp)))
                                const password = { password: newMdpEncoding }
                                this.props.changePassword(password)
                                this.toggleModal()
                            } else {
                                ToastrAction.error('Le mot de passe doit être différent de celui qui vous a été fourni', true)
                            }
                        } else {
                            ToastrAction.error(regexHelp, true)
                        }
                    } else {
                        ToastrAction.error('Les mots de passe ne sont pas identiques', true)
                    }
                }
            })
        }
    }

    onSubmit = () => {
        const { contact, name, phone, email } = this.state
        const newContact = { ...contact, name, phoneTel: phone, email }
        if (isValidEmail(email)) {
            this.setState({ isUnvalidEmail: false })
            this.props.updateContact(newContact).then(() => {
                this.getContact()
            })
        } else {
            this.setState({ isUnvalidEmail: true })
        }
    }

    onSubmitPassword = () => {
        this.toggleModal()
    }

    render() {
        const { dataLoaded, openModal, dateValidCgu, name, phone, email, isUnvalidEmail, openDialogCGU } = this.state
        const { cgu } = this.props
        const login = getLogin()

        return (
            <Grid
                sx={{ padding: {
                    lg: '0px 50px',
                    xs: '0px 30px',
                } }}
            >
                {dataLoaded ? (
                    <Grid container>
                        <Grid
                            item xs={12} md={8.5}
                            container
                            direction='column'
                            justifyContent='flex-start'
                            sx={{
                                padding: '8px 30px 8px 8px',
                                backgroundColor: 'white',
                            }}
                        >
                            <Grid sx={{ paddingBottom: '7px', borderBottom: '2px solid #e9e9e9' }}>
                                <Label>{i18n.myAccount} : {login}</Label>
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{ padding: '0px 30px' }}
                            >
                                <Grid item xs={12} className='noPadding'>
                                    <Label style={{ marginLeft: '20px' }}>{i18n.myName}</Label>
                                    <InputRow
                                        id='name'
                                        value={name}
                                        onChange={(event) =>
                                            this.handleChangeValue({ name: event.target.value })
                                        }
                                        placeholder={i18n.myName}
                                        variant='outlined'
                                        inputProps={{
                                            maxLength: 115,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className='noPadding'>
                                    <Label style={{ marginLeft: '20px' }}>{i18n.myPhone}</Label>
                                    <InputRow
                                        id='phoneTel'
                                        value={phone}
                                        onChange={event => {
                                            const value = event.target.value
                                            if (value.length < phone.length || isStringNumber(value)) {
                                                this.handleChangeValue({ phone: value })
                                            }
                                        }}
                                        variant='outlined'
                                        placeholder='00 00 00 00 00'
                                        inputProps={{
                                            maxLength: 20,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className='noPadding'>
                                    <Label style={{ marginLeft: '20px' }}>{i18n.myMail}</Label>
                                    <InputRow
                                        id='email'
                                        value={email}
                                        onChange={(event) =>
                                            this.handleChangeValue({ email: event.target.value })
                                        }
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        variant='outlined'
                                        placeholder={i18n.email}
                                        error={isUnvalidEmail}
                                        helperText={isUnvalidEmail ? i18n.emailFormatNotRespected : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} className='noPadding margin-top-2'>
                                    <PrimaryButton
                                        onClick={this.onSubmit}
                                    >
                                        {i18n.register}
                                    </PrimaryButton>
                                </Grid>
                                <hr style={{ width: '100%', backgroundColor: 'black' }} />
                                <Grid item xs={12} className='noPadding margin-top-2'>
                                    <PrimaryButton
                                        onClick={this.onSubmitPassword}
                                    >
                                        {i18n.passwordReset}
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                            <hr style={{ width: '100%', backgroundColor: 'black' }} />
                            <Grid
                                container
                                justifyContent='space-between'
                                sx={{
                                    backgroundColor: 'white',
                                }}
                            >
                                <Grid item className='clickable' onClick={() => this.setState({ openDialogCGU: true })}>
                                    <Label style={{ marginBlock: '0px' }}>
                                        {dateValidCgu && isNumber(dateValidCgu) ? `${i18n.CGUvalidDate} ${getFullDate(dateValidCgu)}` : i18n.lastAcceptUnknown}
                                    </Label>
                                    {i18n.consultCGU}
                                </Grid>
                                <Icon sx={{ fontSize: '60px' }} className='clickable' onClick={() => this.setState({ openDialogCGU: true })}>description</Icon>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3.5} sx={{ marginTop: '30px' }}>
                            <SupportCard height='auto' />
                        </Grid>
                    </Grid>
                ) : <ProgressBar indeterminate />}
                <DialogUpdatePassword
                    open={openModal}
                    toggleModal={this.toggleModal}
                    handleChangeMdp={(e) =>
                        this.handleChangeValue({ mdp: e.target.value })
                    }
                    handleChangeNewMdp={(e) =>
                        this.handleChangeValue({ newMdp: e.target.value })
                    }
                    handleChangeConfirmation={(e) =>
                        this.handleChangeValue({ mdpConfirmation: e.target.value })
                    }
                    onSavePassword={this.onSavePassword}
                />
                {openDialogCGU && (
                    <DialogCGU
                        open={openDialogCGU}
                        cgu={cgu}
                        onRefuse={() => this.setState({ openDialogCGU: false })}
                        consult
                    />
                )}
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchUser: AccountAction.fetchUser,
    updateUser: AccountAction.updateUser,
    fetchContact: ContactAction.fetchContact,
    updateContact: ContactAction.updateContact,
    createContact: ContactAction.createContact,
    getDateValidCGU: HomeAction.getDateValidCGU,
    changePassword: AccountAction.changePassword,
    getAllCGU: HomeAction.getAllCGU,
    login: HomeAction.login,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    push,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        contact: store.ContactReducer.contact,
        dateValidCgu: store.HomeReducer.dateValidCgu,
        applicationSettings: store.HomeReducer.applicationSettings,
        cgu: store.HomeReducer.cgu,
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

MyAccount.propTypes = {
    setTitle: PropTypes.func,
    fetchUser: PropTypes.func,
    updateUser: PropTypes.func,
    fetchContact: PropTypes.func,
    updateContact: PropTypes.func,
    createContact: PropTypes.func,
    getDateValidCGU: PropTypes.func,
    changePassword: PropTypes.func,
    getAllCGU: PropTypes.func,
    login: PropTypes.func,
    accountUser: PropTypes.instanceOf(DtoUser),
    contact: PropTypes.instanceOf(DtoContact),
    cgu: PropTypes.instanceOf(PropTypes.shape({})),
    dateValidCgu: PropTypes.string,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoApplicationSettings)),
    push: PropTypes.func,
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    fetchApplicationSettings: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
