import moment from 'moment'
import ApplicationConf from 'conf/ApplicationConf'
import { hasValue } from 'utils/NumberUtil'
import { PICTURE } from '../constants/FileConstants'

export default class DtoPicture {
    constructor(obj, detailledName = true) {
        const pointIndex = obj.name.lastIndexOf('.')
        const sliced = obj.name.slice(obj?.stationCode?.length + 1, pointIndex).split('_')
        const [,, shortNameOld, dateOld] = sliced
        const [type, active, date, stationTypeOrShortName, shortName] = sliced
        const [stationCode] = obj.name.slice(0, pointIndex).split('_')

        const objDate = !detailledName ? (obj.updateDate || obj.date) : date

        this.code = obj.code
        this.name = obj.name
        this.isAllDataName = sliced.length === 4 || sliced.length === 5
        this.url = ApplicationConf.file.pictureCompressPath(obj.name)
        this.stationCode = obj.stationCode || stationCode
        this.type = type
        this.active = active || '0'
        this.shortName = new RegExp('^[0-9]+-[0-9]+$').test(date) ? (shortName || stationTypeOrShortName) : shortNameOld
        this.date = !detailledName ? objDate : new RegExp('^[0-9]+-[0-9]+$').test(objDate) ? moment(objDate, 'YYYYMMDD-HHmmss').valueOf() : parseInt(dateOld)
        this.fileType = PICTURE
        this.stationType = shortName ? stationTypeOrShortName : null
        this.shared = !hasValue(shortName)
        this.size = obj.size
        this.isDirectory = obj.isDirectory
    }
}