/* eslint-disable no-process-env */
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import logger from 'redux-logger'
import HomeReducer, { initialState as HomeReducerStore } from '../pages/home/reducers/HomeReducer'
import AccountReducer, { initialState as AccountReducerStore } from '../pages/account/reducers/AccountReducer'
import ContactReducer, { initialState as ContactReducerStore } from '../pages/contact/reducers/ContactReducer'
import CmsReducer, { initialState as CmsReducerStore } from '../pages/cms/reducers/CmsReducer'
import DataManagerReducer, { initialState as DataManagerReducerStore } from '../store/DataManagerReducer'
import FollowReducer, { initialState as FollowReducerStore } from '../pages/online/follows/reducers/FollowReducer'
import ResourcesReducer, { initialState as ResourcesReducerStore } from '../pages/online/resources/reducers/ResourcesReducer'
import ReferencialReducer, { initialState as ReferencialReducerStore } from 'referencial/reducers/ReferencialReducer'
import QualityReducer, { initialState as QualityReducerStore } from 'quality/reducers/QualityReducer'

export const history = createHashHistory()

const initialStore = {
    HomeReducer: HomeReducerStore,
    AccountReducer: AccountReducerStore,
    ContactReducer: ContactReducerStore,
    CmsReducer: CmsReducerStore,
    DataManagerReducer: DataManagerReducerStore,
    FollowReducer: FollowReducerStore,
    ResourcesReducer: ResourcesReducerStore,
    ReferencialReducer: ReferencialReducerStore,
    QualityReducer: QualityReducerStore,
}

const appReducers = combineReducers({
    router: connectRouter(history),
    HomeReducer,
    AccountReducer,
    ContactReducer,
    CmsReducer,
    DataManagerReducer,
    FollowReducer,
    ResourcesReducer,
    ReferencialReducer,
    QualityReducer,
})

const RootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.HomeReducer
        const homeReducer = {
            ...initialStore.HomeReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            HomeReducer: homeReducer,
        }
    }
    return appReducers(state, action)
}

// Make enchancers if app need offline reducer as: enhancers: (defaultEnhancers) => [offline, ...defaultEnhancers] and the result in a final setup is: [offline, applyMiddleware, devToolsExtension]
const loggerMiddleware = process.env.NODE_ENV === 'development' ? [logger] : []

const AppStore = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        ...loggerMiddleware,
        routerMiddleware(history),
    ],
    preloadedState: initialStore,
})

export default AppStore
