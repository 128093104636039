import { createSlice } from '@reduxjs/toolkit'
import FollowAction from '../actions/FollowAction'
import DtoObservatoryFollowResult from '../dto/DtoObservatoryFollowResult'
import WatermassItemDto from '../dto/WatermassItemDto'
import DtoEvent from '../dto/DtoEvent'
import DtoMeasureStats from '../dto/DtoMeasureStats'
import DtoPiezoThreshold from '../dto/DtoPiezoThreshold'
import DtoHydrometryThreshold from '../dto/DtoHydrometryThreshold'
import DtoHydroStats from '../dto/DtoHydroStats'
import PluviometerThresholdDto from '../dto/PluviometerThresholdDto'
import DtoPluviometerStats from '../dto/DtoPluviometerStats'
import DtoOperation from '../dto/DtoOperationHydrobio'
import DtoQualification from '../dto/DtoQualification'
import DtoStatus from '../dto/DtoStatus'
import ParameterDto from '../dto/ParameterDto'
import UnitDto from '../dto/UnitDto'
import DtoInstallationPiezoMeasures from '../dto/DtoInstallationPiezoMeasures'
import DtoParametrageDataType from '../dto/DtoParametrageDataType'
import DtoProductionUnitLinkedPiezo from '../dto/DtoProductionUnitLinkedPiezo'
import DtoDataStation from '../dto/DtoDataStation'
import { flatten } from 'lodash'

export const initialState = {
    piezoObservatoryFollowResults: [],
    hydroObservatoryFollowResults: [],
    pluvioObservatoryFollowResults: [],
    qualitoObservatoryFollowResults: [],
    watermasses: [],
    stationEvents: [],
    piezoMeasuresStats: [],
    piezoThresholds: [],
    hydrometryThresholds: [],
    hydroStatistics: [],
    pluviometerAllThresholds: [],
    piezometerAllThresholds: [],
    pluviometerStatistics: [],
    operations: [],
    qualifications: [],
    status: [],
    parameters: [],
    units: [],
    linkedPiezoMeasures: [],
    piezometryDataTypes: [],
    hydrometryDataTypes: [],
    pluviometryDataTypes: [],
    linkedPiezoPrel: [],
    linkedPiezosPrel: [],
    piezoLastMeasures: [],
    hydroLastMeasures: [],
    pluvioLastMeasures: [],
}

const store = createSlice({
    name: 'Follow',
    initialState,
    reducers: {
        reset: (state) => {
            state.piezoObservatoryFollowResults = []
            state.hydroObservatoryFollowResults = []
            state.pluvioObservatoryFollowResults = []
            state.qualitoObservatoryFollowResults = []
            state.watermasses = []
            state.stationEvents = []
            state.piezoMeasuresStats = []
            state.piezoThresholds = []
            state.hydrometryThresholds = []
            state.hydroStatistics = []
            state.pluviometerAllThresholds = []
            state.piezometerAllThresholds = []
            state.pluviometerStatistics = []
            state.operations = []
            state.qualifications = []
            state.status = []
            state.parameters = []
            state.units = []
            state.linkedPiezoMeasures = []
            state.piezometryDataTypes = []
            state.hydrometryDataTypes = []
            state.pluviometryDataTypes = []
            state.linkedPiezoPrel = []
            state.linkedPiezosPrel = []
            state.piezoLastMeasures = []
            state.hydroLastMeasures = []
            state.pluvioLastMeasures = []
        },
    },
    extraReducers: {
        [FollowAction.fetchpiezoObservatoryFollowResults.fulfilled]: (state, action) => {
            state.piezoObservatoryFollowResults = action.payload.map((s) => new DtoObservatoryFollowResult(s))
        },
        [FollowAction.fetchhydroObservatoryFollowResults.fulfilled]: (state, action) => {
            state.hydroObservatoryFollowResults = action.payload.map((s) => new DtoObservatoryFollowResult(s))
        },
        [FollowAction.fetchpluvioObservatoryFollowResults.fulfilled]: (state, action) => {
            state.pluvioObservatoryFollowResults = action.payload.map((s) => new DtoObservatoryFollowResult(s))
        },
        [FollowAction.fetchqualitoObservatoryFollowResults.fulfilled]: (state, action) => {
            state.qualitoObservatoryFollowResults = action.payload.map((s) => new DtoObservatoryFollowResult(s))
        },
        [FollowAction.fetchWatermasses.fulfilled]: (state, action) => {
            state.watermasses = action.payload.map((s) => new WatermassItemDto(s))
        },
        [FollowAction.fetchStationsEvents.fulfilled]: (state, action) => {
            state.stationEvents = action.payload.map((s) => new DtoEvent(s))
        },
        [FollowAction.fetchPiezoMeasuresStats.fulfilled]: (state, action) => {
            state.piezoMeasuresStats = action.payload.map((s) => new DtoMeasureStats(s))
        },
        [FollowAction.fetchPiezometerThresholds.fulfilled]: (state, action) => {
            state.piezoThresholds = action.payload.map((s) => new DtoPiezoThreshold(s))
        },
        [FollowAction.fetchHydrometricThresholds.fulfilled]: (state, action) => {
            state.hydrometryThresholds = action.payload.map((s) => new DtoHydrometryThreshold(s))
        },
        [FollowAction.fetchHydroStatistics.fulfilled]: (state, action) => {
            state.hydroStatistics = action.payload.map((s) => new DtoHydroStats(s))
        },
        [FollowAction.fetchPluviometerAllThresholds.fulfilled]: (state, action) => {
            state.pluviometerAllThresholds = action.payload.map((s) => new PluviometerThresholdDto(s))
        },
        [FollowAction.fetchPiezometerAllThresholds.fulfilled]: (state, action) => {
            state.piezometerAllThresholds = action.payload.map((s) => new DtoPiezoThreshold(s))
        },
        [FollowAction.fetchPluvioStatistics.fulfilled]: (state, action) => {
            state.pluviometerStatistics = action.payload.map((s) => new DtoPluviometerStats(s))
        },
        [FollowAction.fetchQualitometerOperations.fulfilled]: (state, action) => {
            state.operations = action.payload.map((s) => new DtoOperation(s))
        },
        [FollowAction.fetchQualifications.fulfilled]: (state, action) => {
            state.qualifications = action.payload.map((s) => new DtoQualification(s))
        },
        [FollowAction.fetchStatus.fulfilled]: (state, action) => {
            state.status = action.payload.map((s) => new DtoStatus(s))
        },
        [FollowAction.fetchParameters.fulfilled]: (state, action) => {
            state.parameters = action.payload.map((s) => new ParameterDto(s))
        },
        [FollowAction.fetchUnits.fulfilled]: (state, action) => {
            state.units = action.payload.map((s) => new UnitDto(s))
        },
        [FollowAction.fetchLinkedPiezoMeasures.fulfilled]: (state, action) => {
            state.linkedPiezoMeasures = action.payload.map((s) => new DtoInstallationPiezoMeasures(s))
        },
        [FollowAction.fetchPiezometryDataTypes.fulfilled]: (state, action) => {
            state.piezometryDataTypes = action.payload.map((s) => new DtoParametrageDataType(s))
        },
        [FollowAction.fetchHydrometryDataTypes.fulfilled]: (state, action) => {
            state.hydrometryDataTypes = action.payload.map((s) => new DtoParametrageDataType(s))
        },
        [FollowAction.fetchPluviometryDataTypes.fulfilled]: (state, action) => {
            state.pluviometryDataTypes = action.payload.map((s) => new DtoParametrageDataType(s))
        },
        [FollowAction.fetchLinkedPiezoPrel.fulfilled]: (state, action) => {
            state.linkedPiezoPrel = action.payload.map((s) => new DtoProductionUnitLinkedPiezo(s))
        },
        [FollowAction.fetchLinkedPiezosPrel.fulfilled]: (state, action) => {
            state.linkedPiezosPrel = action.payload
        },
        [FollowAction.fetchPiezoLastMeasures.fulfilled]: (state, action) => {
            state.piezoLastMeasures = action.payload.map(d => new DtoDataStation(d))
        },
        [FollowAction.fetchAllHydroLastMeasures.fulfilled]: (state, action) => {
            state.hydroLastMeasures = flatten(action.payload.map(ac => ac.payload)).map(d => new DtoDataStation(d))
        },
        [FollowAction.fetchPluvioLastMeasures.fulfilled]: (state, action) => {
            state.pluvioLastMeasures = action.payload.map(d => new DtoDataStation(d))
        },
    },
})

export const FollowActionConstant = store.actions
export default store.reducer