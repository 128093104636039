import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import { IDLE } from 'store/DataManagerConstants'
import { genericPromise2 } from 'utils/ActionUtils'

const ReferencialThunk = {
    fetchParameters: createAsyncThunk(
        'fetchParameters',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.parameters(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { parametersStatus } = state.DataManagerReducer.referencial
                return parametersStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchSupports: createAsyncThunk(
        'fetchSupports',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.supports(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { supportsStatus } = state.DataManagerReducer.referencial
                return supportsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchUnits: createAsyncThunk(
        'fetchUnits',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.units(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { unitsStatus } = state.DataManagerReducer.referencial
                return unitsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchFractions: createAsyncThunk(
        'fetchFractions',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.fractions(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { fractionsStatus } = state.DataManagerReducer.referencial
                return fractionsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchSandreCodes: createAsyncThunk(
        'fetchSandreCodes',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.sandreCodes(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { sandreCodesStatus } = state.DataManagerReducer.referencial
                return sandreCodesStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchContributors: createAsyncThunk(
        'fetchContributors',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.contributor(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { contributorsStatus } = state.DataManagerReducer.referencial
                return contributorsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchCities: createAsyncThunk(
        'fetchCities',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.referencial.cities(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { citiesStatus } = state.DataManagerReducer.referencial
                return citiesStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default ReferencialThunk