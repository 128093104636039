import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import { IDLE } from 'store/DataManagerConstants'
import { genericPromise2 } from 'utils/ActionUtils'

const QualityThunk = {
    fetchQualitometers: createAsyncThunk(
        'fetchQualitometers',
        async(_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.quality.qualitometers(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { qualitometersStatus } = state.DataManagerReducer.quality
                return qualitometersStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchThresholds: createAsyncThunk(
        'fetchThresholds',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.quality.thresholds(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { thresholdsStatus } = state.DataManagerReducer.quality
                return thresholdsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchRemarks: createAsyncThunk(
        'fetchRemarks',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.quality.remark(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { remarksStatus } = state.DataManagerReducer.quality
                return remarksStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchStatus: createAsyncThunk(
        'fetchStatus',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.quality.status(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { statusStatus } = state.DataManagerReducer.quality
                return statusStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchQualifications: createAsyncThunk(
        'fetchQualifications',
        async (_, { rejectWithValue, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.quality.qualification(), { signal })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { qualificationsStatus } = state.DataManagerReducer.quality
                return qualificationsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default QualityThunk