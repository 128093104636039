import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Icon from 'components/Icon'
import { mainBlack, SMALL_RADIUS } from './Theme'

export const AccordionMUI = ({ children, sx, ...otherProps }) => (
    <Accordion
        disableGutters
        slotProps={{ transition: { unmountOnExit: !otherProps.noUnmount, timeout: 250 } }}
        sx={{
            borderRadius: SMALL_RADIUS,
            boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)',
            margin: 0,
            ...sx,
        }}
        {...otherProps}
    >
        {children}
    </Accordion>
)

AccordionMUI.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.shape({}),
}

export const AccordionSummaryMUI = ({ children, sx, iconColor, ...otherProps }) => (
    <AccordionSummary
        expandIcon={(
            <Icon
                size='small'
                sx={{
                    borderRadius: '50rem',
                    border: `solid ${iconColor || 'white'} 2px`,
                    color: iconColor || 'white',
                }}
            >
                {sx?.icon || 'expand_more'}
            </Icon>
        )}
        sx={{
            color: 'white',
            background: otherProps.color || mainBlack,
            borderBottom: 'solid 1px grey',
            minHeight: '3rem',
            height: '3rem',
            fontSize: '0.938rem',
            fontWeight: '600',
            borderRadius: SMALL_RADIUS,
            ...sx,
        }}
        {...otherProps}
    >
        {children}
    </AccordionSummary>
)

AccordionSummaryMUI.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.shape({
        icon: PropTypes.string,
    }),
    iconColor: PropTypes.string,
}

export const AccordionDetailsMUI = ({ children, sx, ...otherProps }) => (
    <AccordionDetails
        sx={{
            borderRadius: `0 0 ${SMALL_RADIUS} ${SMALL_RADIUS}`,
            padding: otherProps.nopadding ? 0 : 'auto',
            ...sx,
        }}
        {...otherProps}
    >
        {children}
    </AccordionDetails>
)

AccordionDetailsMUI.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.shape({
        icon: PropTypes.string,
    }),
}