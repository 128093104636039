import { Icon, Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

const Other = ({
    icon = '',
    tooltip = '',
    onClick = () => { },
    fontSize = '28px',
    color = 'white',
}) => {
    return (
        <Tooltip title={tooltip}>
            <Icon sx={{ color, fontSize, cursor: 'pointer' }} onClick={onClick}>{icon}</Icon>
        </Tooltip>
    )
}

Other.propTypes = {
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
}

const Download = ({
    onClick = () => { },
    fontSize = '28px',
    color = 'white',
}) => {
    return (
        <Tooltip title='Download'>
            <FileDownloadIcon sx={{ color, fontSize, cursor: 'pointer' }} onClick={onClick} />
        </Tooltip>
    )
}

Download.propTypes = {
    onClick: PropTypes.func,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
}

export {
    Download,
    Other,
}