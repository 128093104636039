import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { Grid, useMediaQuery } from '@mui/material'
import HomeAction from 'pages/home/actions/HomeAction'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { orderBy, uniqWith } from 'lodash'
import ProgressBar from 'components/progress/ProgressBar'
import FollowAction from '../follows/actions/FollowAction'
import useStateProgress from 'utils/customHook/useStateProgress'
import useTitle from 'utils/customHook/useTitle'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { mainBlack, mainGrey, SMALL_RADIUS } from 'components/styled/Theme'
import Option from 'components/Option'
import ResourcesAction from '../resources/actions/ResourcesAction'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_DISTRIBUTION_MODULE } from 'pages/home/constants/AccessRulesConstants'
import { MainButton } from '../components/styled/buttons'
import MultiContributorsAutocomplete from 'components/MultiContributorsAutocomplete'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { filterObsLinkedStations } from 'utils/StationUtils'
import UnitCard from '../units/UnitCard'
import { mainBlue } from 'components/styled/Theme'
import SuperMultiAutocomplete from 'components/SuperMultiAutocomplete'
import useListIndexed from 'utils/customHook/useListIndexed'

const NO_INDICATOR = -1
const NORMAL = 0
const VIGILANCE = 1
const CRISIS = 2
const NO_DATA = 3

const FilterPanel = ({
    defaultExploited,
    defaultNotExploited,
    defaultCities,
    defaultAdmin,
    defaultOperator,

    setExploited = () => {},
    setNotExploited = () => {},
    setCities = () => {},
    setAdmin = () => {},
    setOperator = () => {},
}) => {
    const {
        cities,
        contributors,
    } = useSelector(store => ({
        cities: store.ReferencialReducer.cities,
        contributors: store.HomeReducer.contributors,
    }), shallowEqual)

    const [tmpExploited, setTmpExploited] = useState(defaultExploited || true)
    const [tmpNotExploited, setTmpNotExploited] = useState(defaultNotExploited || false)
    const [tmpCities, setTmpCities] = useState(defaultCities)
    const [tmpAdmin, setTmpAdmin] = useState(defaultAdmin)
    const [tmpOperator, setTmpOperator] = useState(defaultOperator)

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const onValidate = () => {
        setExploited(tmpExploited)
        setNotExploited(tmpNotExploited)
        setCities(tmpCities)
        setAdmin(tmpAdmin)
        setOperator(tmpOperator)
    }

    return (
        <Grid container item xs={12} sx={{ padding: mdMatches ? '1rem 3rem 0.7rem' : '1rem 3rem 0.7rem', backgroundColor: mainGrey, borderTop: `solid 1px ${mainBlack}` }}>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
                <Grid container item md={7.5} xs={12}>
                    <Grid item xs={4}>
                        <SuperMultiAutocomplete
                            label={i18n.cities}
                            values={tmpCities}
                            onChange={setTmpCities}
                            options={cities}
                            disablePortal={false}
                            multiple
                            limit={2}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ paddingLeft: '1rem' }}>
                        <MultiContributorsAutocomplete
                            label={i18n.buildingOwner}
                            disablePortal={false}
                            options={contributors}
                            values={tmpAdmin}
                            onChange={setTmpAdmin}
                            multiple={true}
                            limit={2}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ paddingLeft: '1rem' }}>
                        <MultiContributorsAutocomplete
                            label={i18n.operator}
                            disablePortal={false}
                            options={contributors}
                            values={tmpOperator}
                            onChange={setTmpOperator}
                            multiple={true}
                            limit={2}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={4} xs={12} alignItems='center' justifyContent={mdMatches ? 'flex-end' : 'space-around'}>
                    <Option
                        selected={tmpExploited}
                        label={i18n.exploited}
                        onClick={() => setTmpExploited(!tmpExploited)}
                        xs='auto'
                        sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                    />
                    <Option
                        selected={tmpNotExploited}
                        label={i18n.notExploited}
                        onClick={() => setTmpNotExploited(!tmpNotExploited)}
                        xs='auto'
                        sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end' alignItems='center' sx={{ paddingTop: '0.5rem' }}>
                <Grid item>
                    <MainButton
                        variant='contained'
                        sx={{ borderRadius: SMALL_RADIUS, backgroundColor: `${mainBlue} !important` }}
                        onClick={onValidate}
                    >
                        {i18n.search}
                    </MainButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

FilterPanel.propTypes = {
    defaultExploited: PropTypes.bool,
    defaultNotExploited: PropTypes.bool,
    defaultCities: PropTypes.arrayOf(PropTypes.number),
    defaultIndicators: PropTypes.arrayOf(PropTypes.number),
    defaultAdmin: PropTypes.arrayOf(PropTypes.number),
    defaultOperator: PropTypes.arrayOf(PropTypes.number),

    setExploited: PropTypes.func,
    setNotExploited: PropTypes.func,
    setCities: PropTypes.func,
    setIndicators: PropTypes.func,
    setAdmin: PropTypes.func,
    setOperator: PropTypes.func,
}

const DEFAULT_OPERATOR = 4 // exploitant
const DEFAULT_BUILDING_OWNER = 0 // maître d'oeuvre

const UDIS = () => {
    const {
        distributionUnits,
        cities,

        piezometersLight,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        linkedStations,
        resources,
        contributors,
        contributorsLinks,

        piezometersLightStatus,
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
        linkedStationsStatus,
        resourcesStatus,
        distributionUnitsStatus,

        accountHabilitations,
    } = useSelector(store => ({
        distributionUnits: store.HomeReducer.distributionUnits,
        cities: store.ReferencialReducer.cities,

        piezometersLight: store.HomeReducer.piezometersLight,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        linkedStations: store.HomeReducer.linkedStations,
        resources: store.ResourcesReducer.resources,
        contributors: store.HomeReducer.contributors,
        contributorsLinks: store.HomeReducer.contributorsLinks,

        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        piezoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus: store.DataManagerReducer.follow.hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus: store.DataManagerReducer.follow.pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.qualitoObservatoryFollowResultsStatus,
        linkedStationsStatus: store.DataManagerReducer.home.linkedStationsStatus,
        resourcesStatus: store.DataManagerReducer.resources.resourcesStatus,
        distributionUnitsStatus: store.DataManagerReducer.distributionUnits.distributionUnitsStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const citiesIndex = useListIndexed(cities, 'id')

    const [exploited, setExploited] = useState(true)
    const [notExploited, setNotExploited] = useState(false)
    const [citiesFilter, setCitiesFilter] = useState([])
    const [adminFilter, setAdminFilter] = useState([])
    const [operatorFilter, setOperatorFilter] = useState([])
    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)
    const [linkedStationsProgress, setLinkedStationsProgress] = useState(0)

    const contributorTypeOperator = useApplicationSetting('contributorTypeOperator', setting => parseInt(setting) || DEFAULT_OPERATOR)
    const contributorTypeAdministrator = useApplicationSetting('contributorTypeAdministrator', setting => parseInt(setting) || DEFAULT_BUILDING_OWNER)

    const { progress, isLoaded } = useStateProgress([
        piezometersLightStatus,
        resourcesStatus,
        distributionUnitsStatus,
    ])

    const { isLoaded: linkedStationsLoaded } = useStateProgress([
        linkedStationsStatus,
    ])

    const { isLoaded: observatoryResultsLoaded } = useStateProgress([
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
    ])

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.distributionUnits,
        href: '/distributions',
    }], [])

    const haveUnitAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_DISTRIBUTION_MODULE), [accountHabilitations])

    useEffect(() => {
        if (!haveUnitAccess) {
            dispatch(HomeAction.logout())
            dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
        }
    }, [haveUnitAccess])

    useEffect(() => {
        dispatch(HomeAction.fetchAllContributorsLink(STATION_TYPE_CONSTANT.DISTRIBUTION_UNIT))
        if (!distributionUnits.length) {
            dispatch(HomeAction.fetchDistributionUnits())
        }
        if (!piezometersLight.length) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }

        return () => {
            dispatch(HomeActionConstant.resetLinkedStations())
        }
    }, [])

    const fetchObsResults = (r) => {
        const ls = r.payload.flatMap((s) => new DtoAssociatedStation(s))
        if (!piezoObservatoryFollowResults.length) {
            const piezoIds = distributionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.piezometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchpiezoObservatoryFollowResults({ ids: piezoIds, progressCallback: setPiezoObservatoryProgress }))
        }
        if (!hydroObservatoryFollowResults.length) {
            const hydroIds = distributionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchhydroObservatoryFollowResults({ ids: hydroIds, progressCallback: setHydroObservatoryProgress }))
        }
        if (!pluvioObservatoryFollowResults.length) {
            const pluvioIds = distributionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchpluvioObservatoryFollowResults({ ids: pluvioIds, progressCallback: setPluvioObservatoryProgress }))
        }
        if (!qualitoObservatoryFollowResults.length) {
            const qualitoIds = distributionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.quality && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchqualitoObservatoryFollowResults({ ids: qualitoIds, progressCallback: setQualitoObservatoryProgress }))
        }
    }

    useEffect(() => {
        if (distributionUnits.length) {
            dispatch(HomeAction.fetchAllLinkedUnitsStations({ ids: distributionUnits.map((p) => p.id), isUDI: true, progressCallback: setLinkedStationsProgress })).then(r => fetchObsResults(r))
        }
    }, [distributionUnits])

    const getListOfOfr = (typeName) => {
        switch (typeName) {
            case STATION_TYPE_NAME.piezometry:
                return piezoObservatoryFollowResults
            case STATION_TYPE_NAME.hydrometry:
                return hydroObservatoryFollowResults
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObservatoryFollowResults
            case STATION_TYPE_NAME.quality:
                return qualitoObservatoryFollowResults
            default:
                return []
        }
    }

    const getIndicatorValue = (udiIndicators) => {
        const indicatorsColors = udiIndicators.map(({ color }) => color)
        const joinedValues = udiIndicators.map(({ value }) => (value || '').toLowerCase()).join()
        if (!udiIndicators?.length) {
            return NO_INDICATOR
        }
        if (joinedValues.includes('alerte') || indicatorsColors.some(color => ['red', 'indianred', 'darkmagenta'].includes(color))) {
            return CRISIS
        } else if (joinedValues.includes('vigilance')) {
            return VIGILANCE
        } else if (indicatorsColors.some(color => ['grey', 'gray'].includes(color))) {
            return NO_DATA
        }
        return NORMAL
    }

    const filteredUdisByExploited = useMemo(() => [
        ...(exploited ? distributionUnits.filter((p) => ![2, 3].includes(p.statusCode)) : []),
        ...(notExploited ? distributionUnits.filter((p) => [2, 3].includes(p.statusCode)) : []),
    ], [exploited, notExploited, distributionUnits])

    const uniqUdis = useMemo(() => orderBy(uniqWith(filteredUdisByExploited, (l1, l2) => (l1.stationLinkedId && l1.stationLinkedId === l2.stationLinkedId) && (l1.typeName && l1.typeName === l2.typeName)), 'name'), [filteredUdisByExploited])

    const getContributor = (up, ref, keyName) => {
        const contributorLink = contributorsLinks.find(cl => up.id === cl.idStation && cl.contributorType === ref && !cl.endDate)
        const contributor = contributorLink && contributors.find(c => contributorLink.idContributor === c.id)
        return contributor && {
            [keyName]: (contributor.mnemonique || contributor.name),
            [`${keyName}Code`]: contributor.id,
        } || {}
    }

    const formattedUnits = useMemo(() => uniqUdis.map(u => {
        const filteredLinks = filterObsLinkedStations(linkedStations).filter(s => s.code === u.code)
        const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const indicators = uniqLinks.flatMap(l => {
            const listOfOfr = getListOfOfr(l.typeName)
            return listOfOfr.find(o => o.id === l.stationLinkedId)?.data || []
        })
        return {
            ...u,
            resource: uniqLinks.find(s => s.typeName === STATION_TYPE_NAME.resource)?.stationLinkedId,
            ...getContributor(u, contributorTypeOperator, 'operator'),
            ...getContributor(u, contributorTypeAdministrator, 'buildingOwner'),
            indic: getIndicatorValue(indicators),
            cities: u.link_associatedTowns.map(town => {
                const city = citiesIndex[town.city] || {}
                return `[${town.city}]${city.name ? ` ${city.name}` : ''}`
            }),
        }
    }), [linkedStations, uniqUdis, piezoObservatoryFollowResults, hydroObservatoryFollowResults, pluvioObservatoryFollowResults, qualitoObservatoryFollowResults, citiesIndex])

    const unitsByCities = useMemo(() => citiesFilter?.length ? formattedUnits.filter(u => u.link_associatedTowns.some(town => citiesFilter.includes(town.city))) : formattedUnits, [citiesFilter, formattedUnits])
    const unitsByAdmin = useMemo(() => adminFilter?.length ? unitsByCities.filter(u => adminFilter.includes(u.buildingOwnerCode)) : unitsByCities, [adminFilter, unitsByCities])
    const unitsByOperator = useMemo(() => operatorFilter?.length ? unitsByAdmin.filter(u => operatorFilter.includes(u.operatorCode)) : unitsByAdmin, [operatorFilter, unitsByAdmin])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <>
            <FilterPanel
                defaultExploited={exploited}
                defaultNotExploited={notExploited}
                setExploited={setExploited}
                setNotExploited={setNotExploited}
                defaultCities={citiesFilter}
                defaultAdmin={adminFilter}
                defaultOperator={operatorFilter}
                setCities={setCitiesFilter}
                setAdmin={setAdminFilter}
                setOperator={setOperatorFilter}
            />
            {(isLoaded && linkedStationsLoaded && observatoryResultsLoaded) ? (
                <Grid container sx={{ padding: mdMatches ? '10px 50px' : '10px 30px' }}>
                    {unitsByOperator.map((p) => (
                        <UnitCard
                            unit={p}
                            piezoObsResults={piezoObservatoryFollowResults}
                            hydroObsResults={hydroObservatoryFollowResults}
                            pluvioObsResults={pluvioObservatoryFollowResults}
                            qualitoObsResults={qualitoObservatoryFollowResults}
                            isUDI
                        />
                    ))}
                </Grid>
            ) : <ProgressBar title={i18n.unitsLoading} progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress + linkedStationsProgress) / 6} />}
        </>
    )
}

export default UDIS
