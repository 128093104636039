/* eslint-disable consistent-return */
/* eslint-disable react/react-in-jsx-scope */
import { maxBy, round, isUndefined, max, minBy, isNil, orderBy, meanBy, ceil, sum, findKey } from 'lodash'
import i18n from 'simple-react-i18n'
import { hasValue } from './NumberUtil'
import { getSetting, sieauTooltip } from './FormUtils'
import insitu from 'assets/pictures/pictos/insitu.png'
import labo from 'assets/pictures/pictos/labo.png'

const THRESHOLD_COLOR = {
    red: '#f44336',
    orange: '#ff9800',
    yellow: '#ffeb3b',
    green: '#4caf50',
    blue: '#2196f3',
    white: '#ffffff',
}

const remarks = [
    {
        id: 1,
        code: 1,
        plot: 'square',
        color: 'blue',
        title: '',
        logo: '■',
    }, {
        id: 2,
        code: 2,
        plot: 'circle',
        color: 'orange',
        title: '',
        logo: '●',
    }, {
        id: 3,
        code: 3,
        plot: 'diamond',
        color: 'red',
        title: '',
        logo: '◆',
    }, {
        id: 10,
        code: 10,
        plot: 'triangle',
        color: 'black',
        title: '',
        logo: '▲',
    },
]

const getRemarks = id => remarks.find(r => r.id == id)

const tabColorsBySize = {
    1: [THRESHOLD_COLOR.blue],
    2: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.red],
    3: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.red],
    4: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.yellow, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.red],
    5: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.green, THRESHOLD_COLOR.yellow, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.red],
    6: [THRESHOLD_COLOR.red, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.yellow, THRESHOLD_COLOR.green, THRESHOLD_COLOR.blue, THRESHOLD_COLOR.white],
}

const getTabColorsBySize = () => tabColorsBySize

// truc degeux pour l'export d'analyses
const thresholdColorToName = color => findKey(THRESHOLD_COLOR, c => c === color)

const THRESHOLD_TYPE = {
    UNKNOWN: 'UNKNOWN',
    PH: 'PH',
    INCREASING: 'INCREASING',
    DECREASING: 'DECREASING',
}

const getLocalizationPicto = (localization) => {
    switch (localization) {
        case '0': return <i className='material-icons clickable' {...sieauTooltip(() => i18n.unknownLocalization)}>live_help</i>
        case '1': return <img className='clickable' {...sieauTooltip(() => i18n.insitu)} src={insitu} />
        case '2': return <img className='clickable' {...sieauTooltip(() => i18n.laboratory)} src={labo} />
        default: return ''
    }
}

const getLocalizationLabel = (localization) => {
    switch (localization) {
        case '0': return i18n.unknownLocalization
        case '1': return i18n.insitu
        case '2': return i18n.laboratory
        default: return ''
    }
}

// filtre une liste d'analyses
const filterValid = (analysisList = []) => analysisList.filter(a => a.status === '2' || a.status === '3')
const filterResult = (analysisList = []) => analysisList.filter(a => a.remark !== '0' && (!isUndefined(a.result) || a.remark === '7'))
const filterQuantification = (analysisList = []) => analysisList.filter(a => a.remark && !(['0', '2', '3', '5', '7', '10'].includes(a.remark)))
const filterDetection = (analysisList = []) => analysisList.filter(a => ['1', '3', '7', '10'].includes(a.remark))

// fonctions utils
const thresholdToTab = (th = {}) => [1, 2, 3, 4].map(nb => th[`threshold${nb}`]).filter(t => !isNil(t))
const getNbThresholds = (th = {}) => 1 + !isNil(th.threshold1) + !isNil(th.threshold2) + !isNil(th.threshold3) + !isNil(th.threshold4)

const getThresholdType = (threshold) => {
    if (isUndefined(threshold)) {
        return THRESHOLD_TYPE.UNKNOWN
    }
    // specific ph
    if (threshold.parameterCode === '1302' && threshold.threshold1 < 7 && threshold.threshold2 > 7 && isNil(threshold.threshold3) && isNil(threshold.threshold4)) {
        return THRESHOLD_TYPE.PH
    }
    // specific conductivity
    if ((threshold.parameterCode === '1304' || threshold.parameterCode === '1303') && threshold.threshold1 < 500 && threshold.threshold2 > 500 && isNil(threshold.threshold3) && isNil(threshold.threshold4)) {
        return THRESHOLD_TYPE.CONDUCTIVITY
    }
    if (!isUndefined(threshold) && hasValue(threshold.threshold1) && hasValue(threshold.threshold2) && threshold.threshold1 > threshold.threshold2) {
        return THRESHOLD_TYPE.DECREASING
    }
    return THRESHOLD_TYPE.INCREASING
}

const thresholdIndiceToTab = threshold => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return [1, 0, 2]
        case THRESHOLD_TYPE.INCREASING: // return thresholdToTab(threshold).map((_, i) => i + 1)
        case THRESHOLD_TYPE.DECREASING: return [0, ...thresholdToTab(threshold).map((_, i) => i + 1)]
        default: return THRESHOLD_COLOR.white
    }
}

const getMinThresholdColor = threshold => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return THRESHOLD_COLOR.red // or blue ?
        case THRESHOLD_TYPE.INCREASING: return THRESHOLD_COLOR.blue
        case THRESHOLD_TYPE.DECREASING: return THRESHOLD_COLOR.red
        default: return THRESHOLD_COLOR.white
    }
}

const getMaxThresholdColor = threshold => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return THRESHOLD_COLOR.red // or blue ?
        case THRESHOLD_TYPE.INCREASING: return THRESHOLD_COLOR.red
        case THRESHOLD_TYPE.DECREASING: return THRESHOLD_COLOR.blue
        default: return THRESHOLD_COLOR.white
    }
}

const getMaxIndiceThreshold = (threshold = {}) => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return 2
        case THRESHOLD_TYPE.INCREASING: {
            const normalizeThreshold = { ...threshold, threshold0: -500000 }
            return max([0, 1, 2, 3, 4].filter(nb => !isUndefined(normalizeThreshold[`threshold${nb}`])))
        }
        case THRESHOLD_TYPE.DECREASING: return 0
        default: return
    }
}

const getMinIndiceThreshold = (threshold = {}) => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return 0
        case THRESHOLD_TYPE.INCREASING: return 0
        case THRESHOLD_TYPE.DECREASING: {
            const normalizeThreshold = { ...threshold, threshold0: 500000 }
            return max([0, 1, 2, 3, 4].filter(nb => !isUndefined(normalizeThreshold[`threshold${nb}`])))
        }
        default: return
    }
}

const getIncreasingThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    if (indice === 0) {
        return `< ${threshold.threshold1}`
    }
    const maxIndice = getMaxIndiceThreshold(threshold)
    if (indice === maxIndice) {
        return `>= ${threshold[`threshold${indice}`]}`
    }
    return `>= ${threshold[`threshold${indice}`]} ${i18n.and} < ${threshold[`threshold${indice + 1}`]}`
}

const getDecreasingThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    const minIndice = getMinIndiceThreshold(threshold)

    if (indice === 0) {
        return `>= ${threshold.threshold1}`
    }
    if (indice === minIndice) {
        return `< ${threshold[`threshold${minIndice}`]}`
    }
    return `>= ${threshold[`threshold${indice + 1}`]} ${i18n.and} < ${threshold[`threshold${indice}`]}`
}

const getSpecificThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    if (indice === 1) {
        return `<= ${threshold.threshold1}`
    }

    if (indice === 2) {
        return `>= ${threshold.threshold2}`
    }

    return `> ${threshold.threshold1} ${i18n.and} < ${threshold.threshold2}`
}

const getThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return getSpecificThresholdLabel(indice, threshold)
        case THRESHOLD_TYPE.INCREASING: return getIncreasingThresholdLabel(indice, threshold)
        case THRESHOLD_TYPE.DECREASING: return getDecreasingThresholdLabel(indice, threshold)
        default: return ''
    }
}

const getThresholdColor = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return THRESHOLD_COLOR.white

    const nbThresholds = getNbThresholds(threshold)
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return indice === 0 ? THRESHOLD_COLOR.blue : THRESHOLD_COLOR.red
        case THRESHOLD_TYPE.INCREASING: return tabColorsBySize[nbThresholds][indice]
        case THRESHOLD_TYPE.DECREASING: return tabColorsBySize[nbThresholds][indice] // reverse?
        default: return ''
    }
}

const getThreshold = (thresholds = [], parameter, unit, station) => {
    const threshold = thresholds.find(t => t.parameterCode === parameter && t.unit === unit && t.station === station)
    if (!isUndefined(threshold)) return threshold

    const stationThreshold = thresholds.find(t => t.parameterCode === parameter && isUndefined(t.unit) && t.station === station)
    if (!isUndefined(stationThreshold)) return stationThreshold

    const unitThreshold = thresholds.find(t => t.parameterCode === parameter && t.unit === unit && isUndefined(t.station))
    if (!isUndefined(unitThreshold)) return unitThreshold

    const parameterThreshold = thresholds.find(t => t.parameterCode === parameter && isUndefined(t.unit) && isUndefined(t.station))
    if (!isUndefined(parameterThreshold)) return parameterThreshold

    return thresholds.find(t => isUndefined(t.parameterCode) && isUndefined(t.unit) && isUndefined(t.station)) // default threshold
}

const getThresholdResult = (threshold, value) => {
    if (isUndefined(threshold) || isUndefined(value)) {
        return {}
    }
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: {
            const color = value > threshold.threshold1 && value < threshold.threshold2 ? THRESHOLD_COLOR.blue : THRESHOLD_COLOR.red
            const valueIndice = value >= threshold.threshold2 && 2 || value <= threshold.threshold1 && 1 || 0
            return { thresholdIndice: valueIndice, color }
        }
        case THRESHOLD_TYPE.INCREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            const colorTab = tabColorsBySize[nbThresholds]
            const formatThreshold = { ...threshold, threshold0: -500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value >= formatThreshold[`threshold${nb}`]))
            return { thresholdIndice: valueIndice, color: colorTab[valueIndice] }
        }
        case THRESHOLD_TYPE.DECREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            const colorTab = tabColorsBySize[nbThresholds]
            const formatThreshold = { ...threshold, threshold0: 500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value < formatThreshold[`threshold${nb}`]))
            return { thresholdIndice: valueIndice, color: colorTab[valueIndice] }
        }
        default: return {}
    }
}

const calculateResult = analysis => {
    if (!analysis) {
        return
    }
    const {
        remarkCode,
        result,
        saturationThreshold,
    } = analysis
    return remarkCode === '3' && saturationThreshold || result
}

const calculateValue = analysis => {
    if (isUndefined(analysis)) {
        return ''
    }
    switch (analysis.remark) {
        case '0': // Analyse non faite
            return ''
        case '2': // < seuil de détection
        case '7': // Traces
        case '10': // < au seuil de quantification
            return !isUndefined(analysis.result) ? `<${round(analysis.result, 5)}` : ''
        case '3': // > seuil de saturation
            return !isUndefined(analysis.result) ? `>${round(analysis.saturationThreshold ?? analysis.result, 5)}` : ''
        default:
            return !isUndefined(analysis.result) ? `${round(analysis.result, 5)}` : ''
    }
}

// formatage d'une analyse
const calculateThresholdResult = (analysis, thresholds = []) => {
    const threshold = getThreshold(thresholds, analysis.parameter, analysis.unit, analysis.qualitometer)
    switch (analysis.remark) {
        case '0': { // Analyse non faite
            const minIndice = getMinIndiceThreshold(threshold)
            return {
                value: '',
                color: THRESHOLD_COLOR.white,
                textColor: 'black',
                thresholdLabel: `< ${i18n.threshold} ${minIndice}`,
                threshold,
                thresholdIndice: minIndice,
            }
        }
        case '2': // < seuil de détection
        case '7': // Traces
        case '10': { // < au seuil de quantification
            const minIndice = getMinIndiceThreshold(threshold)
            return {
                value: hasValue(analysis.result) ? `<${round(analysis.result, 5)}` : '',
                color: getMinThresholdColor(threshold),
                textColor: 'black',
                thresholdLabel: `< ${i18n.threshold} ${minIndice}`,
                threshold,
                thresholdIndice: minIndice,
            }
        }
        case '3': // > seuil de saturation
            const maxIndice = getMaxIndiceThreshold(threshold)
            return {
                value: hasValue(analysis.result) ? `>${round(analysis.result, 5)}` : '',
                color: getMaxThresholdColor(threshold),
                textColor: 'black',
                thresholdLabel: `> ${i18n.threshold} ${maxIndice}`,
                threshold,
                thresholdIndice: maxIndice,
            }
        default:
            const {
                thresholdIndice = 0,
                color = THRESHOLD_COLOR.white,
            } = getThresholdResult(threshold, analysis.result)
            return {
                value: hasValue(analysis.result) ? `${round(analysis.result, 5)}` : '',
                color,
                textColor: 'black',
                thresholdLabel: thresholdIndice > 0 ? `> ${i18n.threshold} ${thresholdIndice}` : `< ${i18n.threshold} 1`,
                threshold,
                thresholdValue: threshold?.[`threshold${thresholdIndice}`],
                thresholdIndice,
            }
    }
}

// calcule sur une liste d'analyse
const searchMaxAnalysis = (analysisList = []) => {
    if (!analysisList.length) {
        return
    }
    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))
    return maxBy([...superior, ...tab, ...inferior], calculateResult)
}

const searchMaxResult = (analysisList = []) => {
    const analysis = searchMaxAnalysis(analysisList)
    const result = calculateResult(analysis)
    if (isUndefined(result)) {
        return
    }
    return round(result, 4)
}

const searchMaxValue = (analysisList = []) => {
    const analysis = searchMaxAnalysis(analysisList)
    return calculateValue(analysis)
}

const searchMinAnalysis = (analysisList = []) => {
    if (!analysisList.length) {
        return
    }
    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))
    return minBy([...inferior, ...tab, ...superior], calculateResult)
}

const searchMinResult = (analysisList = []) => {
    const analysis = searchMinAnalysis(analysisList)
    const result = calculateResult(analysis)
    if (isUndefined(result)) {
        return
    }
    return round(result, 4)
}

const searchMinValue = (analysisList = []) => {
    const analysis = searchMinAnalysis(analysisList)
    return calculateValue(analysis)
}

const calculatePercentile90 = (list = []) => { // list should be ordered
    // old
    // if (list.length <= 9) {
    //     return list[list.length-2]
    // }
    // return list[floor(90 / 100 * list.length)]

    // v6
    if (list.length < 10) {
        return list[list.length - 1]
    }
    return list[round(0.9 * list.length - 0.45, 0)] // (0.9 * length - 0.55) - 1
}

const searchP90Analysis = (analysisList = []) => {
    if (!analysisList.length) {
        return
    }
    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))

    const orderedInferior = orderBy(inferior, calculateResult)
    const orderedsSuperior = orderBy(superior, calculateResult)
    const orderedTab = orderBy(tab, calculateResult)

    return calculatePercentile90([...orderedInferior, ...orderedTab, ...orderedsSuperior])
}

const searchP90Result = (analysisList = []) => {
    const analysis = searchP90Analysis(analysisList)
    const result = calculateResult(analysis)
    if (isUndefined(result)) {
        return
    }
    return round(result, 4)
}

const searchP90Value = (analysisList = []) => {
    const analysis = searchP90Analysis(analysisList)
    return calculateValue(analysis)
}

const calculateAverage = (analysisList = [], settings = []) => {
    if (!analysisList.length) {
        return
    }

    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const tab = analysisList.filter(elem => !elem.value.includes('<'))

    if (!inferior.length) {
        return round(meanBy(tab, calculateResult), 3)
    }

    const methodLQ = getSetting(settings, 'TypeLimiteQuantification') || '3'

    if (methodLQ === '4') {
        const choosenLQValue = getSetting(settings, 'ValeurLimiteQuantification') || '0'
        const correctedInferior = inferior.map(a => ({ ...a, result: parseFloat(choosenLQValue) || 0 }))
        return round(meanBy([...correctedInferior, ...tab], calculateResult), 3)
    }

    if (methodLQ === '5') {
        if (!tab.length) {
            return
        }
        return round(meanBy(tab, calculateResult), 3)
    }
    // methodLQ === '3'
    const correctedInferior = inferior.map(a => ({ ...a, result: a.result / 2 }))
    return round(meanBy([...correctedInferior, ...tab], calculateResult), 3)
}

const calculateGeometricAverage = (list = []) => {
    if (!list.length) {
        return
    }
    const listResult = list.map(calculateResult)
    const filteredResult = listResult.filter(v => v > 0)
    const resultSum = filteredResult.reduce((acc, v) => acc + Math.log(v), 0)
    return round(Math.exp(resultSum / filteredResult.length), 3)
}

const searchQuartile = (arr, q) => {
    const pos = arr.length * q
    const index = ceil(pos)
    return calculateResult(arr[index])
}

const calculateRangeInterquartile = analysisList => {
    if (analysisList.length < 4) {
        return 0
    }

    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))

    const orderedInferior = orderBy(inferior, calculateResult)
    const orderedsSuperior = orderBy(superior, calculateResult)
    const orderedTab = orderBy(tab, calculateResult)

    const orderedList = [...orderedInferior, ...orderedTab, ...orderedsSuperior]

    const q1 = searchQuartile(orderedList, 0.25)
    const q3 = searchQuartile(orderedList, 0.75)
    return round(q3 - q1, 4)
}

const calculateStandardDeviation = analysisList => {
    if (!analysisList.length) {
        return
    }
    if (analysisList.length === 1) {
        return 0
    }
    const mean = calculateAverage(analysisList)
    const v = sum(analysisList.map(a => (calculateResult(a) - mean) ** 2))
    return round(Math.sqrt(v / (analysisList.length - 1)), 3)
}

export {
    getRemarks,
    remarks,
    THRESHOLD_TYPE,
    tabColorsBySize,
    getTabColorsBySize,
    thresholdColorToName,
    getThresholdType,

    /* NEW */
    filterValid,
    filterResult,
    filterQuantification,
    filterDetection,

    thresholdToTab,
    getNbThresholds,
    thresholdIndiceToTab,
    getMaxIndiceThreshold,
    getMinIndiceThreshold,
    getIncreasingThresholdLabel,
    getDecreasingThresholdLabel,
    getSpecificThresholdLabel,
    getThresholdLabel,
    getThresholdColor,
    getThreshold,
    getThresholdResult,

    calculateResult,
    calculateValue,
    calculateThresholdResult,

    searchMaxAnalysis,
    searchMaxResult,
    searchMaxValue,
    searchMinAnalysis,
    searchMinResult,
    searchMinValue,
    searchP90Analysis,
    searchP90Result,
    searchP90Value,
    calculateAverage,
    calculateGeometricAverage,
    calculateRangeInterquartile,
    calculateStandardDeviation,

    getLocalizationPicto,
    getLocalizationLabel,
}
