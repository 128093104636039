import { Checkbox, Grid, Icon, Slider, Tooltip } from '@mui/material'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { mainBlack, mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { orderBy } from 'lodash'
import React from 'react'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { RGBToHexColor } from 'utils/ColorUtil'

const LayerTab = ({
    selectedLayers = [],
    setSelectedLayers = () => {},
    layersOpacity = [],
    setLayersOpacity = () => [],
    setLayersOpacityCommitted = () => [],

    layersByThemes = [],

    marge = '0.5rem',
}) => (
    <Grid container sx={{ padding: marge, fontWeight: 'bold' }}>
        <Grid
            item xs={12}
            sx={{
                backgroundColor: mainBlack,
                color: mainWhite,
                height: '35px',
                textAlign: 'center',
                display: 'grid',
                alignItems: 'center',
                borderRadius: SMALL_RADIUS,
            }}
        >
            <span>{i18n.mapLayers}</span>
        </Grid>
        <Grid container item xs={12} sx={{ paddingTop: marge }}>
            {orderBy(layersByThemes, 'themeOrder').map((lt, i) => (
                <Grid item xs={12} sx={{ paddingTop: i !== 0 && marge }}>
                    <AccordionMUI>
                        <AccordionSummaryMUI
                            expandIcon={null}
                            sx={{ backgroundColor: RGBToHexColor(lt.color), color: mainWhite }}
                        >
                            {lt.name}
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding>
                            {lt.layers.map((layer, index) => {
                                const layerName = layer.layer
                                const layerOpacity = layersOpacity.find(v => v.layerName === layerName)?.opacity || 1

                                return (
                                    <Grid container item xs={12} sx={{ padding: marge, borderTop: index !== 0 && `solid 1px ${mainBlack}` }} alignItems='center' justifyContent='space-between'>
                                        <Grid item xs={8.25}>
                                            {layer.name}
                                        </Grid>
                                        <Grid container item xs={3.5} justifyContent='space-between' alignItems='center'>
                                            <Grid container item xs={2.75} alignItems='center'>
                                                <Grid item>
                                                    <Checkbox
                                                        checked={selectedLayers.includes(`${layer.id}`)}
                                                        color='primary'
                                                        onChange={event => {
                                                            if (event.target.checked) {
                                                                return setSelectedLayers([...selectedLayers, `${layer.id}`])
                                                            }
                                                            return setSelectedLayers(selectedLayers.filter(sl => sl !== `${layer.id}`))
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} sx={{ paddingRight: marge }}>
                                                <Slider
                                                    sx={{ verticalAlign: 'middle' }}
                                                    min={0}
                                                    max={1}
                                                    step={0.1}
                                                    value={layerOpacity}
                                                    onChange={(_, opacity) => {
                                                        setLayersOpacity(prev => [
                                                            ...prev.filter(p => p.layerName !== layerName),
                                                            { layerName, opacity },
                                                        ])
                                                    }}
                                                    onChangeCommitted={(_, opacity) => setLayersOpacityCommitted([
                                                        ...layersOpacity.filter(p => p.layerName !== layerName),
                                                        { layerName, opacity },
                                                    ])}
                                                />
                                            </Grid>
                                            <Grid container item xs={2} justifyContent='center' alignItems='center'>
                                                {!!layer.legend && (
                                                    <Grid item sx={{ '& svg': { verticalAlign: 'middle' } }}>
                                                        <Tooltip
                                                            placement='left-start'
                                                            arrow
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        maxWidth: 'fit-content',
                                                                        width: 'fit-content',
                                                                    },
                                                                },
                                                            }}
                                                            title={<img src={layer.legend} alt={layerName} style={{ maxWidth: '28vw', maxHeight: '60vh' }} />}
                                                        >
                                                            <Icon>info_outline</Icon>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {!!layer.source && (
                                            <Grid item xs={8.25} sx={{ fontSize: '0.75rem', fontStyle: 'italic' }}>
                                                {i18n.source}: {layer.source}
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            })}
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </Grid>
            ))}
        </Grid>
    </Grid>
)

LayerTab.propTypes = {
    selectedLayers: PropTypes.arrayOf(PropTypes.string),
    setSelectedLayers: PropTypes.func,
    layersOpacity: PropTypes.arrayOf(PropTypes.shape({
        layerName: PropTypes.string,
        opacity: PropTypes.number,
    })),
    setLayersOpacity: PropTypes.func,
    setLayersOpacityCommitted: PropTypes.func,

    layersByThemes: PropTypes.arrayOf(PropTypes.shape({})),

    marge: PropTypes.string,
}

export default LayerTab