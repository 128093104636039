import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { orderBy, partition } from 'lodash'
import CmsAction from 'pages/cms/actions/CmsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DocumentCard from './DocumentCard'
import i18n from 'simple-react-i18n'
import { Button, Grid, Icon, TextField } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import { getLogin } from 'utils/LocalStorageUtils'
import DtoCMSMessage from 'pages/cms/dto/DtoCMSMessage'
import { getFullDateMini } from 'utils/DateUtil'
import { CmsActionConstant } from 'pages/cms/reducers/CmsReducer'
import ProgressBar from 'components/progress/ProgressBar'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { lightGrey, mainBlack, mainGrey, mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { PICTURES_EXTENSIONS } from '../referencials/documents/constants/FileConstants'
import { getFileExtension } from 'utils/FileUtils'
import SmallPicturePanel from '../components/SmallPicturePanel'
import FilePanel from '../components/FilePanel'

const CmsMessage = ({
    cmsMessage = {},
}) => {
    const myMessage = cmsMessage.author === getLogin()

    return (
        <Grid container justifyContent={myMessage ? 'flex-end' : 'flex-start'}>
            <Grid
                container item xs={10}
                sx={{
                    background: myMessage ? mainWhite : lightGrey,
                    border: `solid 1px ${myMessage ? mainBlack : mainGrey}`,
                    color: mainBlack,
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: SMALL_RADIUS,
                }}
            >
                <Grid item xs={12}>{`${cmsMessage.author} - ${getFullDateMini(cmsMessage.dateMessage)}`}</Grid>
                <Grid item xs={12} sx={{ paddingTop: '0.5rem' }}><span style={{ whiteSpace: 'pre-wrap' }}>{cmsMessage.message}</span></Grid>
            </Grid>
        </Grid>
    )
}

CmsMessage.propTypes = {
    cmsMessage: PropTypes.instanceOf(DtoCMSMessage),
}

const CmsDialog = ({
    cmsId,
    isOpenMessages = false,
    closeDialog = () => {},
}) => {
    const messagesEndRef = useRef()

    const {
        cmsMessages,
    } = useSelector(store => ({
        cmsMessages: store.CmsReducer.cmsMessages,
    }), shallowEqual)

    const [cms, setCms] = useState({})
    const [message, setMessage] = useState('')
    // const [isFollowed, setIsFollowed] = useState(false)
    const [cmsMessagesLoaded, setCmsMessagesLoaded] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (cmsId) {
            dispatch(CmsAction.fetchCMSEvent(cmsId)).then(json => setCms(json))
            dispatch(CmsAction.fetchCmsMessages(cmsId)).then(() => setCmsMessagesLoaded(true))
        }
        return () => {
            dispatch(CmsActionConstant.resetCmsMessages())
            setCms({})
            setMessage('')
            // setIsFollowed(false)
            setCmsMessagesLoaded(false)
        }
    }, [cmsId])

    // useEffect(() => {
    //     setIsFollowed(cms?.cmsFollowers?.some(u => u === getLogin()))
    // }, [cms])

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [cmsMessages])

    const addMessage = () => {
        if (message.length) {
            dispatch(CmsAction.addMessage({
                cmsId,
                message,
                author: getLogin(),
            })).then(() => {
                setMessage('')
                dispatch(CmsAction.fetchCmsMessages(cmsId))
            })
        }
    }

    // const followCms = () => {
    //     const callObj = { id: cmsId, login: getLogin() }
    //     dispatch(isFollowed ? CmsAction.unFollowCms(callObj) : CmsAction.followCms(callObj)).then(() => {
    //         dispatch(CmsAction.fetchCMSEvent(cmsId)).then(json => setCms(json))
    //     })
    //     setIsFollowed(prev => !prev)
    // }

    const cmsAnswersParam = useApplicationSetting('cmsReponses', setting => setting === 'true')

    const cmsDocuments = cms.document || cms.cmsDocument || []
    const [pictures, files] = partition(cmsDocuments, d => PICTURES_EXTENSIONS.includes(`.${getFileExtension(d.name)}`))

    return (
        <DialogMUI open={isOpenMessages}>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={11.5} container justifyContent='space-between' alignItems='center' spacing={1}>
                        <Grid item>
                            <h3 style={{ margin: 0, fontWeight: '600', fontSize: '20px' }}>{cms?.title}</h3>
                        </Grid>
                        {/* A REMETTRE QUAND IL Y AURA DES ALERTES POUR LES CMS ABONNES {!!cmsAnswersParam && (
                            <Grid item container sx={{ width: 'fit-content' }} justifyContent='space-between' alignItems='center'>
                                <Grid
                                    container item
                                    alignItems='center'
                                    justifyContent='center'
                                    onClick={followCms}
                                    sx={{
                                        marginRight: '1rem',
                                        padding: '0.25rem 1rem',
                                        width: 'fit-content',
                                        backgroundColor: isFollowed ? mainWhite : 'transparent',
                                        color: isFollowed ? mainBlue : mainWhite,
                                        border: `solid 1px ${mainWhite}`,
                                        borderRadius: '50rem',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid item>
                                        <p style={{ fontSize: '16px', margin: 0 }}>{isFollowed ? i18n.unfollowCms : i18n.followCms}</p>
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '0.5rem' }}>
                                        <Icon sx={{ color: isFollowed ? mainBlue : mainWhite }} size='small'>notifications_active</Icon>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )} */}
                    </Grid>
                    <Grid item xs={0.5} container justifyContent='flex-end'>
                        <Icon
                            sx={{ color: mainWhite, cursor: 'pointer' }}
                            size='small'
                            icon='close'
                            onClick={() => {
                                setMessage('')
                                closeDialog()
                                dispatch(CmsAction.fetchCMSEvents())
                            }}
                        >
                                close
                        </Icon>
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <DocumentCard cms={cms} noConsult showTitle={false} />
                <Grid container sx={{ borderTop: `solid 1px ${lightGrey}` }}>
                    {!!pictures.length && (
                        <Grid container item xs={12} sx={{ paddingTop: '1rem 0' }}>
                            <Grid item xs={12} sx={{ paddingBottom: '0.25rem', '& span': { fontWeight: 700, fontSize: '1.15rem' } }}>
                                <span>{i18n.pictures}</span>
                            </Grid>
                            <SmallPicturePanel pictures={pictures} noBorder={!!files.length} isCms />
                        </Grid>
                    )}
                    {!!files.length && (
                        <Grid container item xs={12} sx={{ padding: '1rem 0', borderBottom: `solid 1px ${lightGrey}` }}>
                            <Grid item xs={12} sx={{ paddingBottom: '0.25rem', '& span': { fontWeight: 700, fontSize: '1.15rem' } }}>
                                <span>{i18n.files}</span>
                            </Grid>
                            <FilePanel files={files} noBorder isCms />
                        </Grid>
                    )}
                </Grid>
                <Grid container justifyContent='center' sx={{ padding: '2rem 0' }}>
                    <Grid container item xs={12}>
                        {cmsMessagesLoaded ? (
                            <Grid item xs={12} id='faq-messages' sx={{ marginTop: '10px' }}>
                                {orderBy(cmsMessages.filter(c => !c.archive), 'dateMessage', 'asc').map(msg => <CmsMessage cmsMessage={msg} />)}
                                <div ref={messagesEndRef} />
                            </Grid>
                        ) : (
                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <ProgressBar indeterminate />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContentMUI>
            {!!cmsAnswersParam && (
                <DialogActionsMUI>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' spacing={1}>
                        <Grid item md={10} xs={12}>
                            <TextField
                                item
                                id='name'
                                color='primary'
                                label={i18n.yourMessage}
                                type='text'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                variant='outlined'
                                multiline
                                sx={{ width: '100%', lineHeight: '15px' }}
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item md={1.5} xs={12}>
                            <Button variant='contained' color='primary' fullWidth onClick={addMessage}>{i18n.add}</Button>
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            )}
        </DialogMUI>
    )
}


CmsDialog.propTypes = {
    cmsId: PropTypes.number,
    isOpenMessages: PropTypes.bool,
    closeDialog: PropTypes.func,
}

export default CmsDialog