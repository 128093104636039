import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Autocomplete, TextField, Icon, createFilterOptions } from '@mui/material'
import { uniq } from 'lodash'
import { hasValue } from 'utils/NumberUtil'
import { selectedColor } from './styled/Theme'

const seeMoreId = 'seeMoreId'

const SimpleMultiAutocomplete = ({
    id = 'simpleMultiAutocomplete',
    options = [],
    value = '',
    keyLabel = 'name',
    keyValue = 'id',
    label = '',
    placeholder = '',
    disabled = false,
    fullWidth = true,
    onChange = () => {},
    className = '',
    sx = {},
    clearIcon = true,
    additionalKeysFilter = [],
    displayWithCode = false,
    disablePortal = true,
    size = '30px',
    obligatory = false,
}) => {
    const [maxOptions, setMaxOptions] = useState(99)
    const input = useRef()

    const filterOptions = createFilterOptions({
        stringify: (option) => option && option[keyLabel] + (additionalKeysFilter.map(akf => option[akf]) || '') || '',
        limit: maxOptions,
        trim: true,
    })

    const getId = useCallback((element) => {
        if (element) {
            if (hasValue(element[keyValue])) {
                return element[keyValue]
            } else if (hasValue(element.code)) {
                return element.code
            } else if (hasValue(element.id)) {
                return element.id
            } else if (hasValue(element.value)) {
                return element.value
            }
        }
        return null
    }, [keyValue])

    const getName = useCallback((element) => {
        const code = displayWithCode ? getId(element) : null
        const codeShown = hasValue(code) && code !== '' ? ` [${code}]` : ''
        return (element[keyLabel] || element.mnemonique || element.name || element.label || '') + codeShown
    }, [displayWithCode, getId, keyLabel])

    const optionsOrdered = useMemo(() => uniq(options.map(o => ({
        ...o,
        name: getName(o),
    }))), [getName, options])

    const optionsFiltered = useMemo(() => optionsOrdered.filter(o => o), [optionsOrdered])

    const dataOptions = useMemo(() => optionsFiltered.find(o => value === o?.[keyValue]), [keyValue, optionsFiltered, value])

    const handleChange = (handleValue) => {
        onChange(handleValue?.[keyValue])
    }

    return (
        <div className={className}>
            <Autocomplete
                id={id}
                options={optionsFiltered}
                getOptionLabel={(option) => option?.[keyLabel] || ''}
                value={dataOptions || null}
                disableCloseOnSelect={false}
                disabled={disabled}
                noOptionsText={i18n.noOption}
                openText={i18n.open}
                clearText={i18n.toEmpty}
                clearIcon={clearIcon && (
                    <Icon
                        fontSize='small'
                        sx={{
                            color: 'black',
                            visibility: 'visible',
                        }}
                    >
                        clear
                    </Icon>
                )}
                popupIcon={(
                    <Icon
                        fontSize='small'
                        sx={{
                            color: `${!disabled ? 'black' : 'rgba(0, 0, 0, 0.38)'}`,
                            fontWeight: 'bold',
                            visibility: 'visible',
                        }}
                    >
                        expand_more
                    </Icon>
                )}
                fullWidth={fullWidth}
                freeSolo
                disablePortal={disablePortal}
                forcePopupIcon
                filterOptions={(optionsFilter, params) => {
                    const filtered = filterOptions(optionsFilter, params)
                    const filteredFormatted = filtered?.length === maxOptions ? [...filtered, {
                        [keyValue]: seeMoreId,
                        [keyLabel]: i18n.seeMore,
                    }] : filtered
                    return filteredFormatted
                }}
                onChange={(_, newValue) => handleChange(newValue)}
                isOptionEqualToValue={(option, newValue) => option?.[keyValue] === newValue?.[keyValue]}
                size={size}
                sx={{
                    '& input': {
                        padding: '0.75rem !important',
                    },
                    '& .MuiOutlinedInput-root': {
                        padding: '0 !important',
                    },
                    '& .MuiChip-filled': {
                        height: 18,
                        margin: '0px 3px !important',
                        backgroundColor: 'transparent',
                        '& span': {
                            height: 18,
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2px !important',
                        },
                    },
                    '& .MuiAutocomplete-tag': {
                        height: 18,
                        margin: '0px 3px !important',
                    },
                    '& .MuiChip-deleteIcon': {
                        fontSize: '13px !important',
                        color: 'black !important',
                    },
                }}
                renderInput={({ InputProps, InputLabelProps, ...params }) => (
                    <TextField
                        {...params}
                        inputRef={input}
                        label={obligatory ? `${label} *` : label}
                        placeholder={placeholder}
                        variant='outlined'
                        color='primary'
                        fullWidth={fullWidth}
                        InputLabelProps={{
                            ...InputLabelProps,
                            sx: {
                                top: input?.current?.value?.length ? '0.35rem' : 0,
                                '&.Mui-focused': {
                                    top: '0.35rem',
                                },
                            },
                        }}
                        InputProps={{
                            ...InputProps,
                            sx: {
                                margin: '5px 0',
                                width: '100% !important',
                                zIndex: '0',
                                '& input': {
                                    padding: '0.75rem',
                                },
                                ...sx,
                            },
                        }}
                    />
                )}
                ListboxProps={{
                    style: {
                        padding: 0,
                    },
                }}
                renderOption={(propsOption, option, { selected }) => {
                    if (option?.[keyValue] === seeMoreId) {
                        return (
                            <div
                                {...propsOption}
                                key={option?.[keyValue]}
                                onClick={() => setMaxOptions(maxOptions + 100)}
                                style={{
                                    position: 'relative',
                                    padding: '3px 6px',
                                    marginTop: '-3px',
                                }}
                            >
                                <span style={{ float: 'right' }}>{option[keyLabel]} {'>'}</span>
                            </div>
                        )
                    }
                    if (option?.[keyLabel]) {
                        return (
                            <li
                                {...propsOption}
                                key={option?.[keyValue]}
                                style={{
                                    position: 'relative',
                                    marginLeft: '0.1rem',
                                    padding: '3px 6px',
                                    marginTop: `${propsOption['data-option-index'] !== 0 ? '-3px' : '3px'}`,
                                    marginBottom: '3px',
                                    backgroundColor: `${selected ? selectedColor : 'white'}`,
                                }}
                            >
                                <>
                                    {!!option.icon && (
                                        <Icon>
                                            {option.icon}
                                        </Icon>
                                    )}
                                    <span style={{ paddingLeft: option.icon ? '0.5rem' : '0px', paddingRight: option.endIcon ? '0.5rem' : '0px' }}>
                                        {option[keyLabel] || ''}
                                    </span>
                                    {option.endIcon || ''}
                                </>
                            </li>
                        )
                    }
                    return null
                }}
            />
        </div>
    )
}

SimpleMultiAutocomplete.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
    })).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    sx: PropTypes.shape({}),
    clearIcon: PropTypes.bool,
    additionalKeysFilter: PropTypes.arrayOf(PropTypes.string),
    displayWithCode: PropTypes.bool,
    disablePortal: PropTypes.bool,
    size: PropTypes.string,
    obligatory: PropTypes.bool,
}

export default SimpleMultiAutocomplete