import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Grid, Icon } from '@mui/material'
import HomeAction from '../actions/HomeAction'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import { cmsPath } from 'conf/basepath'
import ToastrAction from 'toastr/actions/ToastrAction'
import bg1 from 'ressources/pictures/bg1.webp'
import aquasysLogo from 'ressources/pictures/logos/AquasysPetitLogo.png'
import CmsAction from 'pages/cms/actions/CmsAction'
import packageJson from '../../../../package.json'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { aquasysPath } from 'conf/SieauConstants'
import { hasValue } from 'utils/NumberUtil'


const ResetPassword = ({
    match: { params: { token } },
}) => {
    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const [newPassword, setNewPassword] = useState()
    const [newPasswordAgain, setNewPasswordAgain] = useState()
    const [passwordError, setPasswordError] = useState(false)
    const [cmsHome, setCmsHome] = useState()
    const [cmsPartners, setCmsPartners] = useState([])

    const securityPassword = useApplicationSetting('securityPasswordDescription')
    const passwordPolicy = useApplicationSetting('passwordPolicy')
    const cmsHomeId = useApplicationSetting('cmsAccueil')
    const categoryId = useApplicationSetting('categorieCmsPartenaires')

    const dispatch = useDispatch()

    useEffect(() => {
        if (!applicationSettings.length) {
            dispatch(HomeAction.fetchApplicationSettings())
        }
    }, [])

    useEffect(() => {
        if (hasValue(cmsHomeId)) {
            dispatch(CmsAction.fetchCMSEvent(cmsHomeId)).then(homeCms => setCmsHome(homeCms))
        }
        if (hasValue(categoryId)) {
            dispatch(CmsAction.fetchCmsByCateg(categoryId)).then(cmsEvents => setCmsPartners(cmsEvents.map(c => new DtoCMSEvent(c)).filter(c => c?.document[0])))
        }
    }, [categoryId, cmsHomeId])

    const imageUrl = useMemo(() => cmsHome?.cmsDocument[0]?.name ? cmsPath + cmsHome.cmsDocument[0]?.name : bg1, [cmsHome?.cmsDocument])

    const onSubmit = () => {
        setPasswordError(false)
        const regex = new RegExp(passwordPolicy)
        if (newPassword !== newPasswordAgain) {
            setPasswordError(true)
            dispatch(ToastrAction.error(i18n.confirmPasswordError))
        } else if (!regex.test(newPassword)) {
            setPasswordError(true)
            dispatch(ToastrAction.error(i18n.passwordPolicyError))
        } else {
            setPasswordError(false)
            dispatch(HomeAction.updatePassword(newPassword, token))
        }
    }

    const onKeyDown = e => e.key === 'Enter' && onSubmit()

    const openWebSite = (link) => link && window.open(link, '_blank')

    const getLogoPartenaires = () => {
        const images = cmsPartners.map(c => ({
            link: c.link,
            docName: c.document[0].name,
            name: c.title,
        }))
        return [...images.map((i, index) => {
            return (
                <Grid item xs={2} className='clickable' key={index} sx={{ height: '50%' }}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        sx={{ height: '100%' }}
                    >
                        <img
                            src={`${cmsPath}${i.docName}`}
                            alt={`logo partenaire ${i.name}`}
                            onClick={() => openWebSite(i.link)}
                            style={{
                                height: '100%',
                                width: 'auto',
                            }}
                        />
                    </Grid>
                </Grid>
            )
        }), (
            <Grid item xs={2} className='clickable' sx={{ height: '50%' }}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{ height: '100%' }}
                    onClick={() => openWebSite(aquasysPath)}
                >
                    <img
                        src={aquasysLogo}
                        alt='logo aquasys'
                        style={{
                            height: '100%',
                            width: 'auto',
                            maxWidth: '100%',
                        }}
                    />
                </Grid>
            </Grid>
        )]
    }

    return (
        <div style={{ height: '100%' }}>
            <Grid container alignItems='stretch' sx={{ height: '100%' }}>
                <Grid item md={6} xs={0} sx={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain', backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat' }} />
                <Grid
                    item md={6} xs={12}
                    container
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Grid item container alignItems='center' justifyContent='center' sx={{ height: '100vh' }}>
                        <Grid item xs={9}>
                            <Grid item xs={12} sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '5vh', fontSize: '26px' }}>
                                {i18n.passwordReset}
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '10vh', fontSize: '20px' }}>
                                {securityPassword}
                            </Grid>
                            <Grid item xs={12} container sx={{ marginBottom: '2vh' }} justifyContent='center' alignItems='center'>
                                <Grid container item xs={10} sx={{ marginBottom: '5vh' }} justifyContent='space-between'>
                                    <Grid item xs={1} container alignItems='center' justifyContent='center'>
                                        <Icon>lock</Icon>
                                    </Grid>
                                    <Grid item xs={10.5}>
                                        <InputRow
                                            id='newPassword'
                                            label={i18n.newPassword}
                                            type='password'
                                            value={newPassword}
                                            onChange={e => setNewPassword(e.target.value)}
                                            variant='outlined'
                                            error={passwordError}
                                            helperText={passwordError && i18n.fillField}
                                            onKeyDown={onKeyDown}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container sx={{ marginBottom: '5vh' }} justifyContent='center' alignItems='center'>
                                    <Grid container item xs={10} justifyContent='space-between'>
                                        <Grid item xs={1} container alignItems='center' justifyContent='center'>
                                            <Icon>lock</Icon>
                                        </Grid>
                                        <Grid item xs={10.5}>
                                            <InputRow
                                                id='newPasswordAgain'
                                                label={i18n.newPasswordAgain}
                                                type='password'
                                                value={newPasswordAgain}
                                                onChange={e => setNewPasswordAgain(e.target.value)}
                                                variant='outlined'
                                                error={passwordError}
                                                helperText={passwordError && i18n.fillField}
                                                onKeyDown={onKeyDown}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} justifyContent='center' sx={{ marginBottom: '10vh' }}>
                                    <Grid item xs={10}>
                                        <PrimaryButton onClick={onSubmit}>
                                            {i18n.validate}
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justifyContent='space-around'
                                    alignItems='center'
                                    sx={{ height: '10vh', width: '100%', padding: '0 8%', borderTop: '1px solid black', marginBottom: '2vh' }}
                                >
                                    {getLogoPartenaires()}
                                </Grid>
                                <Grid container item xs={12} justifyContent='center' alignItems='center'>
                                    <Grid item>
                                        {i18n.observatory.toUpperCase()} v{packageJson.version}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

ResetPassword.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string,
        }),
    }),
}

export default ResetPassword