import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Button, Grid, Icon } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'pages/online/components/styled/dialog'
import { mainWhite } from 'components/styled/Theme'
import { InputRow } from 'components/styled/Inputs'

const DialogUpdatePassword = ({
    open = false,
    toggleModal = () => {},
    handleChangeMdp = () => {},
    handleChangeNewMdp = () => {},
    handleChangeConfirmation = () => {},
    onSavePassword = () => {},
    firstLogin = false,
    title,
    label,
    noMdp = false,
}) => (
    <DialogMUI
        open={open}
        maxWidth='sm'
        PaperProps={{
            sx: {
                minHeight: 'auto',
                maxHeight: 'auto',
            },
        }}
    >
        <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item>
                <h3 style={{ margin: '0px', fontWeight: '600', fontSize: '20px' }}>{firstLogin ? (title || i18n.firstConnection) : !noMdp ? i18n.changeYourPassword : ''}</h3>
            </Grid>
            <Grid item>
                <Icon
                    sx={{ color: mainWhite, cursor: 'pointer' }}
                    size='small'
                    icon='close'
                    onClick={toggleModal}
                >
                    close
                </Icon>
            </Grid>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid container>
                {!!firstLogin && (
                    <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                        <p>{label || i18n.pleaseChangeYourTemporaryPassword}</p>
                    </Grid>
                )}
                <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
                    <InputRow
                        item
                        label={i18n.currentPassword}
                        type='password'
                        onChange={handleChangeMdp}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
                    <InputRow
                        item
                        label={i18n.newPassword}
                        type='password'
                        onChange={handleChangeNewMdp}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
                    <InputRow
                        item
                        label={i18n.confirmation}
                        type='password'
                        onChange={handleChangeConfirmation}
                    />
                </Grid>
            </Grid>
        </DialogContentMUI>
        <DialogActionsMUI>
            <Grid container item xs={12} justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <Button variant='contained' color='primary' fullWidth onClick={onSavePassword}>
                        {i18n.changePassword}
                    </Button>
                </Grid>
            </Grid>
        </DialogActionsMUI>
    </DialogMUI>
)

DialogUpdatePassword.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSavePassword: PropTypes.func.isRequired,
    handleChangeMdp: PropTypes.func,
    handleChangeNewMdp: PropTypes.func,
    handleChangeConfirmation: PropTypes.func,
    firstLogin: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
    noMdp: PropTypes.bool,
}

export default DialogUpdatePassword