import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Grid, useMediaQuery } from '@mui/material'
import HomeAction from 'pages/home/actions/HomeAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ProgressBar from 'components/progress/ProgressBar'
import useStateProgress from 'utils/customHook/useStateProgress'
import LittleMap from '../components/LittleMap'
import EventsCard from '../components/cards/EventsCard'
import SmallPicturePanel from '../components/SmallPicturePanel'
import DtoPicture from '../referencials/documents/dto/DtoPicture'
import DtoFile from '../referencials/documents/dto/DtoFile'
import FilePanel from '../components/FilePanel'
import { getSandreLabel } from 'utils/StringUtil'
import { nbPerPageLabelShort, SANDRE } from '../referencials/constants/ReferencialConstants'
import FollowAction from '../follows/actions/FollowAction'
import { mainBlack, mainGrey, SMALL_RADIUS } from 'components/styled/Theme'
import { isNil } from 'lodash'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { fromLonLat } from 'ol/proj'
import { DEFAULT_MAP_X_COORDINATE, DEFAULT_MAP_Y_COORDINATE } from 'pages/home/constants/HomeConstants'
import Option from 'components/Option'
import { getStationTypeNameFromType } from 'utils/StationUtils'
import { SUCCEEDED } from 'store/DataManagerConstants'
import DtoObservatoryFollowResult from '../follows/dto/DtoObservatoryFollowResult'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_DISTRIBUTION_MODULE } from 'pages/home/constants/AccessRulesConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import UnitCard from '../units/UnitCard'
import UnitQualityPanel from './UnitQualityPanel'
import ReferencialThunk from 'referencial/actions/ReferencialThunk'
import useListIndexed from 'utils/customHook/useListIndexed'
import useApplicationSetting, { listIntParser } from 'utils/customHook/useApplicationSetting'
import SimpleTable from 'pages/components/SimpleTable'

const UnitMap = ({
    kml = [],
    center = [],
    piezoObsResults = [],
}) => {
    const {
        productionUnit,
        linkedStations,
        piezometersLight,
        hydrometers,
        pluviometers,
    } = useSelector(store => ({
        productionUnit: store.HomeReducer.productionUnit,
        linkedStations: store.HomeReducer.linkedStations,
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
    }), shallowEqual)

    const markers = useMemo(() => {
        const catchmentsIds = piezoObsResults.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment).map(p => p.id)
        const catchments = piezometersLight.filter(piezo => catchmentsIds.includes(piezo.id))
        const piezos = piezometersLight.filter(piezo => !catchmentsIds.includes(piezo.id))
        return [
            ...catchments.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.piezometry)).map(c => ({ ...c, typeName: OBSERVATORY_STATION_TYPE_NAME.catchment })),
            ...piezos.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.piezometry)),
            ...hydrometers.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.hydrometry)),
            ...pluviometers.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.pluviometry)),
        ]
    }, [piezoObsResults, piezometersLight, hydrometers, pluviometers, linkedStations])

    return (
        <LittleMap
            points={[ productionUnit, ...markers ]}
            zoom={10}
            kml={kml}
            center={center}
        />
    )
}

UnitMap.propTypes = {
    kml: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    center: PropTypes.arrayOf(PropTypes.number),
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const UnitEvents = ({
    station = {},
    stationType,
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const {
        linkedStations,
    } = useSelector(store => ({
        linkedStations: store.HomeReducer.linkedStations,
    }), shallowEqual)
    const getRealTypeName = ({ stationLinkedId, typeName }) => piezoObsResults.some(p => p.id === stationLinkedId && p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment) ? OBSERVATORY_STATION_TYPE_NAME.catchment : typeName

    const linkedStationsFormatted = useMemo(() => linkedStations.map(ls => ({
        ...ls,
        typeName: ls.typeName === STATION_TYPE_NAME.piezometry ? getRealTypeName(ls) : ls.typeName,
    })), [piezoObsResults, linkedStations])

    const eventsCodes = useMemo(() => [
        { code: station.id, stationType },
        ...linkedStationsFormatted.filter(p => !!getStationTypeNameFromType(p.typeName) && p.typeName !== STATION_TYPE_NAME.piezometry).map(p => ({
            code: p.stationLinkedId,
            stationType: getStationTypeNameFromType(p.typeName),
        })),
    ], [linkedStationsFormatted, station.id, stationType])

    return (
        <EventsCard
            height='calc(100vh - 410px)'
            codes={eventsCodes}
            stationId={station.id}
            stationType={stationType}
            piezoObsResults={piezoObsResults}
            hydroObsResults={hydroObsResults}
            pluvioObsResults={pluvioObsResults}
            qualitoObsResults={qualitoObsResults}
        />
    )
}

UnitEvents.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        typeName: PropTypes.string,
    }),
    stationType: PropTypes.string,
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const UDIDashboard = ({ match: { params: { id } } }) => {
    const {
        distributionUnit,
        distributionUnitStatus,
        linkedStationsStatus,
        cities,
        // linkedStations,
        sandreCodes,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        applicationSettings,
        accountUserSettings,

        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        distributionUnitsStatus,
        qualitometersLightStatus,

        accountHabilitations,
    } = useSelector(store => ({
        distributionUnit: store.HomeReducer.distributionUnit,
        distributionUnitStatus: store.DataManagerReducer.distributionUnits.distributionUnitStatus,
        linkedStationsStatus: store.DataManagerReducer.home.linkedStationsStatus,
        cities: store.ReferencialReducer.cities,
        // linkedStations: store.HomeReducer.linkedStations,
        sandreCodes: store.HomeReducer.sandreCodes,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountUserSettings: store.AccountReducer.accountUserSettings,

        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        hydrometersStatus: store.DataManagerReducer.hydrometers.hydrometersStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometers.pluviometersStatus,
        distributionUnitsStatus: store.DataManagerReducer.distributionUnits.distributionUnitsStatus,
        qualitometersLightStatus: store.DataManagerReducer.qualitometers.qualitometersLightStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const thresholds = useApplicationSetting('UDI_REFERENCE_THRESHOLD', listIntParser)

    const citiesIndex = useListIndexed(cities, 'id')

    const [details, setDetails] = useState(false)
    const [pictures, setPictures] = useState([])
    const [documents, setDocuments] = useState([])
    const [piezoObsResults, setPiezoObsResults] = useState([])
    const [hydroObsResults, setHydroObsResults] = useState([])
    const [pluvioObsResults, setPluvioObsResults] = useState([])
    const [qualitoObsResults, setQualitoObsResults] = useState([])
    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)
    const [piezoIsLoaded, setPiezoIsLoaded] = useState(false)
    const [hydroIsLoaded, setHydroIsLoaded] = useState(false)
    const [pluvioIsLoaded, setPluvioIsLoaded] = useState(false)
    const [qualitoIsLoaded, setQualitoIsLoaded] = useState(false)

    const dispatch = useDispatch()

    const { progress, isLoaded } = useStateProgress([
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        distributionUnitsStatus,
        distributionUnitStatus,
        linkedStationsStatus,
        qualitometersLightStatus,
    ])

    const observatoryResultsLoaded = piezoIsLoaded && hydroIsLoaded && pluvioIsLoaded && qualitoIsLoaded

    useEffect(() => {
        if (accountHabilitations.length && !componentHasHabilitations(H_DISTRIBUTION_MODULE)) {
            dispatch(HomeAction.logout())
            dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
        }
    }, [accountHabilitations.length])

    useEffect(() => {
        if (piezometersLightStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (hydrometersStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchHydrometers())
        }
        if (pluviometersStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchPluviometers())
        }
        if (qualitometersLightStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchQualitometersLight())
        }
        if (distributionUnitsStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchDistributionUnits())
        }
        dispatch(ReferencialThunk.fetchSandreCodes())

        return () => {
            dispatch(HomeActionConstant.resetFollow())
            dispatch(HomeActionConstant.resetLinkedStations())
        }
    }, [])

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.distributionUnits,
            href: '/distributions',
        }, {
            title: distributionUnit?.name,
            href: `/distributions/${id}`,
        }]))
        dispatch(HomeAction.fetchPictures({ code: distributionUnit.code, stationType: STATION_TYPE_CONSTANT.distributionUnit })).then((res) => {
            setPictures((res?.payload || []).map((p) => new DtoPicture(p)))
        })
        dispatch(HomeAction.fetchDocuments({ code: distributionUnit.code, stationType: STATION_TYPE_CONSTANT.distributionUnit })).then((res) => {
            const resFiltered = (res?.payload || []).filter((d) => !d.name.endsWith('.kml') && !d.name.endsWith('.KML'))
            setDocuments(resFiltered.map((d) => new DtoFile(d)))
        })
    }, [distributionUnit])

    useEffect(() => {
        dispatch(HomeAction.fetchDistributionUnit(id))
    }, [id])

    const fetchObsResults = (r) => {
        const ls = r.payload.flatMap((s) => new DtoAssociatedStation(s))
        const piezoIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.piezometry).map((l) => l.stationLinkedId)
        if (!piezoIds.length) {
            setPiezoObservatoryProgress(100)
            setPiezoIsLoaded(true)
        } else if (!piezoObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificPiezoObservatoryFollowResult(piezoIds, setPiezoObservatoryProgress)).then(result => {
                setPiezoObsResults(result)
                setPiezoObservatoryProgress(100)
                setPiezoIsLoaded(true)
            })
        } else if (piezoObservatoryFollowResults.length) {
            const result = piezoObservatoryFollowResults.filter(ofr => piezoIds.includes(ofr.id))
            setPiezoObsResults(result)
            setPiezoObservatoryProgress(100)
            setPiezoIsLoaded(true)
        }
        const hydroIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map((l) => l.stationLinkedId)
        if (!hydroIds.length) {
            setHydroObservatoryProgress(100)
            setHydroIsLoaded(true)
        } else if (!hydroObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificHydroObservatoryFollowResult(hydroIds, setHydroObservatoryProgress)).then(result => {
                setHydroObsResults(result)
                setHydroObservatoryProgress(100)
                setHydroIsLoaded(true)
            })
        } else if (hydroObservatoryFollowResults.length) {
            const result = hydroObservatoryFollowResults.filter(ofr => hydroIds.includes(ofr.id))
            setHydroObsResults(result)
            setHydroObservatoryProgress(100)
            setHydroIsLoaded(true)
        }
        const pluvioIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry).map((l) => l.stationLinkedId)
        if (!pluvioIds.length) {
            setPluvioObservatoryProgress(100)
            setPluvioIsLoaded(true)
        } else if (!pluvioObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificPluvioObservatoryFollowResult(pluvioIds, setPluvioObservatoryProgress)).then(result => {
                setPluvioObsResults(result)
                setPluvioObservatoryProgress(100)
                setPluvioIsLoaded(true)
            })
        } else if (pluvioObservatoryFollowResults.length) {
            const result = pluvioObservatoryFollowResults.filter(ofr => pluvioIds.includes(ofr.id))
            setPluvioObsResults(result)
            setPluvioObservatoryProgress(100)
            setPluvioIsLoaded(true)
        }
        const qualitoIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.quality).map((l) => l.stationLinkedId)
        if (!qualitoIds.length) {
            setQualitoObservatoryProgress(100)
            setQualitoIsLoaded(true)
        } else if (!qualitoObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificQualitoObservatoryFollowResult(qualitoIds, setQualitoObservatoryProgress)).then(result => {
                setQualitoObsResults(result)
                setQualitoObservatoryProgress(100)
                setQualitoIsLoaded(true)
            })
        } else if (qualitoObservatoryFollowResults.length) {
            const result = qualitoObservatoryFollowResults.filter(ofr => qualitoIds.includes(ofr.id))
            setQualitoObsResults(result)
            setQualitoObservatoryProgress(100)
            setQualitoIsLoaded(true)
        }
    }

    useEffect(() => {
        if (!isNil(distributionUnit.code)) {
            dispatch(HomeAction.fetchAllLinkedUnitsStations({ ids: [distributionUnit.id], isUDI: true })).then(r => fetchObsResults(r))
        }
    }, [distributionUnit.code])

    const { defaultCenter } = useMemo(() => {
        const applicationX = applicationSettings.find(a => a.parameter === DEFAULT_MAP_X_COORDINATE)?.value
        const applicationY = applicationSettings.find(a => a.parameter === DEFAULT_MAP_Y_COORDINATE)?.value

        const envX = isNil(applicationX) ? process.env.REACT_APP_MAP_X : applicationX
        const envY = isNil(applicationY) ? process.env.REACT_APP_MAP_Y : applicationY

        return { defaultCenter: fromLonLat([Number(envX), Number(envY)]) }
    }, [accountUserSettings, applicationSettings])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const qualityIds = useMemo(() => qualitoObsResults.map(q => q.id), [qualitoObsResults])

    const citiesData = useMemo(() => distributionUnit.link_associatedTowns?.map(town => {
        const city = citiesIndex[town.city] || {}
        return { ...city, code: city.id, servedPopulation: town.servedPopulation, member: town.member }
    }), [citiesIndex, distributionUnit.link_associatedTowns])

    return (
        <Grid container sx={{ position: 'absolute' }}>
            <Grid container item xs={12} sx={{ backgroundColor: mainGrey, minHeight: 50, padding: mdMatches ? '1rem 3rem 0.7rem' : '1rem 3rem 0.7rem', borderTop: `solid 1px ${mainBlack}` }}>
                <Option
                    first
                    selected={!details}
                    label={i18n.simpleCard}
                    onClick={() => setDetails(false)}
                    xs='auto'
                    sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                />
                <Option
                    selected={details}
                    label={i18n.detailsCard}
                    onClick={() => setDetails(true)}
                    xs='auto'
                    sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                />
            </Grid>
            {(isLoaded && observatoryResultsLoaded && distributionUnit.id === Number(id)) ? (
                <Grid container sx={{ padding: '15px 50px' }}>
                    <Grid item md={8.5} xs={12} sx={{ paddingRight: '50px' }}>
                        <Grid container item xs={12} sx={{ padding: '0.5em 0' }}>
                            <UnitCard
                                unit={distributionUnit}
                                piezoObsResults={piezoObsResults}
                                hydroObsResults={hydroObsResults}
                                pluvioObsResults={pluvioObsResults}
                                qualitoObsResults={qualitoObsResults}
                                small
                                isUDI
                            />
                            {!!pictures.length && (
                                <SmallPicturePanel element={'station'} pictures={pictures} />
                            )}
                            {!!documents.length && (
                                <FilePanel files={documents} hideTitle />
                            )}
                            {details && (
                                <Grid container item xs={12} sx={{ border: `1px solid ${mainBlack}`, padding: '0.75rem', borderRadius: SMALL_RADIUS }}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12}><h4 style={{ margin: '0 0 5px' }}>{i18n.descriptif}:</h4></Grid>
                                        <Grid container item xs={6}>
                                            <Grid item xs={12}><span>{i18n.name}: {distributionUnit.name}</span></Grid>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Grid item xs={12}>{i18n.state} : {getSandreLabel(sandreCodes, SANDRE.CODE_ETAT, distributionUnit.status)}</Grid>
                                            <Grid item xs={12}>{i18n.exploitationMode} : {sandreCodes.find(sc => sc.field === SANDRE.MODE_EXPLOITATION && sc.code === distributionUnit.exploitationModeCode)?.mnemonique || ''}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item><h4 style={{ margin: '10px 0 5px' }}>{i18n.comment}:</h4></Grid>
                                        {!!isNil(distributionUnit.descriptive) && <Grid item><span style={{ wordWrap: 'break-word' }}>{distributionUnit.descriptive}</span></Grid>}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ margin: '0.5rem 0 1rem' }}>
                            <SimpleTable
                                datas={citiesData}
                                headers={['code', 'name', 'population', 'servedPopulation', 'member']}
                                nbPerPage={nbPerPageLabelShort}
                                densePadding
                            />
                        </Grid>
                        <UnitQualityPanel ids={qualityIds} thresholds={thresholds}/>
                    </Grid>
                    <Grid item md={3.5} xs={12}>
                        <UnitMap
                            kml={documents.filter((d) => d.name.endsWith('.kml') || d.name.endsWith('.KML'))}
                            center={defaultCenter}
                            piezoObsResults={piezoObsResults}
                        />
                        <UnitEvents
                            station={distributionUnit}
                            stationType={STATION_TYPE_NAME.distributionUnit}
                            piezoObsResults={piezoObsResults}
                            hydroObsResults={hydroObsResults}
                            pluvioObsResults={pluvioObsResults}
                            qualitoObsResults={qualitoObsResults}
                        />
                    </Grid>
                </Grid>
            ) : <ProgressBar progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress) / 5} />}
        </Grid>
    )
}

UDIDashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default UDIDashboard
