module.exports = {
    LOGIN: 'LOGIN',
    LOGIN_FAIL: 'LOGIN_FAIL',
    ADD_TOKEN: 'ADD_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    SIEAU_TOKEN: 'sieau-token',
    SIEAU_LOGIN: 'SIEAU_LOGIN',
    MAP_STATIONS_VISIBLE: 'MAP_STATIONS_VISIBLE',
    DISCONNECTED: 'DISCONNECTED',
    TITLE: 'TITLE',
    BACK_PATH: 'BACK_PATH',
    NAVBAR_LINKS: 'NAVBAR_LINKS',
    CREDENTIALS: 'CREDENTIALS',
    ACTIONS: 'ACTIONS',
    RECEIVE_NOTIFICATIONS: 'RECEIVE_NOTIFICATIONS',
    READ_NOTIFICATION: 'READ_NOTIFICATION',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    RECEIVE_THEME_LAYERS: 'RECEIVE_THEME_LAYERS',
    RECEIVE_LAST_DOCUMENTS: 'RECEIVE_LAST_DOCUMENTS',
    SHOW_WELCOME_POPUP: 'SHOW_WELCOME_POPUP',
    UPDATE_GLOBAL_RESEARCH: 'UPDATE_GLOBAL_RESEARCH',
    SET_HELP_LINK: 'SET_HELP_LINK',
    RECEIVE_DATE_VALID_CGU: 'RECEIVE_DATE_VALID_CGU',
    RECEIVE_ALL_CGU: 'RECEIVE_ALL_CGU',
    RECEIVE_CONTRIBUTORS: 'RECEIVE_CONTRIBUTORS',
    RECEIVE_PARTENAIRE_CMS: 'RECEIVE_PARTENAIRE_CMS',
    RECEIVE_CGU_CMS: 'RECEIVE_CGU_CMS',
    RECEIVE_CURRENT_LOCATION: 'RECEIVE_CURRENT_LOCATION',
    ACTION: 'ACTION',
    MENU: 'MENU',
    RECEIVE_WATCHID: 'RECEIVE_WATCHID',
    POPUP: 'POPUP',
    MENU_RESET: 'MENU_RESET',
    FULL_RESET: 'FULL_RESET',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    RECEIVE_CMS_HOME: 'RECEIVE_CMS_HOME',
    SET_RELOAD: 'SET_RELOAD',
    MODULE: 'Observatoire',

    KEY_RECAPTCHA: '6LcyzBgqAAAAAMxtBVOYlZdSQo3NQa4gSyVYLduy',
    RECAPTCHA_API_KEY: 'AIzaSyCgjoMNQ5TaJhv4IJLIiFaxcy-IIED5rIQ',

    HOME_LIST_OF_SELECTED_STATIONS: 'homeListOfSelectedStations',
    HOME_LIST_OF_SELECTED_LAYERS: 'homeListOfSelectedLayers',
    HOME_LIST_OF_LAYERS_OPACITY: 'homeListOfLayersOpacity',
    HOME_SELECTED_BACKGROUND: 'homeSelectedBackground',
    CHART_SELECTED_TIME: 'CHART_SELECTED_TIME',
    MY_MAPS: 'myMaps',
    OBS_FOLLOWS_FILTER: 'obsFollowsFilter',
    OSM_BACKGROUND: 'OSM',
    ROADMAP_BACKGROUND: 'ROADMAP',
    RELIEF_BACKGROUND: 'RELIEF',
    SATELLITE_BACKGROUND: 'SATELLITE',
    OPERATOR_TYPE_NAME: 'Exploitant',
    OWNER_TYPE_NAME: 'Gestionnaire',
    DEFAULT_MAP_X_COORDINATE: 'defaultMapXCoordinate',
    DEFAULT_MAP_Y_COORDINATE: 'defaultMapYCoordinate',
    DEFAULT_MAP_ZOOM: 'defaultMapZoom',
    TOKEN_MOBILE_LOGIN:
        // eslint-disable-next-line max-len
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MDA3MjQ4MjEyMTcsImxvZ2luIjoib2JzZXJ2YXRvaXJlIiwiY29ubmVjdGlvbiI6IjIwMjAtMDktMjEgMTE6NDc6MDEiLCJsaWNlbnNlIjpbIlNJRyIsIlNJQURNIiwiU0lNQVQiLCJTSVEiLCJTSUVTIiwiU0lIIiwiU0lQIl19.wVkHPP2QQ6HkOJmbgGD8Z3gud_jSxFOkME6rPTR0H4w',
}