import React from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'
import { getNbThresholds, getThresholdColor, getThresholdLabel, thresholdIndiceToTab } from 'utils/AnalyseUtils'
import { Grid } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const SliderPanel = ({
    analyse = {},
}) => {
    const {
        thresholds,
        parameters,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
        parameters: store.ReferencialReducer.parameters,
    }), shallowEqual)

    const thresholdLabel = thresholds.find(th => th.code === analyse.threshold?.thresholdType)?.name

    const nbThresholds = getNbThresholds(analyse.threshold)
    const tabIndices = thresholdIndiceToTab(analyse.threshold)

    const param = parameters.find(p => p.code === analyse.parameter)
    const title = isUndefined(param) ? `<${analyse.parameter}>` : `${param.name} [${analyse.parameter}]`

    return (
        <Grid container>
            <Grid item container>
                <Grid item xs={12} sx={{ backgroundColor: '#000000', padding: '5px 6px', height: '24px' }}>
                    {title}
                </Grid>
                {tabIndices.map((i, key) => (
                    <Grid key={key} container item xs={12/nbThresholds} sx={{ color: '#000000', height: '12px' }} justifyContent='center'>
                        {analyse.thresholdIndice === i && (
                            <ArrowDropDownIcon sx={{ fontSize: '50px', top: '-21px', position: 'relative' }} />
                        )}
                    </Grid>
                ))}
            </Grid>
            <Grid item container xs={12}>
                {tabIndices.map((indice, key) => (
                    <Grid key={key} item xs={12/nbThresholds} sx={{ backgroundColor: getThresholdColor(indice, analyse.threshold), color: '#000000', padding: '1px 3px' }}>
                        {thresholdLabel} {getThresholdLabel(indice, analyse.threshold)}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

SliderPanel.propTypes = {
    analyse: PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */}),
}

SliderPanel.defaultProps = {
    withThresholdLevels: false,
    onOpen: () => { },
    className: '',
}

const mapStateToProps = store => ({
    thresholds: store.QualityReducer?.thresholds ?? [],
})

export default connect(mapStateToProps)(SliderPanel)
