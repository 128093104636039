import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DtoProductionUnit from 'pages/home/dto/DtoProductionUnit'
import { groupBy, isNil, keys, orderBy, uniqWith } from 'lodash'
import { filterObsLinkedStations, getMarkerByStationType, getStationTypeNameFromTypeCode } from 'utils/StationUtils'
import { getSandreLabel } from 'utils/StringUtil'
import { SANDRE } from '../referencials/constants/ReferencialConstants'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { getTextColorByBackground } from 'utils/ColorUtil'
import { mainBlack, SMALL_RADIUS } from 'components/styled/Theme'
import DtoObservatoryFollowResult from '../follows/dto/DtoObservatoryFollowResult'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'

const UDIDefaultColor = '#4b8f42'

const UnitCard = ({
    unit = {},
    hideAssociatedStation = false,
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
    small = false,
    isUDI = false,
}) => {
    const {
        linkedStations,
        contributors,
        sandreCodes,
        contributorsLinks,
        accountHabilitations,
    } = useSelector(store => ({
        linkedStations: store.HomeReducer.linkedStations,
        contributors: store.HomeReducer.contributors,
        sandreCodes: store.HomeReducer.sandreCodes,
        contributorsLinks: store.HomeReducer.contributorsLinks,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const getOfr = ({ stationLinkedId, typeName }) => {
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                return piezoObsResults.find(p => p.id === stationLinkedId) || {}
            case STATION_TYPE_NAME.hydrometry:
                return hydroObsResults.find(p => p.id === stationLinkedId) || {}
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObsResults.find(p => p.id === stationLinkedId) || {}
            case STATION_TYPE_NAME.quality:
                return qualitoObsResults.find(p => p.id === stationLinkedId) || {}
            default:
                return {}
        }
    }

    const getInCrisisTag = allTags => {
        const inCrisisTag = !small ? [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '').toLowerCase().includes('alerte'))].filter(d => !!d) : allTags.filter(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '').toLowerCase().includes('alerte'))
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = !small ? [allTags.find(d => (d?.value || '').toLowerCase().includes('vigilance'))].filter(d => !!d) : allTags.filter(d => (d?.value || '').toLowerCase().includes('vigilance'))
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange', realColor: tag.color })) : []
    }

    const getColorLink = (link, ofr) => {
        const allTags = ofr?.data?.map(d => ({ ...d, stationName: link.name || link.code, value: !['grey', 'gray'].includes(d.color) && d.value })) || []
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return tagsNoData[0]?.color
    }

    const allLinks = useMemo(() => {
        const filteredLinks = filterObsLinkedStations(linkedStations).filter(s => s.code === unit.code)
        return uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
    }, [linkedStations, unit.code])

    const groupedLinks = useMemo(() => {
        const formattedLinks = allLinks.map(l => {
            const ofr = getOfr(l)
            return {
                ...l,
                typeName: ofr.typeName || getStationTypeNameFromTypeCode(l.stationLinkedType),
                name: ofr?.name || l.stationLinkedName,
                code: ofr?.code || l.code,
                color: ofr.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment ? getColorLink(l, ofr) : 'black',
            }
        })
        return groupBy(orderBy(formattedLinks, 'typeName'), 'typeName')
    }, [allLinks])

    const getListOfOfr = (typeName) => {
        switch (typeName) {
            case STATION_TYPE_NAME.piezometry:
                return piezoObsResults
            case STATION_TYPE_NAME.hydrometry:
                return hydroObsResults
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObsResults
            case STATION_TYPE_NAME.quality:
                return qualitoObsResults
            default:
                return []
        }
    }

    const getLabelByType = ({ typeName }) => {
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
                return i18n.catchmentsExploitingResource
            case STATION_TYPE_NAME.piezometry:
                return i18n.monitoringStations
            case STATION_TYPE_NAME.hydrometry:
                return i18n.hydrometricStations
            case STATION_TYPE_NAME.pluviometry:
                return i18n.pluviometricStations
            case STATION_TYPE_NAME.quality:
                return i18n.qualityStations
            case STATION_TYPE_NAME.productionUnit:
                return i18n.productionUnits
            case STATION_TYPE_NAME.distributionUnit:
                return i18n.distributionUnits
            default:
                return ''
        }
    }

    const ofdDatas = useMemo(() => {
        const datas = allLinks.flatMap(l => {
            const listOfOfr = getListOfOfr(l.typeName)
            const ofr = listOfOfr.find(o => o.id === l.stationLinkedId)
            return ofr?.data?.map(d => ({ ...d, stationName: (d.name || l.stationLinkedName) || l.stationLinkedCode })) || []
        })
        const dataInCrisis = getInCrisisTag(datas)
        const dataOnAlert = dataInCrisis.length ? dataInCrisis : getOnAlertTag(datas)
        const dataNoData = dataOnAlert.length ? dataOnAlert : (!small ? [datas.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d) : datas.filter(d => ['grey', 'gray'].includes(d.color)))
        return dataNoData.length ? dataNoData : (!small ? [datas.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d) : datas.filter(d => ['green', 'lightgreen'].includes(d.color)))
    }, [allLinks, piezoObsResults.length, hydroObsResults.length, pluvioObsResults.length, qualitoObsResults.length])

    const ofdDatasFiltered = !small ? ofdDatas.filter(o => !['grey', 'gray'].includes(o.color)) : ofdDatas

    const unitType = isUDI ? STATION_TYPE_NAME.distributionUnit : STATION_TYPE_NAME.productionUnit
    const img = getMarkerByStationType(unitType, !isUDI ? ofdDatas[0]?.color : UDIDefaultColor)

    const linkedResources = useMemo(() => (!accountHabilitations.length || componentHasHabilitations(H_RESOURCE_MODULE)) ? linkedStations.filter((s) => s.code === unit.code && s.typeName === STATION_TYPE_NAME.resource) : [], [linkedStations, unit.code, accountHabilitations])

    return (
        <Grid container sx={{ borderBottom: '1px solid rgb(233, 233, 233)', padding: '0.5rem 0' }}>
            <Grid
                container
                item
                xs={ofdDatas.length ? 8 : 12}
                alignItems='center'
                onClick={() => isUDI ? dispatch(push(`/distributions/${unit.id}`)) : dispatch(push(`/productions/${unit.id}`))}
            >
                {!!img && (
                    <Grid item>
                        <img src={img} alt={unitType} style={{ height: '45px' }} />
                    </Grid>
                )}
                <Grid
                    item
                    xs={11}
                    className='clickable bold'
                    sx={{ fontSize: '20px', marginLeft: '10px' }}
                >
                    {unit.name}
                </Grid>
                {linkedResources.map((r) => (
                    <Grid
                        item
                        xs={12}
                        className='bold'
                        sx={{ fontSize: '16px' }}
                    >
                        {`${i18n.resource}: ${r.stationLinkedName || ''}`}
                    </Grid>
                ))}
                {(unit.contributors || contributorsLinks.filter((l) => l.idStation === unit.id)).map((c) => {
                    const contributor = contributors.find(cs => cs.id === c.idContributor)
                    const type = getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
                    if (contributor && type) {
                        return (
                            <Grid
                                item
                                xs={12}
                                sx={{ fontSize: '14px', marginTop: '5px' }}
                            >
                                {`${type} : ${contributor?.menomonique || contributor?.name}`}
                            </Grid>
                        )
                    }
                    return null
                })}
                {unit.comments && (
                    <Grid
                        item
                        xs={12}
                        sx={{ fontSize: '14px' }}
                    >
                        {`${i18n.descriptif} : ${unit.comments}`}
                    </Grid>
                )}
                {unit.cities?.length && (
                    <Grid container item xs={12} alignItems='center' sx={{ fontSize: '14px', margin: '10px 0 5px' }}>
                        <Grid item sx={{ paddingRight: '0.5rem' }}>
                            {i18n.cities} :
                        </Grid>
                        {unit.cities.map((city, index) => (
                            <Grid item sx={{ paddingRight: '0.15rem' }}>
                                {city}{index < (unit.cities.length - 1) ? ',' : ''}
                            </Grid>
                        ))}
                    </Grid>
                )}
                {!hideAssociatedStation && keys(groupedLinks).map(key => {
                    const links = groupedLinks[key]
                    return (
                        <>
                            <Grid item xs={12} sx={{ fontSize: '14px', margin: '10px 0 5px' }}>{`${getLabelByType(links[0])}: ${links.length}`}</Grid>
                            {links.map((l, i) => {
                                const linkImg = getMarkerByStationType(l.typeName, l.color)
                                return (
                                    <Grid
                                        item
                                        className='clickable'
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        onClick={e => {
                                            e.stopPropagation()
                                            dispatch(push(`/follows/${l.typeName}/${l.stationLinkedId}`))
                                        }}
                                    >
                                        {!!linkImg && (
                                            <img src={linkImg} style={{ height: '25px', width: 'auto', marginRight: '10px' }} />
                                        )}
                                        <a style={{ fontSize: '14px', marginRight: '10px' }}>{`${l.name || ''} ${l.typeName !== STATION_TYPE_NAME.productionUnit ? `[${l.stationLinkedCode}]` : ''}${i !== (links.length - 1) ? ', ' : ''}`}</a>
                                    </Grid>
                                )
                            })}
                        </>
                    )
                })}
            </Grid>
            {!!ofdDatasFiltered.length && (
                <Grid container item xs={4} alignItems='flex-start' justifyContent='flex-end' sx={small && { maxHeight: '125px', overflow: 'auto' }}>
                    {ofdDatasFiltered.map(ofd => (
                        <Grid
                            item
                            xs={!small ? 5.8 : 12}
                            sx={{
                                backgroundColor: ofd.realColor || ofd.color,
                                padding: '3px',
                                margin: !small ? '4px' : '2px 4px',
                                borderRadius: SMALL_RADIUS,
                                textAlign: 'center',
                                color: getTextColorByBackground(ofd.color),
                                border: (ofd.color === 'white' || ofd.color === '#ffffff') && `solid 1px ${mainBlack}`,
                                fontSize: '10.5px',
                                lineHeight: 1.5,
                                height: 'fit-content',
                            }}
                        >
                            <span>{ofd.stationName}{!isNil(ofd.value) && ` - ${ofd.value}`}</span>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    )
}

UnitCard.propTypes = {
    unit: PropTypes.instanceOf(DtoProductionUnit),
    hideAssociatedStation: PropTypes.bool,
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    small: PropTypes.bool,
    isUDI: PropTypes.bool,
}

export default UnitCard