import { createSlice } from '@reduxjs/toolkit'
import { isNil } from 'lodash'
import ReferencialThunk from 'referencial/actions/ReferencialThunk'

export const initialState = {
    parameters: [],
    support: [],
    sandreCodes: [],
    units: [],
    fractions: [],
    contributors: [],
    cities: [],
}

const store = createSlice({
    name: 'referencial',
    initialState,
    reducers: {},
    extraReducers: {
        [ReferencialThunk.fetchParameters.fulfilled]: (state, action) => {
            state.parameters = action.payload.map(param => ({
                ...param,
                shortLabelWithCode: `${param.shortLabel || param.name} ${param.code ? `[${param.code}]` : ''}${param.internalCode ? ` [${param.internalCode}]` : ''}`,
                displayLabel: `${param.shortLabel || param.name} ${param.code ? `[${param.code}]` : ''}`,
                displayName: param.shortLabel || param.name || (!isNil(param.code) && `<${param.code}>`) || '',
            }))
        },
        [ReferencialThunk.fetchSupports.fulfilled]: (state, action) => {
            state.supports = action.payload
        },
        [ReferencialThunk.fetchUnits.fulfilled]: (state, action) => {
            state.units = action.payload.map(unit => ({
                ...unit,
                symbolWithName: `${unit.name || ''} ${unit.symbol ? `[${unit.symbol}]` : ''}`,
                symbolWithCode: unit.code ? `${unit.symbol || ''} [${unit.code}]` : unit.symbol,
            }))
        },
        [ReferencialThunk.fetchFractions.fulfilled]: (state, action) => {
            state.fractions = action.payload
        },
        [ReferencialThunk.fetchSandreCodes.fulfilled]: (state, action) => {
            state.sandreCodes = action.payload
        },
        [ReferencialThunk.fetchContributors.fulfilled]: (state, action) => {
            state.contributors = action.payload
        },
        [ReferencialThunk.fetchCities.fulfilled]: (state, action) => {
            state.cities = action.payload
        },
    },
})

export const ReferencialActionConstant = store.actions
export default store.reducer