import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid, Icon, LinearProgress, Paper, OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel } from '@mui/material'
import { getLogin } from 'utils/LocalStorageUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import HomeAction from 'pages/home/actions/HomeAction'
import ContactAction from './actions/ContactAction'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { orderBy } from 'lodash'
import SupportCard from 'pages/account/components/SupportCard'
import { MEDIUM_RADIUS } from 'components/styled/Theme'

class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: [],
            dataLoaded: false,
            message: '',
        }
    }

    componentDidMount() {
        const { cmsEvents = [] } = this.props
        const homeCms = cmsEvents.find((cms) => cms.id === 3)
        this.props.setTitle([
            {
                title: `${i18n.contactLabel} ${homeCms && homeCms.subtitle ? `l'${homeCms.subtitle}` : ''}`,
                href: '/contact',
            },
        ])
        this.getAllMessages()
    }

    componentDidUpdate() {
        window.scrollTo(0, document.body.scrollHeight)
    }

    getAllMessages = () => {
        const login = getLogin()
        this.props.getMessagesOf(login).then(() => {
            const { listOfMessages } = this.props
            const newMessages = !listOfMessages.length ? [] : listOfMessages
            this.setState({
                messages: newMessages,
                dataLoaded: true,
            })
        })
    }

    handleChangeMessage = (e) => {
        const value = e.target.value
        this.setState({ message: value })
    }

    sendMessage = () => {
        const { message } = this.state
        if (message) {
            const login = getLogin()
            const userMessage = { login, message }
            this.props.sendMessage(userMessage).then(() => {
                this.getAllMessages()
                this.setState({
                    message: '',
                })
            })
        } else {
            ToastrAction.warning('Pas de message à envoyer')
        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.sendMessage()
        }
    }

    render() {
        const { dataLoaded, messages } = this.state

        return (
            <Grid sx={{ padding: '0px 50px' }}>
                <Grid container>
                    <Grid
                        item xs={12} md={8.5}
                        container
                        direction='column'
                        justifyContent='flex-start'
                        sx={{
                            padding: '8px 30px 8px 8px',
                            backgroundColor: 'white',
                        }}
                    >
                        {dataLoaded ? (
                            <>
                                {orderBy(messages, 'updateDate').map((m) => {
                                    const dateString = new Date(m.updateDate).toLocaleString()
                                    return (
                                        <Grid container direction='column'>
                                            <Grid
                                                container
                                                direction='column'
                                                alignItems={m.updateLogin === m.login ? 'flex-end' : 'flex-start'}
                                            >
                                                <Grid
                                                    container
                                                    sx={{
                                                        backgroundColor: m.updateLogin === m.login ? 'white' : '#e7eaef',
                                                        width: '70%',
                                                        padding: '10px',
                                                        margin: '10px 0 10px 0',
                                                        borderRadius: MEDIUM_RADIUS,
                                                        border: 'solid 3px #e7eaef',
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        direction='column'
                                                        alignItems='flex-start'
                                                        sx={{ background: 'none' }}
                                                    >
                                                        <Paper
                                                            className='bold'
                                                            sx={{
                                                                background: 'none',
                                                                boxShadow: 'none',
                                                            }}
                                                        >
                                                            {dateString}{m.updateLogin !== m.login && ` - ${m.updateLogin}`}
                                                        </Paper>
                                                        <Paper sx={{ background: 'none', boxShadow: 'none', paddingTop: '15px' }}>
                                                            {m.message}
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </>
                        ) : <LinearProgress />}
                        <FormControl
                            sx={{
                                textfield: '25ch',
                                width: '100%',
                                marginTop: '20px',
                            }}
                            variant='outlined'
                        >
                            <InputLabel htmlFor='renseignement'>{i18n.anyInformation}</InputLabel>
                            <OutlinedInput
                                id='renseignement'
                                type='text'
                                label={i18n.anyInformation}
                                value={this.state.message}
                                onChange={this.handleChangeMessage}
                                onKeyDown={(e) => this.onKeyDown(e)}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={this.sendMessage}
                                            sx={{
                                                backgroundColor: '#00beff',
                                                borderRadius: '50rem',
                                                height: '40px',
                                                minWidth: '0',
                                                width: '40px',
                                            }}
                                        >
                                            <Icon
                                                sx={{
                                                    height: '25px',
                                                    width: '25px',
                                                    color: 'white',
                                                }}
                                            >
                                                send
                                            </Icon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3.5} sx={{ margin: '30px 0' }}>
                        <SupportCard height='auto' />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    getMessagesOf: ContactAction.getMessagesOf,
    sendMessage: ContactAction.sendMessage,
    push,
}

const mapStateToProps = (store) => {
    return {
        listOfMessages: store.ContactReducer.listOfMessages,
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

Contact.propTypes = {
    setTitle: PropTypes.func,
    getMessagesOf: PropTypes.func,
    sendMessage: PropTypes.func,
    listOfMessages: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.string)),
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
