import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { Grid } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DtoResource from './dto/DtoResource'
import { groupBy, keys, orderBy, uniqWith } from 'lodash'
import { filterObsLinkedStations, getMarkerByStationType } from 'utils/StationUtils'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import DtoObservatoryFollowResult from '../follows/dto/DtoObservatoryFollowResult'

const ResourceCard = ({
    resource = {},
    hideAssociatedStation = false,
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const {
        linkedStations,
    } = useSelector(store => ({
        linkedStations: store.HomeReducer.linkedStations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const getOfr = ({ stationLinkedId, typeName }) => {
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                return piezoObsResults.find(p => p.id === stationLinkedId) || {}
            case STATION_TYPE_NAME.hydrometry:
                return hydroObsResults.find(p => p.id === stationLinkedId) || {}
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObsResults.find(p => p.id === stationLinkedId) || {}
            case STATION_TYPE_NAME.quality:
                return qualitoObsResults.find(p => p.id === stationLinkedId) || {}
            default:
                return {}
        }
    }

    const getInCrisisTag = allTags => {
        const inCrisisTag = [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '')?.includes('alerte'))].filter(d => !!d)
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = [allTags.find(d => (d?.value || '')?.includes('vigilance'))].filter(d => !!d)
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange', realColor: tag.color })) : []
    }

    const getColorLink = (link, ofr) => {
        const allTags = ofr?.data?.map(d => ({ ...d, stationName: link.name || link.code, value: !['grey', 'gray'].includes(d.color) && d.value })) || []
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return tagsNoData[0]?.color
    }

    const groupedLinks = useMemo(() => {
        const filteredLinks = filterObsLinkedStations(linkedStations).filter(s => s.code === resource.code)
        const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const formattedLinks = uniqLinks.map(l => {
            const ofr = getOfr(l)
            return {
                ...l,
                typeName: ofr.typeName || l.typeName,
                name: ofr?.name || l.stationLinkedName,
                code: ofr?.code || l.code,
                color: ofr.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment && getColorLink(l, ofr),
            }
        })
        return groupBy(orderBy(formattedLinks, 'typeName'), 'typeName')
    }, [linkedStations, resource.code])

    const getLabelByType = ({ typeName }) => {
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
                return i18n.catchmentsExploitingResource
            case STATION_TYPE_NAME.piezometry:
                return i18n.monitoringStations
            case STATION_TYPE_NAME.hydrometry:
                return i18n.hydrometricStations
            case STATION_TYPE_NAME.pluviometry:
                return i18n.pluviometricStations
            case STATION_TYPE_NAME.quality:
                return i18n.qualityStations
            case STATION_TYPE_NAME.productionUnit:
                return i18n.productionUnits
            default:
                return ''
        }
    }

    return (
        <Grid container sx={{ borderBottom: '2px solid rgb(233, 233, 233)', padding: '5px 0' }}>
            <Grid item xs={12} onClick={() => dispatch(push(`/resources/${resource.id}`))} className='clickable bold' sx={{ fontSize: '20px' }}>{resource.name}</Grid>
            {!hideAssociatedStation && keys(groupedLinks).map(key => {
                const links = groupedLinks[key]
                return (
                    <>
                        <Grid item xs={12} sx={{ fontSize: '14px', margin: '10px 0 5px' }}>{`${getLabelByType(links[0])}: ${links.length}`}</Grid>
                        {links.map((l, i) => {
                            const url = l.typeName === STATION_TYPE_NAME.productionUnit ? `/productions/${l.stationLinkedId}` : `/follows/${l.typeName}/${l.stationLinkedId}`
                            const img = getMarkerByStationType(l.typeName, l.realColor || l.color)
                            return (
                                <Grid item className='clickable' sx={{ display: 'flex', alignItems: 'center' }} onClick={() => dispatch(push(url))}>
                                    {!!img && (
                                        <img src={img} style={{ height: '25px', width: 'auto', marginRight: '10px' }} />
                                    )}
                                    <a style={{ fontSize: '14px', marginRight: '10px' }}>{`${l.name || ''} ${l.typeName !== STATION_TYPE_NAME.productionUnit ? `[${l.stationLinkedCode}]` : ''}${i !== (links.length - 1) ? ', ' : ''}`}</a>
                                </Grid>
                            )
                        })}
                    </>
                )
            })}
        </Grid>
    )
}

ResourceCard.propTypes = {
    resource: PropTypes.instanceOf(DtoResource),
    hideAssociatedStation: PropTypes.bool,
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

export default ResourceCard