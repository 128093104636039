import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { mainBlue, mainWhite } from 'components/styled/Theme'

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction='up' ref={ref} {...props} />
})

export const DialogMUI = ({ children, PaperProps: { sx, ...otherPaperProps } = {}, maxWidth = 'lg', ...otherProps }) => (
    <Dialog
        fullWidth
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        PaperProps={{
            sx: {
                minHeight: '90vh',
                maxHeight: '90vh',
                ...sx,
            },
            ...otherPaperProps,
        }}
        {...otherProps}
    >
        {children}
    </Dialog>
)

DialogMUI.propTypes = {
    children: PropTypes.element,
    maxWidth: PropTypes.string,
    PaperProps: PropTypes.shape({
        sx: PropTypes.shape({}),
    }),
}

export const DialogTitleMUI = ({ children, sx, ...otherProps }) => (
    <DialogTitle
        sx={{
            borderBottom: 'solid 1px grey',
            fontSize: '1.25rem',
            lineHeight: '1.25rem',
            color: mainWhite,
            backgroundColor: mainBlue,
            padding: '12px 20px',
            ...sx,
        }}
        {...otherProps}
    >
        {children}
    </DialogTitle>
)

DialogTitleMUI.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.shape({}),
}

export const DialogContentMUI = ({ children, sx, ...otherProps }) => (
    <DialogContent
        sx={{
            backgroundColor: 'white',
            padding: '12px 20px !important',
            ...sx,
        }}
        {...otherProps}
    >
        {children}
    </DialogContent>
)

DialogContentMUI.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.shape({}),
}

export const DialogActionsMUI = ({ children, sx, ...otherProps }) => (
    <DialogActions
        sx={{
            borderTop: 'solid 1px grey',
            padding: '12px 20px',
            maxHeight: '10rem',
            ...sx,
        }}
        {...otherProps}
    >
        {children}
    </DialogActions>
)

DialogActionsMUI.propTypes = {
    children: PropTypes.element,
    sx: PropTypes.shape({}),
}