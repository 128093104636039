import ApplicationConf from 'conf/ApplicationConf'
import { path } from 'conf/basepath'
import { orderBy } from 'lodash'
import { genericPromise2 } from 'utils/ActionUtils'
import { getLogin } from 'utils/LocalStorageUtils'
import { hasValue } from 'utils/NumberUtil'

const IAEauAction = {
    getResultChainSync: (body, stationCode) => genericPromise2(ApplicationConf.iaeau.executeChainSync(body.chainCode), {
        method: 'POST',
        body: {
            ...body,
            batchCode: stationCode,
            sieauUser: getLogin(),
            sieauInstance: path.replace('https://', '').replace('/api/', '').replace('.fr', '').replace('.com', '').replace(/\./gi, '').replace('sieau', '').replace('aquasys', '').replace('recette', ''),
        },
    }),
    getHypeTrend: (measures, stationCode = 'BSS') => {
        const iaeauInput = {
            chainCode: 'HYPE',
            overrideInputNodes: [1],
            outputsToTake: [2],
            inputs: [
                {
                    id: 1,
                    dataColumns: [
                        { column: 1, name: 'BSS_CODE', dataFormat: 'text' },
                        { column: 3, name: 'SAMPLE_DATE', dataFormat: 'timestamp' },
                        { column: 10, name: 'REMARK_CODE', dataFormat: 'integer' },
                        { column: 8, name: 'ANALYSIS_RESULT', dataFormat: 'number' },
                        { column: 7, name: 'PARAMETER_CODE', dataFormat: 'integer' },
                        { column: 42, name: 'NAME_UNIT', dataFormat: 'text' },
                        { column: 41, name: 'NAME_PARAMETER', dataFormat: 'text' },
                    ],
                    data: orderBy(measures.filter(m => hasValue(m.measureDate) && hasValue(m.measure)).map(m => ['BSS', m.measureDate, '1', m.measure, 1340, 'unit', '1340']), d => d[1]),
                },
            ],
        }
        return IAEauAction.getResultChainSync(iaeauInput, stationCode).then(result => {
            if (!result?.length) {
                return { trends: [], breaks: [] }
            }
            const values = result[0].data[0]
            if (!values?.length) {
                return { trends: [], breaks: [] }
            }
            let trends = []
            let breaks = []
            if (values[0] !== 'NA') {
                trends.push({
                    statisticsType: 'MANN_KENDALL',
                    value: values[0],
                    coefficient: values[1],
                    stationCode,
                })
            }
            if (values[2] !== 'NA') {
                trends.push({
                    statisticsType: 'LINEAR_REGRESSION',
                    value: values[2],
                    coefficient: values[3],
                    stationCode,
                })
            }
            if (values[6] !== 'NA') {
                breaks.push({
                    statisticsType: 'AVERAGE_RUPTURE',
                    preValue: values[4],
                    postValue: values[5],
                    breakDate: values[6],
                    stationCode,
                })
            }
            if (values[11] !== 'NA') {
                breaks.push({
                    statisticsType: 'TREND_RUPTURE',
                    preValue: values[7],
                    preCoefficient: values[8],
                    postValue: values[9],
                    postCoefficient: values[10],
                    breakDate: values[11],
                    stationCode,
                })
            }
            return { trends, breaks }
        }).catch(() => {
            // eslint-disable-next-line no-console
            // console.error(e)
            return { trends: [], breaks: [] }
        })
    },
}

export default IAEauAction
