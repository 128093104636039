import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { mainBlack, mainGrey, SMALL_RADIUS } from 'components/styled/Theme'
import { Grid, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useStateProgress from 'utils/customHook/useStateProgress'
import { useEffect } from 'react'
import HomeAction from 'pages/home/actions/HomeAction'
import FollowAction from './actions/FollowAction'
import ProgressBar from 'components/progress/ProgressBar'
import { OBSERVATORY_STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { getMarkerByStationType } from 'utils/StationUtils'
import DtoObservatoryFollowResult from './dto/DtoObservatoryFollowResult'
import useTitle from 'utils/customHook/useTitle'
import { isNil, sortBy } from 'lodash'
import { push } from 'connected-react-router'
import Option from 'components/Option'
import { getTextColorByBackground } from 'utils/ColorUtil'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import ResourcesAction from '../resources/actions/ResourcesAction'
import { OBS_FOLLOWS_FILTER } from 'pages/home/constants/HomeConstants'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { SUCCEEDED } from 'store/DataManagerConstants'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE, H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import ToastrAction from 'toastr/actions/ToastrAction'

const OfrDescriptions = ({
    listOfOfr = [],
}) => {
    const dispatch = useDispatch()

    const getInCrisisTag = allTags => {
        const inCrisisTag = [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || d.value?.includes('alerte'))].filter(d => !!d)
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = [allTags.find(d => d.value?.includes('vigilance'))].filter(d => !!d)
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange' })) : []
    }

    const getOfrColor = ofr => {
        const allTags = ofr?.data || []
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return tagsNoData[0]?.color
    }

    return listOfOfr.map(ofr => {
        const img = getMarkerByStationType(ofr.typeName, ofr.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment ? getOfrColor(ofr) : 'black')
        return (
            <Grid container item xs={12} sx={{ borderBottom: `solid 2px ${mainGrey}`, padding: '0.5em 0' }}>
                <Grid container item xs={12} sx={{ cursor: 'pointer' }} onClick={() => dispatch(push(`/follows/${ofr.typeName}/${ofr.id}`))}>
                    {!!img && (
                        <Grid item>
                            <img src={img} alt={ofr.typeName} style={{ height: '25px' }} />
                        </Grid>
                    )}
                    {ofr.city && (
                        <Grid item sx={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '15px' }}>
                            <span>{ofr.city || ''}</span>
                        </Grid>
                    )}
                    {ofr.name && (
                        <>
                            <Grid item sx={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '15px' }}>
                                <span>-</span>
                            </Grid>
                            <Grid item sx={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '15px' }}>
                                <span>{ofr.name}</span>
                            </Grid>
                        </>
                    )}
                    {ofr.code && (
                        <>
                            <Grid item sx={{ fontWeight: 'bold', fontSize: '20px', margin: '0 15px' }}>
                                <span>-</span>
                            </Grid>
                            <Grid item sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                                <span>{ofr.code}</span>
                            </Grid>
                        </>
                    )}
                </Grid>
                {(ofr.aquifer || ofr.nature || ofr.fieldMode) && (
                    <Grid container item xs={12} sx={{ marginTop: '5px' }}>
                        {ofr.aquifer && (
                            <Grid item className='clickable' onClick={() => ofr.aquiferId ? dispatch(push(`/resources/${ofr.aquiferId}`)) : {}}>
                                <span>{i18n.resource}: {ofr.aquifer || ''}</span>
                            </Grid>
                        )}
                        {ofr.nature && (
                            <>
                                {!!ofr.aquifer && (
                                    <Grid item sx={{ marginLeft: '5px' }}>
                                        <span>-</span>
                                    </Grid>
                                )}
                                <Grid item sx={{ marginLeft: '5px' }}>
                                    <span>{ofr.nature}</span>
                                </Grid>
                            </>
                        )}
                        {ofr.fieldMode && (
                            <>
                                {!!ofr.nature && (
                                    <Grid item sx={{ marginLeft: '5px' }}>
                                        <span>-</span>
                                    </Grid>
                                )}
                                <Grid item sx={{ marginLeft: '5px' }}>
                                    <span>{ofr.fieldMode}</span>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
                {!!ofr.data?.length && (
                    <Grid container item xs={12} justifyContent='flex-end'>
                        {ofr.data.filter(ofd => ofd.color !== 'white').map(ofd => (
                            <Grid
                                item
                                md={1.6}
                                xs={4}
                                sx={{
                                    backgroundColor: ofd.color,
                                    padding: '3px',
                                    margin: '0 4px',
                                    borderRadius: SMALL_RADIUS,
                                    textAlign: 'center',
                                    color: getTextColorByBackground(ofd.color),
                                    border: (ofd.color === 'white' || ofd.color === '#ffffff') && `solid 1px ${mainBlack}`,
                                    fontSize: '10.5px',
                                    lineHeight: 1.5,
                                    height: 'fit-content',
                                }}
                            >
                                <span>{ofd.value}</span>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        )
    })
}

OfrDescriptions.propTypes = {
    listOfOfr: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const Follows = () => {
    const {
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        resources,

        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        qualitometersLightStatus,
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
        resourcesStatus,

        accountHabilitations,
    } = useSelector(store => ({
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        resources: store.ResourcesReducer.resources,

        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        hydrometersStatus: store.DataManagerReducer.hydrometers.hydrometersStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometers.pluviometersStatus,
        qualitometersLightStatus: store.DataManagerReducer.qualitometers.qualitometersLightStatus,
        piezoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus: store.DataManagerReducer.follow.hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus: store.DataManagerReducer.follow.pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.qualitoObservatoryFollowResultsStatus,
        resourcesStatus: store.DataManagerReducer.resources.resourcesStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const defaultFilterUser = useAccountSetting(OBS_FOLLOWS_FILTER, setting => setting ? JSON.parse(setting) : {})

    const [showCatchments, setShowCatchments] = useState(true)
    const [showPiezometers, setShowPiezometers] = useState(true)
    const [showHydrometers, setShowHydrometers] = useState(true)
    const [showPluviometers, setShowPluviometers] = useState(true)
    const [showQualitometers, setShowQualitometers] = useState(true)
    const [associatedResource, setAssociatedResource] = useState()

    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)

    useEffect(() => {
        setShowCatchments(isNil(defaultFilterUser.showCatchments) ? true : defaultFilterUser.showCatchments)
        setShowPiezometers(isNil(defaultFilterUser.showPiezometers) ? true : defaultFilterUser.showPiezometers)
        setShowHydrometers(isNil(defaultFilterUser.showHydrometers) ? true : defaultFilterUser.showHydrometers)
        setShowPluviometers(isNil(defaultFilterUser.showPluviometers) ? true : defaultFilterUser.showPluviometers)
        setShowQualitometers(isNil(defaultFilterUser.showQualitometers) ? true : defaultFilterUser.showQualitometers)
        setAssociatedResource(isNil(defaultFilterUser.associatedResource) ? undefined : defaultFilterUser.associatedResource)
    }, [defaultFilterUser])

    const { progress, isLoaded } = useStateProgress([
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        qualitometersLightStatus,
        resourcesStatus,
    ])

    const { isLoaded: observatoryResultsLoaded } = useStateProgress([
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
    ])

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.follows,
        href: '/follows',
    }], [])

    const havePiezoAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PIEZO_MODULE), [accountHabilitations])
    const haveHydroAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_HYDRO_MODULE), [accountHabilitations])
    const havePluvioAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PLUVIO_MODULE), [accountHabilitations])
    const haveQualitoAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_QUALITO_MODULE), [accountHabilitations])
    const haveResourceAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_RESOURCE_MODULE), [accountHabilitations])

    useEffect(() => {
        const habs = havePiezoAccess || haveHydroAccess || havePluvioAccess || haveQualitoAccess
        if (!habs) {
            dispatch(HomeAction.logout())
            dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
        }
    }, [haveHydroAccess, havePiezoAccess, havePluvioAccess, haveQualitoAccess])

    useEffect(() => {
        if (!piezometersLight.length) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (!hydrometers.length) {
            dispatch(HomeAction.fetchHydrometers())
        }
        if (!pluviometers.length) {
            dispatch(HomeAction.fetchPluviometers())
        }
        if (!qualitometersLight.length) {
            dispatch(HomeAction.fetchQualitometersLight())
        }
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }
    }, [])

    useEffect(() => {
        if (!piezoObservatoryFollowResults.length && piezometersLightStatus === SUCCEEDED) {
            const piezoIds = piezometersLight.map(p => p.id)
            dispatch(FollowAction.fetchpiezoObservatoryFollowResults({ ids: piezoIds, progressCallback: setPiezoObservatoryProgress }))
        }
    }, [piezoObservatoryFollowResults.length, piezometersLightStatus])

    useEffect(() => {
        if (!hydroObservatoryFollowResults.length && hydrometersStatus === SUCCEEDED) {
            const hydroIds = hydrometers.map(p => p.id)
            dispatch(FollowAction.fetchhydroObservatoryFollowResults({ ids: hydroIds, progressCallback: setHydroObservatoryProgress }))
        }
    }, [hydroObservatoryFollowResults.length, hydrometersStatus])

    useEffect(() => {
        if (!pluvioObservatoryFollowResults.length && pluviometersStatus === SUCCEEDED) {
            const pluvioIds = pluviometers.map(p => p.id)
            dispatch(FollowAction.fetchpluvioObservatoryFollowResults({ ids: pluvioIds, progressCallback: setPluvioObservatoryProgress }))
        }
    }, [pluvioObservatoryFollowResults.length, pluviometersStatus])

    useEffect(() => {
        if (!qualitoObservatoryFollowResults.length && qualitometersLightStatus === SUCCEEDED) {
            const qualitoIds = qualitometersLight.map(p => p.id)
            dispatch(FollowAction.fetchqualitoObservatoryFollowResults({ ids: qualitoIds, progressCallback: setQualitoObservatoryProgress }))
        }
    }, [qualitoObservatoryFollowResults.length, qualitometersLightStatus])

    const catchmentsOfr = useMemo(() => {
        const ofrByType = sortBy(piezoObservatoryFollowResults.filter(o => o.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment), 'name')
        return !isNil(associatedResource) ? ofrByType.filter(o => o.aquiferId === associatedResource) : ofrByType
    }, [associatedResource, piezoObservatoryFollowResults])
    const piezosOfr = useMemo(() => {
        const ofrByType = sortBy(piezoObservatoryFollowResults.filter(o => o.typeName !== OBSERVATORY_STATION_TYPE_NAME.catchment), 'name')
        return !isNil(associatedResource) ? ofrByType.filter(o => o.aquiferId === associatedResource) : ofrByType
    }, [associatedResource, piezoObservatoryFollowResults])
    const hydrosOfr = useMemo(() => {
        const ofrSorted = sortBy(hydroObservatoryFollowResults, 'name')
        return !isNil(associatedResource) ? ofrSorted.filter(o => o.aquiferId === associatedResource) : ofrSorted
    }, [associatedResource, hydroObservatoryFollowResults])
    const pluviosOfr = useMemo(() => {
        const ofrSorted = sortBy(pluvioObservatoryFollowResults, 'name')
        return !isNil(associatedResource) ? ofrSorted.filter(o => o.aquiferId === associatedResource) : ofrSorted
    }, [associatedResource, pluvioObservatoryFollowResults])
    const qualitosOfr = useMemo(() => {
        const ofrSorted = sortBy(qualitoObservatoryFollowResults, 'name')
        return !isNil(associatedResource) ? ofrSorted.filter(o => o.aquiferId === associatedResource) : ofrSorted
    }, [associatedResource, qualitoObservatoryFollowResults])

    const catchmentsContent = useMemo(() => <OfrDescriptions listOfOfr={catchmentsOfr} />, [catchmentsOfr])
    const piezometersContent = useMemo(() => <OfrDescriptions listOfOfr={piezosOfr} />, [piezosOfr])
    const hydrometersContent = useMemo(() => <OfrDescriptions listOfOfr={hydrosOfr} />, [hydrosOfr])
    const pluviometersContent = useMemo(() => <OfrDescriptions listOfOfr={pluviosOfr} />, [pluviosOfr])
    const qualitometersContent = useMemo(() => <OfrDescriptions listOfOfr={qualitosOfr} />, [qualitosOfr])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const updateSettingFilter = (value) => {
        dispatch(HomeAction.updateSetting({
            settingName: OBS_FOLLOWS_FILTER,
            value: JSON.stringify({
                showCatchments,
                showPiezometers,
                showHydrometers,
                showPluviometers,
                showQualitometers,
                associatedResource,
                ...value,
            }),
        }))
    }

    return (
        <Grid container>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ padding: '1rem', backgroundColor: mainGrey, borderTop: `solid 1px ${mainBlack}` }}>
                <Grid item md={2.5} xs={12}>
                    {!!(haveResourceAccess && resources.length) && (
                        <SimpleMultiAutocomplete
                            value={associatedResource}
                            onChange={(v) => {
                                setAssociatedResource(v)
                                updateSettingFilter({ associatedResource: v })
                            }}
                            options={resources}
                            disablePortal={false}
                            size='30px'
                            labelSize={16}
                        />
                    )}
                </Grid>
                <Grid container item md={9} xs={12} alignItems='center' justifyContent={mdMatches ? 'flex-end' : 'space-around'}>
                    {(componentHasHabilitations(H_PRODUCTION_MODULE) && havePiezoAccess) && (
                        <Option
                            selected={showCatchments}
                            label={i18n.catchments}
                            onClick={() => {
                                setShowCatchments(!showCatchments)
                                updateSettingFilter({ showCatchments: !showCatchments })
                            }}
                            xs='auto'
                            sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                        />
                    )}
                    {havePiezoAccess && (
                        <Option
                            selected={showPiezometers}
                            label={i18n.piezometers}
                            onClick={() => {
                                setShowPiezometers(!showPiezometers)
                                updateSettingFilter({ showPiezometers: !showPiezometers })
                            }}
                            xs='auto'
                            sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                        />
                    )}
                    {haveHydroAccess && (
                        <Option
                            selected={showHydrometers}
                            label={i18n.hydrometricStations}
                            onClick={() => {
                                setShowHydrometers(!showHydrometers)
                                updateSettingFilter({ showHydrometers: !showHydrometers })
                            }}
                            xs='auto'
                            sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                        />
                    )}
                    {havePluvioAccess && (
                        <Option
                            selected={showPluviometers}
                            label={i18n.pluviometers}
                            onClick={() => {
                                setShowPluviometers(!showPluviometers)
                                updateSettingFilter({ showPluviometers: !showPluviometers })
                            }}
                            xs='auto'
                            sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                        />
                    )}
                    {haveQualitoAccess && (
                        <Option
                            selected={showQualitometers}
                            label={i18n.qualitometers}
                            onClick={() => {
                                setShowQualitometers(!showQualitometers)
                                updateSettingFilter({ showQualitometers: !showQualitometers })
                            }}
                            xs='auto'
                            sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                        />
                    )}
                </Grid>
            </Grid>
            {(isLoaded && observatoryResultsLoaded) ? (
                <Grid container item xs={12} sx={{ padding: 'calc(10px - 0.5em) 50px 10px' }}>
                    {showCatchments && catchmentsContent}
                    {showPiezometers && piezometersContent}
                    {showHydrometers && hydrometersContent}
                    {showPluviometers && pluviometersContent}
                    {showQualitometers && qualitometersContent}
                </Grid>
            ) : (
                <ProgressBar progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress) / 5} style={{ width: 'calc(100% - 100px)' }} />
            )}

        </Grid>
    )
}

Follows.propTypes = {

}

export default Follows
