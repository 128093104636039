import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from 'log/actions/LogAction'
import { genericPromise, genericPromise2, promiseAllProgressSeq } from 'utils/ActionUtils'
import ApplicationConf from 'conf/ApplicationConf'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { chunk, flatten, uniqBy } from 'lodash'
import DtoPiezoChartMeasures from '../dto/DtoPiezoChartMeasures'
import DtoPiezoMeasureLight from '../dto/DtoPiezoMeasureLight'
import DtoHydroMeasures from '../dto/DtoHydroMeasures'
import DtoHydroMeasureLight from '../dto/DtoHydroMeasureLight'
import DtoHydroMeasureValidation from '../dto/DtoHydroMeasureValidation'
import DtoPluvioMeasures from '../dto/DtoPluvioMeasures'
import DtoPluvioMeasureLight from '../dto/DtoPluvioMeasureLight'
import DtoHydroStats from '../dto/DtoHydroStats'
import DtoPluviometerStats from '../dto/DtoPluviometerStats'
import DtoMeasureStats from '../dto/DtoMeasureStats'
import { hasValue } from 'utils/NumberUtil'
import DtoPiezometerChartOptions from '../dto/DtoPiezometerChartOptions'
import { IDLE } from 'store/DataManagerConstants'
import moment from 'moment'
import { HYDROMETER_HEIGHT_TYPE } from 'pages/online/referencials/constants/HydrometryConstants'

const FollowAction = {

    fetchpiezoObservatoryFollowResults: createAsyncThunk(
        'fetchpiezoObservatoryFollowResults',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { ids = [], progressCallback = () => {} } = dataObj
            try {
                if (!ids.length) {
                    progressCallback(100)
                    return fulfillWithValue([])
                }
                const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
                const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.piezometer), method: 'POST', body: { ids: idsGroup } }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
                return rejectWithValue(err)
            }
        },
    ),

    fetchhydroObservatoryFollowResults: createAsyncThunk(
        'fetchhydroObservatoryFollowResults',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { ids = [], progressCallback = () => {} } = dataObj
            try {
                if (!ids.length) {
                    progressCallback(100)
                    return fulfillWithValue([])
                }
                const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
                const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.hydrologicalStation), method: 'POST', body: { ids: idsGroup } }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
                return rejectWithValue(err)
            }
        },
    ),

    fetchpluvioObservatoryFollowResults: createAsyncThunk(
        'fetchpluvioObservatoryFollowResults',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { ids = [], progressCallback = () => {} } = dataObj
            try {
                if (!ids.length) {
                    progressCallback(100)
                    return fulfillWithValue([])
                }
                const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
                const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.pluviometer), method: 'POST', body: { ids: idsGroup } }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
                return rejectWithValue(err)
            }
        },
    ),

    fetchqualitoObservatoryFollowResults: createAsyncThunk(
        'fetchqualitoObservatoryFollowResults',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { ids = [], progressCallback = () => {} } = dataObj
            try {
                if (!ids.length) {
                    progressCallback(100)
                    return fulfillWithValue([])
                }
                const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
                const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.qualitometer), method: 'POST', body: { ids: idsGroup } }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
                return rejectWithValue(err)
            }
        },
    ),

    fetchSpecificPiezoObservatoryFollowResult: (ids = [], progressCallback = () => {}) => dispatch => {
        const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
        const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.piezometer), method: 'POST', body: { ids: idsGroup } }))
        return promiseAllProgressSeq(urls, progressCallback).then(jsonResults => flatten(jsonResults))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
            })
    },

    fetchSpecificHydroObservatoryFollowResult: (ids = [], progressCallback = () => {}) => dispatch => {
        const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
        const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.hydrologicalStation), method: 'POST', body: { ids: idsGroup } }))
        return promiseAllProgressSeq(urls, progressCallback).then(jsonResults => flatten(jsonResults))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
            })
    },

    fetchSpecificPluvioObservatoryFollowResult: (ids = [], progressCallback = () => {}) => dispatch => {
        const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
        const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.pluviometer), method: 'POST', body: { ids: idsGroup } }))
        return promiseAllProgressSeq(urls, progressCallback).then(jsonResults => flatten(jsonResults))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
            })
    },

    fetchSpecificQualitoObservatoryFollowResult: (ids = [], progressCallback = () => {}) => dispatch => {
        const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
        const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.station.observatoryFollowResults(OBSERVATORY_STATION_TYPE_NAME.qualitometer), method: 'POST', body: { ids: idsGroup } }))
        return promiseAllProgressSeq(urls, progressCallback).then(jsonResults => flatten(jsonResults))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
            })
    },

    fetchWatermasses: createAsyncThunk(
        'fetchWatermasses',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.watermasses())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.watermasses}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.watermasses))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezometerThresholds: createAsyncThunk(
        'fetchPiezometerThresholds',
        async(stationId, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.thresholds(stationId))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometerThreshold}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerThreshold))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezometerAllThresholds: createAsyncThunk(
        'fetchPiezometerAllThresholds',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.allThresholds())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchHydrometricThresholds: createAsyncThunk(
        'fetchHydrometricThresholds',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.thresholds())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchSeveralHydroStatistics: createAsyncThunk(
        'fetchSeveralHydroStatistics',
        async(ids, { rejectWithValue, fulfillWithValue, dispatch }) => {
            try {
                if (!ids?.length) {
                    return fulfillWithValue([])
                }
                const promises = ids.map(id => ({ url: ApplicationConf.hydrometricStation.statistics(), method: 'POST', body: { id } }))
                const response = await promiseAllProgressSeq(promises, () => {})
                return uniqBy(flatten(response), 'typeId').map(h => new DtoHydroStats(h))
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchSeveralPluvioStatistics: createAsyncThunk(
        'fetchSeveralPluvioStatistics',
        async(ids, { rejectWithValue, fulfillWithValue, dispatch }) => {
            try {
                if (!ids?.length) {
                    return fulfillWithValue([])
                }
                const promises = ids.map(id => ({ url: ApplicationConf.pluviometer.measuresStats(id) }))
                const response = await promiseAllProgressSeq(promises, () => {})
                return uniqBy(flatten(response), 'typeId').map(p => new DtoPluviometerStats(p))
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPluviometerAllThresholds: createAsyncThunk(
        'fetchPluviometerAllThresholds',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometer.thresholds())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezoMeasuresStats: createAsyncThunk(
        'fetchPiezoMeasuresStats',
        async(stationId, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.measuresStats(stationId))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchSeveralPiezoStatistics: createAsyncThunk(
        'fetchSeveralPiezoStatistics',
        async(ids, { rejectWithValue, fulfillWithValue, dispatch }) => {
            try {
                if (!ids?.length) {
                    return fulfillWithValue([])
                }
                const promises = ids.map(id => ({ url: ApplicationConf.piezometer.measuresStats(id) }))
                const response = await promiseAllProgressSeq(promises, () => {})
                return uniqBy(flatten(response), 'typeId').map(p => new DtoMeasureStats(p))
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchHydroStatistics: createAsyncThunk(
        'fetchHydroStatistics',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.statistics(), 'POST', { id })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPluvioStatistics: createAsyncThunk(
        'fetchPluvioStatistics',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometer.measuresStats(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    promiseStationsEvents(type, id) {
        switch (type) {
            case STATION_TYPE_NAME.quality:
                return ApplicationConf.qualitometers.events(id)
            case STATION_TYPE_NAME.piezometry:
                return ApplicationConf.piezometer.events(id)
            case STATION_TYPE_NAME.pluviometry:
                return ApplicationConf.pluviometer.events(id)
            case STATION_TYPE_NAME.hydrometry:
                return ApplicationConf.hydrometricStation.events(id)
            default:
                return null
        }
    },

    fetchStationsEvents: createAsyncThunk(
        'fetchStationsEvents',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { type, ids, progressCallback = () => {} } = dataObj
            try {
                if (!ids?.length) {
                    return fulfillWithValue([])
                }
                const promises = ids.map(id => ({ url: FollowAction.promiseStationsEvents(type, id) }))
                const response = await promiseAllProgressSeq(promises, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.events}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                return rejectWithValue(err)
            }
        },
    ),

    loadPiezoChartMeasures(
        listOfInputs, // liste d'objets correspondants à ChartMeasuresInput dans le service piézo
        progressCallback = () => {},
        signal) {
        return (dispatch) => {
            const promises = listOfInputs.map(body => ({ url: ApplicationConf.piezometer.chartMeasures(), method: 'POST', body, signal }))
            return promiseAllProgressSeq(promises, progressCallback).then(jsonResults => {
                if (signal.aborted) {
                    return []
                }
                return jsonResults.map((json, idx) => {
                    return new DtoPiezoChartMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPiezoMeasureLight(j)) })
                })
            })
                .catch((err) => {
                    if (!`${err}`.includes('signal')) {
                        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                    }
                })
        }
    },

    loadHydroChronicMeasures(
        listOfInputs, // liste d'objets correspondants à HydroMeasuresInput dans le service hydro
        progressCallback = () => {},
        signal) {
        return (dispatch) => {
            const promises = listOfInputs.map(body => ({ url: ApplicationConf.hydrometricStation.chronicMeasures(), method: 'POST', body, signal }))
            return promiseAllProgressSeq(promises, progressCallback).then(jsonResults => {
                if (signal.aborted) {
                    return []
                }
                return jsonResults.map((json, idx) => {
                    return new DtoHydroMeasures({ ...listOfInputs[idx], measures: listOfInputs[idx].chartMode ? json.map(j => new DtoHydroMeasureLight(j)) : json.map(j => new DtoHydroMeasureValidation(j)) })
                })
            })
                .catch((err) => {
                    if (!`${err}`.includes('signal')) {
                        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                    }
                })
        }
    },

    loadPluvioChronicMeasures(
        listOfInputs, // liste d'objets correspondants à PluvioMeasuresInput dans le service pluvio
        progressCallback = () => {},
        signal) {
        return (dispatch) => {
            const promises = listOfInputs.map(body => ({ url: ApplicationConf.pluviometer.chartMeasures(), method: 'POST', body, signal }))
            return promiseAllProgressSeq(promises, progressCallback).then(jsonResults => {
                if (signal.aborted) {
                    return []
                }
                return jsonResults.map((json, idx) => {
                    return new DtoPluvioMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPluvioMeasureLight(j)) })
                })
            })
                .catch((err) => {
                    if (!`${err}`.includes('signal')) {
                        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                    }
                })
        }
    },

    fetchQualitometerOperations: createAsyncThunk(
        'fetchQualitometerOperations',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.qualitometers.operations(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchQualifications: createAsyncThunk(
        'fetchQualifications',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.qualification(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchStatus: createAsyncThunk(
        'fetchStatus',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.status(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchParameters: createAsyncThunk(
        'fetchParameters',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.parameters())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.parameters}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.parameters))
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { parametersStatus } = state.DataManagerReducer.follow
                return parametersStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    simpleGetAnalysis: (filter, signal) => genericPromise2(ApplicationConf.qualitometers.allAnalysis(), { method: 'POST', body: filter, signal }),

    getAnalysis: (queries, stations = [], callback = () => { }, progressCallback = () => { }) => dispatch => {
        const groupsSize = stations.length / 50 > 20 ? stations.length / 20 : 50
        const promises = chunk(stations, groupsSize).map(stationsGroup => ({ url: ApplicationConf.qualitometers.allAnalysis(), method: 'POST', body: { ...queries, stations: stationsGroup } }))
        return promiseAllProgressSeq(promises, progressCallback).then(jsonResults => {
            const results = flatten(jsonResults)
            callback(results.length)
            return results
        })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.analysis}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                callback()
            })
    },

    fetchUnits: createAsyncThunk(
        'fetchUnits',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.units())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.units}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.units))
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                const { unitsStatus } = state.DataManagerReducer.follow
                return unitsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchLinkedPiezoMeasures: createAsyncThunk(
        'fetchLinkedPiezoMeasures',
        async(dataObj, { rejectWithValue, dispatch }) => {
            const { id, occurence, nbOccurence } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.piezometer.linkedPiezoMeasures(id, occurence, nbOccurence))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometerMesures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezometryDataTypes: createAsyncThunk(
        'fetchPiezometryDataTypes',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.dataTypes())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.dataTypes}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.dataTypes))
                return rejectWithValue(err)
            }
        },
    ),

    fetchHydrometryDataTypes: createAsyncThunk(
        'fetchHydrometryDataTypes',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.dataTypes())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.dataTypes}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.dataTypes))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPluviometryDataTypes: createAsyncThunk(
        'fetchPluviometryDataTypes',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometer.dataTypes())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.dataTypes}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.dataTypes))
                return rejectWithValue(err)
            }
        },
    ),

    fetchLinkedPiezoPrel: createAsyncThunk(
        'fetchLinkedPiezoPrel',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.linkedPiezoPrel(), 'POST', data)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometerMesures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
                return rejectWithValue(err)
            }
        },
    ),

    fetchLinkedPiezosPrel: createAsyncThunk(
        'fetchLinkedPiezosPrel',
        async(ids, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.linkedPiezosPrel(), 'POST', ids)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometerMesures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezometerChartOptions: createAsyncThunk(
        'fetchPiezometerChartOptions',
        async(stationId, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.chartOptions(stationId))
                return response.map(dt => new DtoPiezometerChartOptions({ ...dt, dataType: hasValue(dt.dataType) ? dt.dataType : '-1' }))
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometryOptions}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometryOptions))
                return rejectWithValue(err)
            }
        },
    ),

    hasSeveralDataPerDay: createAsyncThunk(
        'hasSeveralDataPerDay',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { dataTypesId, stationId, progressCallback = () => {} } = dataObj
            try {
                if (!dataTypesId.length) {
                    progressCallback(100)
                    return fulfillWithValue([])
                }
                const urls = dataTypesId.map(typeId => ({
                    url: ApplicationConf.piezometer.hasSeveralDataPerDay(),
                    method: 'POST',
                    body: { stationId, typeId },
                    setResult: (finalResult, newRes) => finalResult.push({ hasSeveralDataPerDay: newRes, typeId }),
                }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.numberDataPerDay}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.numberDataPerDay))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezoLastMeasures: createAsyncThunk(
        'fetchPiezoLastMeasures',
        async(stations, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.piezometerLastMeasures(), 'POST', {
                    date: moment().valueOf(),
                    nbDays: 30,
                    ids: stations.map(station => station.id),
                })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                return rejectWithValue(err)
            }
        },
    ),

    fetchHydroLastMeasures: createAsyncThunk(
        'fetchHydroLastMeasures',
        async({ stations, dataType = HYDROMETER_HEIGHT_TYPE }, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.hydrologicalStationLastMeasures(), 'POST', {
                    ids: stations.map(station => station.id),
                    dataType,
                })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                return rejectWithValue(err)
            }
        },
    ),

    fetchAllHydroLastMeasures: createAsyncThunk(
        'fetchAllHydroLastMeasures',
        async({ stations, dataTypes }, { rejectWithValue, dispatch }) => {
            try {
                const response = await Promise.all(dataTypes.map(dataType => dispatch(FollowAction.fetchHydroLastMeasures({ stations, dataType }))))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPluvioLastMeasures: createAsyncThunk(
        'fetchPluvioLastMeasures',
        async(stations, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometer.pluviometerLastMeasures(), 'POST', stations.map(station => station.id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                return rejectWithValue(err)
            }
        },
    ),

}

export default FollowAction
