import { ADMIN, CONSULT, GEST, TECH } from 'pages/home/constants/HabilitationConstants'
import AppStore from '../store/AppStore'
import { hasValue } from './NumberUtil'

const componentHasHabilitations = (habs) => {
    const accountUser = AppStore.getState().AccountReducer.accountUser
    const accountHabilitations = AppStore.getState().AccountReducer.accountHabilitations
    const requiredValid = habs.required.every(c => accountHabilitations.map(a => a.habilitation).includes(c))
    const atLeastOneValid = !habs.atLeastOne.length || habs.atLeastOne.some(c => accountHabilitations.map(a => a.habilitation).includes(c))
    const requiredRoles = !habs.requiredRoles.length || habs.requiredRoles.some(c => (
        c === ADMIN && accountUser.isAdmin === '1' ||
        c === GEST && accountUser.metadata === '1' ||
        c === TECH && accountUser.isTechnicien === '1' ||
        c === CONSULT && accountUser.consultant === '1'
    ))
    const forbiddenRoles = !habs.forbiddenRoles.some(c => (
        c === ADMIN && accountUser.isAdmin === '1' ||
        c === GEST && accountUser.metadata === '1' ||
        c === TECH && accountUser.isTechnicien === '1' ||
        c === CONSULT && accountUser.consultant === '1'
    ))
    return requiredValid && atLeastOneValid && requiredRoles && forbiddenRoles
}

const isAuthorized = (hab = {}, store = {}) => {
    const {
        accountUser = [],
        accountHabilitations = [],
    } = store.AccountReducer ?? store // perhaps chance that

    const {
        required = [],
        atLeastOne = [],
        requiredRoles = [],
        forbiddenRoles = [],
    } = hab

    const requiredValid = required.every(c => accountHabilitations.some(h => h.habilitation === c))
    const atLeastOneValid = !atLeastOne.length || atLeastOne.some(c => accountHabilitations.some(h => h.habilitation === c))

    const functionTestRole = role => {
        return role === ADMIN && accountUser.isAdmin === '1'
            || role === GEST && accountUser.metadata === '1'
            || role === TECH && accountUser.isTechnicien === '1'
            || role === CONSULT && accountUser.consultant === '1'
    }

    const requiredRolesValid = !requiredRoles.length || requiredRoles.some(functionTestRole)
    const forbiddenRolesValid = !forbiddenRoles.some(functionTestRole)

    return requiredValid && atLeastOneValid && requiredRolesValid && forbiddenRolesValid
}

const authorizeFilter = (list, user, selected, elemKey = 'contributorCode') => {
    if (user.isAdmin === '1' || !hasValue(user.contributorCode)) {
        return list
    }
    return list.filter(elem => !hasValue(elem[elemKey]) || elem[elemKey] === user.contributorCode || elem.id === selected)
}

const authorizeContributors = (contributors, user, selected) => {
    if (user.isAdmin === '1' || !hasValue(user.contributorCode)) {
        return contributors
    }
    return contributors.filter(({ contributorCode, id }) => !hasValue(contributorCode) || contributorCode === user.contributorCode || id === user.contributorCode || id === selected)
}

const authorizeExport = (module = 'PIEZO') => {
    const accountHabilitations = AppStore.getState().AccountReducer.accountHabilitations
    return accountHabilitations.some(h => (h.habilitation === `${module}_ALL`) || (h.habilitation === `${module}_EXPORT`))
}

export { componentHasHabilitations, authorizeFilter, authorizeContributors, isAuthorized, authorizeExport }
