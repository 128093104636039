import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useBoolean from 'utils/customHook/useBoolean'
import { Grid, Tooltip, Icon, useMediaQuery } from '@mui/material'
import { MEDIUM_RADIUS, secondaryBlue } from 'components/styled/Theme'

const RIGHT = 'right'
const LEFT = 'left'

const SimpleTabSideList = ({
    defaultTab,
    tabs = [],
    children,

    position = RIGHT,
    isOpenByDefault = false,

    onChangeTab = () => {},
    containerStyle = {},
}) => {
    const {
        value: isOpen,
        toggle,
    } = useBoolean(isOpenByDefault)

    const isRight = position === RIGHT

    const [tab, setTab] = useState(defaultTab)
    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <div
            style={{
                zIndex: 11,
                height: '100%',
                position: 'absolute',
                [position]: isOpen ? '0' : (mdMatches ? '-35%' : '-75%'),
                width: mdMatches ? '35%' : '75%',
                transition: 'all 0.4s cubic-bezier(0.19, 0.9, 0.46, 0.99)',
                ...containerStyle,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    [position]: '100%',
                    width: '50px',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Grid container direction='column' rowSpacing={'10px'} sx={{ paddingTop: '10px' }}>
                    {
                        tabs.map(({ label, icon, constant }, i) => {
                            const isCurrent = constant === tab
                            const content = (
                                <Grid
                                    item
                                    key={i}
                                    sx={{
                                        zIndex: isCurrent ? 9 : undefined,
                                        maxWidth: '100%',
                                    }}
                                >
                                    <div
                                        className='clickable'
                                        style={{
                                            backgroundColor: isCurrent ? 'white' : '#f4f4f4',
                                            color: secondaryBlue,
                                            padding: '10px',
                                            borderRadius: isRight ? `${MEDIUM_RADIUS} 0 0 ${MEDIUM_RADIUS}` : `0 ${MEDIUM_RADIUS} ${MEDIUM_RADIUS} 0`,
                                            textAlign: 'center',
                                            display: 'flex',
                                            boxShadow: `${isRight ? '5px' : '-5px'} 0px 2px white, inset ${isRight ? '-2px' : '2px'} 0px 2px white, 0px 0px 5px #888`,
                                        }}
                                        onClick={() => {
                                            if (isCurrent || !isOpen) {
                                                toggle()
                                            }
                                            onChangeTab(constant)
                                            setTab(constant)
                                        }}
                                    >
                                        <Icon>{icon}</Icon>
                                    </div>
                                </Grid>
                            )
                            return label ? (
                                <Tooltip title={label} placement={isRight ? LEFT : RIGHT}>
                                    {content}
                                </Tooltip>
                            ) : content
                        })
                    }
                </Grid>
            </div>
            <div
                style={{
                    position: 'initial',
                    height: '100%',
                    display: 'block',
                    backgroundColor: 'white',
                    width: '100%',
                    padding: '10px',
                    overflowY: 'auto',
                    boxShadow: isOpen ? '0px 0px 5px #888' : undefined,
                }}
            >
                {children(tab)}
            </div>
        </div>
    )
}

SimpleTabSideList.propTypes = {
    defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        label: PropTypes.string,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    children: PropTypes.func.isRequired,

    position: PropTypes.oneOf([RIGHT, LEFT]),
    isOpenByDefault: PropTypes.bool,
    onChangeTab: PropTypes.func,
    containerStyle: PropTypes.shape({}),
}

export default SimpleTabSideList
