import { range } from 'lodash'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { HYPE_TRENDS_CONSTANTS } from 'pages/online/components/echart/ChartConstant'

const getTrendName = type => {
    switch (type) {
        case HYPE_TRENDS_CONSTANTS.MANN_KENDALL:
            return i18n.mannKendallTrend
        case HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION:
            return i18n.linearRegressionTrend
        case HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE:
            return i18n.averageTrend
        case HYPE_TRENDS_CONSTANTS.TREND_RUPTURE:
            return i18n.trendRupture
        default:
            return i18n.mannKendallTrend
    }
}

const formatHypeTrend = (coefficient, value, minDate, maxDate) => {
    const minYear = moment(minDate).year()
    const maxYear = moment(maxDate).year()
    return range(minYear, maxYear + 1).flatMap(y => {
        const totalDays = moment(`31/12/${y}`, 'DD/MM/YYYY').dayOfYear()
        return range(1, totalDays + 1).map(day => ({
            date: moment().year(y).dayOfYear(day).valueOf(),
            value: coefficient * (y - 1970 + ((day - 1) / totalDays)) + value,
        })).filter((_, index) => (index % 5) === 0)
    })
}

const formatHypeTrendRupture = (rupture, minDate, maxDate) => {
    const preTrend = ['preValue', 'preCoefficient', 'breakDate'].every(k => hasValue(rupture[k])) ? formatHypeTrend(rupture.preCoefficient, rupture.preValue, minDate, rupture.breakDate) : []
    const postTred = ['postValue', 'postCoefficient', 'breakDate'].every(k => hasValue(rupture[k])) ? formatHypeTrend(rupture.postCoefficient, rupture.postValue, rupture.breakDate, maxDate) : []
    return [
        ...preTrend.filter(t => t.date <= rupture.breakDate),
        { date: rupture.breakDate, value: null },
        ...postTred.filter(t => t.date >= rupture.breakDate),
    ]
}

export {
    getTrendName,
    formatHypeTrend,
    formatHypeTrendRupture,
}
