import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, LinearProgress, styled } from '@mui/material'
import { LARGE_RADIUS, mainGrey, mainWhite, SMALL_RADIUS } from 'components/styled/Theme'

const Progress = styled((props) => (
    <LinearProgress
        sx={{
            height: '7px',
            borderRadius: LARGE_RADIUS,
            backgroundColor: mainWhite,
        }}
        color='black'
        {...props}
    />
))({})

const ProgressBar = ({
    progress = 0,
    indeterminate = false,
    style = {},
    title,
}) => (
    <div style={{ paddingTop: '1.5rem', width: '100%' }}>
        <div
            style={{
                backgroundColor: mainGrey,
                borderRadius: SMALL_RADIUS,
                padding: '2rem',
                width: '80%',
                margin: 'auto',
                ...style,
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <h3 style={{ fontSize: '18px', textAlign: 'center' }}>{title || i18n.progressLoading}</h3>
                </Grid>
                <Grid item xs={12}>
                    {indeterminate ? (
                        <Progress />
                    ) : (
                        <Progress variant='determinate' value={progress} />
                    )}
                </Grid>
            </Grid>
        </div>
    </div>
)

ProgressBar.propTypes = {
    progress: PropTypes.number,
    indeterminate: PropTypes.bool,
    style: PropTypes.shape({}),
    title: PropTypes.string,
}

export default ProgressBar
