import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CmsAction from 'pages/cms/actions/CmsAction'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Button, Grid, Icon, TextField } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { InputRow } from 'components/styled/Inputs'
import { mainWhite } from 'components/styled/Theme'
import { STATUS } from 'pages/cms/constants/CmsConstants'


const CmsFaqDialog = ({
    isOpenFaq = false,
    setIsOpenFaq = () => { },
}) => {
    const cmsFAQParam = useApplicationSetting('categoriesCmsFaq', setting => setting ? parseInt(setting) : undefined)
    const dispatch = useDispatch()

    const [title, setTitle] = useState('')
    const [comment, setComment] = useState('')

    const createFaq = () => {
        if (title?.length) {
            dispatch(CmsAction.createCMSEvent({
                title,
                comment,
                idCategory: cmsFAQParam,
                status: STATUS.DRAFTED,
                cmsDocument: [],
            })).then(() => {
                setTitle('')
                setComment('')
                setIsOpenFaq(false)
            })
        }
    }

    return (
        <DialogMUI
            open={isOpenFaq}
            maxWidth='md'
            PaperProps={{
                sx: {
                    minHeight: '60vh',
                    maxHeight: '60vh',
                },
            }}
        >
            <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid item>
                    <h3 style={{ margin: 0, fontWeight: '600', fontSize: '20px' }}>{i18n.addNewSubject}</h3>
                </Grid>
                <Grid item>
                    <Icon
                        sx={{ color: mainWhite, cursor: 'pointer' }}
                        size='small'
                        icon='close'
                        onClick={() => setIsOpenFaq(false)}
                    >
                        close
                    </Icon>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container>
                    <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                        <InputRow
                            item
                            id='title'
                            label={i18n.title}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            variant='outlined'
                            color='primary'
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                        <TextField
                            item
                            id='name'
                            color='primary'
                            label={i18n.comment}
                            type='text'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            variant='outlined'
                            multiline
                            rows={6}
                            sx={{ width: '100%', lineHeight: '15px' }}
                        />
                    </Grid>
                </Grid>
                {i18n.newTopicRestrictions}
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container item xs={12} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <Button variant='contained' color='primary' fullWidth onClick={createFaq}>
                            {i18n.creation}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}


CmsFaqDialog.propTypes = {
    isOpenFaq: PropTypes.bool,
    setIsOpenFaq: PropTypes.func,
}

export default CmsFaqDialog