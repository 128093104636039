import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import HomeAction from '../actions/HomeAction'
import ProgressBar from 'components/progress/ProgressBar'
import { loadWMSProjections } from 'utils/mapUtils/CoordinateUtils'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import {
    HOME_LIST_OF_LAYERS_OPACITY,
    HOME_LIST_OF_SELECTED_LAYERS,
    HOME_LIST_OF_SELECTED_STATIONS,
    HOME_SELECTED_BACKGROUND,
    MY_MAPS,
} from '../constants/HomeConstants'
import FollowAction from 'pages/online/follows/actions/FollowAction'
import ObservatoryMap from './map/ObservatoryMap'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { WMS_LAYER_TYPE } from './map/MapConstant'
import { parseNowVariableURL } from 'utils/mapUtils/UrlUtils'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { compact } from 'lodash'
import CmsAction from 'pages/cms/actions/CmsAction'

const Home = () => {
    const {
        piezometersLight,
        hydrometers,
        pluviometers,
        qualitometersLight,
        productionUnits,
        mapLayers,
        cartographyThemes,

        piezoLastMeasures,
        hydroLastMeasures,
        pluvioLastMeasures,

        piezometerAllThresholds,
        hydrometryThresholds,
        pluviometerAllThresholds,

        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,

        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,

        cmsTitle,
        cmsCategories,
        cmsEvents,
    } = useSelector(store => ({
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        qualitometersLight: store.HomeReducer.qualitometersLight,
        productionUnits: store.HomeReducer.productionUnits,
        mapLayers: store.HomeReducer.mapLayers,
        cartographyThemes: store.HomeReducer.cartographyThemes,

        piezoLastMeasures: store.FollowReducer.piezoLastMeasures,
        hydroLastMeasures: store.FollowReducer.hydroLastMeasures,
        pluvioLastMeasures: store.FollowReducer.pluvioLastMeasures,

        piezometerAllThresholds: store.FollowReducer.piezometerAllThresholds,
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,

        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
        hydrometryDataTypes: store.FollowReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.FollowReducer.pluviometryDataTypes,

        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,

        cmsTitle: store.CmsReducer.cmsTitle,
        cmsCategories: store.CmsReducer.cmsCategories,
        cmsEvents: store.CmsReducer.cmsEvents,
    }), shallowEqual)

    const myMaps = useApplicationSetting(MY_MAPS, setting => setting || '')

    const defaultSelectedStations = useAccountSetting(HOME_LIST_OF_SELECTED_STATIONS, setting => setting?.split(',') || undefined)
    const defaultSelectedLayers = useAccountSetting(HOME_LIST_OF_SELECTED_LAYERS, setting => setting?.split(',') || undefined)
    const defaultLayersOpacity = useAccountSetting(HOME_LIST_OF_LAYERS_OPACITY, v => v ? JSON.parse(v) : [])
    const defaultSelectedBackground = useAccountSetting(HOME_SELECTED_BACKGROUND, setting => setting || undefined)

    const [layers, setLayers] = useState([])

    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)
    const [linkedStationsProgress, setLinkedStationsProgress] = useState(0)
    const [layerProgress, setLayersProgress] = useState(0)

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        !mapLayers.length && HomeAction.fetchMapLayers,
        !cartographyThemes.length && HomeAction.fetchCartographyThemes,
        !piezometersLight.length && HomeAction.fetchPiezometersLight,
        !hydrometers.length && HomeAction.fetchHydrometers,
        !pluviometers.length && HomeAction.fetchPluviometers,
        !qualitometersLight.length && HomeAction.fetchQualitometersLight,
        !productionUnits.length && HomeAction.fetchProductionUnits,

        !piezometerAllThresholds.length && FollowAction.fetchPiezometerAllThresholds,
        !hydrometryThresholds.length && FollowAction.fetchHydrometricThresholds,
        !pluviometerAllThresholds.length && FollowAction.fetchPluviometerAllThresholds,

        !piezometryDataTypes.length && FollowAction.fetchPiezometryDataTypes,
        !hydrometryDataTypes.length && FollowAction.fetchHydrometryDataTypes,
        !pluviometryDataTypes.length && FollowAction.fetchPluviometryDataTypes,

        !cmsEvents.length && CmsAction.fetchCMSEvents,
        !cmsCategories.length && CmsAction.fetchCMSCategories,
    ]).map(p => dispatch(p())), [])

    const getLegend = (url, layerName) => {
        if (url.includes('#MYMAPS/')) {
            return `${parseNowVariableURL(url, myMaps)}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
        }
        const urlLower = url.toLowerCase()
        return `${url}${urlLower.indexOf('?') >= 0 ? urlLower.indexOf('version') >= 0 ? '' : 'version=1.3.0' : '?version=1.3.0'}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
    }

    const { isLoaded: followsLoaded } = useProgressDispatch(() => {
        if (!isLoaded) {
            return []
        }
        const piezoLastMeasurePromise = !piezoLastMeasures.length ? [dispatch(FollowAction.fetchPiezoLastMeasures(piezometersLight))] : []
        const hydroLastMeasurePromise = !hydroLastMeasures.length ? [dispatch(FollowAction.fetchAllHydroLastMeasures({ stations: hydrometers, dataTypes: hydrometryDataTypes.map(hdt => hdt.id) }))] : []
        const pluvioLastMeasurePromise = !pluvioLastMeasures.length ? [dispatch(FollowAction.fetchPluvioLastMeasures(pluviometers))] : []

        const linkedStations = [
            dispatch(HomeAction.fetchAllLinkedUnitsStations({ ids: productionUnits.map((p) => p.id), progressCallback: setLinkedStationsProgress })),
            ...piezoLastMeasurePromise,
            ...hydroLastMeasurePromise,
            ...pluvioLastMeasurePromise,
        ]
        const piezoFollowPromise = !piezoObservatoryFollowResults.length ? [...linkedStations, dispatch(FollowAction.fetchpiezoObservatoryFollowResults({
            ids: piezometersLight.map(p => p.id),
            progressCallback: setPiezoObservatoryProgress,
        }))] : linkedStations
        const hydroFollowPromise = !hydroObservatoryFollowResults.length ? [...piezoFollowPromise, dispatch(FollowAction.fetchhydroObservatoryFollowResults({
            ids: hydrometers.map(p => p.id),
            progressCallback: setHydroObservatoryProgress,
        }))] : piezoFollowPromise
        const pluvioFollowPromise = !pluvioObservatoryFollowResults.length ? [...hydroFollowPromise, dispatch(FollowAction.fetchpluvioObservatoryFollowResults({
            ids: pluviometers.map(p => p.id),
            progressCallback: setPluvioObservatoryProgress,
        }))] : hydroFollowPromise
        const qualitoFollowPromise = !qualitoObservatoryFollowResults.length ? [...pluvioFollowPromise, dispatch(FollowAction.fetchqualitoObservatoryFollowResults({
            ids: qualitometersLight.map(p => p.id),
            progressCallback: setQualitoObservatoryProgress,
        }))] : pluvioFollowPromise

        // Layers
        const filteredLayers = mapLayers.filter(ml => cartographyThemes.some(ct => ct.id === ml.theme))
        return [...qualitoFollowPromise, ...filteredLayers.map((la, layerIndex) => {
            const legend = (parseInt(la.typeLayer) === WMS_LAYER_TYPE && la.url) && getLegend(la.url, la.layer)
            if (!legend) {
                setLayers(prev => [...prev, { ...la, legend: undefined }])
                return undefined
            }
            return dispatch(HomeAction.checkUrl(legend)).then(validUrl => {
                setLayers(prev => [...prev, { ...la, legend: validUrl && legend }])
                setLayersProgress((layerIndex / filteredLayers.length) * 100)
            })
        }).filter(p => !!p)]
    }, [isLoaded])

    useTitle(() => [{
        title: i18n.home,
        href: 'home',
    },
    loadWMSProjections(),
    ], [])

    const heightMenu = cmsTitle?.title?.length > 1 ? '128px' : '100px'

    const homeProgress = ((progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress + linkedStationsProgress + layerProgress) / 7)

    return (
        <Grid container sx={{ height: `calc(100vh - ${heightMenu})`, maxHeight: `calc(100vh - ${heightMenu})`, padding: '10px' }}>
            <Grid container item xs={12} sx={{ position: 'relative', overflow: 'hidden' }} alignItems='stretch'>
                {(isLoaded && followsLoaded) ? (
                    <ObservatoryMap
                        defaultSelectedStations={defaultSelectedStations}
                        defaultSelectedLayers={defaultSelectedLayers}
                        defaultLayersOpacity={defaultLayersOpacity}
                        defaultSelectedBackground={defaultSelectedBackground}

                        layers={layers}
                    />
                ) : <ProgressBar progress={homeProgress} />}
            </Grid>
        </Grid>
    )
}

export default Home
