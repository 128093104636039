import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { Grid, Icon, TextField } from '@mui/material'
import { InputRow } from 'components/styled/Inputs'
import ReCAPTCHA from 'react-google-recaptcha'
import { KEY_RECAPTCHA } from '../constants/HomeConstants'
import { PrimaryButton } from 'components/styled/Buttons'
import ProgressBar from 'components/progress/ProgressBar'
import { useDispatch } from 'react-redux'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import ToastrAction from 'toastr/actions/ToastrAction'
import HomeAction from '../actions/HomeAction'

const LoginContact = ({}) => {
    const [loading, setLoading] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState()
    const [contact, setContact] = useState({ name: '', mail: '', message: '' })
    const [contactErrors, setContactErrors] = useState({ nameError: false, emailError: false, messageError: false })
    const [validRecaptcha, setValidRecaptcha] = useState(false)

    const mailSupport = useApplicationSetting('supportMailAddress', setting => setting || 'support@aquasys.fr')

    const dispatch = useDispatch()

    useEffect(() => {
        if (recaptchaToken) {
            dispatch(HomeAction.checkReCaptchaAssessment(recaptchaToken)).then(setValidRecaptcha)
        }
    }, [recaptchaToken])

    const isValidEmail = email => (!!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))

    const onSendEmail = () => {
        const { name, mail, message } = contact
        if (name && mail && message) {
            if (!isValidEmail(mail)) {
                ToastrAction.error(i18n.invalidEmail)
            } else {
                setLoading(true)
                const email = {
                    to: mailSupport,
                    from: mailSupport,
                    subject: `Contact de : ${name} (${mail})`,
                    message: `Contenu du message : ${message}\n${mail}`,
                }
                dispatch(HomeAction.sendMailToAdmin(email)).then(() => {
                    setContact({ name: '', mail: '', message: '' })
                    setLoading(false)
                })
            }
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            onSendEmail()
        }
    }

    return (
        <Grid container item xs={12} justifyContent='center'>
            <Grid item xs={12} sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '1.25rem', fontSize: '1.75rem' }}>
                {i18n.contactUs}
            </Grid>
            {!loading ? (
                <>
                    <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                        <Grid item xs={1} />
                        <Grid item xs={1}>
                            <Icon>person</Icon>
                        </Grid>
                        <Grid item xs={8}>
                            <InputRow
                                id='name'
                                label={i18n.name}
                                type='text'
                                value={contact.name}
                                onChange={e => {
                                    setContact(prev => ({ ...prev, name: e.target.value }))
                                    setContactErrors(prev => ({ ...prev, nameError: false }))
                                }}
                                error={contactErrors.nameError}
                                helperText={contactErrors.nameError && i18n.fillField}
                                onKeyDown={onKeyDown}
                            />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                    <Grid container item xs={12} alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                        <Grid item xs={1} />
                        <Grid item xs={1}>
                            <Icon>email</Icon>
                        </Grid>
                        <Grid item xs={8}>
                            <InputRow
                                id='email'
                                label={i18n.email}
                                type='text'
                                value={contact.mail}
                                onChange={e => {
                                    setContact(prev => ({ ...prev, mail: e.target.value }))
                                    setContactErrors(prev => ({ ...prev, emailError: false }))
                                }}
                                variant='outlined'
                                error={contactErrors.emailError}
                                helperText={contactErrors.emailError && i18n.fillField}
                                onKeyDown={onKeyDown}
                            />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                    <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ marginBottom: '1.5rem' }}>
                        <Grid item xs={8}>
                            <TextField
                                id='message'
                                color='primary'
                                label={i18n.yourMessage}
                                type='text'
                                value={contact.message}
                                onChange={e => {
                                    setContact(prev => ({ ...prev, message: e.target.value }))
                                    setContactErrors(prev => ({ ...prev, messageError: false }))
                                }}
                                variant='outlined'
                                multiline
                                sx={{ width: '100%', lineHeight: '15px' }}
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='center' sx={{ marginBottom: '1.5rem' }}>
                        <Grid item>
                            <ReCAPTCHA
                                sitekey={KEY_RECAPTCHA}
                                onChange={setRecaptchaToken}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <PrimaryButton onClick={onSendEmail} disabled={!validRecaptcha}>
                            {i18n.sendMessage}
                        </PrimaryButton>
                    </Grid>
                </>
            ) : <ProgressBar indeterminate />}
        </Grid>
    )
}

export default LoginContact