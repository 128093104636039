import DtoDistributionAssociatedTown from './DtoDistributionAssociatedTown'
import DtoDistributionCounter from './DtoDistributionCounter'
import DtoDistributionNetwork from './DtoDistributionNetwork'
import DtoPiezometerContributorLink from './DtoPiezometerContributorLink'
import DtoStationAssociation from './DtoStationAssociation'


export default class DtoDistributionUnit {
    constructor(obj) {
        this.id = obj.id // Option[Int] = None,
        this.code = obj.code // Option[String] = None,
        this.name = obj.name // Option[String] = None,
        this.geographicSector = obj.geographicSector // Option[String] = None,
        this.creationDate = obj.creationDate // Option[DateTime] = None,
        this.exploitationModeCode = obj.exploitationModeCode // Option[Int] = None,
        this.subscriberNumber = obj.subscriberNumber // Option[Double] = None,
        this.productionQuantity = obj.productionQuantity // Option[Double] = None,
        this.distributedQuantity = obj.distributedQuantity // Option[Double] = None,
        this.descriptive = obj.descriptive // Option[String] = None,
        this.mnemonic = obj.mnemonic // Option[String] = None,
        this.waterNature = obj.waterNature // Option[Double] = None,
        this.managementUnit = obj.managementUnit // Option[Long] = None,
        this.decreePopulation = obj.decreePopulation // Option[Double] = None,
        this.permanentPopulation = obj.permanentPopulation // Option[Double] = None,
        this.summerPopulation = obj.summerPopulation // Option[Double] = None,
        this.winterPopulation = obj.winterPopulation // Option[Double] = None,
        this.dataOrigin = obj.dataOrigin // Option[String] = None,
        this.status = obj.status // Option[Int] = None,
        this.loginStatus = obj.loginStatus // Option[String] = None,
        this.dateStatus = obj.dateStatus // Option[DateTime] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.lengthWaterPipe = obj.lengthWaterPipe // Option[Double] = None,
        this.linearOutConnection = obj.linearOutConnection // Option[Double] = None,
        this.aepNetworkRenewalRate = obj.aepNetworkRenewalRate // Option[Double] = None,
        this.nbConnections = obj.nbConnections // Option[Int] = None,
        this.lengthConnections = obj.lengthConnections // Option[Double] = None,
        this.averageAgeConnection = obj.averageAgeConnection // Option[Double] = None,
        this.mixteSyndicate = obj.mixteSyndicate // Option[Boolean] = None,
        this.member = obj.member // Option[Boolean] = None,
        this.enterYear = obj.enterYear // Option[Int] = None,
        this.exitYear = obj.exitYear // Option[Int] = None,
        this.treatedlength = obj.treatedlength // Option[Double] = None,
        this.connectionWithoutCounter = obj.connectionWithoutCounter // Option[Int] = None,
        this.sector = obj.sector // Option[Long] = None,

        this.link_contributors = obj.link_contributors ? obj.link_contributors.map(e => new DtoPiezometerContributorLink(e)) : [] // Option[Seq[StationContributorLink]] = Some(Seq()),
        this.link_associatedStations = obj.link_associatedStations ? obj.link_associatedStations.map(ce => new DtoStationAssociation(ce)) : [] // Option[Seq[StationLinkDetailed]] = Some(Seq()),
        this.link_associatedTowns = obj.link_associatedTowns ? obj.link_associatedTowns.map(at => new DtoDistributionAssociatedTown(at)) : [] // Option[Seq[DistributionAssociatedTown]] = Some(Seq()),
        this.link_counters = obj.link_counters ? obj.link_counters.map(ai => new DtoDistributionCounter(ai)) : [] // Option[Seq[DistributionCounter]] = Some(Seq())
        this.link_networks = obj.link_networks ? obj.link_networks.map(ai => new DtoDistributionNetwork(ai)) : [] // Option[Seq[DtoDistributionNetwork]] = Some(Seq())

        // front
        this.townCode = obj.townCode
        this.typeName = 'distributionUnit'
        this.headers = ['nullValue', 'code', 'name', 'sector', 'waterNature', 'operator', 'collectivity', 'exploitationModeCode', 'subscriberNumber', 'productionQuantity', 'distributedQuantity']
    }
}