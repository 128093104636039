import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/styles'
import { mainBlack, mainBlue } from 'components/styled/Theme'

const Span = styled('span')(() => ({
    color: mainBlack,
    fontWeight: 'bold',
    marginLeft: '1.2rem',
    fontSize: '14px',
    height: '19px',
}))

const FieldLabel = ({
    label,
    required,
}) => {
    return (
        <Span>
            {label}
            {required && (<span style={{ color: mainBlue, marginLeft: '5px' }}>*</span>)}
        </Span>
    )
}

FieldLabel.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
}

export default FieldLabel