import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, XYZ } from 'ol/source'
import { Point } from 'ol/geom'
import { Style, Icon as Marker } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import { Feature, Map, Overlay, View } from 'ol'
import { ScaleLine, Rotate, defaults as defaultControls, FullScreen } from 'ol/control'
import { filterObsLinkedStations, getMarkerByStationType } from 'utils/StationUtils'
import { getWGS84Coordinate } from 'utils/mapUtils/CoordinateUtils'
import { compact, keyBy, uniqWith } from 'lodash'
import DtoFile from '../referencials/documents/dto/DtoFile'
import { KML } from 'ol/format'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import DtoObservatoryFollowResult from '../follows/dto/DtoObservatoryFollowResult'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { Grid } from '@mui/material'
import { push } from 'connected-react-router'
import { SMALL_RADIUS } from 'components/styled/Theme'

class LittleMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: uuidv4(),
        }
    }

    componentDidMount() {
        this.setMap()
    }

    getListOfOfr = (typeName) => {
        const { piezoObservatoryFollowResults, hydroObservatoryFollowResults, pluvioObservatoryFollowResults, qualitoObservatoryFollowResults } = this.props
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                return piezoObservatoryFollowResults
            case STATION_TYPE_NAME.hydrometry:
                return hydroObservatoryFollowResults
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObservatoryFollowResults
            case STATION_TYPE_NAME.quality:
                return qualitoObservatoryFollowResults
            default:
                return []
        }
    }

    getInCrisisTag = allTags => {
        const inCrisisTag = [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '')?.includes('alerte'))].filter(d => !!d)
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    getOnAlertTag = allTags => {
        const alertTag = [allTags.find(d => (d?.value || '')?.includes('vigilance'))].filter(d => !!d)
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange' })) : []
    }

    formatUnitPoint = (list, stationType, catchments) => {
        const { linkedStations } = this.props
        return list.map(l => {
            const links = filterObsLinkedStations(linkedStations).filter(s => s.code === l.code)
            const uniqLinks = uniqWith(links, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
            const catchmentsIds = catchments.map(c => c.id)
            const allTags = uniqLinks.filter(ul => catchmentsIds.includes(ul.stationLinkedId)).flatMap(ul => {
                const listOfOfr = this.getListOfOfr(ul.typeName)
                const ofr = listOfOfr.find(o => o.id === ul.stationLinkedId)
                return ofr?.data?.map(d => ({ ...d, stationName: ul.stationLinkedName || ul.stationLinkedCode })) || []
            })
            const tagsInCrisis = this.getInCrisisTag(allTags)
            const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : this.getOnAlertTag(allTags)
            const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
            const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
            return {
                ...l,
                marker: getMarkerByStationType(stationType, tagsNoData[0]?.color),
            }
        })
    }

    formatCatchmentPoint = list => list.map(l => {
        const listOfOfr = this.getListOfOfr(l.typeName)
        const ofr = listOfOfr.find(o => o.id === l.id)
        const allTags = ofr?.data?.map(d => ({ ...d, stationName: l.name || l.code })) || []
        const tagsInCrisis = this.getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : this.getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return {
            ...l,
            color: tagsNoData[0]?.color,
        }
    })

    getFormattedPoints = (points) => {
        const catchmentsPoints = points.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment)
        const unitsPoints = points.filter(p => p.typeName === STATION_TYPE_NAME.productionUnit)
        const filteredPoints = points.filter(p => ![OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.productionUnit].includes(p.typeName))
        return [
            ...this.formatCatchmentPoint(catchmentsPoints),
            ...unitsPoints,
            ...filteredPoints,
        ]
    }

    setMap = () => {
        const { points, kml, zoom, center, cities } = this.props
        const citiesIndex = keyBy(cities, 'id')
        const { id } = this.state

        const container = document.getElementById('popup')
        const closer = document.getElementById('popup-closer')

        const kmlLayers = kml?.length ? kml.map((k) => new VectorLayer({
            source: new VectorSource({
                url: k.url,
                format: new KML(),
            }),
        })) : []
        const overlay = new Overlay({
            element: container,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        })
        const olMap = new Map({
            target: `ol-map-${id}`,
            controls: defaultControls().extend([
                new ScaleLine({
                    units: 'metric',
                }),
                new Rotate(),
                new FullScreen({
                    className: 'ol-full-screen',
                }),
            ]),
            layers: [
                new TileLayer({
                    source: new XYZ({
                        attributions: ['Tiles &copy Esri &mdash Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'],
                        url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    }),
                }),
                ...kmlLayers,
            ],
            overlays: [overlay],
        })
        const wgs84Sites = compact(this.getFormattedPoints(points).map((point) => {
            if (point?.x && point?.y) {
                const coordinates = getWGS84Coordinate(point)
                const marker = getMarkerByStationType(point.typeName, point.color)
                const city = citiesIndex[point.townCode]
                const markersLayer = new VectorLayer({
                    className: 'clickable',
                    zIndex: 100,
                    source: new VectorSource({
                        features: [
                            new Feature({
                                geometry: new Point(fromLonLat(coordinates)),
                                stationName: (
                                    <Grid
                                        key={point.id}
                                        className='clickable'
                                        onClick={() => {
                                            if ([OBSERVATORY_STATION_TYPE_NAME.catchment,
                                                STATION_TYPE_NAME.piezometry,
                                                STATION_TYPE_NAME.hydrometry,
                                                STATION_TYPE_NAME.pluviometry,
                                                STATION_TYPE_NAME.quality].includes(point.typeName)) {
                                                this.props.push(`/follows/${point.typeName}/${point.id}`)
                                            } else if (point.typeName === STATION_TYPE_NAME.productionUnit) {
                                                this.props.push(`/productions/${point.id}`)
                                            } else if (point.typeName === STATION_TYPE_NAME.resource) {
                                                this.props.push(`/resources/${point.id}`)
                                            }
                                        }}
                                        container
                                        justifyContent='flex-start'
                                        alignItems='center'
                                    >
                                        <Grid item xs='auto' container alignItems='center' className='padding-right-2'>
                                            <img src={point.marker} style={{ height: '30px', width: 'auto' }} />
                                        </Grid>
                                        <Grid item xs='auto' container direction='column'>
                                            <Grid>{point.code ? `${point.typeName !== STATION_TYPE_NAME.productionUnit ? `[${point.code}]` : ''} ` : ''}{point.name || ''}</Grid>
                                            <Grid><b>{city ? city.name : ''}</b></Grid>
                                        </Grid>
                                    </Grid>
                                ),
                            }),
                        ],
                    }),
                    style: new Style({
                        image: new Marker({
                            anchor: [0.5, 1],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                            scale: 0.75,
                            src: marker,
                        }),
                    }),
                })
                olMap.addLayer(markersLayer)
                return coordinates
            }
            return null
        }))
        const sumX = wgs84Sites.map((coord) => coord[0]).reduce((a, b) => a + b, 0) || 2.549
        const sumY = wgs84Sites.map((coord) => coord[1]).reduce((a, b) => a + b, 0) || 47.233
        const centerMap = fromLonLat([sumX / (wgs84Sites.length || 1), sumY / (wgs84Sites.length || 1)]) || center

        olMap.setView(new View({
            center: centerMap,
            zoom: zoom || 14,
        }))
        closer.onclick = () => {
            overlay.setPosition(undefined)
            closer.blur()
            return false
        }
        olMap.on('singleclick', (evt) => {
            const stationName = olMap.forEachFeatureAtPixel(evt.pixel, (feature) => {
                return feature.get('stationName')
            })
            if (stationName) {
                container.style.display = 'block'
                const coordinate = evt.coordinate
                this.setState({ content: stationName })
                overlay.setPosition(coordinate)
            } else {
                container.style.display = 'none'
            }
        })
    }

    render() {
        const { styleCard, height, card } = this.props
        const { id, content } = this.state
        if (card) {
            return (
                <div style={{ height: `${height}px`, ...styleCard }}>
                    <div id={`ol-map-${id}`} className='ol-little-map' style={{ height: `${height}px` }} />
                    <div id='popup' className='ol-popup'>
                        <a href='#' id='popup-closer' className='ol-popup-closer' />
                        <div id='popup-content'>{content}</div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div id={`ol-map-${id}`} className='ol-little-map' style={{ height: `${height}px`, borderRadius: `${SMALL_RADIUS} ${SMALL_RADIUS} 0 0`, overflow: 'hidden' }} />
                <div id='popup' className='ol-popup'>
                    <a href='#' id='popup-closer' className='ol-popup-closer' />
                    <div id='popup-content'>{content}</div>
                </div>
            </div>
        )
    }
}

LittleMap.propTypes = {
    points: PropTypes.instanceOf(PropTypes.instanceOf({})),
    cities: PropTypes.arrayOf(PropTypes.shape({})),
    stateCode: PropTypes.number,
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
    height: PropTypes.string,
    card: PropTypes.bool,
    push: PropTypes.func,
    styleCard: PropTypes.shape({}),
    kml: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    piezoObservatoryFollowResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObservatoryFollowResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObservatoryFollowResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObservatoryFollowResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    linkedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
}

const mapStateToProps = (store) => ({
    piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
    hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
    pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
    qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
    linkedStations: store.HomeReducer.linkedStations,
    cities: store.ReferencialReducer.cities,
})

const mapDispatchToProps = {
    push,
}

LittleMap.defaultProps = {
    height: 200,
    point: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(LittleMap)