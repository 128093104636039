/* eslint-disable consistent-return */
/* eslint-disable max-nested-callbacks */
import { every, groupBy, isNull, isUndefined, keys, max, maxBy, mean, minBy, orderBy, range, round, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getRemarks, getTabColorsBySize, getThresholdType, THRESHOLD_TYPE, calculateAverage, filterResult, filterValid, calculateThresholdResult, getThreshold, searchMinResult, searchMaxResult, searchP90Result } from 'utils/AnalyseUtils'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import QualityChart from '../../pages/online/components/echart/QualityChart'
import { AUTO, DEFAULT_NB_POINT_MOVING_AVERAGE, HYPE_TRENDS_CONSTANTS, MONTH, WEEK, YEAR } from '../../pages/online/components/echart/ChartConstant'
import DtoQualityThreshold from 'pages/online/follows/dto/DtoQualityThreshold'
import useAbortController from 'utils/customHook/useAbortController'
import useBoolean from 'utils/customHook/useBoolean'
import { formatHypeTrend, formatHypeTrendRupture, getTrendName } from 'utils/HypeUtils'
import WaitAction from 'wait/WaitAction'
import IAEauAction from 'pages/online/follows/actions/IAEauAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../pages/online/components/styled/dialog'
import { Grid } from '@mui/material'
import Icon from 'components/Icon'
import { StyledFieldSet } from 'components/StyledElements'
import { RemarkLegend, ThresholdLegend } from 'quality/components/Legend'
import SimpleCheckbox from '../../pages/online/components/forms/SimpleCheckbox'
import QualityAction from 'quality/actions/QualityAction'
import RadioButtons from 'components/form/RadioButtons'

const movingAveragePoints = [
    { value: 1, label: '3' },
    { value: 2, label: '5' },
    { value: 3, label: '7' },
    { value: 4, label: '9' },
]

const getStationName = (station, parameter) => {
    if (station?.code) {
        return `[${station?.code}] ${station?.name || ''}, ${parameter?.displayName || ''}`
    }
    return station?.name ? `${station.name}, ${parameter?.displayName || ''}` : parameter?.displayName
}

const getColorTab = (paramThreshold, thresholdType) => {
    if (!paramThreshold) {
        return []
    }
    if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
        return ['red', 'blue', 'red']
    }
    const allColorTab = getTabColorsBySize()
    const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(paramThreshold[`threshold${nb}`])).length + 1
    return allColorTab[nbThresholds]
}

const ParameterGraph = ({
    analysis = [],
    thresholds = [],

    hypeRuptures = [],
    hypeTrends = [],

    graphOptions = {},

    componentHeight = 600,

    withToolLegend = false,
    withArea = false,

    exportName,

    dataZoomPosition = { bottom: 40 },
    legendPosition = { bottom: 10, right: 20, top: undefined },
    toolboxPosition = { top: 5 },
}) => {
    const {
        qualitometers,
        parameters,
        units,
        settings,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
        parameters: store.ReferencialReducer.parameters,
        units: store.ReferencialReducer.units,
        settings: store.AccountReducer.accountUserSettings,
    }), shallowEqual)

    const {
        regroupAxis = false,
        graphicTitle,

        displayStatistics = false,
        displayAverages = false,
        nbPointMovingAverage = DEFAULT_NB_POINT_MOVING_AVERAGE,
        displayHype = false,

        minY,
        maxY,
        xAxisSpace,
        maxXAxisSpace,
        showXSplitLines,
        showYSplitLines,

        stationsOptions = [],
    } = graphOptions

    const parametersCode = useMemo(() => uniqBy(analysis, 'parameter').map(a => a.parameter), [analysis])
    const onlyOneParameter = useMemo(() => parametersCode.length === 1, [parametersCode.length])
    const onlyOneQualitometer = useMemo(() => uniqBy(analysis, 'qualitometer').length === 1, [analysis])

    const unitsCode = useMemo(() => uniqBy(analysis, 'unit').map(a => a.unit || 'unknown'), [analysis])
    const unitByParam = useMemo(() => {
        const groupByParameter = groupBy(analysis, 'parameter')
        return Object.keys(groupByParameter).reduce((acc, paramCode) => {
            const uniqUnit = uniqBy(groupByParameter[paramCode], 'unit').map(a => a.unit || 'unknown')
            acc[paramCode] = {
                unitsCode: uniqUnit.join('#'),
                unitslabel: uniqUnit.map(code => getLabel(units, code, 'symbolWithCode') || i18n.unknownUnit).join(', '),
            }
            return acc
        }, {})
    }, [analysis, units])

    const paramThreshold = useMemo(() => {
        if (!onlyOneParameter || (unitsCode.length > 1 && !regroupAxis)) {
            return
        }
        const [parameterCode] = parametersCode
        const unitCode = unitsCode.length === 1 ? analysis[0]?.unit : undefined
        const qualitometerId = onlyOneQualitometer ? analysis[0]?.qualitometer : undefined
        return getThreshold(thresholds, parameterCode, unitCode, qualitometerId)
    }, [analysis, onlyOneParameter, onlyOneQualitometer, parametersCode, regroupAxis, thresholds, unitsCode.length])

    const thresholdType = useMemo(() => getThresholdType(paramThreshold), [paramThreshold])

    const getMarkerColor = (color) => {
        if (!onlyOneParameter || isUndefined(paramThreshold)) {
            return undefined
        }
        return color === 'white' ? 'black' : color
    }

    const analysisFormated = useMemo(() => {
        const groupAnalysis = groupBy(analysis, a => regroupAxis ? `${a.qualitometer}#${a.parameter}` : `${a.qualitometer}#${a.parameter}#${a.unit || 'unknown'}`)
        return Object.keys(groupAnalysis).flatMap(key => {
            const [stationId, paramCode, unitCode] = key.split('#')
            const parseStationId = parseInt(stationId)
            const stationOptions = stationsOptions.find(o => o.id === parseStationId)
            const {
                [key]: analysisFiltered = [],
            } = groupAnalysis
            const parameter = parameters.find(p => p.code === paramCode)
            const qualitometer = qualitometers.find(q => q.id === parseStationId)
            return {
                // uniqId: parseStationId,
                name: getStationName(qualitometer, parameter),
                unit: unitByParam[paramCode]?.unitslabel,
                yAxisId: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
                color: stationOptions?.color || (onlyOneQualitometer && onlyOneParameter ? 'black' : undefined),
                dataList: analysisFiltered.map(a => ({
                    date: a.sampleDate,
                    value: a.result,
                    unit: getLabel(units, a.unit, 'symbol') || i18n.unknownUnit,
                    marker: getRemarks(a.remark)?.plot,
                    color: getMarkerColor(a.color),
                })),
            }
        })
    }, [analysis, onlyOneParameter, onlyOneQualitometer, parameters, qualitometers, regroupAxis, stationsOptions, units, unitByParam])

    const parametersObj = parametersCode.map(code => parameters.find(p => p.code === code)).filter(u => !!u)
    const unitsObj = uniqBy(analysis, 'unit').map(a => units.find(u => u.code === a.unit)).filter(u => !!u)

    const chartTitle = isUndefined(graphicTitle) ? `${parametersObj.map(p => p.displayLabel).join(', ')} ${unitsObj.length ? `(${unitsObj.map(u => u.symbolWithCode).join(', ')})` : ''}` : graphicTitle

    const thresholdFormated = useMemo(() => {
        if (!paramThreshold) {
            return []
        }
        const [paramCode] = parametersCode
        const [unitCode] = unitsCode

        if (isUndefined(paramThreshold)) return []

        const colorList = getColorTab(paramThreshold, thresholdType)

        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            return [{
                yAxisId: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
                colorList,
                dataList: [
                    {
                        value: paramThreshold.threshold1,
                        color: 'red',
                    },
                    {
                        value: paramThreshold.threshold2,
                        color: 'red',
                    },
                ],
            }]
        }

        return [{
            yAxisId: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
            colorList,
            dataList: [1, 2, 3, 4].map(nb => ({
                value: paramThreshold[`threshold${nb}`],
                color: colorList[nb],
            })).filter(t => !isUndefined(t.value)),
        }]
    }, [paramThreshold, parametersCode, regroupAxis, thresholdType, unitByParam, unitsCode])

    const markLines = useMemo(() => {
        if (!displayStatistics) {
            return []
        }
        const validAnalysis = filterValid(filterResult(analysis))
        if (!validAnalysis.length) {
            return []
        }
        const minResult = searchMinResult(validAnalysis)
        const maxResult = searchMaxResult(validAnalysis)
        const p90Result = searchP90Result(validAnalysis)
        const averageResult = calculateAverage(validAnalysis, settings)

        const [paramCode] = parametersCode
        const [unitCode] = unitsCode

        return [{
            yAxisId: regroupAxis ? unitByParam[paramCode]?.unitsCode : unitCode,
            dataList: [
                {
                    value: minResult,
                    label: i18n.min,
                },
                {
                    value: maxResult,
                    label: i18n.max,
                },
                {
                    value: p90Result,
                    label: i18n.percentile90,
                },
                {
                    value: averageResult,
                    label: i18n.average,
                },
            ],
        }]
    }, [analysis, displayStatistics, parametersCode, regroupAxis, settings, unitByParam, unitsCode])

    const movingAverage = useMemo(() => {
        if (!displayAverages || parametersCode.length !== 1) {
            return []
        }
        const [paramCode] = parametersCode
        const analysisGroup = groupBy(analysis, a => regroupAxis ? a.qualitometer : `${a.qualitometer}#${a.unit || 'unknown'}`)
        return keys(analysisGroup).map(key => {
            const [, unit] = key.split('#')
            const orderedAnalysis = orderBy(analysisGroup[key], 'sampleDate')
            const dataList = orderedAnalysis.flatMap((a, index) => {
                if (index < nbPointMovingAverage || index >= (orderedAnalysis.length - nbPointMovingAverage)) {
                    return []
                }
                const rangeList = range(index - nbPointMovingAverage, index + nbPointMovingAverage + 1)
                const average = mean(rangeList.map(idx => orderedAnalysis[idx].result))
                return { date: a.sampleDate, value: round(average, 3) }
            })
            const { qualitometer: stationId } = analysisGroup[key][0]
            const qualitometer = qualitometers.find(q => q.id === stationId)
            const stationOptions = stationsOptions.find(o => o.id === stationId)
            return {
                name: `[${qualitometer?.code || key}] ${i18n.movingAverage} ${nbPointMovingAverage * 2 + 1}`,
                lineStyle: {
                    type: 'dotted',
                },
                color: stationOptions?.color,
                yAxisId: regroupAxis ? unitByParam[paramCode]?.unitsCode : unit,
                dataList,
            }
        })
    }, [analysis, displayAverages, nbPointMovingAverage, parametersCode, qualitometers, regroupAxis, stationsOptions, unitByParam])

    const hypeLines = useMemo(() => {
        const ids = uniqBy(analysis, 'qualitometer').map(a => a.qualitometer)
        if (!displayHype || ids.length !== 1 || parametersCode.length !== 1) {
            return []
        }

        if (hypeTrends.length || hypeRuptures.length) {
            const [paramCode] = parametersCode
            const hypeData = analysis.filter(a => hasValue(a.value)).map(a => ({
                date: a.sampleDate,
                result: a.result || 0,
            }))
            const maxValue = max([maxBy(hypeData, 'result')?.result || 0, ...thresholdFormated.map(t => t.value)])
            const minDate = minBy(hypeData, 'date')?.date
            const maxDate = maxBy(hypeData, 'date')?.date
            const lines = hypeTrends.filter(t => t.coefficient && t.value).map(trend => {
                return {
                    name: getTrendName(trend.statisticsType),
                    showSymbol: false,
                    yAxisId: unitByParam[paramCode]?.unitsCode,
                    dataList: formatHypeTrend(trend.coefficient, trend.value, minDate, maxDate),
                }
            })
            const ruptures = hypeRuptures.flatMap(rupture => {
                const name = getTrendName(rupture.statisticsType)
                const ruptureLine = {
                    name,
                    dataList: [
                        { date: rupture.breakDate, value: 1.1 * maxValue },
                        { date: rupture.breakDate, value: 0 },
                    ],
                    yAxisId: unitByParam[paramCode]?.unitsCode,
                    lineStyle: {
                        type: 'dashed',
                    },
                }
                if (rupture.statisticsType === HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE && every(['preValue', 'postValue', 'breakDate'], key => hasValue(rupture[key]))) {
                    // average line
                    const line = {
                        name,
                        connectNulls: false,
                        yAxisId: unitByParam[paramCode]?.unitsCode,
                        dataList: [
                            { date: minDate, value: rupture.preValue },
                            { date: rupture.breakDate, value: rupture.preValue },
                            { date: rupture.breakDate, value: null },
                            { date: rupture.breakDate, value: rupture.postValue },
                            { date: maxDate, value: rupture.postValue },
                        ],
                    }
                    return [ruptureLine, line]
                } else if (rupture.statisticsType === HYPE_TRENDS_CONSTANTS.TREND_RUPTURE && (['preValue', 'preCoefficient', 'breakDate'].every(k => hasValue(rupture[k])) || ['postValue', 'postCoefficient', 'breakDate'].every(k => hasValue(rupture[k])))) {
                    // trend inversion line
                    const line = {
                        name,
                        connectNulls: false,
                        showSymbol: false,
                        yAxisId: unitByParam[paramCode]?.unitsCode,
                        dataList: formatHypeTrendRupture(rupture, minDate, maxDate),
                    }
                    return [ruptureLine, line]
                }
                return []
            })
            return [...lines, ...ruptures]
        }
        return []
    }, [analysis, displayHype, hypeRuptures, hypeTrends, parametersCode, thresholdFormated, unitByParam])

    const yAxis = useMemo(() => {
        if (regroupAxis) {
            const paramAxis = parametersCode.map(code => {
                return {
                    name: unitByParam[code]?.unitslabel,
                    yAxisId: unitByParam[code]?.unitsCode,
                    min: minY,
                    max: maxY,
                    splitLine: { show: showYSplitLines },
                }
            })
            return uniqBy(paramAxis, 'yAxisId')
        }
        return unitsCode.map(code => ({
            name: getLabel(units, code, 'symbolWithCode') || i18n.unknownUnit,
            yAxisId: code,
            min: minY,
            max: maxY,
            splitLine: { show: showYSplitLines },
        }))
    }, [maxY, minY, parametersCode, regroupAxis, showYSplitLines, unitByParam, units, unitsCode])

    return (
        <QualityChart
            title={chartTitle}
            exportName={exportName ?? `${i18n.follow} - ${parametersObj.map(p => p.displayName).join(', ')}`}
            grids={[{
                height: 'auto',
                bottom: 120,
                top: 10,
            }]}
            xAxis={[{
                splitLine: { show: showXSplitLines },
            }]}
            yAxis={yAxis}
            // series={[]}
            series={[...analysisFormated, ...movingAverage, ...hypeLines]}
            thresholds={thresholdFormated}
            markLines={markLines}

            headerHeight={0}
            footerHeight={120}
            componentHeight={componentHeight}

            xAxisSpace={xAxisSpace}
            maxXAxisSpace={maxXAxisSpace}

            withToolTypeLine
            withToolTypeBar={!displayHype}
            withToolTypeStack={!displayHype}
            withToolLog={!displayHype}
            withToolThreshold
            withToolLegend={withToolLegend}
            withToolMarker
            withToolLine={!displayHype}

            withArea={withArea}
            withDataZoom
            withHidingYAxis

            dataZoomPosition={dataZoomPosition}
            legendPosition={legendPosition}
            toolboxPosition={toolboxPosition}
        />
    )
}

ParameterGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    hypeRuptures: PropTypes.arrayOf(PropTypes.shape({})),
    hypeTrends: PropTypes.arrayOf(PropTypes.shape({})),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        graphicTitle: PropTypes.string,

        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,

        minY: PropTypes.number,
        maxY: PropTypes.number,
        xAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        maxXAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,

        stationsOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            color: PropTypes.string,
        })),
    }),

    componentHeight: PropTypes.number,

    withToolLegend: PropTypes.bool,
    withArea: PropTypes.bool,

    exportName: PropTypes.string,

    dataZoomPosition: PropTypes.shape({}),
    legendPosition: PropTypes.shape({}),
    gridPosition: PropTypes.shape({}),
    toolboxPosition: PropTypes.shape({}),
}

const ParameterGraphModal = ({
    isOpen = false,
    closeGraph = () => { },

    analysis = [],
    thresholds = [],

    graphOptions = {},
    hideActions = false,
}) => {
    const dispatch = useDispatch()
    const {
        qualitometers,
        parameters,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
        parameters: store.ReferencialReducer.parameters,
    }), shallowEqual)

    const { value: displayStatistics, toggle: toggleDisplayStatistics, setValue: setDisplayStatistics } = useBoolean(false)
    const { value: displayAverages, toggle: toggleDisplayAverages, setValue: setDisplayAverages } = useBoolean(false)
    const [nbPointMovingAverage, setNbPointMovingAverage] = useState(1)
    const { value: displayHype, toggle: toggleDisplayHype, setValue: setDisplayHype } = useBoolean(false)

    const [hypeRuptures, setHypeRuptures] = useState([])
    const [hypeTrends, setHypeTrends] = useState([])

    useEffect(() => {
        if (!isOpen) {
            setDisplayStatistics(false)
            setDisplayAverages(false)
            setNbPointMovingAverage(1)
            setDisplayHype(false)
        }
    }, [isOpen])

    useEffect(() => {
        const ids = uniqBy(analysis, 'qualitometer').map(a => a.qualitometer)
        if (displayHype && ids.length === 1) {
            const [id] = ids
            const qualitometer = qualitometers.find(q => q.id === id)
            if (!qualitometer) {
                return
            }

            const hypeData = analysis.filter(a => hasValue(a.value)).map((a, index) => ({
                stationCode: qualitometer.code,
                stationTypeCode: qualitometer.stationType,
                parameterCode: parseInt(a.parameter) || 0,
                analysisNumber: index,
                measureDate: a.sampleDate,
                measure: a.result,
                unitCode: 0,
                remark: 1,
            }))
            if (hypeData.length) {
                dispatch(WaitAction.waitStart())
                IAEauAction.getHypeTrend(hypeData, qualitometer.code)
                    .then(({ trends, breaks }) => {
                        setHypeTrends(trends)
                        setHypeRuptures(breaks)
                        if (!trends.length && !breaks.length) {
                            dispatch(ToastrAction.warning(i18n.hypeNoTrendsDetected))
                            setDisplayHype(false)
                        }
                    })
                    .finally(() => {
                        dispatch(WaitAction.waitStop())
                    })
            }
        }
    }, [analysis, dispatch, displayHype, qualitometers])

    const onlyOneQualitometer = useMemo(() => uniqBy(analysis, 'qualitometer').length === 1, [analysis])
    const onlyOneUnit = useMemo(() => uniqBy(analysis, 'unit').length === 1, [analysis])

    const parameterCode = analysis[0]?.parameter

    const parameterObj = parameters.find(p => p.code === parameterCode)
    const dialogTitle = `${i18n.followUp} ${parameterObj?.name || ''} ${parameterCode ? `[${parameterCode}]` : ''}`

    const paramThreshold = useMemo(() => {
        const unitCode = onlyOneUnit ? analysis[0]?.unit : undefined
        const qualitometerId = onlyOneQualitometer ? analysis[0]?.qualitometer : undefined
        return getThreshold(thresholds, parameterCode, unitCode, qualitometerId)
    }, [analysis, onlyOneQualitometer, onlyOneUnit, parameterCode, thresholds])

    const colorTab = useMemo(() => {
        if (!paramThreshold) {
            return {}
        }
        const thresholdType = getThresholdType(paramThreshold)
        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            return ['red', 'blue', 'red']
        }
        const allColorTab = getTabColorsBySize()
        const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(paramThreshold[`threshold${nb}`])).length + 1
        return allColorTab[nbThresholds]
    }, [paramThreshold])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {dialogTitle}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeGraph} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <ParameterGraph
                                analysis={analysis}
                                thresholds={thresholds}

                                hypeRuptures={hypeRuptures}
                                hypeTrends={hypeTrends}

                                componentHeight={560}

                                graphOptions={{
                                    ...graphOptions,
                                    displayStatistics,
                                    displayAverages,
                                    nbPointMovingAverage,
                                    displayHype,
                                }}
                            />
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={colorTab.length ? 8 : 12}>
                        <RemarkLegend
                            list={analysis}
                            col={colorTab.length ? 4 : 3}
                        />
                    </Grid>
                    {
                        !!colorTab.length && (
                            <Grid item xs={4}>
                                <ThresholdLegend
                                    threshold={paramThreshold}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </DialogContentMUI>
            {
                !hideActions && (
                    <DialogActionsMUI>
                        <Grid container alignItems='center' justifyContent='left' columnSpacing={2} style={{ padding: '0 10' }}>
                            <Grid item xs={3}>
                                <SimpleCheckbox
                                    checked={displayStatistics}
                                    label={i18n.displayStatistics}
                                    onToggle={toggleDisplayStatistics}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SimpleCheckbox
                                    checked={displayAverages}
                                    label={i18n.displayAverages}
                                    onToggle={toggleDisplayAverages}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <RadioButtons
                                    label={i18n.numberOfPoints}
                                    value={nbPointMovingAverage}
                                    items={movingAveragePoints}
                                    onChange={setNbPointMovingAverage}
                                    disabled={!displayAverages}
                                />
                            </Grid>
                            {
                                onlyOneQualitometer && (
                                    <Grid item xs={3}>
                                        <SimpleCheckbox
                                            checked={displayHype}
                                            label={i18n.displayTrends}
                                            onToggle={toggleDisplayHype}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </DialogActionsMUI>
                )
            }
        </DialogMUI>
    )
}

ParameterGraphModal.propTypes = {
    isOpen: PropTypes.bool,
    closeGraph: PropTypes.func,
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,
    }),
    hideActions: PropTypes.bool,
}

const SuperParameterGraphModal = ({
    isOpen = false,
    closeGraph = () => { },

    parameter,
    qualitometer,
    thresholds = [],

    graphOptions = {},
}) => {
    const dispatch = useDispatch()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const defaultDraphOptions = useMemo(() => ({
        regroupAxis: true,
        ...graphOptions,
    }), [graphOptions])

    const [analysis, setAnalysis] = useState([])
    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        if (isUndefined(parameter) || isUndefined(qualitometer) || !isOpen) {
            return
        }

        notLoaded()
        setAnalysis([])
        initController()

        const analysisFilter = {
            lightMode: true, // Option[Boolean] = None,

            parameters: [parameter], // Option[Seq[String]] = None,
            stations: [qualitometer], // Option[Seq[Int]] = None
        }

        QualityAction.getAnalysis(analysisFilter, controllerRef.current.signal)
            .then(list => {
                setAnalysis(list.map(analyse => {
                    const ana = analyse.map(e => isNull(e) ? undefined : e)
                    return {
                        qualitometer: ana[0], // Double
                        id: ana[1], // Double
                        sample: ana[2], // Double
                        operation: ana[3], // Double
                        sampleDate: ana[4], // Option[DateTime]
                        analysisDate: ana[5] ?? ana[4], // Option[DateTime]
                        parameter: ana[6], // Option[String]
                        unit: ana[7], // Option[String]
                        result: ana[8], // Option[Double]
                        remark: ana[9], // Option[String]
                        support: ana[10], // Option[Double]
                        localization: ana[11], // Option[String]
                        lq: ana[12], // Option[Double]
                        ld: ana[13], // Option[Double]
                        saturationThreshold: ana[14], // Option[Double]
                        qualification: ana[15], // Option[String]
                        status: ana[16], // Option[String]
                        fraction: ana[17], // Option[String]
                        labo: ana[18], // Option[Double]
                        jobexecutionid: ana[19], // Option[Long]
                        importDate: ana[20], // Option[DateTime]
                        updateDate: ana[21], // Option[DateTime]
                        updateLogin: ana[22], // Option[String]
                        numSampling: ana[23], // Option[Int]
                    }
                }))
            })
            .finally(loaded)

        return () => {
            controllerRef.current.abort()
        }
    }, [dispatch, parameter, qualitometer, isOpen])

    const formattedAnalysis = useMemo(() => {
        return analysis.map(a => ({
            ...a,
            ...calculateThresholdResult(a, thresholds),
        }))
    }, [analysis, thresholds])

    return (
        <ParameterGraphModal
            isOpen={isOpen && isLoaded}
            closeGraph={closeGraph}

            analysis={formattedAnalysis}
            thresholds={thresholds}

            graphOptions={{ ...defaultDraphOptions }}
        />
    )
}

SuperParameterGraphModal.propTypes = {
    isOpen: PropTypes.bool,
    closeGraph: PropTypes.func,

    parameter: PropTypes.string,
    qualitometer: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,
    }),
}

export {
    ParameterGraph,
    ParameterGraphModal,
    SuperParameterGraphModal,
}