import React, { useEffect, useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HomeAction from 'pages/home/actions/HomeAction'
import ResourcesAction from './actions/ResourcesAction'
import { Grid, useMediaQuery } from '@mui/material'
import ProgressBar from 'components/progress/ProgressBar'
import ResourceCard from './ResourceCard'
import { useState } from 'react'
import { orderBy } from 'lodash'
import FollowAction from '../follows/actions/FollowAction'
import useStateProgress from 'utils/customHook/useStateProgress'
import { mainBlack, mainGrey } from 'components/styled/Theme'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import { STATION_TYPE_CONSTANT } from 'pages/home/constants/StationConstants'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import useTitle from 'utils/customHook/useTitle'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import ToastrAction from 'toastr/actions/ToastrAction'

const Resources = () => {
    const {
        resources,
        piezometersLight,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,

        piezometersLightStatus,
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
        resourcesStatus,
        linkedStationsStatus,

        accountHabilitations,
    } = useSelector(store => ({
        resources: store.ResourcesReducer.resources,
        piezometersLight: store.HomeReducer.piezometersLight,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,

        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        piezoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus: store.DataManagerReducer.follow.hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus: store.DataManagerReducer.follow.pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.qualitoObservatoryFollowResultsStatus,
        resourcesStatus: store.DataManagerReducer.resources.resourcesStatus,
        linkedStationsStatus: store.DataManagerReducer.home.linkedStationsStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const [selectedResource, setSelectedResource] = useState()
    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)

    const { progress, isLoaded } = useStateProgress([
        piezometersLightStatus,
        resourcesStatus,
        linkedStationsStatus,
    ])

    const { isLoaded: observatoryResultsLoaded } = useStateProgress([
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
    ])

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.resources,
        href: '/resources',
    }], [])

    useEffect(() => {
        if (accountHabilitations.length && !componentHasHabilitations(H_RESOURCE_MODULE)) {
            dispatch(HomeAction.logout())
            dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
        }
    }, [accountHabilitations.length])

    useEffect(() => {
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }
        if (!piezometersLight.length) {
            dispatch(HomeAction.fetchPiezometersLight())
        }

        return () => {
            dispatch(HomeActionConstant.resetLinkedStations())
        }
    }, [])

    const fetchObsResults = (r) => {
        const ls = r.payload.flatMap((s) => new DtoAssociatedStation(s))
        if (!piezoObservatoryFollowResults.length) {
            const piezoIds = resources.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.piezometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchpiezoObservatoryFollowResults({ ids: piezoIds, progressCallback: setPiezoObservatoryProgress }))
        }
        if (!hydroObservatoryFollowResults.length) {
            const hydroIds = resources.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchhydroObservatoryFollowResults({ ids: hydroIds, progressCallback: setHydroObservatoryProgress }))
        }
        if (!pluvioObservatoryFollowResults.length) {
            const pluvioIds = resources.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchpluvioObservatoryFollowResults({ ids: pluvioIds, progressCallback: setPluvioObservatoryProgress }))
        }
        if (!qualitoObservatoryFollowResults.length) {
            const qualitoIds = resources.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.quality && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchqualitoObservatoryFollowResults({ ids: qualitoIds, progressCallback: setQualitoObservatoryProgress }))
        }
    }

    useEffect(() => {
        if (resources.length !== 0) {
            dispatch(HomeAction.fetchAllLinkedStation({ codes: resources.map((p) => p.code), stationType: 9 })).then(r => fetchObsResults(r))
        }
    }, [resources])

    const filteredResources = useMemo(() => orderBy(selectedResource ? resources.filter(r => r.id === selectedResource) : resources, 'name'), [resources, selectedResource])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <>
            <Grid container item xs={12} alignItems='center' sx={{ padding: mdMatches ? '1rem 3rem 0.7rem' : '1rem 3rem 0.7rem', backgroundColor: mainGrey, borderTop: `solid 1px ${mainBlack}` }}>
                <Grid item md={2.5} xs={12}>
                    <SimpleMultiAutocomplete
                        value={selectedResource}
                        onChange={setSelectedResource}
                        options={resources}
                        disablePortal={false}
                        size='30px'
                        labelSize={16}
                    />
                </Grid>
            </Grid>
            {(isLoaded && observatoryResultsLoaded) ? (
                <Grid container sx={{ padding: mdMatches ? '10px 50px' : '10px 30px' }}>
                    {filteredResources.map(r => (
                        <ResourceCard
                            resource={r}
                            piezoObsResults={piezoObservatoryFollowResults}
                            hydroObsResults={hydroObservatoryFollowResults}
                            pluvioObsResults={pluvioObservatoryFollowResults}
                            qualitoObsResults={qualitoObservatoryFollowResults}
                        />
                    ))}
                </Grid>
            ) : <ProgressBar title={i18n.resourcesLoading} progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress) / 5} />}
        </>
    )
}

export default Resources
