import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Icon } from '@mui/material'
import { hasValue } from 'utils/NumberUtil'
import { FROZEN_CODE_STATUS } from 'pages/home/constants/StationConstants'
import SuperMultiAutocomplete from './SuperMultiAutocomplete'
import DtoIntervenant from 'pages/contact/dto/DtoIntervenant'
import HomeAction from 'pages/home/actions/HomeAction'

const MultiContributorsAutocomplete = ({
    col,
    className = '',
    multiple = false,
    limit = 1,
    onChange = () => {},
    label,
    values,
    disabled = false,
    options,
    keyLabel = 'name',
    keyValue = 'id',
    obligatory,
    disablePortal = true,
    placeholder,
}) => {
    const {
        contributorsGroups,
    } = useSelector(store => ({
        contributorsGroups: store.HomeReducer.contributorsGroups,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [data, setData] = useState(multiple ? [] : undefined)

    useEffect(() => {
        if (!contributorsGroups.length) {
            dispatch(HomeAction.fetchContributorsGroups())
        }
    }, [])

    useEffect(() => {
        if (!multiple) {
            setData(values)
        } else if (multiple && hasValue(values)) {
            setData(prev => [ ...prev, ...values ])
        }
    }, [multiple, values])

    const contributorsFormatted = useMemo(() => options.map(c => {
        const nameFormatted = `${c.name} - [${c?.siret || c?.sandre || ''}]`
        return {
            ...c,
            name: nameFormatted,
            endIcon: c?.status === FROZEN_CODE_STATUS && (
                <Icon fontSize='small' sx={{ marginLeft: '1rem' }}>lock</Icon>
            ),
        }
    }), [options])

    const contributorsGroupsFormatted = useMemo(() => contributorsGroups.map(cg => ({ ...cg, elements: cg.contributors })), [contributorsGroups])

    const handleChange = (value) => {
        setData(value)
        if (value?.length > 0 || !multiple) {
            onChange(value)
        } else {
            onChange(undefined)
        }
    }

    return (
        <SuperMultiAutocomplete
            className={className}
            options={contributorsFormatted}
            optionsGroups={contributorsGroupsFormatted}
            keyLabel={keyLabel}
            keyValue={keyValue}
            label={label ?? i18n.organism}
            col={col}
            onChange={handleChange}
            values={data}
            multiple={multiple}
            groupIcon='domain'
            additionalKeysFilter={[ 'groupTitle' ]}
            limit={limit}
            groupsCanBeSelected={multiple}
            disabled={disabled}
            obligatory={obligatory}
            disablePortal={disablePortal}
            placeholder={placeholder}
        />
    )
}

MultiContributorsAutocomplete.propTypes = {
    col: PropTypes.number,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    limit: PropTypes.number,
    onChange: PropTypes.func,
    label: PropTypes.string,
    values: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
        PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenant)),
    keyValue: PropTypes.string,
    keyLabel: PropTypes.string,
    obligatory: PropTypes.bool,
    disablePortal: PropTypes.bool,
    placeholder: PropTypes.string,
}

export default MultiContributorsAutocomplete