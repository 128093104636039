import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { groupBy, isNil, keys, orderBy } from 'lodash'
import CmsAction from 'pages/cms/actions/CmsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getYear } from 'utils/DateUtil'
import DocumentCard from './DocumentCard'
import { getI18nTitleData } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'
import { Grid, Icon, InputAdornment, TextField, useMediaQuery } from '@mui/material'
import ActualitiesCard from './ActualitiesCard'
import { getSetting } from 'utils/FormUtils'
import HomeAction from 'pages/home/actions/HomeAction'
import ProgressBar from 'components/progress/ProgressBar'
import { mainBlack, mainGrey } from 'components/styled/Theme'
import SupportCard from 'pages/account/components/SupportCard'

const NORMAL_STATUS = 1

const Documents = ({
    actualities = false,
}) => {
    const inputRef = useRef()

    const [cmsLoaded, setCmsLoaded] = useState(false)
    const [search, setSearch] = useState('')

    const {
        cmsEvents,
        cmsCategories,
        applicationSettings,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
        cmsCategories: store.CmsReducer.cmsCategories,
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const dispatch = useDispatch()
    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.documents,
            href: '/documents',
        }]))
    }, [])


    useEffect(() => {
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents()).then(() => setCmsLoaded(true))
        } else {
            setCmsLoaded(true)
        }
    }, [cmsEvents])

    useEffect(() => {
        if (!cmsCategories.length) {
            dispatch(CmsAction.fetchCMSCategories())
        }
    }, [cmsCategories])

    const getDocuments = () => {
        const catsId = (getSetting(applicationSettings, 'categoriesCmsDocuments') || '').split(',').map((id) => Number(id))
        return cmsEvents.filter(c => {
            const searched = `${c.title} ${c.subtitle} ${c.comment} ${c.author} ${c?.document[0]?.name} ${cmsCategories.find((cat) => cat.id === c.idCategory)?.title || ''}`.toLocaleLowerCase()
            return c.status === NORMAL_STATUS && catsId.includes(c.idCategory) && searched.includes(search?.toLocaleLowerCase())
        })
    }

    const documents = useMemo(() => {
        const categoriesCmsActualites = getSetting(applicationSettings, 'categoriesCmsActualites')
        const actualitiesId = !isNil(categoriesCmsActualites) ? parseInt(categoriesCmsActualites) : undefined
        const filteredCMS = !actualities ? getDocuments() : cmsEvents.filter(c => c.idCategory === actualitiesId)
        const formattedCMS = filteredCMS.map((c) => ({ ...c, year: getYear(c.dateDebut || c.updateDate) }))
        return groupBy(formattedCMS, 'year')
    }, [applicationSettings, actualities, cmsEvents, search])

    return (
        <Grid container>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ padding: '1rem', backgroundColor: mainGrey, borderTop: `solid 1px ${mainBlack}` }}>
                <Grid item md={2.5} xs={12}>
                    <TextField
                        id='search'
                        label={i18n.search}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        inputRef={inputRef}
                        variant='outlined'
                        fullWidth
                        sx={{ height: '2.5rem' }}
                        InputLabelProps={{
                            sx: {
                                height: '2.5rem',
                                top: '-0.5rem',
                            },
                        }}
                        InputProps={{
                            sx: {
                                height: '2.5rem',
                            },
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {cmsLoaded ? (
                <Grid container item xs={12} sx={{ padding: lgMatches ? '30px 50px' : '30px 30px' }}>
                    <Grid item lg={8.5} xs={12}>
                        {orderBy(keys(documents), [], 'desc').map((year, i) => {
                            const docsYear = documents[year]
                            return (
                                <AccordionMUI sx={{ marginBottom: '30px' }} defaultExpanded={i === 0}>
                                    <AccordionSummaryMUI sx={{ fontSize: '20px' }}>{`${year} - ${docsYear.length} ${getI18nTitleData(i18n.document, i18n.documents, docsYear)}`}</AccordionSummaryMUI>
                                    <AccordionDetailsMUI nopadding>
                                        <Grid container>
                                            {orderBy(docsYear, ['dateDebut', 'updateDate'], 'desc').map((d) => (
                                                <DocumentCard cms={d} />
                                            ))}
                                        </Grid>
                                    </AccordionDetailsMUI>
                                </AccordionMUI>
                            )
                        })}
                    </Grid>
                    <Grid item lg={3.5} xs={12} sx={{ paddingLeft: '1rem' }}>
                        {actualities ? <SupportCard /> : <ActualitiesCard />}
                    </Grid>
                </Grid>
            ) : <Grid item xs={12}><ProgressBar indeterminate /></Grid>}
        </Grid>
    )
}

Documents.propTypes = {
    actualities: PropTypes.bool,
}

export default Documents