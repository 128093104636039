import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { ButtonGroup, Grid, Icon } from '@mui/material'
import { groupBy, keys, range, toLower, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { getDate, getMonthYear, getWeekYear, getYear } from 'utils/DateUtil'
import { formatMilliers, getI18nTitleDataLength, geti18n } from 'utils/StringUtil'
import { nbPerPageLabelShort } from 'pages/online/referencials/constants/ReferencialConstants'
import { ButtonMUI } from 'components/styled/Buttons'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import FollowAction from 'pages/online/follows/actions/FollowAction'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import ProgressBar from 'components/progress/ProgressBar'
import SimpleTable from 'pages/components/SimpleTable'
import ExportFileModal from 'pages/online/components/ExportFileModal'
import HomeAction from 'pages/home/actions/HomeAction'
import { formatData } from 'utils/ActionUtils'
import { authorizeExport } from 'utils/HabilitationUtil'
import { PIEZO } from 'pages/home/constants/HabilitationConstants'

const OCCURENCE_TYPE = {
    DAY: { value: 'DAY', defaultValue: 7, nbValue: 30 },
    WEEK: { value: 'WEEK', defaultValue: 8, nbValue: 52 },
    MONTH: { value: 'MONTH', defaultValue: 12, nbValue: 24 },
    YEAR: { value: 'YEAR', defaultValue: 5, nbValue: 20 },
}

const VOLUME_UNIT = 'm3'

const ProductionUnitLinkedPiezo = ({
    piezoIds = [],
}) => {
    const {
        linkedPiezoPrel,
    } = useSelector(store => ({
        linkedPiezoPrel: store.FollowReducer.linkedPiezoPrel,
    }), shallowEqual)

    const [openExport, setOpenExport] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [occurence, setOccurence] = useState(OCCURENCE_TYPE.DAY)
    const [nbOccurence, setNbOccurence] = useState(OCCURENCE_TYPE.DAY.defaultValue)

    const dispatch = useDispatch()

    const buttonsOccurence = [
        { value: 'DAY', title: i18n.day, borderWidth: '2px 0px 2px 2px' },
        { value: 'WEEK', title: i18n.week, borderWidth: 2 },
        { value: 'MONTH', title: i18n.month, borderWidth: '2px 1px' },
        { value: 'YEAR', title: i18n.year, borderWidth: '2px 2px 2px 1px' },
    ]

    const formatDate = useCallback(date => {
        switch (occurence.value) {
            case 'WEEK':
                return getWeekYear(date)
            case 'MONTH':
                return getMonthYear(date)
            case 'YEAR':
                return getYear(date)
            default:
                return getDate(date)
        }
    }, [occurence])

    useEffect(() => {
        if (piezoIds.length && occurence && nbOccurence) {
            setDataLoaded(false)
            dispatch(FollowAction.fetchLinkedPiezoPrel({ ids: piezoIds, occurence: occurence.value, nbOccurence })).then(() => setDataLoaded(true))
        }
    }, [piezoIds, occurence, nbOccurence, dispatch])

    const getPiezoName = useCallback(({ idPiezometer, code, designation, name }) => {
        const idCode = code ? `${code}${designation ? `/${designation}` : ''}` : idPiezometer
        return `${idCode} ${name ? `- ${name}` : ''}`
    }, [])

    const headers = useMemo(() => {
        const addedPiezos = uniqBy(linkedPiezoPrel, 'idPiezometer').map(dt => getPiezoName(dt))
        return ['date', ...addedPiezos, 'sum']
    }, [getPiezoName, linkedPiezoPrel])

    const data = useMemo(() => {
        const grouped = groupBy(linkedPiezoPrel, 'measureDate')
        return keys(grouped).map((date, index) => {
            const line = grouped[date]
            const others = line.filter(l => l.dataType !== -1).reduce((acc, l) => {
                return { ...acc, [getPiezoName(l)]: `${formatMilliers(l.value)} ${VOLUME_UNIT}`, sum: acc.sum + l.value }
            }, { sum: 0 })
            return {
                date: formatDate(line[0].measureDate),
                ...others,
                sum: `${formatMilliers(others.sum)} ${VOLUME_UNIT}`,
                headers: index === 0 ? headers : [],
            }
        })
    }, [linkedPiezoPrel, formatDate, getPiezoName, headers])

    const dataToExport = useMemo(() => {
        const exportData = data?.length ? formatData(data) : []
        return [{
            name: i18n.syntheticExport,
            formats: [{
                type: i18n.csv,
                callback: () => dispatch(HomeAction.export({ exportData, type: 'csv', titleFile: i18n.datas })),
            }, {
                type: i18n.exportXLSX,
                callback: () => dispatch(HomeAction.export({ exportData, type: 'xlsx', titleFile: i18n.datas })),
            }],
        }]
    }, [data, dispatch])

    const selectValues = useMemo(() => {
        const labelKey = toLower(occurence.value)
        return range(occurence.nbValue).map(index => ({ label: `${index + 1} ${getI18nTitleDataLength(geti18n(labelKey), geti18n(`${labelKey}s`), index + 1)}`, id: index + 1 }))
    }, [occurence])

    if (!piezoIds.length) {
        return null
    }

    return (
        <AccordionMUI round>
            <AccordionSummaryMUI iconColor='black' sx={{ background: 'white', color: 'black' }}>
                <Grid container justifyContent='space-between'>
                    <Grid item>{i18n.volumesWithdrawn}</Grid>
                    {authorizeExport(PIEZO) && (
                        <Grid item sx={{ paddingRight: '1rem' }}>
                            <Icon
                                fontSize='small'
                                sx={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                    visibility: 'visible',
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                    setOpenExport(true)
                                }}
                            >
                            file_download
                            </Icon>
                        </Grid>
                    )}
                </Grid>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <Grid container className='padding-1'>
                    <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={6}>
                            <ButtonGroup>
                                {buttonsOccurence.map((o) => (
                                    <ButtonMUI
                                        variant={occurence.value === o.value ? 'contained' : 'outlined'}
                                        color='primary'
                                        onClick={() => {
                                            setOccurence(OCCURENCE_TYPE[o.value])
                                            setNbOccurence(OCCURENCE_TYPE[o.value].defaultValue)
                                        }}
                                        sx={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: o.borderWidth, fontWeight: 600 }}
                                    >{o.title}</ButtonMUI>
                                ))}
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleMultiAutocomplete
                                value={nbOccurence}
                                onChange={setNbOccurence}
                                options={selectValues}
                                label={i18n.occurency}
                                disablePortal={false}
                                noSort
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                        {dataLoaded && !!data.length && (
                            <SimpleTable
                                datas={data}
                                headers={headers}
                                nbPerPage={nbPerPageLabelShort}
                                densePadding
                            />
                        )}
                        {!dataLoaded && (
                            <ProgressBar
                                indeterminate
                                sx={{
                                    padding: '0.75rem 0.75rem 0 1.25rem',
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
                <ExportFileModal
                    onClose={() => setOpenExport(false)}
                    data={dataToExport}
                    open={openExport}
                    title={i18n.productionUnit}
                    closeOnExport
                />
            </AccordionDetailsMUI>
        </AccordionMUI >
    )
}

ProductionUnitLinkedPiezo.propTypes = {
    piezoIds: PropTypes.arrayOf(PropTypes.number),
}

export default ProductionUnitLinkedPiezo