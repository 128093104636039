import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { maxBy, orderBy, some } from 'lodash'
import DtoFile from '../referencials/documents/dto/DtoFile'
import { PICTURES_EXTENSIONS } from '../referencials/documents/constants/FileConstants'
import { getDocumentTypeIcon, getFileExtension } from 'utils/FileUtils'
import { capitalizeFirstLetter } from 'utils/StringUtil'
import { Grid } from '@mui/material'
import { cmsPath } from 'conf/basepath'
import { SMALL_RADIUS } from 'components/styled/Theme'

const openDocument = url => url && window.open(url, '_blank')

const FilePanel = ({
    files = [],
    showLast = false,
    editable = false,
    noBorder = false,
    isCms = false,
}) => {
    const filteredFiles = useMemo(() => {
        const filterFiles = files.filter(f => !some(PICTURES_EXTENSIONS, extension => f.name.endsWith(extension)))
        return (!!showLast && !!filterFiles.length) ? [maxBy(filterFiles, 'updateDate')] : filterFiles
    }, [files, showLast])

    return !!filteredFiles.length && (
        <Grid container item xs={12} alignItems='center' justifyContent='flex-start' sx={{ gap: '1rem', padding: '0.5rem 0', borderBottom: !noBorder && '1px solid rgba(233, 233, 233, 0.8)', overflowY: 'auto', maxHeight: '25vh' }}>
            {orderBy(filteredFiles, 'updateDate', 'desc').map(file => {
                const nameFormat = file.isAllDataName ? capitalizeFirstLetter(file.shortName) : file.name
                const fileExtension = getFileExtension(file.name)
                return (
                    <Grid
                        container item xs='auto'
                        justifyContent='space-between'
                        alignItems='center'
                        onClick={() => openDocument(isCms ? `${cmsPath}${file.name}` : file.url)}
                        sx={{
                            border: 'solid 1px black',
                            borderRadius: SMALL_RADIUS,
                            padding: '6px 8px',
                            cursor: 'pointer',
                        }}
                    >
                        {!!editable && <span style={{ marginRight: '10px', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</span>}
                        <Grid item xs='auto'>
                            <img src={ getDocumentTypeIcon(fileExtension) } style={{ maxWidth: '15px', height: 'auto', verticalAlign: 'sub' }} />
                        </Grid>
                        <Grid item xs sx={{ paddingLeft: '0.75rem' }}>
                            <b className='truncate padding-left-1' style={{ fontSize: '12px', color: 'black' }}>{ nameFormat }</b>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

FilePanel.propTypes = {
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    showLast: PropTypes.bool,
    editable: PropTypes.bool,
    noBorder: PropTypes.bool,
    isCms: PropTypes.bool,
}

export default FilePanel