export default class DtoDataStation {
    constructor(dataStation) {
        this.id = dataStation.id || dataStation.pluvioId || dataStation.piezoId || dataStation.hydroId
        this.value = dataStation.value
        this.cote = dataStation.cote
        this.lastMeasureDate = dataStation.lastMeasureDate || dataStation.date || dataStation.measureDate
        this.max = dataStation.max
        this.measuresCount = dataStation.measuresCount
        this.min = dataStation.min
        this.ngf = dataStation.ngf
        this.startDate = dataStation.startDate
        this.allMeasuresCount = dataStation.allMeasuresCount
        this.lastMeasureHour = dataStation.lastMeasureHour
        this.dataType = dataStation.dataType
    }
}