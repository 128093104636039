/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { mainBlue, mainWhite, SMALL_RADIUS } from './styled/Theme'

const Option = ({
    first = false,
    label = '',
    selected = true,
    onClick = () => {},
    xs = 1.5,
    sx = {},
}) => (
    <Grid
        container
        item xs={xs}
        alignItems='center'
        justifyContent='center'
        sx={{
            height: 30,
            marginLeft: !first && '0.35rem',
            backgroundColor: selected ? mainBlue : 'transparent',
            border: `solid 1px ${mainBlue}`,
            cursor: 'pointer',
            borderRadius: SMALL_RADIUS,
            ...sx,
        }}
        onClick={onClick}
    >
        <Grid item>
            <span style={{ fontWeight: 'bold', fontSize: 15, color: selected ? mainWhite : mainBlue }}>{label}</span>
        </Grid>
    </Grid>
)

Option.propTypes = {
    first: PropTypes.bool,
    label: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    xs: PropTypes.number,
    sx: PropTypes.shape({}),
}

export default Option
