import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Checkbox, Grid } from '@mui/material'
import { mainBlack, mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { getMarkerByStationType } from 'utils/StationUtils'

const StationTab = ({
    selectedStations = [],
    setSelectedStations = () => {},

    stationLength = 0,
    moduleByType = {},
    stationsTypes = [],

    marge = '0.5rem',
}) => (
    <Grid container sx={{ padding: marge, fontWeight: 'bold' }}>
        <Grid item xs={12} sx={{ backgroundColor: mainBlack, color: mainWhite, height: '35px', textAlign: 'center', display: 'grid', alignItems: 'center', borderRadius: SMALL_RADIUS }}>
            <span>{stationLength} {i18n.availableStations}</span>
        </Grid>
        <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ marginTop: marge }}>
            {stationsTypes.map(st => {
                const img = getMarkerByStationType(st)
                return (
                    <Grid container key={st} item xs={12} sx={{ marginTop: '9px', borderBottom: `solid 1px ${mainBlack}`, paddingBottom: '9px' }} justifyContent='space-between'>
                        <Grid container item xs={10} sx={{ paddingLeft: '0.5rem' }} alignItems='center'>
                            <Grid item>
                                {!!img && (
                                    <img src={img} style={{ height: '30px' }} />
                                )}
                            </Grid>
                            <Grid container item xs={8} alignItems='center' sx={{ paddingLeft: '1rem' }}>
                                <Grid item xs={12}>
                                    <span>{`${i18n[st]} (${moduleByType[st].length})`}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                checked={selectedStations.includes(moduleByType[st].obsStationType)}
                                color='primary'
                                onChange={event => {
                                    if (event.target.checked) {
                                        return setSelectedStations([...selectedStations, moduleByType[st].obsStationType])
                                    }
                                    return setSelectedStations(selectedStations.filter(p => p !== moduleByType[st].obsStationType))
                                }}
                            />
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    </Grid>
)

StationTab.propTypes = {
    selectedStations: PropTypes.arrayOf(PropTypes.string),
    setSelectedStations: PropTypes.func,

    stationLength: PropTypes.number,
    moduleByType: PropTypes.shape({}),
    stationsTypes: PropTypes.arrayOf(PropTypes.string),

    marge: PropTypes.string,
}

export default StationTab