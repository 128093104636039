import {
    STATION_TYPE_CONSTANT,
    STATION_TYPE_NAME,
    OBSERVATORY_STATION_TYPE_NAME,
} from 'pages/home/constants/StationConstants'
import markerInstallation from 'assets/pictures/markers/map_installation_defaut.png'
import { getCardHydroMarker, getCardPiezoMarker, getCardPluvioMarker, getCatchmentMarker, getHydroMarker, getInstMarker, getPiezoMarker, getPluvioMarker, getQualityMarker, getUdiMarker, getUnitProdMarker } from './mapUtils/SvgUtil'
import { isNil } from 'lodash'
import { H_PRODUCTION_MODULE } from 'pages/home/constants/AccessRulesConstants'
import { componentHasHabilitations } from './HabilitationUtil'
import { defaultHydroColor, defaultMonitoringColor, defaultPiezoColor, defaultPluvioColor, defaultQualitoColor, mainBlack, mainWhite } from 'components/styled/Theme'

const getDefaultColor = (color, defaultColor) => !componentHasHabilitations(H_PRODUCTION_MODULE) ? defaultColor : (color || defaultColor)
const getDefaultIndicatorColor = (color, defaultColor) => !componentHasHabilitations(H_PRODUCTION_MODULE) ? defaultColor : (color || mainBlack)

const getColorByStationType = (st, color) => {
    switch (st) {
        case STATION_TYPE_CONSTANT.piezometer:
        case STATION_TYPE_NAME.piezometer:
        case STATION_TYPE_NAME.piezometry:
            return getDefaultIndicatorColor(color, defaultPiezoColor)
        case STATION_TYPE_CONSTANT.hydrometricStation:
        case STATION_TYPE_NAME.hydrometricStation:
        case STATION_TYPE_NAME.hydrometry:
            return getDefaultIndicatorColor(color, defaultHydroColor)
        case STATION_TYPE_CONSTANT.pluviometer:
        case STATION_TYPE_NAME.pluviometer:
        case STATION_TYPE_NAME.pluviometry:
            return getDefaultIndicatorColor(color, defaultPluvioColor)
        case STATION_TYPE_CONSTANT.qualitometer:
        case STATION_TYPE_NAME.qualitometer:
        case STATION_TYPE_NAME.quality:
            return getDefaultIndicatorColor(color, defaultQualitoColor)
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
            return getDefaultColor(color, defaultMonitoringColor)
        case STATION_TYPE_CONSTANT.installation:
        case STATION_TYPE_NAME.installation:
            return color
        case STATION_TYPE_CONSTANT.productionUnit:
        case STATION_TYPE_NAME.productionUnit:
            return getDefaultColor(color, defaultMonitoringColor)
        case STATION_TYPE_CONSTANT.distributionUnit:
        case STATION_TYPE_NAME.distributionUnit:
            return getDefaultColor(color, defaultMonitoringColor)
        default:
            return color
    }
}

const getMarkerByStationType = (st, color) => {
    switch (st) {
        case STATION_TYPE_CONSTANT.piezometer:
        case STATION_TYPE_NAME.piezometer:
        case STATION_TYPE_NAME.piezometry:
            return `data:image/svg+xml;utf8,${getPiezoMarker(getDefaultIndicatorColor(color, defaultPiezoColor))}`
        case STATION_TYPE_CONSTANT.hydrometricStation:
        case STATION_TYPE_NAME.hydrometricStation:
        case STATION_TYPE_NAME.hydrometry:
            return `data:image/svg+xml;utf8,${getHydroMarker(getDefaultIndicatorColor(color, defaultHydroColor))}`
        case STATION_TYPE_CONSTANT.pluviometer:
        case STATION_TYPE_NAME.pluviometer:
        case STATION_TYPE_NAME.pluviometry:
            return `data:image/svg+xml;utf8,${getPluvioMarker(getDefaultIndicatorColor(color, defaultPluvioColor))}`
        case STATION_TYPE_CONSTANT.qualitometer:
        case STATION_TYPE_NAME.qualitometer:
        case STATION_TYPE_NAME.quality:
            return `data:image/svg+xml;utf8,${getQualityMarker(getDefaultIndicatorColor(color, defaultQualitoColor))}`
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
            return `data:image/svg+xml;utf8,${getCatchmentMarker(getDefaultColor(color, defaultMonitoringColor))}`
        case STATION_TYPE_CONSTANT.installation:
        case STATION_TYPE_NAME.installation:
            return !isNil(color) ? `data:image/svg+xml;utf8,${getInstMarker(color)}` : markerInstallation
        case STATION_TYPE_CONSTANT.productionUnit:
        case STATION_TYPE_NAME.productionUnit:
            return `data:image/svg+xml;utf8,${getUnitProdMarker(getDefaultColor(color, defaultMonitoringColor))}`
        case STATION_TYPE_CONSTANT.distributionUnit:
        case STATION_TYPE_NAME.distributionUnit:
            return `data:image/svg+xml;utf8,${getUdiMarker(getDefaultColor(color, defaultMonitoringColor))}`
        default:
            return null
    }
}

const getCardMarkerByStationType = (stationType, color) => {
    switch (stationType) {
        case STATION_TYPE_CONSTANT.piezometer:
        case STATION_TYPE_NAME.piezometer:
        case STATION_TYPE_NAME.piezometry:
            return `data:image/svg+xml;utf8,${getCardPiezoMarker(color || mainWhite)}`
        case STATION_TYPE_CONSTANT.hydrometricStation:
        case STATION_TYPE_NAME.hydrometricStation:
        case STATION_TYPE_NAME.hydrometry:
            return `data:image/svg+xml;utf8,${getCardHydroMarker(color || mainWhite)}`
        case STATION_TYPE_CONSTANT.pluviometer:
        case STATION_TYPE_NAME.pluviometer:
        case STATION_TYPE_NAME.pluviometry:
            return `data:image/svg+xml;utf8,${getCardPluvioMarker(color || mainWhite)}`
        case STATION_TYPE_CONSTANT.qualitometer:
        case STATION_TYPE_NAME.qualitometer:
        case STATION_TYPE_NAME.quality:
            return `data:image/svg+xml;utf8,${getQualityMarker(getDefaultIndicatorColor(mainBlack, defaultQualitoColor))}` // To update after card marker
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
            return `data:image/svg+xml;utf8,${getCatchmentMarker(getDefaultColor(color || defaultMonitoringColor, mainBlack))}` // To update after card marker
        case STATION_TYPE_CONSTANT.installation:
        case STATION_TYPE_NAME.installation:
            return !isNil(color) ? `data:image/svg+xml;utf8,${getInstMarker(mainBlack)}` : markerInstallation // To update after card marker
        case STATION_TYPE_CONSTANT.productionUnit:
        case STATION_TYPE_NAME.productionUnit:
            return `data:image/svg+xml;utf8,${getUnitProdMarker(getDefaultColor(color || defaultMonitoringColor, mainBlack))}` // To update after card marker
        case STATION_TYPE_CONSTANT.distributionUnit:
        case STATION_TYPE_NAME.distributionUnit:
            return `data:image/svg+xml;utf8,${getUdiMarker(getDefaultColor(color || defaultMonitoringColor, mainBlack))}` // To update after card marker
        default:
            return null
    }
}

const getStationTypeFromName = (stationName) => {
    switch (stationName) {
        case STATION_TYPE_NAME.qualitometer:
            return STATION_TYPE_NAME.quality
        case STATION_TYPE_NAME.piezometer:
            return STATION_TYPE_NAME.piezometry
        case STATION_TYPE_NAME.pluviometer:
            return STATION_TYPE_NAME.pluviometry
        case STATION_TYPE_NAME.hydrometricStation:
            return STATION_TYPE_NAME.hydrometry
        case 'productionUnit':
            return 'productionUnit'
        case 'distributionUnit':
            return 'distributionUnit'
        case 'installation':
            return 'installation'
        default:
            return null
    }
}

const getStationTypeNameFromType = (stationName) => {
    switch (stationName) {
        case STATION_TYPE_NAME.quality:
            return STATION_TYPE_NAME.qualitometer
        case STATION_TYPE_NAME.piezometry:
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
            return STATION_TYPE_NAME.piezometer
        case STATION_TYPE_NAME.hydrometry:
            return OBSERVATORY_STATION_TYPE_NAME.hydrologicalStation
        case STATION_TYPE_NAME.pluviometry:
            return STATION_TYPE_NAME.pluviometer
        case STATION_TYPE_NAME.cce:
            return STATION_TYPE_NAME.cce
        case 'productionUnit':
            return 'productionUnit'
        case 'distributionUnit':
            return 'distributionUnit'
        case 'installation':
            return 'installation'
        default:
            return null
    }
}

const getStationTypeCodeFromType = (stationType) => {
    switch (stationType) {
        case OBSERVATORY_STATION_TYPE_NAME.catchment:
        case STATION_TYPE_NAME.piezometry:
        case STATION_TYPE_NAME.piezometer:
            return 1
        case STATION_TYPE_NAME.pluviometry:
        case STATION_TYPE_NAME.pluviometer:
            return 2
        case STATION_TYPE_NAME.quality:
        case STATION_TYPE_NAME.qualitometer:
            return 3
        case OBSERVATORY_STATION_TYPE_NAME.hydrologicalStation:
        case STATION_TYPE_NAME.hydrometry:
            return 4
        case STATION_TYPE_NAME.productionUnit:
            return 5
        case STATION_TYPE_NAME.distributionUnit:
            return 6
        case STATION_TYPE_NAME.installation:
            return 7
        case STATION_TYPE_NAME.resource:
            return 9
        default:
            return null
    }
}

const getStationTypeNameFromTypeCode = (stationTypeCode) => {
    switch (stationTypeCode) {
        case 1:
            return STATION_TYPE_NAME.piezometry
        case 2:
            return STATION_TYPE_NAME.pluviometry
        case 3:
            return STATION_TYPE_NAME.quality
        case 4:
            return STATION_TYPE_NAME.hydrometry
        case 5:
            return STATION_TYPE_NAME.productionUnit
        case 6:
            return STATION_TYPE_NAME.distributionUnit
        case 7:
            return STATION_TYPE_NAME.installation
        case 9:
            return STATION_TYPE_NAME.resource
        default:
            return null
    }
}

const filterObsLinkedStations = (linkedStations) => linkedStations.filter(s => ![null, STATION_TYPE_NAME.resource, STATION_TYPE_NAME.installation, STATION_TYPE_NAME.distributionUnit].includes(s.typeName))

export {
    getMarkerByStationType,
    getStationTypeFromName,
    getStationTypeNameFromType,
    getStationTypeCodeFromType,
    filterObsLinkedStations,
    getStationTypeNameFromTypeCode,
    getColorByStationType,
    getCardMarkerByStationType,
}
