import ApplicationConf from 'conf/ApplicationConf'
import { chunk, flatten } from 'lodash'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { genericPromise2, promiseAllProgress } from 'utils/ActionUtils'

const QualityAction = {
    getOperation: (id, stationId, signal) => genericPromise2(ApplicationConf.quality.operation(id, stationId), { signal }),
    getOperations: (id, signal) => genericPromise2(ApplicationConf.quality.operations([id]), { signal }),
    getAllOperation: (ids, signal) => {
        const operationPromise = (() => {
            if (ids) {
                const groupSize = Math.min(Math.floor(ids.length / 20), 150)
                return chunk(ids, groupSize > 0 ? groupSize : 1).map(group => genericPromise2(ApplicationConf.quality.operations(group), { signal }))
            }
            return [genericPromise2(ApplicationConf.quality.operations(), { signal })]
        })()
        return Promise.all(operationPromise)
            .then(jsonTab => flatten(jsonTab))
            .catch(() => [])
    },

    getAnalysis: (filter, signal) => genericPromise2(ApplicationConf.quality.analysis(), { method: 'POST', body: filter, signal }),
    fetchAnalysis: (filter = {}, signal, onProgress = () => {}) => dispatch => {
        const {
            stations,
            ...searchFilter
        } = filter
        const group = Math.floor(stations.length / 25)
        const promises = chunk(stations, group > 0 ? group : 1).map(stationsGroup => QualityAction.getAnalysis({ ...searchFilter, stations: stationsGroup }, signal)
            .catch(() => []))
        return promiseAllProgress(promises, onProgress)
            .then(jsonTab => {
                if (signal.aborted) {
                    return 0
                }
                const results = flatten(jsonTab)
                dispatch(QualityActionConstant.receiveSearchAnalysis(results))
                return results.length
            })
    },

    promiseQualityThresholds: (code, thresholdType, signal) => genericPromise2(ApplicationConf.quality.qualityThresholds(code, thresholdType), { signal }),
    fetchQualityThresholds: (thresholds = []) => (dispatch, getState) => {
        const state = getState()
        const { qualityThresholds } = state.QualityReducer
        const filteredThresholds = thresholds.filter(th => !qualityThresholds.some(qt => qt.code === th.code && qt.thresholdType === th.thresholdType))
        return Promise.all(filteredThresholds.map(th => QualityAction.promiseQualityThresholds(th.code, th.thresholdType))).then(jsonTab => {
            const formattedQualityThresholds = [
                ...qualityThresholds,
                ...jsonTab.map((list, i) => ({
                    code: filteredThresholds[i]?.code,
                    thresholdType: filteredThresholds[i]?.thresholdType,
                    thresholds: list,
                })),
            ]
            dispatch(QualityActionConstant.qualityThresholds(formattedQualityThresholds))
        })
    },
}

export default QualityAction