import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compact, concat, groupBy, isNil, keys, round, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import MultiChart from './MultiChart'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { getDateWithHour, getDayDiff, getFullDate } from 'utils/DateUtil'
import { getColorFromPalette, getColorFromPalette2, getColorFromPalettePluvio, getEventColor, getRGBColor } from 'utils/ColorUtil'
import { renderToString } from 'react-dom/server'
import { statusIcon } from 'utils/StatusUtil'
import { HYDROMETER_HEIGHT_TYPE } from 'pages/online/referencials/constants/HydrometryConstants'
import DtoHydrometricStation from 'pages/home/dto/DtoHydrometricStation'
import PluviometerDto from 'pages/home/dto/PluviometerDto'
import DtoHydroStats from 'pages/online/follows/dto/DtoHydroStats'
import DtoEvent from 'pages/online/follows/dto/DtoEvent'
import DtoHydroMeasures from 'pages/online/follows/dto/DtoHydroMeasures'
import { STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import FollowAction from 'pages/online/follows/actions/FollowAction'
import { DATA_TYPE } from 'pages/online/referencials/constants/PluviometryConstant'
import HomeAction from 'pages/home/actions/HomeAction'
import ProgressBar from 'components/progress/ProgressBar'
import DtoPluvioMeasures from 'pages/online/follows/dto/DtoPluvioMeasures'
import DtoPluviometerStats from 'pages/online/follows/dto/DtoPluviometerStats'
import { getBarWidth, setYOptions, setYOptionsPiezo, yAutomaticScaleValues } from './EChartUtils'
import DtoPiezoChartMeasures from 'pages/online/follows/dto/DtoPiezoChartMeasures'
import DtoMeasureStats from 'pages/online/follows/dto/DtoMeasureStats'
import useStateProgress from 'utils/customHook/useStateProgress'
import ChartTabs from './ChartTabs'
import { HISTO, J365 } from './ChartFollowContants'
import { chunkWithWords } from 'utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import useAbortController from 'utils/customHook/useAbortController'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { CHART_SELECTED_TIME } from 'pages/home/constants/HomeConstants'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'

const PLUVIO_GROUPED = 'SUM_AUTO'
const ROUND_VALUE = 3
const CHRONIC_TYPE = -1
const SAMPLE_TYPE = -2

const FlowObstructionMultiGraph = ({
    hydroStats = [],
    pluvioStats = [],
    piezoStats = [],
    graphicHeight = 250,
    stationsEvents = [],
    hydroMeasures = [],
    pluvioMeasures = [],
    piezoMeasures = [],
    hydroIds = [],
    pluvioIds = [],
    piezoIds = [],
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
    exportName,
}) => {
    const {
        hydrometers,
        pluviometers,
        piezometersLight,
        hydrometryThresholds,
        pluviometerAllThresholds,
        piezometerAllThresholds,
        piezometryDataTypes,
    } = useSelector(store => ({
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,
        piezometerAllThresholds: store.FollowReducer.piezometerAllThresholds,
        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
    }), shallowEqual)

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1'
        }
        return false
    })

    const getBarDataEvents = () => events.map(e => ({
        date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
        value: 1,
        color: getRGBColor(getEventColor(e.eventType)),
        event: e,
    }))

    const eventsFormatted = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        gridName: i18n.events,
        dataList: getBarDataEvents(),
        type: 'bar',
        barWidth: '10px',
    }], [])

    const getFilteredData = (data) => data.filter((m, i) => {
        const diffInDays = (m?.date && m[i + 1]?.date) ? getDayDiff(m.date, m[i + 1].date) : 0
        return (m.initialPoint !== 1 && diffInDays <= 31 && !isNil(m?.date)) ? [m] : [
            { ...m, value: undefined, date: moment(m.date).subtract(1, 'second').valueOf() },
            { ...m, marker: 'emptyCircle' },
        ]
    })

    const getRealData = (measures) => measures.filter(measure => moment(measure.date).year() > 1000)

    const piezoMeasuresFiltered = piezoMeasures.filter(m => !!m.measures.length && !!getRealData(m.measures).length) // To keep only the real data
    const piezoGroupedMeasures = groupBy(piezoMeasuresFiltered, 'dataType')
    const groupedMeasuresKeys = useMemo(() => {
        const keysGroupedMeasures = keys(piezoGroupedMeasures)
        const chronicleTypeIds = piezometryDataTypes.filter(pdt => pdt.isPiezo).map(pdt => pdt.id)
        const chronicleKeys = keysGroupedMeasures.filter(k => k === `${CHRONIC_TYPE}` || chronicleTypeIds.includes(parseInt(k)))
        const chronicleKey = chronicleKeys.length ? [`${CHRONIC_TYPE}`] : []
        return [
            ...chronicleKey,
            ...keysGroupedMeasures.filter(k => k === `${SAMPLE_TYPE}`),
            ...keysGroupedMeasures.filter(k => k !== `${CHRONIC_TYPE}` && k !== `${SAMPLE_TYPE}` && !chronicleTypeIds.includes(parseInt(k))).sort((a, b) => parseInt(a) - parseInt(b)),
            ...keysGroupedMeasures.filter(k => k !== `${CHRONIC_TYPE}` && k !== `${SAMPLE_TYPE}` && chronicleTypeIds.includes(parseInt(k))).sort((a, b) => parseInt(a) - parseInt(b)),
        ].filter(k => !!k && k !== 'undefined')
    }, [piezoGroupedMeasures])

    const piezoFormatted = useMemo(() => groupedMeasuresKeys.flatMap((key, index) => {
        const values = piezoGroupedMeasures[key] || []
        return values.map((value, dataIndex) => {
            const station = piezometersLight.find(a => a.id === value.stationId) || {}
            const piezoStat = piezoStats.find(h => h.typeId === value.dataType)
            const dataType = piezometryDataTypes.find(pdt => pdt.id === value.dataType) || {}
            const unit = piezoStat?.unit ? `${piezoStat?.unit} ` : ''
            // const defaultColor = piezoStat?.color || (dataType.isPiezo ? 'red' : chartGrey)
            return {
                gridIndex: dataType.isPiezo ? 1 : index + 1,
                name: station?.name || station?.code || '',
                dataType: value.dataType,
                unit,
                color: getColorFromPalette(dataIndex),
                gridName: piezoStat?.label || key,
                dataList: getFilteredData(getRealData(value.measures)),
                type: piezoStat?.typeId === SAMPLE_TYPE && 'bar',
                connectNulls: false,
                barWidth: getBarWidth(value.measures.length),
                ...(piezoStat?.typeId === SAMPLE_TYPE ? { stack: 'samples' } : {}),
            }
        })
    }), [groupedMeasuresKeys, piezometersLight, piezoStats])

    const nbPiezoGrids = keys(groupBy(piezoFormatted, 'gridIndex')).length

    const hydroMeasuresFiltered = hydroMeasures.filter(m => !!m.measures.length)
    const hydroGroupedMeasures = groupBy(hydroMeasuresFiltered, 'dataType')
    const hydroGroupedMeasuresKeys = useMemo(() => [
        ...keys(hydroGroupedMeasures).filter(k => k === `${HYDROMETER_HEIGHT_TYPE}`),
        ...keys(hydroGroupedMeasures).filter(k => k !== `${HYDROMETER_HEIGHT_TYPE}`).sort((a, b) => parseInt(a) - parseInt(b)),
    ], [hydroGroupedMeasures])

    const hydroFormatted = useMemo(() => hydroStats.length ? hydroGroupedMeasuresKeys.flatMap((key, index) => {
        const values = hydroGroupedMeasures[key]
        return values.map((value, dataIndex) => {
            const stationName = hydrometers.find(a => a.id === value.stationId)?.name || ''
            const hydroStat = hydroStats.find(h => h.typeId === value.dataType)
            const unit = hydroStat?.unit ? `${hydroStat?.unit} ` : ''
            // const color = hydroStat?.color || ((parseInt(value.dataType) === HYDROMETER_HEIGHT_TYPE || hydroStat.chronicFollowUp) ? 'blue' : getColorFromPalette2(dataIndex))
            return {
                gridIndex: (parseInt(value.dataType) === HYDROMETER_HEIGHT_TYPE || hydroStat.chronicFollowUp) ? (nbPiezoGrids + 1) : index + (nbPiezoGrids + 1),
                name: `${stationName}${hydroStat.label && ` - ${hydroStat.label}` || ''}`,
                dataType: value.dataType,
                unit,
                color: getColorFromPalette2(dataIndex),
                gridName: hydroStat?.label || key,
                dataList: getFilteredData(value.measures),
            }
        })
    }) : [], [hydroGroupedMeasures, hydrometers, hydroStats, nbPiezoGrids])

    const nbHydroGrids = keys(groupBy(hydroFormatted, 'gridIndex')).length

    const pluvioMeasuresFiltered = pluvioMeasures.filter(m => !!m.measures.length)
    const pluvioGroupedMeasures = groupBy(pluvioMeasuresFiltered, 'dataType')

    const pluvioFormatted = useMemo(() => keys(pluvioGroupedMeasures).flatMap((key, index) => {
        const values = pluvioGroupedMeasures[key]
        return values.map((value, dataIndex) => {
            const stationName = pluviometers.find(a => a.id === value.stationId)?.name || ''
            const pluvioStat = pluvioStats.find(h => h.typeId === value.dataType)
            const unit = pluvioStat?.unit ? `${pluvioStat?.unit} ` : ''
            // const color = pluvioStat?.color || 'blue'
            return {
                gridIndex: index + (nbPiezoGrids + nbHydroGrids + 1),
                name: stationName,
                dataType: value.dataType,
                unit,
                color: getColorFromPalettePluvio(dataIndex),
                gridName: pluvioStat?.label || key,
                dataList: getFilteredData(value.measures),
                type: 'bar',
                connectNulls: false,
                barWidth: getBarWidth(value.measures.length),
            }
        })
    }), [pluvioGroupedMeasures, pluviometers, pluvioStats, nbPiezoGrids, nbHydroGrids])

    const dataFormatted = [...eventsFormatted, ...piezoFormatted, ...hydroFormatted, ...pluvioFormatted]

    const piezoDataTypesId = useMemo(() => piezoStats.map(stat => stat.typeId), [piezoStats])
    const piezoCodes = piezometersLight.filter(p => piezoIds.includes(p.id)).map(p => p.code)

    const piezoThresholds = piezometerAllThresholds.filter(t => piezoCodes.includes(t.code) && piezoDataTypesId.includes(parseInt(t.dataType))).map((t, index) => {
        const piezoStat = piezoStats.find(h => h.typeId === parseInt(t.dataType))
        const unit = piezoStat?.unit ? `${piezoStat?.unit} ` : ''
        return {
            ...t,
            unit,
            gridName: piezoStat?.label || t.dataType,
            gridIndex: piezoFormatted.find(mf => `${mf.dataType}` === t.dataType)?.gridIndex || (index + 1),
        }
    })

    const hydroDataTypesId = useMemo(() => hydroStats.map(stat => stat.typeId), [hydroStats])

    const hydroThresholds = hydroStats.length ? uniqBy(hydrometryThresholds.filter(t => hydroIds.includes(parseInt(t.stationId)) && hydroDataTypesId.includes(parseInt(t.dataType))), 'id').map((t, index) => {
        const hydroStat = hydroStats.find(h => h.typeId === parseInt(t.dataType))
        const unit = hydroStat?.unit ? `${hydroStat?.unit} ` : ''
        return {
            ...t,
            unit,
            gridName: hydroStat?.label || t.dataType,
            gridIndex: hydroFormatted.find(mf => `${mf.dataType}` === t.dataType)?.gridIndex || (index + 1),
        }
    }) : []

    const pluvioDataTypesId = useMemo(() => pluvioStats.map(stat => stat.typeId), [pluvioStats])

    const pluvioThresholds = uniqBy(pluviometerAllThresholds.filter(t => pluvioIds.includes(parseInt(t.stationId)) && pluvioDataTypesId.includes(parseInt(t.dataType))), 'id').map((t, index) => {
        const pluvioStat = pluvioStats.find(h => h.typeId === parseInt(t.dataType))
        const unit = pluvioStat?.unit ? `${pluvioStat?.unit} ` : ''
        return {
            ...t,
            unit,
            gridName: pluvioStat?.label || t.dataType,
            gridIndex: pluvioFormatted.find(mf => `${mf.dataType}` === t.dataType)?.gridIndex || (index + 1),
        }
    })

    const thresholds = [...piezoThresholds, ...hydroThresholds, ...pluvioThresholds]

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 25,
            left: '100px',
            height: 40,
        },
    }], [])

    const piezoGrids = useMemo(() => piezoFormatted.length ? groupedMeasuresKeys.filter(g => parseInt(g) === CHRONIC_TYPE || !piezometryDataTypes.find(pdt => pdt.id === parseInt(g)).isPiezo).map((key, index) => {
        const dataType = piezoGroupedMeasures[key]?.[0]?.dataType
        const piezoStat = piezoStats.find(h => h.typeId === dataType)
        const unit = piezoStat?.unit ? `[${piezoStat?.unit}] ` : ''

        const gridIndex = (index + 1)
        const option = dataType !== SAMPLE_TYPE ? setYOptionsPiezo(
            null,
            dataType,
            yAutomaticScaleValues(concat(
                piezoFormatted.filter(m => m.gridIndex === gridIndex).flatMap(m => m.dataList.map(d => d.value)),
                piezoThresholds.filter(m => m.gridIndex === gridIndex).map(({ value }) => value),
            )),
        ) : {}

        return {
            gridIndex,
            name: `${dataType === CHRONIC_TYPE ? i18n.depths : piezoStat?.label || key} ${unit}`,
            dataType,
            gridOptions: {
                left: 100,
                top: 25,
                height: graphicHeight,
            },
            yOptions: {
                calculateY: dataType !== SAMPLE_TYPE,
                inverse: dataType === CHRONIC_TYPE,
                ...option,
            },
        }
    }) : [], [graphicHeight, piezoFormatted, piezoGroupedMeasures, piezoStats])

    const hydroGrids = useMemo(() => hydroFormatted.length ? hydroGroupedMeasuresKeys.map((key, index) => {
        const dataType = hydroGroupedMeasures[key]?.[0]?.dataType
        const hydroStat = hydroStats.find(h => h.typeId === dataType)
        const unit = hydroStat?.unit ? `[${hydroStat?.unit}] ` : ''
        const gridIndex = index + (nbPiezoGrids + 1)

        return {
            gridIndex,
            name: `${hydroStat?.label || key} ${unit}`,
            dataType,
            gridOptions: {
                left: 100,
                top: 25,
                height: graphicHeight,
            },
            yOptions: {
                ...setYOptions(
                    null,
                    yAutomaticScaleValues(concat(
                        thresholds.filter(t => t.dataType === `${dataType}`).map(t => t.value),
                        hydroFormatted.filter(h => h.gridIndex === gridIndex).flatMap(h => h.dataList.map(d => d.value)),
                    ), 3),
                ),
            },
        }
    }) : [], [graphicHeight, hydroFormatted, hydroGroupedMeasures, hydroGroupedMeasuresKeys, hydroStats, nbPiezoGrids])

    const pluvioGrids = useMemo(() => pluvioFormatted.length ? keys(pluvioGroupedMeasures).map((key, index) => {
        const dataType = pluvioGroupedMeasures[key]?.[0]?.dataType
        const pluvioStat = pluvioStats.find(h => h.typeId === dataType)
        const unit = pluvioStat?.unit ? `[${pluvioStat?.unit}] ` : ''
        const gridIndex = index + (nbPiezoGrids + nbHydroGrids + 1)

        return {
            gridIndex,
            name: `${pluvioStat?.label || key} ${unit}`,
            gridOptions: {
                left: 100,
                top: 25,
                height: graphicHeight,
            },
            yOptions: {
                ...setYOptions(
                    null,
                    yAutomaticScaleValues(concat(
                        thresholds.filter(t => t.dataType === `${dataType}`).map(t => t.value),
                        pluvioFormatted.filter(h => h.gridIndex === gridIndex).flatMap(h => h.dataList.map(d => d.value)),
                    ), 3),
                ),
            },
        }
    }) : [], [graphicHeight, nbPiezoGrids, nbHydroGrids, pluvioFormatted, pluvioGroupedMeasures, pluvioStats])

    const grids = [...eventGrids, ...piezoGrids, ...hydroGrids, ...pluvioGrids]

    const formatTooltip = params => {
        if (keys(params[0].value[2]?.event)?.length) {
            return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [,, { event }] }) => {
                return `<br/>${marker} ${seriesName}: ${event.comment ? (chunkWithWords(event.comment, 40).replaceAll('\n', '<br />')) : ''}<br />
                ${hasValue(event.ns) ? `${i18n.staticLevelMeasure} : ${event.ns}m<br />` : ''}
                ${hasValue(event.nc) ? `${i18n.sensorInstantLevel} : ${event.nc}m<br />` : ''}`
            }).join('')
        }
        return getFullDate(params[0].axisValue) + params.filter(({ value: [, result] }) => !isNil(result)).map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    return (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            footerHeight={70}
            withToolTypeLine
            withToolTypeBar
            withToolThreshold
            withToolLine
            withToolMarker
            withDataZoom
            withToolLog
            withFullScreen
            withToolTypeStack
            withYZoom
            withOtherLegend
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            exportName={exportName}
            withExport
        />
    )
}

FlowObstructionMultiGraph.propTypes = {
    stationId: PropTypes.number,
    hydroStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    pluvioStations: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydroStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroStats)),
    pluvioStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    piezoStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    graphicHeight: PropTypes.number,
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    hydroMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    pluvioMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),
    piezoMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoChartMeasures)),
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    piezoIds: PropTypes.arrayOf(PropTypes.number),
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
    exportName: PropTypes.string,
}

const FlowObstructionChartPanel = ({
    stationId,
    piezoStatistics = [],
    hydroStatistics = [],
    pluvioStatistics = [],
    stationsEvents = [],
    graphicHeight = 250,
    hydroIds = [],
    pluvioIds = [],
    piezoIds = [],
    showTitle = true,
    exportName,
}) => {
    const idMinJournalier = useApplicationSetting('ID_MIN_JOURNALIER', intParser)

    const {
        hydrometers,
    } = useSelector(store => ({
        hydrometers: store.HomeReducer.hydrometers,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J365)) || J365

    const getDefaultMinDate = () => lastSelectedTime === HISTO ? undefined : moment().subtract(lastSelectedTime, 'days').startOf('day').valueOf()
    const getDefaultMaxDate = () => lastSelectedTime === HISTO ? moment().valueOf() : undefined

    const getInitialPiezoTypes = () => piezoStatistics.map(s => ({ ...s, type: STATION_TYPE_NAME.piezometry }))
    const getInitialHydroTypes = () => hydroStatistics.map(s => ({ ...s, type: STATION_TYPE_NAME.hydrometry }))
    const getInitialPluvioTypes = () => pluvioStatistics.map(s => ({ ...s, type: STATION_TYPE_NAME.pluviometry }))

    const [time, setTime] = useState(lastSelectedTime || J365)
    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())

    const [hydroMeasures, setHydroMeasures] = useState([])
    const [pluvioMeasures, setPluvioMeasures] = useState([])
    const [piezoMeasures, setPiezoMeasures] = useState([])

    const [piezoDataTypes, setPiezoDataTypes] = useState(getInitialPiezoTypes())
    const [hydroDataTypes, setHydroDataTypes] = useState(getInitialHydroTypes())
    const [pluvioDataTypes, setPluvioDataTypes] = useState(getInitialPluvioTypes())

    const [fullScreen, setFullScreen] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        initController()

        return () => {
            controllerRef.current.abort()

            setTime(lastSelectedTime || J365)
            setMinDate(getDefaultMinDate())
            setMaxDate(getDefaultMaxDate())

            setHydroMeasures([])
            setPluvioMeasures([])
            setPiezoMeasures([])

            setPiezoDataTypes(getInitialPiezoTypes())
            setHydroDataTypes(getInitialHydroTypes())
            setPluvioDataTypes(getInitialPluvioTypes())

            setFullScreen(false)
        }
    }, [])

    const {
        isLoaded: piezoIsLoaded,
        progress: piezoProgress,
    } = useProgressDispatch(() => {
        setPiezoMeasures([])
        const defaultGroupMode = time !== HISTO ? 'all' : 'MAX'
        return concat(piezoIds.map(id => {
            const piezoInputs = uniq(piezoDataTypes.map(stat => stat.typeId)).map(typeId => {
                const isMinJournalier = (!isNil(idMinJournalier) && typeId === Number(idMinJournalier))
                return {
                    stationId: id,
                    dataType: typeId,
                    groupFunc: typeId == SAMPLE_TYPE ? 'SUM' : defaultGroupMode,
                    chartMode: true,
                    startDate: minDate,
                    endDate: maxDate,
                    validOnly: (typeId === CHRONIC_TYPE),
                    displayCote: (typeId === CHRONIC_TYPE || isMinJournalier) ? 2 : 0,
                }
            })
            return dispatch(FollowAction.loadPiezoChartMeasures(piezoInputs, () => {}, controllerRef.current.signal)).then(data => setPiezoMeasures(prev => [...prev, ...data]))
        }))
    }, [minDate, maxDate, piezoDataTypes])

    const {
        isLoaded: hydroIsLoaded,
        progress: hydroProgress,
    } = useProgressDispatch(() => {
        setHydroMeasures([])
        const defaultGroupMode = time !== HISTO ? 'all' : 'MAX'
        return concat(hydroIds.map(id => {
            const AVhydroDataTypeIds = uniq([
                ...hydroStatistics?.filter(h => {
                    const hydroDataType = hydroDataTypes.find(dt => dt.typeId === h?.typeId) || {}
                    return hydroDataType.typeId && !hydroDataType.monitoringChronicle
                }),
                hydroDataTypes?.find(dt => dt.typeId === HYDROMETER_HEIGHT_TYPE),
            ].map(h => h?.typeId)).filter(h => !!h)
            const hydroTypes = hydrometers.find(h => h.id === id)?.code?.includes('_AM') ? uniq(hydroDataTypes.map(stat => stat.typeId)) : AVhydroDataTypeIds
            const hydroInputs = hydroTypes.map(typeId => ({
                stationId: id,
                dataType: typeId,
                groupFunc: defaultGroupMode,
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
            }))
            return dispatch(FollowAction.loadHydroChronicMeasures(hydroInputs, () => {}, controllerRef.current.signal))
                .then(data => setHydroMeasures(prev => [...prev, ...data]))
        }))
    }, [minDate, maxDate, hydroDataTypes])

    const {
        isLoaded: pluvioIsLoaded,
        progress: pluvioProgress,
    } = useProgressDispatch(() => {
        setPluvioMeasures([])
        const defaultGroupMode = time !== HISTO ? 'all' : 'MAX'
        return concat(pluvioIds.map(id => {
            const pluvioInputs = uniq(pluvioDataTypes.map(stat => stat.typeId)).map(typeId => ({
                stationId: id,
                dataType: typeId,
                groupFunc: [DATA_TYPE.VIRTUAL_EFFECTIVE_RAIN, DATA_TYPE.RAIN, DATA_TYPE.ETP].includes(typeId) ? PLUVIO_GROUPED : defaultGroupMode,
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
            }))
            return dispatch(FollowAction.loadPluvioChronicMeasures(pluvioInputs, () => {}, controllerRef.current.signal))
                .then(data => setPluvioMeasures(prev => [...prev, ...data]))
        }))
    }, [minDate, maxDate, pluvioDataTypes])

    const showProgress = !hydroIsLoaded || !pluvioIsLoaded || !piezoIsLoaded

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: '116px',
        left: 0,
        width: '100%',
        height: 'calc(100vh - 116px)',
        zIndex: 9,
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = !fullScreen ? graphicHeight : (window.innerHeight / 2.1)

    const onChangeDate = (newTime) => {
        if (newTime === HISTO) {
            setMinDate(undefined)
            setMaxDate(moment().valueOf())
        } else {
            setMinDate(moment().subtract(newTime, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
        }
        setTime(newTime)
    }

    const initialDataTypes = useMemo(() => concat(getInitialPiezoTypes(), getInitialHydroTypes(), getInitialPluvioTypes()), [])

    return (
        <Grid container sx={fullScreenStyle}>
            <Grid item xs={12}>
                <ChartTabs
                    time={time}
                    onChangeTime={onChangeDate}
                    inprogress={showProgress}
                    dataTypes={initialDataTypes}
                    setDataTypes={dataTypes => {
                        setPiezoDataTypes(dataTypes.filter(({ type }) => type === STATION_TYPE_NAME.piezometry))
                        setHydroDataTypes(dataTypes.filter(({ type }) => type === STATION_TYPE_NAME.hydrometry))
                        setPluvioDataTypes(dataTypes.filter(({ type }) => type === STATION_TYPE_NAME.pluviometry))
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {showProgress ? (
                    <ProgressBar progress={((hydroProgress + pluvioProgress + piezoProgress) / 3)} className='padding-top-4' />
                ) : (
                    <FlowObstructionMultiGraph
                        stationId={stationId}
                        hydroStats={hydroStatistics}
                        pluvioStats={pluvioStatistics}
                        piezoStats={piezoStatistics}
                        graphicHeight={chartHeight}
                        stationsEvents={stationsEvents}
                        hydroMeasures={hydroMeasures}
                        pluvioMeasures={pluvioMeasures}
                        piezoMeasures={piezoMeasures}
                        hydroIds={hydroIds}
                        pluvioIds={pluvioIds}
                        piezoIds={piezoIds}
                        onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                        defaultMinDate={minDate}
                        defaultMaxDate={moment().valueOf()}
                        showTitle={showTitle}
                        exportName={exportName}
                    />
                )}
            </Grid>
        </Grid>
    )
}

FlowObstructionChartPanel.propTypes = {
    stationId: PropTypes.number,
    piezoStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    hydroStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    pluvioStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    graphicHeight: PropTypes.number,
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    piezoIds: PropTypes.arrayOf(PropTypes.number),
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
    exportName: PropTypes.string,
}

const ObservatoryChartPanel = ({
    stationId,
    graphHeight = 250,
    showTitle,
    exportName,
}) => {
    const {
        linkedStations,
        hydrometers,
        pluviometers,
        piezometersLight,
        hydroDataTypes,
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        qualitometersLightStatus,
        productionUnitsStatus,

        piezometryDataTypes,
        piezometerAllThresholds,
        hydrometryThresholds,
        pluviometerAllThresholds,
    } = useSelector(store => ({
        linkedStations: store.HomeReducer.linkedStations,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        piezometersLight: store.HomeReducer.piezometersLight,
        hydroDataTypes: store.HomeReducer.hydroDataTypes,
        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        hydrometersStatus: store.DataManagerReducer.hydrometers.hydrometersStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometers.pluviometersStatus,
        qualitometersLightStatus: store.DataManagerReducer.qualitometers.qualitometersLightStatus,
        productionUnitsStatus: store.DataManagerReducer.productionUnits.productionUnitsStatus,

        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
        piezometerAllThresholds: store.FollowReducer.piezometerAllThresholds,
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,
    }), shallowEqual)

    const [hydroStatistics, setHydroStatistics] = useState([])
    const [pluvioStatistics, setPluvioStatistics] = useState([])
    const [piezoStatistics, setPiezoStatistics] = useState([])

    const [piezoStatsLoaded, setPiezoStatsLoaded] = useState(false)
    const [hydroStatsLoaded, setHydroStatsLoaded] = useState(false)
    const [pluvioStatsLoaded, setPluvioStatsLoaded] = useState(false)

    const [hydroStationsEvents, setHydroStationsEvents] = useState([])
    const [pluvioStationsEvents, setPluvioStationsEvents] = useState([])
    const [piezoStationsEvents, setPiezoStationsEvents] = useState([])

    const dispatch = useDispatch()

    const { isLoaded, progress } = useStateProgress([
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        qualitometersLightStatus,
        productionUnitsStatus,
    ])

    useEffect(() => {
        return () => {
            setHydroStatistics([])
            setPluvioStatistics([])
            setPiezoStatistics([])

            setHydroStationsEvents([])
            setPluvioStationsEvents([])
            setPiezoStationsEvents([])
        }
    }, [])

    const piezoIds = useMemo(() => {
        if (!linkedStations.length || !piezometersLight.length) {
            return []
        }
        const associatedStationsPiezo = linkedStations.filter(as => as.typeName === STATION_TYPE_NAME.piezometry).map(as => as.stationLinkedId)
        return piezometersLight.filter(h => associatedStationsPiezo.includes(h.id)).map(pluvio => pluvio.id)
    }, [linkedStations, piezometersLight])

    const hydroIds = useMemo(() => {
        if (!linkedStations.length || !hydrometers.length) {
            return []
        }
        const associatedStationsHydro = linkedStations.filter(as => as.typeName === STATION_TYPE_NAME.hydrometry).map(as => as.stationLinkedId)
        return hydrometers.filter(h => associatedStationsHydro.includes(h.id)).map(hydro => hydro.id)
    }, [linkedStations, hydrometers])

    const pluvioIds = useMemo(() => {
        if (!linkedStations.length || !pluviometers.length) {
            return []
        }
        const associatedStationsPluvio = linkedStations.filter(as => as.typeName === STATION_TYPE_NAME.pluviometry).map(as => as.stationLinkedId)
        return pluviometers.filter(h => associatedStationsPluvio.includes(h.id)).map(pluvio => pluvio.id)
    }, [linkedStations, pluviometers])

    const {
        isLoaded: dataIsLoaded,
        progress: dataProgress,
    } = useProgressDispatch(() => compact(
        !hydroDataTypes.length && dispatch(HomeAction.fetchDataTypesHydro()),
        !piezometerAllThresholds.length && dispatch(FollowAction.fetchPiezometerAllThresholds()),
        !hydrometryThresholds.length && dispatch(FollowAction.fetchHydrometricThresholds()),
        !pluviometerAllThresholds.length && dispatch(FollowAction.fetchPluviometerAllThresholds()),
    ), [])

    const {
        isLoaded: piezoDataIsLoaded,
        progress: piezoDataProgress,
    } = useProgressDispatch(() => {
        if (piezoIds.length) {
            return compact(
                dispatch(FollowAction.fetchSeveralPiezoStatistics(piezoIds)).then(piezoStats => {
                    setPiezoStatistics((piezoStats?.payload || []))
                    setPiezoStatsLoaded(true)
                }),
                !piezometryDataTypes.length && dispatch(FollowAction.fetchPiezometryDataTypes()),
                dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.piezometry, ids: piezoIds })).then(events => {
                    setPiezoStationsEvents((events?.payload || []))
                }),
            )
        }
        setPiezoStatsLoaded(true)
        return []
    }, [])

    const {
        isLoaded: hydroDataIsLoaded,
        progress: hydroDataProgress,
    } = useProgressDispatch(() => {
        if (hydroIds.length) {
            return compact(
                dispatch(FollowAction.fetchSeveralHydroStatistics(hydroIds)).then(hydroStats => {
                    setHydroStatistics((hydroStats?.payload || []))
                    setHydroStatsLoaded(true)
                }),
                dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.hydrometry, ids: hydroIds })).then(events => {
                    setHydroStationsEvents((events?.payload || []))
                }),
            )
        }
        setHydroStatsLoaded(true)
        return []
    }, [])

    const {
        isLoaded: pluvioDataIsLoaded,
        progress: pluvioDataProgress,
    } = useProgressDispatch(() => {
        if (pluvioIds.length) {
            return compact(
                dispatch(FollowAction.fetchSeveralPluvioStatistics(pluvioIds)).then(pluvioStats => {
                    setPluvioStatistics((pluvioStats?.payload || []))
                    setPluvioStatsLoaded(true)
                }),
                dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.pluviometry, ids: pluvioIds })).then(events => {
                    setPluvioStationsEvents((events?.payload || []))
                }),
            )
        }
        setPluvioStatsLoaded(true)
        return []
    }, [])

    const graphicHeight = graphHeight || window.innerHeight / 2.5
    const stationsEvents = [...hydroStationsEvents, ...pluvioStationsEvents, ...piezoStationsEvents]

    const allDataAreLoaded = dataIsLoaded && isLoaded && piezoDataIsLoaded && hydroDataIsLoaded && pluvioDataIsLoaded && piezoStatsLoaded && hydroStatsLoaded && pluvioStatsLoaded

    return (allDataAreLoaded) ? (
        <FlowObstructionChartPanel
            stationId={stationId}
            piezoStatistics={piezoStatistics}
            hydroStatistics={hydroStatistics}
            pluvioStatistics={pluvioStatistics}
            stationsEvents={stationsEvents}
            graphicHeight={graphicHeight}
            hydroIds={hydroIds}
            pluvioIds={pluvioIds}
            piezoIds={piezoIds}
            showTitle={showTitle}
            exportName={exportName}
        />
    ) : <ProgressBar progress={(progress + dataProgress + piezoDataProgress + hydroDataProgress + pluvioDataProgress) / 6} />
}

ObservatoryChartPanel.propTypes = {
    stationId: PropTypes.number.isRequired,
    stationCode: PropTypes.string.isRequired,
    graphHeight: PropTypes.number,
    showTitle: PropTypes.bool,
    exportName: PropTypes.string,
}

export default ObservatoryChartPanel