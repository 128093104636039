import { createAsyncThunk } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import ApplicationConf from '../../../conf/ApplicationConf'
import { checkAuth, checkError, genericPromise, genericPromiseHome, getAuthorization, getAuthorizationLogin, getJson, promiseAllProgressSeq, removeToken } from '../../../utils/ActionUtils'
import { KEY_RECAPTCHA, MODULE, SIEAU_LOGIN, SIEAU_TOKEN } from '../constants/HomeConstants'
import WaitAction from 'wait/WaitAction'
import LogAction from 'log/actions/LogAction'
import { removeNullKeys } from 'utils/StoreUtils'
import { chunk, flatten, max, template, uniqBy } from 'lodash'
import moment from 'moment'
import AccountAction from 'pages/account/actions/AccountAction'
import download from 'downloadjs'
import { getDateExport } from 'utils/DateUtil'
import DtoAssociatedStation from '../dto/DtoAssociatedStation'
import DtoFile from 'pages/online/referencials/documents/dto/DtoFile'
import DtoPicture from 'pages/online/referencials/documents/dto/DtoPicture'
import { HomeActionConstant } from '../reducers/HomeReducer'
import { FollowActionConstant } from 'pages/online/follows/reducers/FollowReducer'
import { CmsActionConstant } from 'pages/cms/reducers/CmsReducer'
import { AccountActionConstant } from 'pages/account/reducers/AccountReducer'
import { PIEZO_MEASURES_TYPES, STATION_TYPE_CONSTANT } from '../constants/StationConstants'
import { observatoryUsedUrl } from 'conf/basepath'
import { getLogin } from 'utils/LocalStorageUtils'

const HomeAction = {
    login: createAsyncThunk(
        'login',
        async(dataObj, { rejectWithValue, dispatch }) => {
            dispatch(WaitAction.waitStart())
            const { login, password } = dataObj
            const basicAuth = btoa(`${login}:${password}`)
            try {
                const response = await fetch(ApplicationConf.login(), {
                    method: 'POST',
                    headers: {
                        Authorization: `Basic ${basicAuth}`,
                    },
                    body: JSON.stringify({ module: MODULE }),
                })
                    .then(checkAuth)
                    .then(getJson)
                    .then(json => {
                        localStorage.setItem(SIEAU_LOGIN, login)
                        localStorage.setItem(SIEAU_TOKEN, json)
                        dispatch(AccountAction.fetchAccountHabilitations(login))
                        // dispatch(WaitAction.waitStop())
                    })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.unauthorizedLogin}: ${err}`))
                dispatch(ToastrAction.error(i18n.unauthorizedLogin, true))
                return rejectWithValue(err)
            }
        },
    ),

    logout: createAsyncThunk(
        'logout',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.logout(), {
                    method: 'POST',
                    headers: getAuthorization(),
                })
                removeToken()
                localStorage.removeItem(SIEAU_TOKEN)
                dispatch(push('/login'))
                dispatch(HomeActionConstant.reset())
                dispatch(FollowActionConstant.reset())
                dispatch(CmsActionConstant.reset())
                dispatch(AccountActionConstant.reset())
                switch (response.status) {
                    case 200:
                        return response
                    default:
                        throw new Error(response.statusText)
                }
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.logout}: ${err}`))
                return rejectWithValue(err)
            }
        },
    ),

    verifyResetCode: createAsyncThunk(
        'verifyResetCode',
        async(dataObj, { rejectWithValue, dispatch }) => {
            const { login, resetCode } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.verifyResetCode(), 'POST', { login, resetCode }, {
                    404: () => {
                        ToastrAction.error(i18n.unableToResetPassword)
                        return false
                    },
                    429: () => {
                        ToastrAction.error(i18n.error429)
                        return false
                    },
                    200: () => {
                        ToastrAction.success(i18n.valideCode)
                        return true
                    },
                })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.retrievePassword}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.retrievePassword))
                return rejectWithValue(err)
            }
        },
    ),

    resetPassword: createAsyncThunk(
        'resetPassword',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.resetPassword(), 'POST', { login, module: MODULE, url: observatoryUsedUrl })
                    .then((json) => {
                        if (json.status !== 200) {
                            ToastrAction.error(i18n.incorrectId, true)
                            return json
                        }
                        return null
                    })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.retrievePassword}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.retrievePassword))
                return rejectWithValue(err)
            }
        },
    ),

    sendMailToAdmin: createAsyncThunk(
        'sendMailToAdmin',
        async(mail, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromiseHome(ApplicationConf.mailSupport(), 'POST', { ...mail, module: MODULE })
                    .then((json) => {
                        if (json.status !== 200) {
                            ToastrAction.error(i18n.errorOnSendMail, true)
                            return json
                        }
                        ToastrAction.success(i18n.sendMailAdmin, true)
                        return null
                    })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.errorOnSendMail} : ${err}`))
                dispatch(ToastrAction.error(i18n.errorOnSendMail))
                return rejectWithValue(err)
            }
        },
    ),

    fetchUsers: createAsyncThunk(
        'fetchUsers',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.user.userPath())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.users}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchCities: createAsyncThunk(
        'fetchCities',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.cities())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.cities}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    setTitle: createAsyncThunk(
        'setTitle',
        async(title) => title,
    ),

    fetchApplicationSettings: createAsyncThunk(
        'fetchApplicationSettings',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromiseHome(ApplicationConf.referencial.globalParameters())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.settings}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezometersLight: createAsyncThunk(
        'fetchPiezometersLight',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.getAllLight())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometers}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchAllLinkedStation: createAsyncThunk(
        'fetchAllLinkedStation',
        async (dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { codes = [], stationType, progressCallback = () => {} } = dataObj
            try {
                if (!codes.length) {
                    return fulfillWithValue([])
                }
                const urls = codes.map(code => ({ url: ApplicationConf.referencial.associatedLinksLight(code, stationType) }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.associatedStations} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.associatedStations}`))
                return rejectWithValue(err)
            }
        },
    ),

    fetchLinkedStations(codes = [], stationType, progressCallback = () => {}) {
        return (dispatch) => {
            const urls = codes.map(code => ({ url: ApplicationConf.referencial.associatedLinksLight(code, stationType) }))
            return promiseAllProgressSeq(urls, progressCallback)
                .then((json = []) => flatten(json))
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.associatedStations}: ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.associatedStations))
                })
        }
    },

    fetchAllLinkedUnitsStations: createAsyncThunk(
        'fetchAllLinkedUnitsStations',
        async (dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { ids = [], isUDI = false, progressCallback = () => {} } = dataObj
            try {
                if (!ids.length) {
                    progressCallback(100)
                    return fulfillWithValue([])
                }
                const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
                const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.referencial.associatedLinksSeqLight(), method: 'POST', body: { ids: idsGroup, stationType: isUDI ? STATION_TYPE_CONSTANT.distributionUnit : STATION_TYPE_CONSTANT.productionUnit } }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.associatedStations} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.associatedStations}`))
                return rejectWithValue(err)
            }
        },
    ),

    fetchLinkedStation(code, stationType) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.referencial.associatedLinksLight(code, stationType), 'GET')
                .then((json = []) => json.map((s) => new DtoAssociatedStation(s)))
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.associatedStations}: ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.associatedStations))
                })
        }
    },

    fetchAllContributorsLink: createAsyncThunk(
        'fetchAllContributorsLink',
        async (stationType, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.station.allContributors(stationType), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contributors} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.contributors}`))
                return rejectWithValue(err)
            }
        },
    ),

    fetchAllLinkedStationsEvents: createAsyncThunk(
        'fetchAllLinkedStationsEvents',
        async (dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { ids = [], stationType, progressCallback = () => {} } = dataObj
            try {
                if (!ids.length) {
                    return fulfillWithValue([])
                }
                const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
                const urls = chunk(ids, groupsSize).map(idsGroup => ({ url: ApplicationConf.referencial.stationsEvents(), method: 'POST', body: { ids: idsGroup, stationType } }))
                const response = await promiseAllProgressSeq(urls, progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.events} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.events}`))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPictures: createAsyncThunk(
        'fetchPictures',
        async (dataObj, { rejectWithValue, dispatch }) => {
            const { code, stationType } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.file.stationPictures(code, stationType))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.pictures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchDocuments: createAsyncThunk(
        'fetchDocuments',
        async (dataObj, { rejectWithValue, dispatch }) => {
            const { code, stationType } = dataObj
            try {
                const response = await genericPromise(ApplicationConf.file.stationDocuments(code, stationType))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.documents}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchLinkedPictures: (codesAndTypes, callback = () => { }, progressCallback = () => { }) => dispatch => {
        const promises = codesAndTypes.map(d => ({ url: ApplicationConf.file.stationPictures(d.code, d.stationType) }))
        return promiseAllProgressSeq(promises, progressCallback).then(jsonResults => {
            const results = flatten(jsonResults)
            callback(results.length)
            return uniqBy(results.map((d) => new DtoPicture(d, false)), 'url')
        })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.pictures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                callback()
            })
    },

    fetchLinkedDocuments: (codesAndTypes, callback = () => { }, progressCallback = () => { }) => dispatch => {
        const promises = codesAndTypes.map(d => ({ url: ApplicationConf.file.stationDocuments(d.code, d.stationType) }))
        return promiseAllProgressSeq(promises, progressCallback).then(jsonResults => {
            const results = flatten(jsonResults)
            callback(results.length)
            return uniqBy(results.map((d) => new DtoFile(d)), 'url')
        })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.documents}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.documents))
                callback()
            })
    },

    fetchHydrometers: createAsyncThunk(
        'fetchHydrometers',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.getAll())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.hydrometricStations}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezoMeasures: createAsyncThunk(
        'fetchPiezoMeasures',
        async(dataObj, { rejectWithValue, fulfillWithValue, dispatch }) => {
            const { codes, progressCallback = () => {} } = dataObj
            try {
                if (!codes.length) {
                    return fulfillWithValue([])
                }
                const promises1 = codes.map(c => ({ url: ApplicationConf.piezometer.additionalMeasures(c, PIEZO_MEASURES_TYPES.CHRONIC) }))
                const promises2 = codes.map(c => ({ url: ApplicationConf.piezometer.additionalMeasures(c, PIEZO_MEASURES_TYPES.SAMPLE) }))
                const response = await promiseAllProgressSeq([...promises1, ...promises2], progressCallback)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.measures}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchDataTypesHydro: createAsyncThunk(
        'fetchDataTypesHydro',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.station.dataTypesByProject('SIH'))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPluviometers: createAsyncThunk(
        'fetchPluviometers',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometer.getAll())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.pluviometers}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchQualitometersLight: createAsyncThunk(
        'fetchQualitometersLight',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.qualitometers.getAllLight())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.qualitometers}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchInstallationsLight: createAsyncThunk(
        'fetchInstallationsLight',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.installation.getAllLight())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.installations}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchSandreCodes: createAsyncThunk(
        'fetchSandreCodes',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.sandreCodes())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchProductionUnits: createAsyncThunk(
        'fetchProductionUnits',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.productionUnit.getAll())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.productionUnits}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchProductionUnit: createAsyncThunk(
        'fetchProductionUnit',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.productionUnit.get(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.productionUnit}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchProductionUnitLinks: createAsyncThunk(
        'fetchProductionUnitLinks',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.productionUnit.links(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.productionUnit}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchDistributionUnits: createAsyncThunk(
        'fetchDistributionUnits',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.distributionUnit.getAll())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.distributionUnits}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchDistributionUnit: createAsyncThunk(
        'fetchDistributionUnit',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.distributionUnit.get(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.distributionUnits}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPiezometer: createAsyncThunk(
        'fetchPiezometer',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.piezometer.get(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometer}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchHydrologicalStation: createAsyncThunk(
        'fetchHydrologicalStation',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.get(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.hydrometricStation}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchPluviometer: createAsyncThunk(
        'fetchPluviometer',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometer.get(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.pluviometer}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchQualitometer: createAsyncThunk(
        'fetchQualitometer',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.qualitometers.get(id))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.qualitometer}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    fetchCMSHome: createAsyncThunk(
        'fetchCMSHome',
        async(id, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cms.get(id), removeNullKeys({
                    method: 'GET',
                    headers: getAuthorizationLogin(),
                }))
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
                dispatch(ToastrAction.error(i18n.article))
                return rejectWithValue(err)
            }
        },
    ),

    getCGUFromCms: createAsyncThunk(
        'getCGUFromCms',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cms.getByCategory(2), removeNullKeys({
                    method: 'GET',
                    headers: getAuthorizationLogin(),
                }))
                    .then(getJson)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
                dispatch(ToastrAction.error(i18n.article))
                return rejectWithValue(err)
            }
        },
    ),

    updatePassword: (password, token) => dispatch => fetch(ApplicationConf.resetPassword(), {
        method: 'PUT',
        body: JSON.stringify({ password, token }),
    })
        .then(checkAuth)
        .then(() => {
            dispatch(push('/login'))
            dispatch(ToastrAction.success(i18n.passwordUpdate))
        })
        .catch(e => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.newPassword} : ${e}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.newPassword))
            dispatch(WaitAction.waitStop())
        }),

    updateSetting: createAsyncThunk(
        'updateSetting',
        async({ settingName, value, refreshData = true }, { rejectWithValue, dispatch, getState }) => {
            const setting = getState().AccountReducer.accountUserSettings.find(s => s.parameter === settingName)
            try {
                const response = await genericPromise(ApplicationConf.user.setting(), setting && setting.login !== '' ? 'PUT' : 'POST', { parameter: settingName, value })
                if (refreshData) {
                    dispatch(AccountAction.fetchAccountUserSettings())
                }
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.setting}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.setting))
                return rejectWithValue(err)
            }
        },
    ),

    updateMultipleSettings: createAsyncThunk(
        'updateMultipleSettings',
        async({ settings, refreshData = true }, { rejectWithValue, dispatch, getState }) => {
            const allNewParams = settings.map((s) => s.parameter)
            const otherSettings = getState().AccountReducer.accountUserSettings.filter(s => !allNewParams.includes(s.parameter))
            try {
                const response = await genericPromise(ApplicationConf.user.saveUserParameters(getLogin()), 'PUT', [...otherSettings, ...settings])
                if (refreshData) {
                    dispatch(AccountAction.fetchAccountUserSettings())
                }
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.updateError} ${i18n.parameters}: ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.parameters))
                return rejectWithValue(err)
            }
        },
    ),

    export: createAsyncThunk(
        'export',
        async(dataObj, { rejectWithValue, dispatch }) => {
            ToastrAction.info(i18n.loadingDocument)
            const { exportData, type, titleFile } = dataObj
            try {
                await fetch(ApplicationConf.export.exportData(), removeNullKeys({
                    method: 'POST',
                    headers: getAuthorizationLogin(),
                    body: JSON.stringify({
                        data: exportData,
                        type,
                    }),
                }))
                    .then((resp) => {
                        return resp.blob()
                    }).then((blob) => {
                        download(blob, `${titleFile}_${getDateExport()}.${type}`, blob.type)
                    })
                return null
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.exportError}: ${err}`))
                dispatch(ToastrAction.error(i18n.exportError))
                return rejectWithValue(err)
            }
        },
    ),

    getCmsPartenaire: createAsyncThunk(
        'getCmsPartenaire',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cms.getByCategory(2), removeNullKeys({
                    method: 'GET',
                    headers: getAuthorizationLogin(),
                }))
                    .then(getJson)
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
                dispatch(ToastrAction.error(i18n.article))
                return rejectWithValue(err)
            }
        },
    ),

    sendDateCgu: createAsyncThunk(
        'sendDateCgu',
        async(postDateCGU, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cms.sendDateCgu(), removeNullKeys({
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(postDateCGU),
                }))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.createError}: ${err}`))
                dispatch(ToastrAction.error(i18n.createError))
                return rejectWithValue(err)
            }
        },
    ),

    getDateValidCGU: createAsyncThunk(
        'getDateValidCGU',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cms.getDateValidCGU(login), removeNullKeys({
                    method: 'GET',
                    headers: getAuthorization(),
                }))
                    .then(getJson)
                return isNaN(Number(response?.date)) ? moment(response?.date) : Number(response?.date)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.article}: ${err}`))
                dispatch(ToastrAction.error(i18n.article))
                return rejectWithValue(err)
            }
        },
    ),

    fetchContributors: createAsyncThunk(
        'fetchContributors',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.contributors())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contributors}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.contributors))
                return rejectWithValue(err)
            }
        },
    ),

    fetchContributorsGroups: createAsyncThunk(
        'fetchContributorsGroups',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.contributorsGroups())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contributorsGroups}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.contributorsGroups))
                return rejectWithValue(err)
            }
        },
    ),

    fetchMapLayers: createAsyncThunk(
        'fetchMapLayers',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.layers.getLayers())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.mapLayers}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.mapLayers))
                return rejectWithValue(err)
            }
        },
    ),

    fetchCartographyThemes: createAsyncThunk(
        'fetchCartographyThemes',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.layers.getAuthorizedLayers())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.authorizedThemes}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.authorizedThemes))
                return rejectWithValue(err)
            }
        },
    ),

    setWindowReload: createAsyncThunk(
        'setWindowReload',
        async(reload) => `{ reload: ${reload} }`,
    ),

    getAllCGU: createAsyncThunk(
        'getAllCGU',
        async(_, { rejectWithValue, dispatch, getState }) => {
            try {
                const applicationSettings = getState().HomeReducer.applicationSettings || []
                const categorieCmsCGU = applicationSettings.find(s => s.parameter === 'categorieCmsCGU')?.value
                const response = await genericPromiseHome(categorieCmsCGU ? ApplicationConf.cms.getByCategory(parseInt(categorieCmsCGU)) : ApplicationConf.cgu())
                return categorieCmsCGU ? {
                    title: `${i18n.cgu}<br /><br />`,
                    comment: response.map(({ title, comment }) => `<strong>${title}</strong><br />${comment}<br /><br />`).toString() || '',
                    lastDate: max(response.map(({ updateDate }) => updateDate)),
                } : {
                    title: response?.title?.rendered || i18n.cgu,
                    comment: response?.content?.rendered || '',
                    lastDate: response?.modified && moment(response.modified).valueOf(),
                }
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        },
    ),

    setLoadingData: createAsyncThunk(
        'setLoadingData',
        async (loading) => {
            return loading
        },
    ),

    checkUrl: (url) => async () => {
        return await fetch(url, { method: 'HEAD' })
            .then(res => res.ok)
            .catch(() => false)
    },

    checkReCaptchaAssessment: createAsyncThunk(
        'checkReCaptchaAssessment',
        async(token, { rejectWithValue, dispatch }) => {
            try {
                const assessment = await fetch(ApplicationConf.recaptcha(), {
                    method: 'POST',
                    body: JSON.stringify({
                        event: {
                            token,
                            expectedAction: 'LOGIN_CONTACT',
                            siteKey: KEY_RECAPTCHA,
                        },
                    }),
                })
                    .then(getJson)
                if (!assessment.tokenProperties.valid) {
                    dispatch(LogAction.logError(template(i18n.failureValidateRecaptchaRisk)({
                        action: assessment.tokenProperties.action,
                        time: assessment.tokenProperties.createTime,
                        invalidReason: assessment.tokenProperties.invalidReason,
                        score: assessment.riskAnalysis.score,
                        reasons: assessment.riskAnalysis.reasons.join('\n - '),
                    })))
                    dispatch(ToastrAction.error(template(i18n.failureValidateRecaptchaRisk)({ score: assessment.riskAnalysis.score })))
                }
                return assessment.tokenProperties.valid
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ReCaptcha: ${err}`))
                dispatch(ToastrAction.error('ReCaptcha'))
                return rejectWithValue(err)
            }
        },
    ),

}

export default HomeAction
