/* eslint-disable consistent-return */
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts/lib/echarts'
import { groupBy, isUndefined, keys, max, maxBy, min, minBy, orderBy, round, sum } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import {
    chartLine, chartSymbol,
    exportExcelIcon,
    exportPictureIcon, fullScreenIcon, getAxisIntervalFormatter, histogramIcon, infoIcon, legendSymbol, lineIcon, logIcon, setYOptions, stackIcon, thresholdIcon, yAutomaticScaleValues,
} from './EChartUtils'
import { hasValue } from 'utils/NumberUtil'
import { getThresholdColor } from 'utils/ColorUtil'
import { getDate, getFullDate } from 'utils/DateUtil'
import { useDispatch } from 'react-redux'
import HomeAction from 'pages/home/actions/HomeAction'
import { formatData } from 'utils/ActionUtils'
import ExportFileModal from '../ExportFileModal'
import { mainBlue, mainWhite, secondaryBlue, SMALL_RADIUS, strongGrey } from 'components/styled/Theme'
import { Grid } from '@mui/material'
import { getQualificationSelectOptions, getStatusSelectOptions } from 'utils/StatusUtil'

const DEFAULT_GRID = 'Grid'
const DEFAULT_GRAPH_HEIGHT = 400
const DEFAULT_GAP = 50
const DEFAULT_HEADER_HEIGHT = 60
const DEFAULT_FOOTER_HEIGHT = 135
const LINE = 'line'
const BAR = 'bar'
const ALL = 'all'

const MultiChart = ({
    data = [],
    grids = [],
    thresholds = [],
    title = '',
    exportName = '',
    componentHeight,
    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
    roundValue = 3,

    defaultMinDate,
    defaultMaxDate,

    xAxisSpace,
    defaultDisplayMarker = true,
    defaultDisplayLine = true,

    withToolTypeLine = false,
    withToolTypeBar = false,
    withToolTypeStack = false,
    withToolLog = false,
    withToolThreshold = false,
    withToolLegend = false,
    withToolMarker = false,
    withToolLine = false,
    withToolZoom = true,
    withFullScreen = false,
    withDataZoom = false,
    withOtherLegend = false,
    withYZoom = false,
    withExport = false,

    dataZoomPosition = { },
    toolboxPosition = { },
    legendPosition = { },

    tooltipFormatter,
    onFullScreen = () => {},

    onChangeLegend = () => {},
    defaultSelectedLegend = {},
}) => {
    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)
    let selectedLegend = useRef(defaultSelectedLegend)

    const getDisplayGraph = (gridIndex) => {
        if (!selectedLegend.current || !keys(selectedLegend.current).length) {
            return true
        }
        return data.some(d => d.gridIndex === gridIndex && (isUndefined(selectedLegend.current[d.name]) || selectedLegend.current[d.name]))
    }

    let gridsWithDisplay = useRef(grids.map((g, gridIndex) => ({ ...g, display: getDisplayGraph(gridIndex) })))

    const [displayLog, setDisplayLog] = useState(false)
    const [displayMarker, setDisplayMarker] = useState(defaultDisplayMarker)
    const [displayLegend, setDisplayLegend] = useState(true)
    const [displayLine, setDisplayLine] = useState(defaultDisplayLine)
    const [displayThreshold, setDisplayThreshold] = useState(true)
    const [chartType, setChartType] = useState(LINE)
    const [stackAll, setStackAll] = useState()
    const [dataExport, setDataExport] = useState({ open: false, data: [] })
    const [openOtherLegend, setOpenOtherLegend] = useState(false)

    const defaultTimestamp = moment().valueOf()

    const thresholdsWithValue = thresholds.filter(({ value }) => hasValue(value))

    const allData = data.flatMap(d => d.dataList)
    const thresholdGroupByAxis = groupBy(thresholdsWithValue, t => t.gridName || DEFAULT_GRID)

    const minDate = defaultMinDate ?? (minBy(allData, 'date')?.date || defaultTimestamp)
    const maxDate = defaultMaxDate ?? (maxBy(allData, 'date')?.date || defaultTimestamp)
    const chartMinDate = xAxisSpace ? moment(minDate).startOf(xAxisSpace) : moment(minDate)
    const chartMaxDate = xAxisSpace ? moment(maxDate).endOf(xAxisSpace) : moment(maxDate)

    const {
        formatter,
        interval,
    } = getAxisIntervalFormatter(chartMaxDate, chartMinDate)

    const getGridList = () => {
        let tmpGap = headerHeight
        return gridsWithDisplay.current.map(({ gridOptions: { top = DEFAULT_GAP, height = DEFAULT_GRAPH_HEIGHT, right = '40px', left = '60px' }, display }) => {
            const tmpTop = tmpGap + top
            const grid = {
                top: tmpTop,
                height: display ? height : 0,
                left,
                right,
            }
            if (display) {
                tmpGap = tmpTop + height
            }
            return grid
        })
    }

    useEffect(() => {
        gridsWithDisplay.current = grids.map((g, gridIndex) => ({ ...g, display: getDisplayGraph(gridIndex) }))
        echartRef.current?.getEchartsInstance().setOption({
            grid: getGridList(),
        })
    }, [grids])

    const getXAxisList = () => gridsWithDisplay.current.map(({ xOptions = {}, display }, index = 0) => {
        const {
            type = 'time',
            boundaryGap = true,
            showSplitLine = true,
            axisLabel = {},
            axisLine = {},
            axisTick = {},
        } = xOptions
        return {
            type,
            boundaryGap,
            axisLabel: {
                rotate: axisLabel.rotate || 0,
                formatter,
                show: display ? (axisLabel.show ?? true) : display,
            },
            axisLine: {
                show: display ? (axisLine.show ?? true) : display,
            },
            axisTick: {
                show: display ? (axisTick.show ?? true) : display,
            },
            splitLine: {
                show: display ? (showSplitLine ?? true) : display,
            },
            maxInterval: interval,
            gridIndex: index,
            min: minDate,
            max: maxDate,
        }
    })

    const thresholdsSeries = displayThreshold ? thresholdsWithValue.map(({ value, unit = '', name = i18n.threshold, color = 'black', gridName = DEFAULT_GRID, htmlColor }) => ({
        yAxis: value,
        symbol: 'none',
        gridName,
        label: {
            show: true,
            position: 'middle',
            formatter: () => name ? `${name} : ${round(value, 2)} ${unit}` : '',
        },
        lineStyle: {
            normal: {
                color: htmlColor || getThresholdColor(color),
                type: 'dashed',
            },
        },
    })) : []

    const series = data.flatMap(({ name, unit, dataList, color, type, lineStyle, showSymbol = true, connectNulls = true, gridName = DEFAULT_GRID, barWidth, gridIndex = 0, stack }) => {
        const dataListFiltered = displayLog ? dataList.filter(d => d.value > 0) : dataList
        const dataOrdered = orderBy(dataListFiltered, 'date').map(dataObj => ({
            value: [dataObj.date, dataObj.value, dataObj],
            symbol: dataObj.marker || (displayMarker ? 'circle' : 'none'),
            symbolSize: dataObj.marker ? 10 : 6,
            itemStyle: {
                color: dataObj.markerColor,
            },
            unit: dataObj.unit ?? unit,
        }))
        return {
            type: type || chartType,
            data: dataOrdered,
            name: name || '',
            connectNulls,
            showSymbol,
            color,
            barWidth,
            lineStyle: {
                opacity: displayLine ? undefined : 0,
                type: lineStyle,
            },
            stack: stack || stackAll,
            yAxisIndex: gridIndex,
            xAxisIndex: gridIndex,
            markLine: {
                silent: false,
                symbol: 'none',
                data: thresholdsSeries.filter(threshold => threshold.gridName === gridName),
            },
        }
    })

    const getYScale = (index) => {
        const keysDisplayed = keys(selectedLegend.current).filter(legend => selectedLegend.current[legend])
        const allSeriesValues = series.filter(({ xAxisIndex, name: serieName }) => xAxisIndex === index && keysDisplayed.includes(serieName)).flatMap(s => s.data).map(({ value }) => value[1])
        const allDatas = data.filter(({ gridIndex }) => gridIndex === index)
        const allThresholdsValues = thresholdsSeries.filter(threshold => allDatas.includes(({ gridName }) => threshold.gridName === gridName)).map(({ yAxis }) => yAxis)
        const minRoundPrecision = (allSeriesValues.length > 0 && min(allSeriesValues) === max(allSeriesValues)) ? 0 : 1
        const yScale = yAutomaticScaleValues([ ...allSeriesValues, ...allThresholdsValues ], minRoundPrecision)
        return setYOptions(null, yScale)
    }

    const getYAxisList = () => gridsWithDisplay.current.map(({ name, yOptions = {}, display }, index = 0) => {
        const optionalOptions = display && yOptions.calculateY ? { ...getYScale(index), ...yOptions } : yOptions
        return {
            type: displayLog ? 'log' : 'value',
            nameLocation: 'center',
            nameGap: 40,
            boundaryGap: true,
            name: display ? name : undefined,
            gridIndex: index,
            max: value => {
                if (displayLog) {
                    return
                }
                const maxThresholdValue = maxBy(thresholdGroupByAxis[name], 'value')?.value
                return round(maxThresholdValue > value.max ? maxThresholdValue : value.max, 3)
            },
            min: value => {
                if (displayLog) {
                    return
                }
                if (chartType === BAR || stackAll) {
                    return 0
                }
                const minThresholdValue = minBy(thresholdGroupByAxis[name], 'value')?.value
                return round(minThresholdValue < value.min ? minThresholdValue : value.min, 3)
            },
            ...optionalOptions,
        }
    })

    const tools = [withToolTypeLine, withToolTypeBar, withToolTypeStack, withToolLog, withToolThreshold, withToolLegend, withToolMarker,
        withToolLine, withToolZoom, withFullScreen, withDataZoom, withOtherLegend, withYZoom, withExport].filter(t => !!t)
    const legendWidth = sum(tools.map(() => 37))

    // these functions are called in the option object as well as in the setOption in the mouseEvent
    const getLegend = () => ({
        top: 10,
        left: 95,
        // adding boolan give number
        right: displayToolbox.current ? 30 * (3 + tools.length) + (parseInt(toolboxPosition.right ?? 35)) : 20,
        ...legendPosition,
        type: 'scroll',
        show: displayLegend,
        width: displayToolbox.current ? `${100 - (tools.length * 4)}%` : 'auto',
        selected: selectedLegend.current,
    })

    const formatTooltip = params => {
        return getDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [, result], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, roundValue)} ${unit}`).join('')
    }

    const dispatch = useDispatch()

    const defaultDataZoom = [{
        type: 'slider',
        xAxisIndex: getGridList().map((_, index) => index),
        filterMode: 'none',
    }, {
        bottom: '10px',
        ...dataZoomPosition,
        type: 'inside',
        filterMode: 'filter',
        xAxisIndex: getGridList().map((_, index) => index),
        handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetY: 2,
        },
    }]
    const filteredDataZoom = withYZoom ? [...defaultDataZoom, {
        show: false,
        height: '90%',
        type: 'slider',
        yAxisIndex: getGridList().map((_, index) => index),
        filterMode: 'none',
    }, {
        type: 'inside',
        filterMode: 'empty',
        yAxisIndex: getGridList().map((_, index) => index),
        handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetY: 2,
        },
    }] : defaultDataZoom

    const getToolbox = () => ({
        show: displayToolbox.current ?? false,
        top: 7,
        right: 35,
        ...toolboxPosition,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            position: 'bottom',
            show: true,
            formatter: param => param.title,
        },
        feature: {
            myToolOtherLegend: {
                show: withOtherLegend,
                title: i18n.additionalInformations,
                icon: infoIcon,
                onclick: () => setOpenOtherLegend(prevOtherLegend => !prevOtherLegend),
                iconStyle: {
                    borderColor: displayMarker ? secondaryBlue : strongGrey,
                },
            },
            myToolLine: {
                show: withToolTypeLine,
                title: i18n.lines,
                icon: lineIcon,
                onclick: () => {
                    setStackAll(undefined)
                    setChartType(LINE)
                },
                iconStyle: {
                    borderColor: chartType === LINE ? secondaryBlue : strongGrey,
                },
            },
            myToolBar: {
                show: withToolTypeBar,
                title: i18n.histogram,
                icon: histogramIcon,
                onclick: () => {
                    setStackAll(undefined)
                    setChartType(BAR)
                },
                iconStyle: {
                    borderColor: chartType === BAR ? secondaryBlue : strongGrey,
                },
            },
            myToolStack: {
                show: withToolTypeStack,
                title: i18n.stack,
                icon: stackIcon,
                onclick: () => {
                    if (stackAll) {
                        setStackAll(undefined)
                    } else {
                        setStackAll(ALL)
                        setChartType(BAR)
                    }
                },
                iconStyle: {
                    borderColor: chartType === BAR && stackAll === ALL ? secondaryBlue : strongGrey,
                },
            },
            myToolLog: {
                show: withToolLog,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => {
                    setStackAll(undefined)
                    setChartType(LINE)
                    setDisplayLog(prevDisplayLog => !prevDisplayLog)
                },
                iconStyle: {
                    borderColor: displayLog ? secondaryBlue : strongGrey,
                },
            },
            myToolToggleThreshold: {
                show: withToolThreshold,
                title: i18n.toggleThreshold,
                icon: thresholdIcon,
                onclick: () => setDisplayThreshold(prevHiddenThreshold => !prevHiddenThreshold),
                iconStyle: {
                    borderColor: displayThreshold ? secondaryBlue : strongGrey,
                },
            },
            myToolToggleLegend: {
                show: withToolLegend,
                title: i18n.toggleLegend,
                icon: legendSymbol,
                onclick: () => setDisplayLegend(prevHiddenLegend => !prevHiddenLegend),
                iconStyle: {
                    borderColor: displayLegend ? secondaryBlue : strongGrey,
                },
            },
            myToolToggleMarker: {
                show: withToolMarker,
                title: i18n.toggleMarker,
                icon: chartSymbol,
                onclick: () => setDisplayMarker(prevHiddenMarker => !prevHiddenMarker),
                iconStyle: {
                    borderColor: displayMarker ? secondaryBlue : strongGrey,
                },
            },
            myToolToggleLine: {
                show: withToolLine,
                title: i18n.toggleLine,
                icon: chartLine,
                onclick: () => setDisplayLine(prevHiddenLine => !prevHiddenLine),
                iconStyle: {
                    borderColor: displayLine ? secondaryBlue : strongGrey,
                },
            },
            dataZoom: {
                show: withToolZoom,
                yAxisIndex: 'none',
                title: {
                    zoom: i18n.zoom,
                    back: i18n.previousZoom,
                },
                brushStyle: {
                    color: mainBlue,
                    opacity: 0.2,
                },
            },
            restore: {
                title: i18n.restore,
            },
            myToolFullScreen: {
                show: withFullScreen,
                title: i18n.fullScreen,
                icon: fullScreenIcon,
                onclick: onFullScreen,
            },
            saveAsImage: {
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: exportName || title,
            },
            myToolExport: {
                show: withExport,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = formatData(data.flatMap(({ name, dataList, unit }) => {
                        return dataList.map(d => ({
                            date: { value: getFullDate(d.date) },
                            value: { value: d.value, format: '0.00000', cellType: 'number' },
                            name,
                            unit: d.unit ?? unit,
                            headers: ['name', 'date', 'value', 'unit'],
                        }))
                    }))
                    const exportDataModal = [{
                        name: i18n.syntheticExport,
                        formats: [{
                            type: i18n.csv,
                            callback: () => dispatch(HomeAction.export({ exportData, type: 'csv', titleFile: exportName || title || i18n.datas })),
                        }, {
                            type: i18n.exportXLSX,
                            callback: () => dispatch(HomeAction.export({ exportData, type: 'xlsx', titleFile: exportName || title || i18n.datas })),
                        }],
                    }]
                    setDataExport({ open: true, data: exportDataModal })
                },
            },
        },
    })

    const getComponentHeightCalculated = () => grids.reduce((acc, { gridOptions: { height = DEFAULT_GRAPH_HEIGHT, top = DEFAULT_GAP }, gridIndex }) => {
        if (gridsWithDisplay.current[gridIndex]?.display) {
            return acc + height + top
        }
        return acc
    }, headerHeight + footerHeight)

    const options = {
        title: {
            text: title,
            x: 'center',
            top: '1%',
        },
        series,
        legend: getLegend(),
        xAxis: getXAxisList(),
        yAxis: getYAxisList(),
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: tooltipFormatter ?? formatTooltip,
        },
        grid: getGridList(),
        dataZoom: withDataZoom ? filteredDataZoom : [],
        toolbox: getToolbox(),
        backgroundColor: '#fff',
    }

    return (
        <div
            id='multiChart'
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge
                lazyUpdate
                style={{ height: `${componentHeight || getComponentHeightCalculated()}px` }}
                ref={e => {
                    echartRef.current = e
                }}
                onEvents={{
                    legendselectchanged: (legend) => {
                        selectedLegend.current = legend.selected
                        gridsWithDisplay.current = grids.map((g, gridIndex) => ({ ...g, display: getDisplayGraph(gridIndex) }))
                        echartRef.current?.getEchartsInstance().setOption({
                            legend: getLegend(),
                            xAxis: getXAxisList(),
                            yAxis: getYAxisList(),
                            grid: getGridList(),
                        })
                        echartRef.current.getEchartsInstance().getDom().style.height = componentHeight || getComponentHeightCalculated()
                        onChangeLegend(legend.selected)
                    },
                }}
            />
            {dataExport.open && (
                <ExportFileModal
                    onClose={() => setDataExport({ open: false, data: [] })}
                    data={dataExport.data}
                    open={dataExport.open}
                    closeOnExport
                />
            )}
            {openOtherLegend && (
                <div
                    style={{
                        position: 'absolute',
                        top: `${document.getElementById('multiChart').offsetTop + 35}px`,
                        left: `${document.getElementById('multiChart').offsetLeft + document.getElementById('multiChart').clientWidth - legendWidth}px`,
                        zIndex: 999,
                        padding: '1rem',
                        border: `solid 2px ${mainBlue}`,
                        backgroundColor: mainWhite,
                        borderRadius: SMALL_RADIUS,
                        width: '16rem',
                        boxShadow: '0px 0px 26px 0px rgb(0 0 0 / 12%)',
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <h3 style={{ margin: '0 0 0.5rem 0' }}>Statuts :</h3>
                        </Grid>
                        <Grid container item xs={12}>
                            {getStatusSelectOptions().map(({ name, icon }) => (
                                <Grid container item xs={12} sx={{ padding: '5px 0 0 1rem' }} alignItems='center'>
                                    <Grid item>
                                        {icon}
                                    </Grid>
                                    <Grid item sx={{ marginLeft: '0.5rem' }}>
                                        <span>{name}</span>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{ margin: '1rem 0 0.5rem' }}>Qualifications :</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {getQualificationSelectOptions().map(({ name, icon }) => (
                                <Grid container item xs={12} sx={{ padding: '5px 0 0 1rem' }} alignItems='center'>
                                    <Grid item>
                                        {icon}
                                    </Grid>
                                    <Grid item sx={{ marginLeft: '0.5rem' }}>
                                        <span>{name}</span>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}

MultiChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        gridIndex: PropTypes.number.isRequired,
        unit: PropTypes.string,
        color: PropTypes.string,
        gridName: PropTypes.string.isRequired,
        type: PropTypes.string,
        lineStyle: PropTypes.string,
        showSymbol: PropTypes.bool,
        connectNulls: PropTypes.bool,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
            marker: PropTypes.string,
            color: PropTypes.string,
            unit: PropTypes.string,
        })).isRequired,
    })).isRequired,
    grids: PropTypes.arrayOf(PropTypes.shape({
        gridIndex: PropTypes.number.isRequired,
        type: PropTypes.string,
        barWidth: PropTypes.string,
        yOptions: PropTypes.shape({
            type: PropTypes.string,
            nameLocation: PropTypes.string,
            nameGap: PropTypes.number,
            boundaryGap: PropTypes.bool,
        }),
        xOptions: PropTypes.shape({
            type: PropTypes.string,
            boundaryGap: PropTypes.bool,
            showSplitLine: PropTypes.bool,
            axisLabel: PropTypes.shape({
                show: PropTypes.bool,
            }),
            axisLine: PropTypes.shape({
                show: PropTypes.bool,
            }),
            axisTick: PropTypes.shape({
                show: PropTypes.bool,
            }),
        }),
        gridOptions: PropTypes.shape({
            top: PropTypes.number.isRequired,
            left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.number.isRequired,
        }),
    })),
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string,
        indexAxis: PropTypes.number,
    })),
    title: PropTypes.string,
    exportName: PropTypes.string,
    componentHeight: PropTypes.number,
    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
    roundValue: PropTypes.number,
    areaColor: PropTypes.arrayOf(PropTypes.string), // 1 more than thresholds
    markLines: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),

    xAxisSpace: PropTypes.oneOf(['year', 'month', 'week', 'day']),
    defaultDisplayMarker: PropTypes.bool,
    defaultDisplayLine: PropTypes.bool,

    withToolTypeLine: PropTypes.bool,
    withToolTypeBar: PropTypes.bool,
    withToolTypeStack: PropTypes.bool,
    withToolLog: PropTypes.bool,
    withToolThreshold: PropTypes.bool,
    withToolLegend: PropTypes.bool,
    withToolMarker: PropTypes.bool,
    withToolLine: PropTypes.bool,
    withToolZoom: PropTypes.bool,
    withFullScreen: PropTypes.bool,
    withArea: PropTypes.bool,
    withDataZoom: PropTypes.bool,
    withOtherLegend: PropTypes.bool,
    withYZoom: PropTypes.bool,
    withExport: PropTypes.bool,

    dataZoomPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    gridPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    toolboxPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
        right: PropTypes.string,
    }),
    legendPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    tooltipFormatter: PropTypes.func,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
    onChangeLegend: PropTypes.func,
    defaultSelectedLegend: PropTypes.object,
}

export default MultiChart