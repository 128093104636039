import React from 'react'
import PropTypes from 'prop-types'
import { orderBy, take } from 'lodash'
import { Grid, Tooltip, Zoom } from '@mui/material'
import { cmsPath, contentsPath } from 'conf/basepath'
import DtoPicture from '../referencials/documents/dto/DtoPicture'
import { SMALL_RADIUS } from 'components/styled/Theme'

const SmallPicturePanel = ({
    pictures = [],
    noBorder = false,
    isCms = false,
}) => {
    const getPath = picture => {
        if (isCms) {
            return `${cmsPath}${picture.name}`
        } else if (picture.url?.startsWith('http')) {
            return picture.url
        }
        return `${contentsPath}${picture.url}`
    }

    const orderedPictures = orderBy(pictures, 'date', 'desc')
    const allPictures = !isCms ? take(orderedPictures, 18) : orderedPictures

    const imgSize = !isCms ? { maxWidth: '100%', maxHeight: '50px' } : { maxHeight: '50px', width: 'auto' }

    return !!pictures.length && (
        <Grid container item xs={12} alignItems='center' justifyContent='flex-start' sx={{ gap: '1rem', padding: '0.5rem 0', borderBottom: !noBorder && '1px solid rgba(233, 233, 233, 0.8)', overflowY: 'auto', maxHeight: '25vh' }}>
            {allPictures.map(picture => {
                const url = getPath(picture)
                return (
                    <Tooltip
                        placement='top'
                        arrow
                        PopperProps={{
                            disablePortal: true,
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    maxWidth: 'fit-content',
                                    width: 'fit-content',
                                    fontSize: '14px',
                                },
                            },
                        }}
                        title={picture.name}
                        TransitionComponent={Zoom}
                    >
                        <Grid
                            item xs='auto'
                            sx={{
                                border: 'solid 1px black',
                                borderRadius: SMALL_RADIUS,
                                overflow: 'hidden',
                            }}
                        >
                            <a href={url} data-fancybox='images' target='_blank' data-caption={picture.name}>
                                <img src={url} className='small-picture' style={imgSize} />
                            </a>
                        </Grid>
                    </Tooltip>
                )
            })}
        </Grid>
    )
}

SmallPicturePanel.propTypes = {
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    noBorder: PropTypes.bool,
    isCms: PropTypes.bool,
}

export default SmallPicturePanel