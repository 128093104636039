/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useListIndexed from 'utils/customHook/useListIndexed'
import { calculateThresholdResult, calculateValue, getLocalizationPicto, getThresholdLabel, thresholdColorToName } from 'utils/AnalyseUtils'
import { isNil, isNull, orderBy } from 'lodash'
import useBoolean from 'utils/customHook/useBoolean'
import { Card, CardContent, Grid, Popover } from '@mui/material'
import { getDate } from 'utils/DateUtil'
import { formatData } from 'utils/ActionUtils'
import PropTypes from 'prop-types'
import useSandreParameters from 'utils/customHook/useSandreParameters'
import { statusIcon } from 'utils/StatusUtil'
import Icon from 'components/Icon'
import TooltipAnalyse from '../../../quality/components/TooltipAnalyse'
import DtoAnalysisLight from '../follows/dto/DtoAnalysisLight'
import { SuperParameterGraphModal } from '../../../quality/components/ParameterGraph'
import QualityAction from 'quality/actions/QualityAction'
import ReferencialThunk from 'referencial/actions/ReferencialThunk'
import { nbPerPageLabelShort } from '../referencials/constants/ReferencialConstants'
import { Table } from 'components/table/Table'
import QualityThunk from 'quality/actions/QualityThunk'
import HomeAction from 'pages/home/actions/HomeAction'
import SimpleDatePicker from 'components/SimpleDatePicker'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import useAbortController from 'utils/customHook/useAbortController'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'

const useFetchAnalysis = (operation) => {
    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        value: isLoading,
        setTrue: loading,
        setFalse: loaded,
    } = useBoolean(false)

    const [analysis, setAnalysis] = useState([])
    useEffect(() => {
        if (isNil(operation)) {
            setAnalysis([])
            return
        }

        if (controllerRef.current.signal.aborted) {
            initController()
        }

        loading()
        const criteria = {
            lightMode: true,
            stations: [operation.qualitometer],
            operations: [operation.id],
        }
        QualityAction.getAnalysis(criteria, controllerRef.current.signal)
            .then((list = []) => {
                setAnalysis(list.map(analyse => {
                    const ana = analyse.map(e => isNull(e) ? undefined : e)
                    return {
                        qualitometer: ana[0], // Double
                        id: ana[1], // Double
                        sample: ana[2], // Double
                        operation: ana[3], // Double
                        sampleDate: ana[4], // Option[DateTime]
                        analysisDate: ana[5] ?? ana[4], // Option[DateTime]
                        parameter: ana[6], // Option[String]
                        unit: ana[7], // Option[String]
                        result: ana[8], // Option[Double]
                        remark: ana[9], // Option[String]
                        support: ana[10], // Option[Double]
                        localization: ana[11], // Option[String]
                        lq: ana[12], // Option[Double]
                        ld: ana[13], // Option[Double]
                        saturationThreshold: ana[14], // Option[Double]
                        qualification: ana[15], // Option[String]
                        status: ana[16], // Option[String]
                        fraction: ana[17], // Option[String]
                        labo: ana[18], // Option[Double]
                        jobexecutionid: ana[19], // Option[Long]
                        importDate: ana[20], // Option[DateTime]
                        updateDate: ana[21], // Option[DateTime]
                        updateLogin: ana[22], // Option[String]
                        numSampling: ana[23], // Option[Int]
                    }
                }))
            })
            .finally(loaded)

        return () => controllerRef.current.abort()
    }, [operation])

    return { analysis, isLoading }
}

const SampleResultTable = ({
    analysis = [],
    thresholds = [],
}) => {
    const {
        parameters,
        units,
        thresholdsProps,
        qualityThresholds,
    } = useSelector(store => ({
        parameters: store.ReferencialReducer.parameters,
        units: store.ReferencialReducer.units,
        thresholdsProps: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }))

    const parametersSandre = useSandreParameters()

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedUnits = useListIndexed(units, 'code')

    const { value: isOpen, setFalse: onClose, setTrue: onOpen } = useBoolean(false)
    const [selectedParameter, setSelectedParameter] = useState()
    const [selectedQualitometer, setSelectedQualitometer] = useState()

    const data = analysis.map(a => {
        const thresholdsResults = thresholds.reduce((acc, th, i) => {
            const threshold = qualityThresholds.find(qth => qth.code === th)
            const result = calculateThresholdResult(a, threshold?.thresholds)
            const label = getThresholdLabel(result.thresholdIndice, result.threshold)
            if (label) {
                acc[`threshold${i}`] = {
                    value: label,
                    color: result.color,
                    tooltip: (<TooltipAnalyse analyse={{ ...a, ...result }} />),
                }
            }
            return acc
        }, {})

        const parameterSandre = parametersSandre[a.parameter]?.find(ps => ps.code === a.result)
        const displayValue = !isNil(parameterSandre) ? `${parameterSandre.name} [${a.result}]` : calculateValue(a)

        return {
            nullValue: { value: statusIcon(a, 18, true), cellStyle: { width: '36px' } },
            place: { value: getLocalizationPicto(a.localization), cellStyle: { width: '60px' } },
            parameter: { value: indexedParameters[a.parameter]?.displayLabel },
            value: {
                value: displayValue,
                tooltip: (<TooltipAnalyse analyse={a} />),
            },
            unit: { value: indexedUnits[a.unit]?.symbolWithCode },
            ...thresholdsResults,
            paramCode: a.parameter,
            qualitoCode: a.qualitometer,
        }
    })

    const thresholdsHeaders = thresholds.map((_, i) => `threshold${i}`)
    const thresholdsNameHeaders = thresholds.reduce((acc, th, i) => {
        const threshold = thresholdsProps.find(t => `${t.code}` === th)
        acc[`threshold${i}`] = threshold?.name ?? i18n.unknownThreshold
        return acc
    }, {})

    return (
        <>
            <Table
                rows={orderBy(data, 'parameter.value')}
                headers={['nullValue', 'place', 'parameter', 'value', 'unit', ...thresholdsHeaders]}
                headersLabel={{ nullValue: '', place: i18n.place, parameter: i18n.parameter, value: i18n.value, unit: i18n.unit, ...thresholdsNameHeaders }}
                nbPerPage={nbPerPageLabelShort}
                onClickRow={({ paramCode, qualitoCode }) => {
                    setSelectedParameter(paramCode)
                    setSelectedQualitometer(qualitoCode)
                    onOpen()
                }}
            />
            <SuperParameterGraphModal
                isOpen={isOpen}
                closeGraph={onClose}
                parameter={selectedParameter}
                qualitometer={selectedQualitometer}
            />
        </>
    )
}

SampleResultTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    thresholds: PropTypes.arrayOf(PropTypes.string),
}

const Calendar = ({
    setSelected = () => {},
    operations = [],
}) => {
    const {
        value: isOpen,
        toggle: toggleOpen,
    } = useBoolean(false)
    const anchorEl = useRef()

    const [date, setDate] = useState()

    useEffect(() => {
        if (isNil(date) || operations.length === 0) {
            return
        }
        const index = operations.findIndex(o => o.date <= date)
        setSelected(index === -1 ? undefined : index)
    }, [date, operations])

    return (
        <>
            <Icon
                tooltip={i18n.date}
                icon={'date_range'}
                ref={anchorEl}
                style={{ color: 'black' }}
                onClick={toggleOpen}
            />
            <Popover
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={toggleOpen}
            >
                <SimpleDatePicker
                    fullWidth
                    value={date}
                    onChange={setDate}
                    sx={{ margin: '0px' }}
                />
            </Popover>
        </>
    )
}

Calendar.propTypes = {
    setSelected: PropTypes.func,
    operations: PropTypes.arrayOf(PropTypes.shape({})),
}

const UnitQualityPanel = ({
    ids = [],
    additionalTitle = '',
    thresholds = [],
    defaultExpanded = false,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        thresholdsProps,
        qualityThresholds,
        remarks,
        cities,
        parameters,
        units,
        fractions,
        supports,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
        thresholdsProps: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        remarks: store.QualityReducer.remarks,
        cities: store.ReferencialReducer.cities,
        parameters: store.ReferencialReducer.parameters,
        units: store.ReferencialReducer.units,
        fractions: store.ReferencialReducer.fractions,
        supports: store.ReferencialReducer.supports,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedUnits = useListIndexed(units, 'code')

    // const thresholds = useApplicationSetting(UDI_REFERENCE_THRESHOLD, listIntParser)

    useEffect(() => {
        dispatch(ReferencialThunk.fetchParameters())
        dispatch(ReferencialThunk.fetchSupports())
        dispatch(ReferencialThunk.fetchFractions())
        dispatch(ReferencialThunk.fetchUnits())
        dispatch(ReferencialThunk.fetchContributors())
        dispatch(ReferencialThunk.fetchCities())
        dispatch(QualityThunk.fetchQualitometers())
        dispatch(QualityThunk.fetchThresholds())
        dispatch(QualityThunk.fetchRemarks())
        dispatch(QualityThunk.fetchStatus())
        dispatch(QualityThunk.fetchQualifications())
        dispatch(QualityThunk.fetchThresholds())
        dispatch(QualityThunk.fetchQualitometers())
    }, [dispatch])

    useEffect(() => {
        const ths = thresholds.map(code => ({ code, thresholdType: '0' }))
        dispatch(QualityAction.fetchQualityThresholds(ths))
    }, [dispatch, thresholds])

    const [selected, setSelected] = useState() // index

    const [operations, setOperations] = useState([])
    useEffect(() => {
        QualityAction.getAllOperation(ids).then((list = []) => {
            const listOperations = orderBy(list, ['date', 'id'], ['desc', 'asc'])
            setOperations(listOperations)
            if (listOperations.length) {
                setSelected(0)
            }
        })
    }, [ids])

    const {
        isLoading = false,
        analysis = [],
    } = useFetchAnalysis(operations[selected])

    const title = useMemo(() => {
        const ope = operations[selected]
        if (isNil(ope)) {
            return i18n.noOperationsDuringThisPeriod
        }
        const qualitometer = qualitometers.find(q => q.id === ope.qualitometer)
        const city = cities.find(c => c.id === qualitometer?.townCode)
        return `${qualitometer?.code ?? ''} ${city?.name ?? ''} ${getDate(ope?.date)}`
    }, [cities, operations, qualitometers, selected])

    const onExport = () => {
        if (analysis.length === 0) {
            return
        }
        const formattedAnalysis = analysis.map(a => {
            const thresholdsResults = thresholds.reduce((acc, th, i) => {
                const threshold = qualityThresholds.find(qth => qth.code === th)
                const result = calculateThresholdResult(a, threshold?.thresholds)
                acc[`threshold${i}`] = {
                    value: getThresholdLabel(result.thresholdIndice, result.threshold),
                    color: thresholdColorToName(result.color),
                }
                return acc
            }, {})
            const remark = remarks.find(r => r.code === a.remark)
            const fraction = fractions.find(f => f.id === a.fraction)
            const support = supports.find(s => s.code === `${a.support}`)
            return {
                parameterCode: a.parameter,
                parameter: indexedParameters[a.parameter]?.displayLabel,
                value: calculateValue(a),
                unitCode: a.unit,
                unit: indexedUnits[a.unit]?.symbolWithCode,
                remarkCode: { value: a.remark, format: '0', cellType: 'number' },
                remark: remark?.name,
                supportCode: { value: a.support, format: '0', cellType: 'number' },
                support: support?.name,
                fractionCode: { value: a.fraction, format: '0', cellType: 'number' },
                fraction: fraction?.name,
                ...thresholdsResults,
            }
        })

        const thresholdsHeaders = thresholds.map((_, i) => `threshold${i}`)
        const thresholdsNameHeaders = thresholds.map(th => {
            const threshold = thresholdsProps.find(t => `${t.code}` === th)
            return threshold?.name ?? i18n.unknownThreshold
        })
        const baseHeaders = ['parameterCode', 'parameter', 'value', 'unitCode', 'unit', 'remarkCode', 'remark', 'supportCode', 'support', 'fractionCode', 'fraction']
        const headers = [...baseHeaders, ...thresholdsHeaders]
        const customHeaders = [...baseHeaders, ...thresholdsNameHeaders]
        const orderedAnalysis = orderBy(formattedAnalysis, 'parameter')
        const data = orderedAnalysis.length ? [{ ...orderedAnalysis[0], headers, customHeaders }, ...orderedAnalysis.slice(1)] : []

        const exportData = data.length ? formatData(data) : []
        dispatch(HomeAction.export({ exportData, type: 'xlsx', titleFile: title }))
    }

    const previousDisabled = isNil(selected) || selected === operations.length - 1
    const nextDisabled = isNil(selected) || selected === 0

    return (
        <AccordionMUI round style={{ marginTop: 10 }} defaultExpanded={defaultExpanded}>
            <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>
                <Grid container style={{ marginRight: '10px' }} alignItems='center'>
                    <Grid item xs>{`${additionalTitle}${title}`}</Grid>
                    <Grid item xs='auto' onClick={e => e.stopPropagation()}>
                        <Icon
                            tooltip={i18n.previousOperation}
                            icon={'chevron_left'}
                            style={{ color: previousDisabled ? 'gray' : 'black' }}
                            onClick={() => !previousDisabled && setSelected(p => p + 1)}
                        />
                        <Icon
                            tooltip={i18n.nextOperation}
                            icon={'chevron_right'}
                            style={{ color: nextDisabled ? 'gray' : 'black' }}
                            onClick={() => !nextDisabled && setSelected(p => p - 1)}
                        />
                        <Calendar
                            setSelected={setSelected}
                            operations={operations}
                        />
                        <Icon
                            tooltip={i18n.export}
                            icon={'file_download'}
                            style={{ color: analysis.length === 0? 'gray' : 'black' }}
                            onClick={onExport}
                        />
                    </Grid>
                </Grid>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI sx={{ padding: 0 }}>
                <div style={{ position: 'relative', backgroundColor: 'pink' }}>
                    {isLoading && (<CircularProgressWithLabel />)}
                    {
                        analysis.length === 0 && (
                            <Card>
                                <CardContent>
                                    <h5>{i18n.noResults}</h5>
                                </CardContent>
                            </Card>
                        )
                    }
                    {
                        analysis.length > 0 && (
                            <SampleResultTable
                                analysis={analysis}
                                thresholds={thresholds}
                            />
                        )
                    }
                </div>
            </AccordionDetailsMUI>
        </AccordionMUI >
    )
}

UnitQualityPanel.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    additionalTitle: PropTypes.string,
    thresholds: PropTypes.arrayOf(PropTypes.string),
    defaultExpanded: PropTypes.bool,
}

export default UnitQualityPanel