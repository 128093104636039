import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import ProgressBar from 'components/progress/ProgressBar'
import { mainBlack, mainWhite, SMALL_RADIUS, textBlue } from 'components/styled/Theme'
import HomeAction from 'pages/home/actions/HomeAction'
import { getMarkerByStationType, getStationTypeCodeFromType } from 'utils/StationUtils'
import { getDate } from 'utils/DateUtil'
import { groupBy, keys, orderBy } from 'lodash'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import DtoObservatoryFollowResult from 'pages/online/follows/dto/DtoObservatoryFollowResult'
import AddEventDialog from 'pages/cms/components/AddEventDialog'
import { EVENT_TYPES } from 'pages/cms/constants/CmsConstants'
import { getI18nOrLabel } from 'utils/StringUtil'
import { lightGrey } from 'components/styled/Theme'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'

const Events = ({
    showCode = true,
    codes = [],
    events = [],
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const getOfr = ({ code, stationType }) => {
        switch (stationType) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
            case STATION_TYPE_NAME.piezometer:
                return piezoObsResults.find(p => p.id === code) || {}
            case STATION_TYPE_NAME.hydrometry:
                return hydroObsResults.find(p => p.id === code) || {}
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObsResults.find(p => p.id === code) || {}
            case STATION_TYPE_NAME.quality:
                return qualitoObsResults.find(p => p.id === code) || {}
            default:
                return {}
        }
    }

    const getInCrisisTag = allTags => {
        const inCrisisTag = [allTags.find(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value || '')?.includes('alerte'))].filter(d => !!d)
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = [allTags.find(d => (d?.value || '')?.includes('vigilance'))].filter(d => !!d)
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange', realColor: tag.color })) : []
    }

    const getColorLink = (ofr) => {
        const allTags = ofr?.data || []
        const tagsInCrisis = getInCrisisTag(allTags)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(allTags)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : [allTags.find(d => ['green', 'lightgreen'].includes(d.color))].filter(d => !!d)
        const tagsNoData = monitoringTags.length ? monitoringTags : [allTags.find(d => ['grey', 'gray'].includes(d.color))].filter(d => !!d)
        return tagsNoData[0]?.color
    }

    const linkedStationsEventsFormatted = useMemo(() => orderBy(events.filter((e) => e.eventType !== 'T'), ['date'], ['desc']).map(e => {
        const stationFind = codes.find(c => c.code === e.code) || {}
        const ofr = getOfr(stationFind)
        return {
            ...e,
            name: !!showCode && (ofr.name || e.name || ''),
            typeName: ofr.typeName,
            code: !!showCode && (ofr?.code || ''),
            color: [OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.productionUnit].includes(ofr.typeName) && getColorLink(ofr),
        }
    }), [codes, events, showCode])

    return (
        <Grid
            item
            xs={12}
            container
            alignItems='center'
            sx={{ padding: '15px' }}
        >
            {linkedStationsEventsFormatted.map(event => {
                const img = getMarkerByStationType(event.typeName, event.color)
                const eventType = EVENT_TYPES.find(e => e.code === event.eventType)
                return (
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' sx={{ padding: '0.5rem', borderBottom: `solid 1px ${lightGrey}` }}>
                        <Grid container item alignItems='center' sx={{ width: 'fit-content' }}>
                            {!!img && (
                                <Grid item>
                                    <img src={img} style={{ height: '25px', width: 'auto', marginRight: '10px' }} />
                                </Grid>
                            )}
                            <Grid item>
                                {`${getDate(event.date)}${event.name ? ` - ${event.name}` : ''}${event.code ? ` - ${event.code}` : ''}`}
                            </Grid>
                        </Grid>
                        <Grid container item alignItems='center' sx={{ width: 'fit-content' }}>
                            <Grid item>
                                {getI18nOrLabel(eventType?.label)}
                            </Grid>
                            <Grid item sx={{ paddingLeft: '0.5rem' }}>
                                <Icon>
                                    {eventType?.icon}
                                </Icon>
                            </Grid>
                        </Grid>
                        {!!event.comment && (
                            <Grid item xs={12} sx={{ fontSize: '14px', marginTop: '0.5rem' }}>
                                {event.comment}
                            </Grid>
                        )}
                    </Grid>
                )
            })}
        </Grid>
    )
}

Events.propTypes = {
    showCode: PropTypes.bool,
    codes: PropTypes.arrayOf(PropTypes.number),
    events: PropTypes.arrayOf(PropTypes.shape({})),
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const EventsCard = ({
    width = '100%',
    height,
    stationId,
    stationType,
    codes = [],
    showCode = true,
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const {
        accountUser,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [isOpenEvent, setIsOpenEvent] = useState(false)
    const [events, setEvents] = useState([])

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => {
        const groupedCodes = groupBy(codes, 'stationType')
        return keys(groupedCodes).map(key => dispatch(HomeAction.fetchAllLinkedStationsEvents({
            ids: groupedCodes[key]?.map(c => c.code),
            stationType: getStationTypeCodeFromType(key),
        })).then(data => setEvents(prev => [...prev, ...data.payload])))
    }, [])

    const canAddEvent = accountUser.isAdmin === '1' && !!stationId

    return (
        <>
            <div style={{ width, backgroundColor: mainWhite, height, borderRadius: `0 0 ${SMALL_RADIUS} ${SMALL_RADIUS}`, overflowY: 'auto' }}>
                <Grid container sx={{ maxHeight: '100%' }}>
                    <Grid item xs={12} sx={{ padding: '15px', borderBottom: `2px solid ${lightGrey}` }}>
                        <span style={{ textTransform: 'uppercase', fontWeight: 'bold', color: textBlue }}>{i18n.events}</span>
                    </Grid>
                    {isLoaded ? (
                        <Events
                            showCode={showCode}
                            codes={codes}
                            events={events}
                            piezoObsResults={piezoObsResults}
                            hydroObsResults={hydroObsResults}
                            pluvioObsResults={pluvioObsResults}
                            qualitoObsResults={qualitoObsResults}
                        />
                    ) : (
                        <ProgressBar progress={progress} sx={{ paddingTop: 0 }} />
                    )}
                </Grid>
                {!!canAddEvent && (
                    <Grid
                        container
                        alignItems='center'
                        justifyContent='center'
                        onClick={() => setIsOpenEvent(true)}
                        sx={{
                            position: 'fixed',
                            bottom: '1rem',
                            right: '1.5rem',
                            width: 'fit-content',
                            padding: '0.5rem 1rem',
                            backgroundColor: mainBlack,
                            color: mainWhite,
                            borderRadius: '50rem',
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        <Grid item>
                            <p style={{ fontSize: '16px', margin: 0 }}>{i18n.addOneEvent}</p>
                        </Grid>
                        <Grid item sx={{ paddingLeft: '0.5rem' }}>
                            <Icon>add</Icon>
                        </Grid>
                    </Grid>
                )}
                <AddEventDialog
                    stationType={stationType}
                    stationId={stationId}
                    isOpen={isOpenEvent}
                    closeDialog={() => setIsOpenEvent(false)}
                />
            </div>
        </>
    )
}

EventsCard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    stationId: PropTypes.number,
    stationType: PropTypes.string,
    showCode: PropTypes.bool,
    codes: PropTypes.arrayOf(PropTypes.number),
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

export default EventsCard