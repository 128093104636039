import { createSlice } from '@reduxjs/toolkit'
import QualityThunk from 'quality/actions/QualityThunk'

export const initialState = {
    qualitometers: [],
    thresholds: [],
    qualityThresholds: [],
    status: [],
    qualifications: [],
    remarks: [],
}

const store = createSlice({
    name: 'quality',
    initialState,
    reducers: {
        qualityThresholds: (state, action) => {
            state.qualityThresholds = action.payload
        },
    },
    extraReducers: {
        [QualityThunk.fetchQualitometers.fulfilled]: (state, action) => {
            state.qualitometers = action.payload.map(qualito => ({
                id: qualito[0],
                code: qualito[1],
                name: qualito[2] ?? '',
                x: qualito[3],
                y: qualito[4],
                projection: qualito[5],
                townCode: qualito[6],
                stationType: qualito[7] ?? '0',
            }))
        },
        [QualityThunk.fetchThresholds.fulfilled]: (state, action) => {
            state.thresholds = action.payload
        },
        [QualityThunk.fetchRemarks.fulfilled]: (state, action) => {
            state.remarks = action.payload
        },
        [QualityThunk.fetchStatus.fulfilled]: (state, action) => {
            state.status = action.payload
        },
        [QualityThunk.fetchQualifications.fulfilled]: (state, action) => {
            state.qualifications = action.payload
        },
    },
})

export const QualityActionConstant = store.actions
export default store.reducer