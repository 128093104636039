import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HomeAction from 'pages/home/actions/HomeAction'
import ResourcesAction from './actions/ResourcesAction'
import { Grid, useMediaQuery } from '@mui/material'
import { mainGrey } from 'components/styled/Theme'
import Option from 'components/Option'
import ResourceCard from './ResourceCard'
import LittleMap from '../components/LittleMap'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProgressBar from 'components/progress/ProgressBar'
import EventsCard from '../components/cards/EventsCard'
import DtoPicture from '../referencials/documents/dto/DtoPicture'
import DtoFile from '../referencials/documents/dto/DtoFile'
import SmallPicturePanel from '../components/SmallPicturePanel'
import FilePanel from '../components/FilePanel'
import { getSandreLabel } from 'utils/StringUtil'
import { SANDRE } from '../referencials/constants/ReferencialConstants'
import ObservatoryChartPanel from '../components/echart/ObservatoryChartPanel'
import FollowAction from '../follows/actions/FollowAction'
import { isNil } from 'lodash'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { fromLonLat } from 'ol/proj'
import { DEFAULT_MAP_X_COORDINATE, DEFAULT_MAP_Y_COORDINATE } from 'pages/home/constants/HomeConstants'
import { getStationTypeCodeFromType, getStationTypeNameFromType } from 'utils/StationUtils'
import { SUCCEEDED } from 'store/DataManagerConstants'
import DtoObservatoryFollowResult from '../follows/dto/DtoObservatoryFollowResult'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import ToastrAction from 'toastr/actions/ToastrAction'

const ResourceMap = ({
    kml = [],
    center = [],
    piezoObsResults = [],
}) => {
    const {
        resource,
        linkedStations,
        piezometersLight,
        hydrometers,
        pluviometers,
        productionUnits,
    } = useSelector(store => ({
        resource: store.ResourcesReducer.resource,
        linkedStations: store.HomeReducer.linkedStations,
        piezometersLight: store.HomeReducer.piezometersLight,
        hydrometers: store.HomeReducer.hydrometers,
        pluviometers: store.HomeReducer.pluviometers,
        productionUnits: store.HomeReducer.productionUnits,
    }), shallowEqual)

    const markers = useMemo(() => {
        const catchmentsIds = piezoObsResults.filter(p => p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment).map(p => p.id)
        const catchments = piezometersLight.filter(piezo => catchmentsIds.includes(piezo.id))
        const piezos = piezometersLight.filter(piezo => !catchmentsIds.includes(piezo.id))
        return [
            ...catchments.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.piezometry)).map(c => ({ ...c, typeName: OBSERVATORY_STATION_TYPE_NAME.catchment })),
            ...piezos.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.piezometry)),
            ...hydrometers.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.hydrometry)),
            ...pluviometers.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.pluviometry)),
            ...productionUnits.filter((p) => linkedStations.some((l) => l.stationLinkedId === p.id && l.typeName === STATION_TYPE_NAME.productionUnit)),

        ]
    }, [piezoObsResults, piezometersLight, hydrometers, pluviometers, linkedStations])

    return (
        <LittleMap
            points={[ resource, ...markers ]}
            zoom={8}
            kml={kml}
            center={center}
        />
    )
}

ResourceMap.propTypes = {
    kml: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    center: PropTypes.arrayOf(PropTypes.number),
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const ResourceEvents = ({
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const {
        linkedStations,
    } = useSelector(store => ({
        linkedStations: store.HomeReducer.linkedStations,
    }), shallowEqual)

    const getRealTypeName = ({ stationLinkedId, typeName }) => piezoObsResults.some(p => p.id === stationLinkedId && p.typeName === OBSERVATORY_STATION_TYPE_NAME.catchment) ? OBSERVATORY_STATION_TYPE_NAME.catchment : typeName

    const linkedStationsFormatted = useMemo(() => linkedStations.map(ls => ({
        ...ls,
        typeName: ls.typeName === STATION_TYPE_NAME.piezometry ? getRealTypeName(ls) : ls.typeName,
    })), [piezoObsResults, linkedStations])

    const eventsCodes = useMemo(() => linkedStationsFormatted.filter((p) => !!getStationTypeNameFromType(p.typeName) && p.typeName !== STATION_TYPE_NAME.piezometry && p.stationLinkedType !== getStationTypeCodeFromType(STATION_TYPE_NAME.productionUnit)).map((p) => ({ code: p.stationLinkedId, stationType: getStationTypeNameFromType(p.typeName) })), [linkedStationsFormatted])

    return (
        <EventsCard
            height='calc(100vh - 410px)'
            codes={eventsCodes}
            piezoObsResults={piezoObsResults}
            hydroObsResults={hydroObsResults}
            pluvioObsResults={pluvioObsResults}
            qualitoObsResults={qualitoObsResults}
        />
    )
}

ResourceEvents.propTypes = {
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const ResourceDashboard = ({ match: { params: { id } } }) => {
    const {
        resource,
        resourceStatus,
        sandreCodes,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        applicationSettings,
        accountUserSettings,

        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        productionUnitsStatus,
        linkedStationsStatus,
        qualitometersLightStatus,

        accountHabilitations,
    } = useSelector(store => ({
        resource: store.ResourcesReducer.resource,
        resourceStatus: store.DataManagerReducer.resources.resourceStatus,
        sandreCodes: store.HomeReducer.sandreCodes,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountUserSettings: store.AccountReducer.accountUserSettings,

        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        hydrometersStatus: store.DataManagerReducer.hydrometers.hydrometersStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometers.pluviometersStatus,
        productionUnitsStatus: store.DataManagerReducer.productionUnits.productionUnitsStatus,
        linkedStationsStatus: store.DataManagerReducer.home.linkedStationsStatus,
        qualitometersLightStatus: store.DataManagerReducer.qualitometers.qualitometersLightStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const [simpleSheet, setSimpleSheet] = useState(true)
    const [pictures, setPictures] = useState([])
    const [documents, setDocuments] = useState([])
    const [piezoObsResults, setPiezoObsResults] = useState([])
    const [hydroObsResults, setHydroObsResults] = useState([])
    const [pluvioObsResults, setPluvioObsResults] = useState([])
    const [qualitoObsResults, setQualitoObsResults] = useState([])
    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)
    const [piezoIsLoaded, setPiezoIsLoaded] = useState(false)
    const [hydroIsLoaded, setHydroIsLoaded] = useState(false)
    const [pluvioIsLoaded, setPluvioIsLoaded] = useState(false)
    const [qualitoIsLoaded, setQualitoIsLoaded] = useState(false)

    const dispatch = useDispatch()

    const { progress, isLoaded } = useStateProgress([
        piezometersLightStatus,
        hydrometersStatus,
        pluviometersStatus,
        productionUnitsStatus,
        resourceStatus,
        linkedStationsStatus,
        qualitometersLightStatus,
    ])

    const observatoryResultsLoaded = piezoIsLoaded && hydroIsLoaded && pluvioIsLoaded && qualitoIsLoaded

    useEffect(() => {
        if (accountHabilitations.length && !componentHasHabilitations(H_RESOURCE_MODULE)) {
            dispatch(HomeAction.logout())
            dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
        }
    }, [accountHabilitations.length])

    useEffect(() => {
        if (piezometersLightStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (hydrometersStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchHydrometers())
        }
        if (pluviometersStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchPluviometers())
        }
        if (qualitometersLightStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchQualitometersLight())
        }
        if (productionUnitsStatus !== SUCCEEDED) {
            dispatch(HomeAction.fetchProductionUnits())
        }

        return () => {
            dispatch(HomeActionConstant.resetFollow())
            dispatch(HomeActionConstant.resetLinkedStations())
        }
    }, [])

    useEffect(() => {
        dispatch(ResourcesAction.fetchResource(id))
    }, [id])

    const fetchObsResults = (r) => {
        const ls = r.payload.flatMap((s) => new DtoAssociatedStation(s))
        const piezoIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.piezometry).map((l) => l.stationLinkedId)
        if (!piezoIds.length) {
            setPiezoObservatoryProgress(100)
            setPiezoIsLoaded(true)
        } else if (!piezoObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificPiezoObservatoryFollowResult(piezoIds, setPiezoObservatoryProgress)).then(result => {
                setPiezoObsResults(result)
                setPiezoObservatoryProgress(100)
                setPiezoIsLoaded(true)
            })
        } else if (piezoObservatoryFollowResults.length) {
            const result = piezoObservatoryFollowResults.filter(ofr => piezoIds.includes(ofr.id))
            setPiezoObsResults(result)
            setPiezoObservatoryProgress(100)
            setPiezoIsLoaded(true)
        }
        const hydroIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map((l) => l.stationLinkedId)
        if (!hydroIds.length) {
            setHydroObservatoryProgress(100)
            setHydroIsLoaded(true)
        } else if (!hydroObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificHydroObservatoryFollowResult(hydroIds, setHydroObservatoryProgress)).then(result => {
                setHydroObsResults(result)
                setHydroObservatoryProgress(100)
                setHydroIsLoaded(true)
            })
        } else if (hydroObservatoryFollowResults.length) {
            const result = hydroObservatoryFollowResults.filter(ofr => hydroIds.includes(ofr.id))
            setHydroObsResults(result)
            setHydroObservatoryProgress(100)
            setHydroIsLoaded(true)
        }
        const pluvioIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry).map((l) => l.stationLinkedId)
        if (!pluvioIds.length) {
            setPluvioObservatoryProgress(100)
            setPluvioIsLoaded(true)
        } else if (!pluvioObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificPluvioObservatoryFollowResult(pluvioIds, setPluvioObservatoryProgress)).then(result => {
                setPluvioObsResults(result)
                setPluvioObservatoryProgress(100)
                setPluvioIsLoaded(true)
            })
        } else if (pluvioObservatoryFollowResults.length) {
            const result = pluvioObservatoryFollowResults.filter(ofr => pluvioIds.includes(ofr.id))
            setPluvioObsResults(result)
            setPluvioObservatoryProgress(100)
            setPluvioIsLoaded(true)
        }
        const qualitoIds = ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.quality).map((l) => l.stationLinkedId)
        if (!qualitoIds.length) {
            setQualitoObservatoryProgress(100)
            setQualitoIsLoaded(true)
        } else if (!qualitoObservatoryFollowResults.length) {
            dispatch(FollowAction.fetchSpecificQualitoObservatoryFollowResult(qualitoIds, setQualitoObservatoryProgress)).then(result => {
                setQualitoObsResults(result)
                setQualitoObservatoryProgress(100)
                setQualitoIsLoaded(true)
            })
        } else if (qualitoObservatoryFollowResults.length) {
            const result = qualitoObservatoryFollowResults.filter(ofr => qualitoIds.includes(ofr.id))
            setQualitoObsResults(result)
            setQualitoObservatoryProgress(100)
            setQualitoIsLoaded(true)
        }
    }

    useEffect(() => {
        if (!isNil(resource.code)) {
            dispatch(HomeAction.fetchAllLinkedStation({ codes: [resource.code], stationType: STATION_TYPE_CONSTANT.resource })).then(r => fetchObsResults(r))
        }
    }, [resource.code])

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.resources,
            href: '/resources',
        }, {
            title: resource.name,
            href: `/resources${resource.id}`,
        }]))
        dispatch(HomeAction.fetchPictures({ code: resource.code, stationType: STATION_TYPE_CONSTANT.resource })).then((res) => {
            setPictures((res?.payload || []).map((p) => new DtoPicture(p)))
        })
        dispatch(HomeAction.fetchDocuments({ code: resource.code, stationType: STATION_TYPE_CONSTANT.resource })).then((res) => {
            const resFiltered = (res?.payload || []).filter((d) => !d.name.endsWith('.kml') && !d.name.endsWith('.KML'))
            setDocuments(resFiltered.map((d) => new DtoFile(d)))
        })
    }, [resource])

    const { defaultCenter } = useMemo(() => {
        const applicationX = applicationSettings.find(a => a.parameter === DEFAULT_MAP_X_COORDINATE)?.value // isNil(paramX) ? applicationSettings.find(a => a.parameter === DEFAULT_MAP_X_COORDINATE)?.value : paramX
        const applicationY = applicationSettings.find(a => a.parameter === DEFAULT_MAP_Y_COORDINATE)?.value // isNil(paramY) ? applicationSettings.find(a => a.parameter === DEFAULT_MAP_Y_COORDINATE)?.value : paramY

        const envX = isNil(applicationX) ? process.env.REACT_APP_MAP_X : applicationX
        const envY = isNil(applicationY) ? process.env.REACT_APP_MAP_Y : applicationY

        return { defaultCenter: fromLonLat([Number(envX), Number(envY)]) }
    }, [accountUserSettings, applicationSettings])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <Grid container sx={{ position: 'absolute' }}>
            <Grid container item xs={12} sx={{ backgroundColor: mainGrey, height: 50, padding: mdMatches ? '10px 50px' : '10px 30px' }}>
                <Option
                    first
                    selected={simpleSheet}
                    label={i18n.simpleCard}
                    onClick={() => setSimpleSheet(true)}
                    xs='auto'
                    sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                />
                <Option
                    selected={!simpleSheet}
                    label={i18n.detailsCard}
                    onClick={() => setSimpleSheet(false)}
                    xs='auto'
                    sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                />
            </Grid>
            {(isLoaded && observatoryResultsLoaded && resource.id === Number(id)) ? (
                <Grid item container sx={{ padding: mdMatches ? '10px 50px' : '10px 30px' }}>
                    <Grid item md={8.5} xs={12} sx={{ paddingRight: '50px' }}>
                        <ResourceCard
                            resource={resource}
                            piezoObsResults={piezoObsResults}
                            hydroObsResults={hydroObsResults}
                            pluvioObsResults={pluvioObsResults}
                            qualitoObsResults={qualitoObsResults}
                        />
                        {!!pictures.length && (
                            <SmallPicturePanel element={'station'} pictures={pictures} />
                        )}
                        {!!documents.length && (
                            <FilePanel files={documents} hideTitle />
                        )}
                        {!simpleSheet && (
                            <Grid container item xs={12} sx={{ backgroundColor: mainGrey, padding: '0.5em' }}>
                                <Grid container item xs={12}>
                                    <Grid container item xs={6}>
                                        <Grid item><h4 style={{ margin: '0 5px 0 0' }}>{i18n.descriptif}:</h4></Grid>
                                        <Grid item><span>{i18n.name}: {resource.name}</span></Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item><h4 style={{ margin: '0 5px 0 0' }}>{i18n.resourceType}:</h4></Grid>
                                        <Grid item><span>{getSandreLabel(sandreCodes, SANDRE.MILIEU_EAU, resource.resourceType)}</span></Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item><h4 style={{ margin: '10px 0 5px' }}>{i18n.comment}:</h4></Grid>
                                    {!!isNil(resource.comment) && <Grid item><span style={{ wordWrap: 'break-word' }}>{resource.exploitationComment}</span></Grid>}
                                </Grid>
                            </Grid>
                        )}
                        <ObservatoryChartPanel stationId={resource.id} stationCode={resource.code} exportName={`${i18n.datas} - ${i18n.resource} - ${resource.name || resource.code}`}/>
                    </Grid>
                    <Grid item md={3.5} xs={12} sx={{ padding: '0 0 0.5em 0', boxShadow: '0px 0px 18px 0px rgb(0 0 0 / 12%)', height: 'calc(100vh - 210px)' }}>
                        <ResourceMap
                            kml={documents.filter((d) => d.name.endsWith('.kml') || d.name.endsWith('.KML'))}
                            center={defaultCenter}
                            piezoObsResults={piezoObsResults}
                        />
                        <ResourceEvents
                            piezoObsResults={piezoObsResults}
                            hydroObsResults={hydroObsResults}
                            pluvioObsResults={pluvioObsResults}
                            qualitoObsResults={qualitoObsResults}
                        />
                    </Grid>
                </Grid>
            ) : <ProgressBar progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress) / 5} />}
        </Grid>
    )
}

ResourceDashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            stationType: PropTypes.string,
        }),
    }),
}

export default ResourceDashboard
