import React from 'react'
import './assets/css/ol.css'
import { Provider } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore, { history } from './store/AppStore'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from '@mui/material/styles'
import fr from 'assets/i18n/properties_fr.json'
import en from 'assets/i18n/properties_en.json'
import it from 'assets/i18n/properties_it.json'
import Login from './pages/home/components/Login'
import AuthRoute from './pages/AuthRoute'
import Home from './pages/home/components/Home'
import { mainTheme } from 'components/styled/Theme'
import MyAccount from 'pages/account/MyAccount'
import Contact from 'pages/contact/Contact'
import App from 'pages/components/App'
import { CssBaseline } from '@mui/material'
import BoundaryError from 'log/components/BoundaryError'
import Resources from 'pages/online/resources/Resources'
import Units from 'pages/online/units/Units'
import Follows from 'pages/online/follows/Follows'
import Documents from 'pages/online/documents/Documents'
import { render } from 'react-dom'
import UnitDashboard from 'pages/online/units/UnitDashboard'
import Follow from 'pages/online/follows/Follow'
import ResourceDashboard from 'pages/online/resources/ResourceDashboard'
import Research from 'pages/research/Research'
import ResetPassword from 'pages/home/components/ResetPassword'
import FAQ from 'pages/online/documents/FAQ'
import UDIS from 'pages/online/distributions/UDIS'
import UDIDashboard from 'pages/online/distributions/UDIDashboard'

i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(it, ['it', 'IT'])
i18n.addLang(fr, 'default')
i18n.init()

const properties = [
    {
        code: 'fr',
        value: fr,
    },
    {
        code: 'en',
        value: en,
    },
    {
        code: 'it',
        value: it,
    },
]

export const handleUpdateLanguage = (code) => {
    const propertie = (properties.find(p => p.code === code) || {})
    i18n.clear()
    i18n.addLang(propertie.value || fr, 'default')
    i18n.init()
}

render(
    <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <BoundaryError>
                    <>
                        <Switch>
                            <Route exact path='/login' component={(props) => <Login {...props} />} />
                            <Route exact path='/reset/:token' component={(props) => <ResetPassword {...props} />} />
                            <Route path='/' component={(props) => <AuthRoute {...props} component={App} />} />
                        </Switch>
                        <Route exact path='/home' component={(props) => <Home {...props} />} />
                        <Route exact path='/resources' component={ (props) => <Resources { ...props } /> } />
                        <Route exact path='/resources/:id' component={ (props) => <ResourceDashboard { ...props } /> } />
                        <Route exact path='/distributions' component={ (props) => <UDIS { ...props } /> } />
                        <Route exact path='/distributions/:id' component={ (props) => <UDIDashboard { ...props } /> } />
                        <Route exact path='/productions' component={ (props) => <Units { ...props } /> } />
                        <Route exact path='/productions/:id' component={ (props) => <UnitDashboard { ...props } /> } />
                        <Route exact path='/follows' component={ (props) => <Follows { ...props } /> } />
                        <Route exact path='/follows/:stationType/:id' component={ (props) => <Follow { ...props } /> } />
                        <Route exact path='/documents' component={ (props) => <Documents { ...props } /> } />
                        <Route exact path='/documents/actualities' component={ (props) => <Documents actualities { ...props } /> } />
                        <Route exact path='/forum' component={ (props) => <FAQ { ...props } /> } />
                        <Route exact path='/contact' component={(props) => <Contact {...props} />} />
                        <Route exact path='/account' component={(props) => <MyAccount {...props} />} />
                        <Route exact path='/research' component={(props) => <Research {...props} />} />
                    </>
                </BoundaryError>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app'),
)
