import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { Grid, useMediaQuery } from '@mui/material'
import HomeAction from 'pages/home/actions/HomeAction'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { orderBy, uniqWith } from 'lodash'
import ProgressBar from 'components/progress/ProgressBar'
import UnitCard from './UnitCard'
import FollowAction from '../follows/actions/FollowAction'
import useStateProgress from 'utils/customHook/useStateProgress'
import useTitle from 'utils/customHook/useTitle'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { mainBlack, mainGrey, SMALL_RADIUS } from 'components/styled/Theme'
import Option from 'components/Option'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import ResourcesAction from '../resources/actions/ResourcesAction'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_PRODUCTION_MODULE, H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import ProductionUnitStats from './ProductionUnitStats'
import { MainButton } from '../components/styled/buttons'
import MultiContributorsAutocomplete from 'components/MultiContributorsAutocomplete'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import SimpleCheckbox from '../components/forms/SimpleCheckbox'
import { filterObsLinkedStations } from 'utils/StationUtils'
import { INDICATORS_COLORS, mainBlue } from 'components/styled/Theme'

const NO_INDICATOR = -1
const NORMAL = 0
const VIGILANCE = 1
const CRISIS = 2
const NO_DATA = 3

const PRODUCTION_UNIT_INDICATORS_FILTER = 'PRODUCTION_UNIT_INDICATORS_FILTER'

const OPERATOR = 'operator'
const BUILDING_OWNER = 'buildingOwner'

const FilterPanel = ({
    defaultExploited,
    defaultNotExploited,
    defaultAssociatedResource,
    defaultIndicators = [NO_INDICATOR, NORMAL, VIGILANCE, CRISIS, NO_DATA],
    defaultAdmin,
    defaultOperator,

    setExploited = () => {},
    setNotExploited = () => {},
    setAssociatedResource = () => {},
    setIndicators = () => {},
    setAdmin = () => {},
    setOperator = () => {},
}) => {
    const {
        resources,
        accountHabilitations,
        contributors,
    } = useSelector(store => ({
        resources: store.ResourcesReducer.resources,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        contributors: store.HomeReducer.contributors,
    }), shallowEqual)

    const [tmpExploited, setTmpExploited] = useState(defaultExploited || true)
    const [tmpNotExploited, setTmpNotExploited] = useState(defaultNotExploited || false)
    const [tmpAssociatedResource, setTmpAssociatedResource] = useState(defaultAssociatedResource)
    const [tmpIndicators, setTmpIndicators] = useState(defaultIndicators)
    const [tmpAdmin, setTmpAdmin] = useState(defaultAdmin)
    const [tmpOperator, setTmpOperator] = useState(defaultOperator)

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const haveResourceAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_RESOURCE_MODULE), [accountHabilitations])

    const onValidate = () => {
        setExploited(tmpExploited)
        setNotExploited(tmpNotExploited)
        setAssociatedResource(tmpAssociatedResource)
        setIndicators(tmpIndicators)
        setAdmin(tmpAdmin)
        setOperator(tmpOperator)
    }

    return (
        <Grid container item xs={12} sx={{ padding: mdMatches ? '1rem 3rem 0.7rem' : '1rem 3rem 0.7rem', backgroundColor: mainGrey, borderTop: `solid 1px ${mainBlack}` }}>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
                <Grid container item md={7.5} xs={12}>
                    {haveResourceAccess && (
                        <Grid item xs={4}>
                            <SimpleMultiAutocomplete
                                label={i18n.resources}
                                value={tmpAssociatedResource}
                                onChange={setTmpAssociatedResource}
                                options={resources}
                                disablePortal={false}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4} sx={{ paddingLeft: haveResourceAccess && '1rem' }}>
                        <MultiContributorsAutocomplete
                            label={i18n.buildingOwner}
                            disablePortal={false}
                            options={contributors}
                            values={tmpAdmin}
                            onChange={setTmpAdmin}
                            multiple={true}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ paddingLeft: '1rem' }}>
                        <MultiContributorsAutocomplete
                            label={i18n.operator}
                            disablePortal={false}
                            options={contributors}
                            values={tmpOperator}
                            onChange={setTmpOperator}
                            multiple={true}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={4} xs={12} alignItems='center' justifyContent={mdMatches ? 'flex-end' : 'space-around'}>
                    <Option
                        selected={tmpExploited}
                        label={i18n.exploited}
                        onClick={() => setTmpExploited(!tmpExploited)}
                        xs='auto'
                        sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                    />
                    <Option
                        selected={tmpNotExploited}
                        label={i18n.notExploited}
                        onClick={() => setTmpNotExploited(!tmpNotExploited)}
                        xs='auto'
                        sx={{ padding: '0 1rem', marginTop: { md: 0, xs: '5px' } }}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' sx={{ paddingTop: !haveResourceAccess && '0.5rem' }}>
                <Grid container item xs={9.75} columnSpacing={2} alignItems='center'>
                    <Grid item>
                        <SimpleCheckbox
                            label={i18n.normal}
                            checked={tmpIndicators.includes(NORMAL)}
                            onToggle={() => setTmpIndicators(prev => prev.includes(NORMAL) ? prev.filter(p => p !== NORMAL) : [NORMAL, ...prev])}
                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: SMALL_RADIUS, backgroundColor: INDICATORS_COLORS.BLUE, color: 'white !important' }}
                        />
                    </Grid>
                    <Grid item>
                        <SimpleCheckbox
                            label={i18n.vigilance}
                            checked={tmpIndicators.includes(VIGILANCE)}
                            onToggle={() => setTmpIndicators(prev => prev.includes(VIGILANCE) ? prev.filter(p => p !== VIGILANCE) : [VIGILANCE, ...prev])}
                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: SMALL_RADIUS, backgroundColor: INDICATORS_COLORS.YELLOW, color: 'black !important' }}
                        />
                    </Grid>
                    <Grid item>
                        <SimpleCheckbox
                            label={i18n.crisis}
                            checked={tmpIndicators.includes(CRISIS)}
                            onToggle={() => setTmpIndicators(prev => prev.includes(CRISIS) ? prev.filter(p => p !== CRISIS) : [CRISIS, ...prev])}
                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: SMALL_RADIUS, backgroundColor: INDICATORS_COLORS.RED, color: 'white !important' }}
                        />
                    </Grid>
                    <Grid item>
                        <SimpleCheckbox
                            label={i18n.noData}
                            checked={tmpIndicators.includes(NO_DATA)}
                            onToggle={() => setTmpIndicators(prev => prev.includes(NO_DATA) ? prev.filter(p => p !== NO_DATA) : [NO_DATA, ...prev])}
                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: SMALL_RADIUS, backgroundColor: INDICATORS_COLORS.GREY, color: 'white !important' }}
                        />
                    </Grid>
                    <Grid item>
                        <SimpleCheckbox
                            label={i18n.notDetermined}
                            checked={tmpIndicators.includes(NO_INDICATOR)}
                            onToggle={() => setTmpIndicators(prev => prev.includes(NO_INDICATOR) ? prev.filter(p => p !== NO_INDICATOR) : [NO_INDICATOR, ...prev])}
                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: SMALL_RADIUS, backgroundColor: INDICATORS_COLORS.BLACK, color: 'white !important' }}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={2} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <MainButton
                            variant='contained'
                            sx={{ borderRadius: SMALL_RADIUS, backgroundColor: `${mainBlue} !important` }}
                            onClick={onValidate}
                        >
                            {i18n.search}
                        </MainButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

FilterPanel.propTypes = {
    defaultExploited: PropTypes.bool,
    defaultNotExploited: PropTypes.bool,
    defaultAssociatedResource: PropTypes.number,
    defaultIndicators: PropTypes.arrayOf(PropTypes.number),
    defaultAdmin: PropTypes.number,
    defaultOperator: PropTypes.number,

    setExploited: PropTypes.func,
    setNotExploited: PropTypes.func,
    setAssociatedResource: PropTypes.func,
    setIndicators: PropTypes.func,
    setAdmin: PropTypes.func,
    setOperator: PropTypes.func,
}

const DEFAULT_OPERATOR = 4 // exploitant
const DEFAULT_BUILDING_OWNER = 0 // maître d'oeuvre

const Units = () => {
    const {
        productionUnits,
        piezometersLight,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        linkedStations,
        resources,
        contributors,
        contributorsLinks,

        piezometersLightStatus,
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
        linkedStationsStatus,
        resourcesStatus,
        productionUnitsStatus,

        accountHabilitations,
    } = useSelector(store => ({
        productionUnits: store.HomeReducer.productionUnits,
        piezometersLight: store.HomeReducer.piezometersLight,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        linkedStations: store.HomeReducer.linkedStations,
        resources: store.ResourcesReducer.resources,
        contributors: store.HomeReducer.contributors,
        contributorsLinks: store.HomeReducer.contributorsLinks,

        piezometersLightStatus: store.DataManagerReducer.piezometer.piezometersLightStatus,
        piezoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus: store.DataManagerReducer.follow.hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus: store.DataManagerReducer.follow.pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus: store.DataManagerReducer.follow.qualitoObservatoryFollowResultsStatus,
        linkedStationsStatus: store.DataManagerReducer.home.linkedStationsStatus,
        resourcesStatus: store.DataManagerReducer.resources.resourcesStatus,
        productionUnitsStatus: store.DataManagerReducer.productionUnits.productionUnitsStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const [exploited, setExploited] = useState(true)
    const [notExploited, setNotExploited] = useState(false)
    const [associatedResource, setAssociatedResource] = useState()
    const [adminFilter, setAdminFilter] = useState([])
    const [operatorFilter, setOperatorFilter] = useState([])
    const [piezoObservatoryProgress, setPiezoObservatoryProgress] = useState(0)
    const [hydroObservatoryProgress, setHydroObservatoryProgress] = useState(0)
    const [pluvioObservatoryProgress, setPluvioObservatoryProgress] = useState(0)
    const [qualitoObservatoryProgress, setQualitoObservatoryProgress] = useState(0)
    const [linkedStationsProgress, setLinkedStationsProgress] = useState(0)
    const [filterIndicators, setFilterIndicators] = useLocalStorage(PRODUCTION_UNIT_INDICATORS_FILTER, [NO_INDICATOR, NORMAL, VIGILANCE, CRISIS, NO_DATA])

    const contributorTypeOperator = useApplicationSetting('contributorTypeOperator', setting => parseInt(setting) || DEFAULT_OPERATOR)
    const contributorTypeAdministrator = useApplicationSetting('contributorTypeAdministrator', setting => parseInt(setting) || DEFAULT_BUILDING_OWNER)

    const { progress, isLoaded } = useStateProgress([
        piezometersLightStatus,
        resourcesStatus,
        productionUnitsStatus,
    ])

    const { isLoaded: linkedStationsLoaded } = useStateProgress([
        linkedStationsStatus,
    ])

    const { isLoaded: observatoryResultsLoaded } = useStateProgress([
        piezoObservatoryFollowResultsStatus,
        hydroObservatoryFollowResultsStatus,
        pluvioObservatoryFollowResultsStatus,
        qualitoObservatoryFollowResultsStatus,
    ])

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.productionUnits,
        href: 'productions',
    }], [])

    const haveUnitAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PRODUCTION_MODULE), [accountHabilitations])

    useEffect(() => {
        if (!haveUnitAccess) {
            dispatch(HomeAction.logout())
            dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
        }
    }, [haveUnitAccess])

    useEffect(() => {
        dispatch(HomeAction.fetchAllContributorsLink(STATION_TYPE_CONSTANT.PRODUCTION_UNIT))
        if (!productionUnits.length) {
            dispatch(HomeAction.fetchProductionUnits())
        }
        if (!piezometersLight.length) {
            dispatch(HomeAction.fetchPiezometersLight())
        }
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }

        return () => {
            dispatch(HomeActionConstant.resetLinkedStations())
        }
    }, [])

    const fetchObsResults = (r) => {
        const ls = r.payload.flatMap((s) => new DtoAssociatedStation(s))
        if (!piezoObservatoryFollowResults.length) {
            const piezoIds = productionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.piezometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchpiezoObservatoryFollowResults({ ids: piezoIds, progressCallback: setPiezoObservatoryProgress }))
        }
        if (!hydroObservatoryFollowResults.length) {
            const hydroIds = productionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchhydroObservatoryFollowResults({ ids: hydroIds, progressCallback: setHydroObservatoryProgress }))
        }
        if (!pluvioObservatoryFollowResults.length) {
            const pluvioIds = productionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.pluviometry && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchpluvioObservatoryFollowResults({ ids: pluvioIds, progressCallback: setPluvioObservatoryProgress }))
        }
        if (!qualitoObservatoryFollowResults.length) {
            const qualitoIds = productionUnits.flatMap(p => ls.filter((l) => l.stationLinkedType === STATION_TYPE_CONSTANT.quality && l.id === p.id).map((l) => l.stationLinkedId))
            dispatch(FollowAction.fetchqualitoObservatoryFollowResults({ ids: qualitoIds, progressCallback: setQualitoObservatoryProgress }))
        }
    }

    useEffect(() => {
        if (productionUnits.length !== 0) {
            dispatch(HomeAction.fetchAllLinkedUnitsStations({ ids: productionUnits.map((p) => p.id), progressCallback: setLinkedStationsProgress })).then(r => fetchObsResults(r))
        }
    }, [productionUnits])

    const getListOfOfr = (typeName) => {
        switch (typeName) {
            case STATION_TYPE_NAME.piezometry:
                return piezoObservatoryFollowResults
            case STATION_TYPE_NAME.hydrometry:
                return hydroObservatoryFollowResults
            case STATION_TYPE_NAME.pluviometry:
                return pluvioObservatoryFollowResults
            case STATION_TYPE_NAME.quality:
                return qualitoObservatoryFollowResults
            default:
                return []
        }
    }

    const getIndicatorValue = (unitIndicators) => {
        const indicatorsColors = unitIndicators.map(({ color }) => color)
        const joinedValues = unitIndicators.map(({ value }) => (value || '').toLowerCase()).join()
        if (!unitIndicators?.length) {
            return NO_INDICATOR
        }
        if (joinedValues.includes('alerte') || indicatorsColors.some(color => ['red', 'indianred', 'darkmagenta'].includes(color))) {
            return CRISIS
        } else if (joinedValues.includes('vigilance')) {
            return VIGILANCE
        } else if (indicatorsColors.some(color => ['grey', 'gray'].includes(color))) {
            return NO_DATA
        }
        return NORMAL
    }

    const filteredUnitsByExploited = useMemo(() => [
        ...(exploited ? productionUnits.filter((p) => ![2, 3].includes(p.statusCode)) : []),
        ...(notExploited ? productionUnits.filter((p) => [2, 3].includes(p.statusCode)) : []),
    ], [exploited, notExploited, productionUnits])

    const uniqUnits = useMemo(() => orderBy(uniqWith(filteredUnitsByExploited, (l1, l2) => (l1.stationLinkedId && l1.stationLinkedId === l2.stationLinkedId) && (l1.typeName && l1.typeName === l2.typeName)), 'name'), [filteredUnitsByExploited])

    const getContributor = (up, ref, keyName) => {
        const contributorsLink = contributorsLinks.filter(cl => up.id === cl.idStation && cl.contributorType === ref && !cl.endDate)
        const findedContributors = contributors.filter(c => contributorsLink.some(cl => cl.idContributor === c.id))
        return {
            [`${keyName}s`]: findedContributors.map(c => ({
                [keyName]: (c.mnemonique || c.name),
                [`${keyName}Code`]: c.id,
            })),
        }
    }

    const formattedUnits = useMemo(() => uniqUnits.map(u => {
        const filteredLinks = filterObsLinkedStations(linkedStations).filter(s => s.code === u.code)
        const uniqLinks = uniqWith(filteredLinks, (linkA, linkB) => linkA.stationLinkedCode === linkB.stationLinkedCode && linkA.stationLinkedType === linkB.stationLinkedType)
        const indicators = uniqLinks.flatMap(l => {
            const listOfOfr = getListOfOfr(l.typeName)
            return listOfOfr.find(o => o.id === l.stationLinkedId)?.data || []
        })
        return {
            ...u,
            resource: uniqLinks.find(s => s.typeName === STATION_TYPE_NAME.resource)?.stationLinkedId,
            ...getContributor(u, contributorTypeOperator, OPERATOR),
            ...getContributor(u, contributorTypeAdministrator, BUILDING_OWNER),
            indic: getIndicatorValue(indicators),
        }
    }), [linkedStations, uniqUnits, piezoObservatoryFollowResults, hydroObservatoryFollowResults, pluvioObservatoryFollowResults, qualitoObservatoryFollowResults])

    const unitsByResources = useMemo(() => associatedResource ? formattedUnits.filter(u => u.resource === associatedResource) : formattedUnits, [associatedResource, formattedUnits])
    const unitsByAdmin = useMemo(() => adminFilter?.length ? unitsByResources.filter(u => u[`${BUILDING_OWNER}s`].some(bo => adminFilter.includes(bo[`${BUILDING_OWNER}Code`]))) : unitsByResources, [adminFilter, unitsByResources])
    const unitsByOperator = useMemo(() => operatorFilter?.length ? unitsByAdmin.filter(u => u[`${OPERATOR}s`].some(bo => operatorFilter.includes(bo[`${OPERATOR}Code`]))) : unitsByAdmin, [operatorFilter, unitsByAdmin])
    const filteredUnits = useMemo(() => unitsByOperator.filter(u => filterIndicators.includes(u.indic)), [filterIndicators, unitsByOperator])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    return (
        <>
            <FilterPanel
                defaultExploited={exploited}
                defaultNotExploited={notExploited}
                defaultAssociatedResource={associatedResource}
                defaultIndicators={filterIndicators}
                setExploited={setExploited}
                setNotExploited={setNotExploited}
                setAssociatedResource={setAssociatedResource}
                setIndicators={setFilterIndicators}
                defaultAdmin={adminFilter}
                defaultOperator={operatorFilter}
                setAdmin={setAdminFilter}
                setOperator={setOperatorFilter}
            />
            {(isLoaded && linkedStationsLoaded && observatoryResultsLoaded) ? (
                <>
                    <Grid container item xs={12}>
                        <ProductionUnitStats
                            productionUnits={filteredUnits}
                        />
                    </Grid>
                    <Grid container sx={{ padding: mdMatches ? '10px 50px' : '10px 30px' }}>
                        {filteredUnits.map((p) => (
                            <UnitCard
                                unit={p}
                                piezoObsResults={piezoObservatoryFollowResults}
                                hydroObsResults={hydroObservatoryFollowResults}
                                pluvioObsResults={pluvioObservatoryFollowResults}
                                qualitoObsResults={qualitoObservatoryFollowResults}
                            />
                        ))}
                    </Grid>
                </>
            ) : <ProgressBar title={i18n.unitsLoading} progress={(progress + piezoObservatoryProgress + hydroObservatoryProgress + pluvioObservatoryProgress + qualitoObservatoryProgress + linkedStationsProgress) / 6} />}
        </>
    )
}

export default Units
