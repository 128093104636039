import React from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { mainBlack, mainWhite, SMALL_RADIUS } from 'components/styled/Theme'
import { OSM_BACKGROUND, RELIEF_BACKGROUND, ROADMAP_BACKGROUND, SATELLITE_BACKGROUND } from 'pages/home/constants/HomeConstants'

const BackgroundTab = ({
    selectedBackground,
    setSelectedBackground = () => {},
    marge = '0.5rem',
}) => {
    const backgrounds = [{
        value: OSM_BACKGROUND,
        label: i18n.openStreetMap,
    }, {
        value: ROADMAP_BACKGROUND,
        label: i18n.roadMap,
    }, {
        value: RELIEF_BACKGROUND,
        label: i18n.relief,
    }, {
        value: SATELLITE_BACKGROUND,
        label: i18n.satellite,
    }]

    const handleChange = (event) => setSelectedBackground(event.target.value)

    return (
        <Grid container sx={{ padding: marge, fontWeight: 'bold' }}>
            <Grid item xs={12} sx={{ backgroundColor: mainBlack, color: mainWhite, height: '35px', textAlign: 'center', display: 'grid', alignItems: 'center', borderRadius: SMALL_RADIUS }}>
                <span>{i18n.backgroundLayers}</span>
            </Grid>
            <Grid container item xs={12} alignItems='center' justifyContent='space-between' sx={{ marginTop: marge, paddingLeft: '0.5rem' }}>
                <RadioGroup
                    value={selectedBackground}
                    onChange={handleChange}
                    sx={{ width: '100%' }}
                >
                    {backgrounds.map(o => (
                        <FormControlLabel
                            value={o?.value}
                            control={<Radio color='primary' />}
                            label={o?.label}
                            key={o.value}
                            labelPlacement='start'
                            sx={{
                                margin: 0,
                                justifyContent: 'space-between',
                                marginTop: '9px',
                                borderBottom: `solid 1px ${mainBlack}`,
                                paddingBottom: '9px',
                                '.MuiFormControlLabel-label': {
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    ))}
                </RadioGroup>
            </Grid>
        </Grid>
    )
}

BackgroundTab.propTypes = {
    selectedBackground: PropTypes.string,
    setSelectedBackground: PropTypes.func,
    marge: PropTypes.string,
}

export default BackgroundTab