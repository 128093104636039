import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'utils/DateUtil'
import { Chip, Grid, Icon } from '@mui/material'
import DtoCMSEvent from 'pages/cms/dto/DtoCMSEvent'
import { getNewsColor } from 'utils/ColorUtil'
import { shallowEqual, useSelector } from 'react-redux'
import { getLogin } from 'utils/LocalStorageUtils'
import { getI18nTitleData } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { LARGE_RADIUS, mainBlack } from 'components/styled/Theme'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import { mainBlue } from 'components/styled/Theme'
import CmsDialog from './CmsDialog'

const openWebSite = (link) => link && window.open(link, '_blank')

const DocumentCard = ({
    cms = {},
    little = false,
    noConsult = false,
    showTitle = true,
}) => {
    const cmsAnswersParam = useApplicationSetting('cmsReponses', setting => setting === 'true')

    const {
        cmsCategories,
    } = useSelector(store => ({
        cmsCategories: store.CmsReducer.cmsCategories,
    }), shallowEqual)

    const [isFollowed, setIsFollowed] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const category = useMemo(() => cmsCategories.find(c => c.id === cms.idCategory), [cms, cmsCategories])

    useEffect(() => {
        if (cms.id) {
            setIsFollowed(cms.cmsFollowers?.some(u => u === getLogin()))
        }
    }, [cms])

    const cmsMessages = cms.cmsMessages?.filter(c => !c.archive) || []

    const fontSize = little ? '14px' : '18px'

    return (
        <Grid
            container
            justifyContent='stretch'
            alignItems='center'
            sx={{ padding: little ? '0.5rem' : '0.5rem 1rem', margin: little && '0.5rem 0' }}
        >
            <Grid container item xs direction='column'>
                <Grid item xs={12} container justifyContent='space-between'>
                    <Grid container alignItems='center' item sx={{ fontSize, width: 'fit-content' }}>
                        {!!cms.levelContent && (
                            <Grid
                                item
                                sx={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    borderRadius: LARGE_RADIUS,
                                    backgroundColor: getNewsColor(cms.levelContent)?.toLowerCase(),
                                }}
                            />
                        )}
                        <Grid item className='bold' sx={{ paddingLeft: !!cms.levelContent && '1rem' }}>
                            {getDate(cms.dateDebut || cms.updateDate) || ''}
                        </Grid>
                        {!!isFollowed && (
                            <Grid container alignItems='center' item sx={{ paddingLeft: '0.5rem', width: 'fit-content' }}>
                                <Grid item>
                                    -
                                </Grid>
                                <Grid item sx={{ paddingLeft: '0.5rem' }}>
                                    {i18n.subscriber}
                                </Grid>
                                <Grid item sx={{ paddingLeft: '0.35rem' }}>
                                    <Icon sx={{ color: mainBlack, fontSize: '14px', fontWeight: 'bold' }} size='small'>check</Icon>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item alignItems='center' className='bold' sx={{ fontSize, width: 'fit-content' }}>
                        <Grid item>
                            {!little && (category?.title || '')}
                        </Grid>
                    </Grid>
                </Grid>
                {!!showTitle && (
                    <Grid container alignItems='center' sx={{ fontSize, marginTop: '0.5rem' }}>
                        {cms.title}
                    </Grid>
                )}
                <Grid item xs={12} sx={{ fontSize, maxWidth: '100% !important', overflowX: 'hidden', marginTop: '0.5rem' }}>
                    <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </Grid>
                <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
                    <Grid item sx={{ fontSize: '12px', padding: '0.35rem 0' }}>
                        {!!cmsAnswersParam && `${cmsMessages.length} ${getI18nTitleData(i18n.message, i18n.messages, cmsMessages)}`}
                    </Grid>
                    <Grid container item alignItems='center' sx={{ width: 'fit-content' }}>
                        {!!cms.link && (
                            <Chip
                                variant='outlined'
                                color='primary'
                                size='small'
                                label={i18n.openLink}
                                icon={<InsertLinkOutlinedIcon sx={{ transform: 'rotate(135deg)', transition: 'color .15s ease-out' }} />}
                                sx={{
                                    height: '26px',
                                    padding: '0 0.35rem',
                                    transition: 'all .25s ease-in-out',
                                    marginRight: '0.5rem',
                                    '&:hover': {
                                        backgroundColor: `${mainBlue} !important`,
                                        color: '#fff',
                                        '& svg': {
                                            color: '#fff',
                                        },
                                    },
                                }}
                                onClick={() => openWebSite(cms.link)}
                            />
                        )}
                        {!noConsult && (
                            <Chip
                                variant='outlined'
                                color='primary'
                                size='small'
                                label={i18n.consult}
                                icon={<VisibilityOutlinedIcon sx={{ transition: 'color .15s ease-out' }} />}
                                sx={{
                                    height: '26px',
                                    padding: '0 0.35rem',
                                    transition: 'all .25s ease-in-out',
                                    '&:hover': {
                                        backgroundColor: `${mainBlue} !important`,
                                        color: '#fff',
                                        '& svg': {
                                            color: '#fff',
                                        },
                                    },
                                }}
                                onClick={() => setIsOpen(true)}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <CmsDialog
                cmsId={cms.id}
                isOpenMessages={isOpen}
                closeDialog={() => setIsOpen(false)}
            />
        </Grid>
    )
}

DocumentCard.propTypes = {
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    little: PropTypes.bool,
    setIsOpenMessages: PropTypes.func,
    noConsult: PropTypes.bool,
    showTitle: PropTypes.bool,
}

export default DocumentCard