import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@mui/material'

const Progress = ({
    progress = 0,
    indeterminate = false,
}) => (
    <Grid container item xs={12} justifyContent='center' alignItems='center' sx={{ height: '90%' }}>
        <Grid item sx={{ padding: '1.5rem', backgroundColor: '#eee', borderRadius: '0.313rem' }}>
            {indeterminate ? (
                <CircularProgress sx={{ verticalAlign: 'middle' }} />
            ) : (
                <CircularProgress variant='determinate' value={!indeterminate && progress} sx={{ verticalAlign: 'middle' }} />
            )}
        </Grid>
    </Grid>
)

Progress.propTypes = {
    progress: PropTypes.number,
    indeterminate: PropTypes.bool,
}

export default Progress